// 资料库 通用函数
export default {
  data() {
    return {
      previewSrc: ''
    }
  },

  // watch: {
  //   post: {
  //     handler(newValue, oldValue) {
  //       const options = _.assign({}, this.post)
  //       // 如果page相等说明 变动的是其他数据 page 需重置
  //       if (
  //         newValue &&
  //         oldValue &&
  //         oldValue.page === newValue.page &&
  //         newValue.page !== 1
  //       ) {
  //         this.post.page = 1
  //       } else {
  //         this.getData(options)
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },

  filters: {
    /**
     * @desc 获取剩余时间
     * @param time 时间
     * @param formatTime 时间格式
     * @param completeDays  整天 是否大于1天的时候 不显示剩余时分秒
     */
    getVideoDuration(timeLeft) {
      timeLeft = timeLeft < 0 ? 0 : timeLeft
      let newFormatTime = 'dd hhmmss'

      const format = [
        { key: 'd+', value: 86400, ext: '天' },
        { key: 'h+', value: 3600, ext: '小时' },
        { key: 'm+', value: 60, ext: '分钟' },
        { key: 's+', value: 1, ext: '秒' }
      ]

      let firstTime = 0

      format.forEach(item => {
        const { key, value, ext } = item
        const v = Math.floor(timeLeft / value)
        if (new RegExp(`(${key})`).test(newFormatTime)) {
          newFormatTime = newFormatTime.replace(RegExp.$1, (firstTime && timeLeft > 0) || v > 0 ? `${v}${ext}` : '')
        }
        // 0小时0分1秒  1小时0分0秒
        firstTime = !firstTime && v > 0
        timeLeft = timeLeft - value * v
      })
      return newFormatTime
    },

    getVideoSize(value) {
      return `${Number(value)}MB`
    }
  },

  methods: {
    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },

    // 下载视频
    downLoad(url) {
      this.$store.dispatch('open', url)
    },

    // 页码变化
    pageCodeChange(value) {
      this.post = _.assign({}, this.post, { page: value })
    }
  }
}
