<template>
  <div id="copyrightprotect">
    <el-dialog
      title="数字版权保护系统"
      class="dialogVisible2"
      :visible.sync="dialogVisible2"
      width="700px"
    >
      <div class="dialogVisible2_body">
        <el-carousel
          @change="changephoto"
          :autoplay="false"
          indicator-position="outside"
        >
          <el-carousel-item v-for="item in photos" :key="item.id">
            <img :src="item.src" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="dialogVisible2_body_font">
          <div class="font1">{{ title }}</div>
          <div class="font2">
            {{ describe }}
          </div>
        </div>
        <el-button
          class="dialogVisible2_body_button"
          size="medium"
          type="primary"
          @click="kaitong"
        >
          立即免费开启
        </el-button>
      </div>
    </el-dialog>
    <openingfunction
      v-if="status"
      :dialogstatus.sync="status"
    ></openingfunction>
  </div>
</template>
<script>
import openingfunction from '@/components/copyrightprotect/openingfunction'
export default {
  name: 'copyrightprotect',
  data() {
    return {
      status: false,
      dialogVisible2: true,
      photos: [
        { id: 1, src: require('@/assets/img/1.3.8/img_tc01.png') },
        { id: 2, src: require('@/assets/img/1.3.8/img_tc02.png') },
        { id: 3, src: require('@/assets/img/1.3.8/img_tc03.png') },
        { id: 4, src: require('@/assets/img/1.3.8/img_tc04.png') },
        { id: 5, src: require('@/assets/img/1.3.8/img_tc05.png') }
      ],
      title: '防录屏三件套',
      describe:
        '版权声明+品牌水印+独家跑马灯技术，彰显品牌权威，最大程度的增加被录后的擦除难度；位置、颜色、大小等属性自由设置'
    }
  },
  components: {
    openingfunction
  },
  created() {
    const status = this.$store.state.userInfo.jg_is_drm
    const key = window.localStorage.getItem('key') || 0
    // console.log(key)
    if (key == 1 || status == 1) {
      this.dialogVisible2 = false
    } else {
      window.localStorage.setItem('key', 1)
    }
  },
  methods: {
    kaitong() {
      this.status = true
    },
    changephoto(val) {
      switch (val) {
        case 0:
          this.title = '防录屏三件套'
          this.describe =
            '版权声明+品牌水印+独家跑马灯技术，彰显品牌权威，最大程度的增加被录后的擦除难度；位置、颜色、大小等属性自由设置'
          break
        case 1:
          this.title = '专利视频加密防盗系统'
          this.describe =
            '叮叮开课独有多重加密算法，高频服务器解密验证，秒级验证，无法破解，100%保证视频安全'
          break
        case 2:
          this.title = '防账号共享'
          this.describe =
            '换IP登录预警控制、异地登录预警控制、锁定单台电脑设备观看，自动验证，自动冻结%保证视频安全'
          break
        case 3:
          this.title = '追溯维权系统'
          this.describe = '双重专利加持，100%追踪翻录视频的账号%保证视频安全'
          break
        case 4:
          this.title = '版权登记'
          this.describe =
            '全方位保护方案，专业商标顾问，免费人工查询；信息保密机制，客户信息，机构信息严格保密'
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialogVisible2 {
  ::v-deep .el-dialog {
    height: 500px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__title {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
  }
  ::v-deep .el-dialog__header {
    padding-top: 14px;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 290px;
    margin: 0;
  }
  .dialogVisible2_body_font {
    position: absolute;
    bottom: 85px;
    padding: 0px 20px;
    height: 66px;
    .font1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
    }
    .font2 {
      margin-top: 10px;
      font-size: 13px;
      color: #666666;
      line-height: 20px;
    }
  }
  .dialogVisible2_body_button {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  ::v-deep .el-carousel__indicators--outside button {
    height: 8px;
    width: 8px;
    border-radius: 6px;
    background-color: #0aa29b;
  }
  ::v-deep .el-carousel__indicators--outside {
    width: 80px;
    margin-left: 20px;
    margin-top: 100px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
    height: 290px;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
    height: 290px;
  }
}
</style>
