<template>
    <div class="container">
        <el-form ref="form" label-position="left" :rules="rules" :model="form">
            <el-form-item label="联系人姓名" prop="contacts_name" label-width="100px">
                <div class="columnBox">
                    <el-input class="inputBtn" v-model="form.contacts_name" placeholder="请输入内容"></el-input>
                    <p class="content">请填写公司运营负责人</p>
                </div>
            </el-form-item>
            <el-form-item label="联系方式" prop="contacts_phone" label-width="100px">
                <div class="columnBox">
                    <el-input class="inputBtn" v-model="form.contacts_phone" placeholder="请输入内容"></el-input>
                    <p class="content">请注意填写格式，举例（座机：0755-88880000；手机号：13823856100；400电话：4008880000）</p>
                </div>
            </el-form-item>
            <el-form-item label="经营地址" prop="contacts_address" label-width="100px">
                <div class="columnBox">
                    <el-cascader class="selectBtn" v-model="form.area" :options="city" placeholder="无"></el-cascader>
                    <el-input class="inputBtn" type="textarea" placeholder="请填写详细地址，例：12号楼8层801室"
                        v-model="form.contacts_address" maxlength="80" show-word-limit
                        :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
                    <p class="content">根据《网络监督管理办法》，依法不需办理市场主体登记的个人商家，需公示经营地址</p>
                </div>
            </el-form-item>
            <el-form-item label="经营类型" prop="contacts_type" label-width="100px" v-if="isBType">
                <div class="columnBox">
                    <el-select class="selectBtn" v-model="form.contacts_type" placeholder="请选择">
                        <el-option
                            v-for="item in BTypeOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="content">据《网络监督管理办法》，依法不需办理市场主体登记的个人商家 需公示经营类理</p>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import selectcities from '@/mixin/selectcities'
import {
    BTypeOption,
} from './mock.js'
export default {
    mixins: [selectcities],
    props: {
        isEdit: Boolean,
        info:Object,
        isBType:Boolean,
        type:String,
    },
    computed:{
        isEditCtf(){
            return this.type=='edit-person'||this.type=='edit-business'
        },
    },
    data() {
        const validateAddress = (rule, value, callback) => {
            if (!this.form.area) {
                callback(new Error('请选择市区'));
            } else if (!value) {
                callback(new Error('请填写详细地址'));
            } else {
                callback();
            }
        }
        return {
            pickerOptions: {},
            form: {
                contacts_name: '',
                contacts_phone: '',
                area: '',
                contacts_address: '',
                contacts_type: '',
            },
            rules: {
                contacts_name: [
                    { required: true, message: '请输入联系人姓名', trigger: 'blur' },
                ],
                contacts_phone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                ],
                contacts_address: [
                    { required: true, message: '请填写经营地址', trigger: 'blur' },
                    { validator: validateAddress, trigger: 'blur' },
                ],
                contacts_type: [
                    { required: true, message: '请输入经营类型', trigger: 'blur' },
                ],
            },
            options: [],

            BTypeOption: BTypeOption,
        }
    },
    created(){
    },
    watch: {
        'form.area': {
            handler() {
                this.$refs.form.validateField('address')
            },
        },
        'form.contacts_phone':{
            handler(newVal){
                this.$emit("getPhone",newVal)
            }
        },
        info:{
            handler(){
                this.setForm()
            },
            // immediate:true,
        }
    },
    methods: {
        setForm(){
            for (const key in this.form) {
                const filter = ['area','contacts_address']
                if(!filter.includes(key)) this.form[key] = this.info[key]||''
            }
            const address = this.info.contacts_address.split(" ")
            this.form = Object.assign(this.form,{
                area: address.slice(0,2),
                contacts_address: address[2],
            })
        },
        submit() {
            return new Promise((res, rej) => {
                this.$refs.form.validate((valid) => {
                    console.log(valid, this.form);
                    if (valid) {
                        res(this.form)
                    } else {
                        rej(false)
                    }
                })
            })
        },
    }
}
</script>

<style scoped lang="scss">
.container {
    .inputBtn {
        max-width: 500px;
    }

    .selectBtn {
        max-width: 230px;
    }

    .columnBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .expirationDate {}

    .content {
        font-size: 14px;
        color: #898989;
        line-height: 19px;
        text-align: left;
        font-style: normal;
    }
}
</style>