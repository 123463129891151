<template>
  <div class="posterTemplate posterTemplate3">
    <img src="https://img.dingdingkaike.com/FutipZcVwH-R0iAlnlfTasHU6Vuk?imageslim" alt="背景图" class="bg-img" />
    <div>
      <div class="block-1">
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
        <!-- <p class="t6">{{ posterData.time }}&nbsp;</p> -->
      </div>
      <div class="block-2">
        <div class="left">
          <div class="left-t">
            <img class="t1" :src="posterData.uphoto" />
            <div class="left-t-r">
              <span class="t2">{{ posterData.uname | unameStr }}</span>
              <span class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</span>
            </div>
          </div>
          <div class="left-b">
            <p>长按识别二维码</p>
            <p class="t2">{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
          </div>
        </div>
        <div class="right">
          <div class="erweima" ref="qrcode"></div>
        </div>
      </div>
      <!-- <p class="tips" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名
      {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
      <p class="jgName">{{ posterData.jg_title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate3',

  filters: {
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  
  mounted() {
    // this.$parent.createEwm(2.4 * 3, this.$refs.qrcode);
    this.$emit('createEwm', 3.1, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.posterTemplate3 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 30px;
    right: 30px;
    top: 66px;
  }
  .block-1 {
    .t4 {
      width: 242px;
      height: 135px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      height: 36px;
      margin-top: 10px;
      font-size: 13px;
      line-height: 18px;
      word-break: break-all;
    }
    .t6 {
      color: #999;
      font-size: 10px;
      margin-top: 9px;
      line-height: 18px;
    }
  }
  .block-2 {
    display: flex;
    align-items: center;
    padding: 86px 0 0 0;
    .left {
      flex: 1;
      > .left-t {
        display: flex;
        align-items: center;
        .t1 {
          width: 34px;
          height: 34px;
          border-radius: 2px;
        }
        .left-t-r {
          padding-left: 9px;
          span {
            display: block;
            line-height: 22px;
          }
          .t2 {
            color: #333;
            font-size: 10px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 125px;
          }
          .t3 {
            position: relative;
            font-size: 11px;
            color: #666;
            z-index: 0;
            line-height: 15px;
            width: 125px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .t3::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2px;
            content: '';
            z-index: -1;
            border: 1px solid #bfc5ff;
          }
        }
      }
      > .left-b {
        display: flex;
        color: #5f64e4;
        font-size: 11px;
        margin-top: 20px;
        line-height: 11px;
        font-weight: bold;
        .t2 {
          margin-left: 4px;
        }
      }
    }
    .right {
      margin-left: 3px;
      .erweima {
        width: 62px;
        height: 62px;
        padding: 1px;
        border: 2px solid #5f64e4;
      }
    }
  }
  .tips {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 143px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 15px;
    margin-left: -71.5px;
    white-space: nowrap;
    border-radius: 11px;
    background-color: #ff0000;
  }
  .jgName {
    color: #a3aaea;
    font-size: 12px;
    padding-top: 25px;
    text-align: center;
    line-height: 21px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ellipsis-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
