<template>
  <div class="textFileALL">
    <!--上传-->
    <!-- <help class="assistant-ps" text="全部图片包含所有分组的视频，图片移动到其他分组后，依然还在全部图片分组下，但不会重复占用存储容量"></help> -->
    <upload
      v-if="uploadStart"
      :leftStorage="leftStorage"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      @getJgInfo="getJgInfo"
      :list="post.group_id"
      :menuName="isMenuName"
      :typeselection="1"
      :title="'上传图片(多选)'"
      :uploadType="2"
    ></upload>
    <!--typeselection 1为可多选-->

    <div class="header">
      <div title="点击上传" class="uploading" @click="uploadvideo">
        上传图片
      </div>
      <div class="flex-center">
        <div class="store">
          <el-select v-model="post.format" placeholder="全部类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="input">
          <el-input
            size="medium"
            :placeholder="
              post.searchType == 1 || post.searchType == ''
                ? '请输入文件名称搜索'
                : '请输入上传者名称搜索'
            "
            v-model="post.search"
            class="input-with-select"
          >
            <el-select
              v-model="post.searchType"
              slot="prepend"
              placeholder="文件"
            >
              <el-option label="文件" value="1"></el-option>
              <el-option label="上传者" value="2"></el-option>
            </el-select>
            <!-- 搜索 -->
          </el-input>
        </div>
      </div>
    </div>
    <div class="contentVideo">
      <!-- 左侧菜单 -->
      <div class="leftMenu">
        <el-tree
          :data="menuList"
          node-key="id"
          :default-checked-keys="[5]"
          :props="defaultProps"
          :expand-on-click-node="false"
          :highlight-current="true"
          :check-on-click-node="true"
        >
          <span
            class="my-custom"
            slot-scope="{ node, data }"
            @click="cilckGroup(node, data)"
          >
            <div style="display: flex">
              <img
                :src="require('@ass/img/1.4.0.2/icon_wjj备份 9@2x.png')"
                class="tree-icon"
                alt=""
              />
              <!-- <i :class="[data.icon,'tree-icon']"></i> -->
              <span class="label" :title="data.name">
                {{ data.name }}
              </span>
            </div>
            <span class="span_2" style="color: rgb(165 162 162)">
              {{ data.count ? data.count : '0' }}
            </span>
          </span>
        </el-tree>
      </div>

      <div class="rightMenu">
        <!-- 右侧内容视频 列表- 分页 -->
        <div class="rightCon">
          <pagination2 :option="post" url="/DataBank/uploaded" ref="childData">
            <template v-slot:default="{ tableData }">
              <div class="pagination2Box">
                <el-table
                  ref="dialogTable_data"
                  :data="tableData"
                  @sort-change="sortChange"
                  @selection-change="handleSelectionChange"
                  :row-key="getRowkey"
                  @row-click="handleRowClick"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column
                    type="selection"
                    width="55"
                  ></el-table-column>

                  <el-table-column
                    prop="file_name"
                    label="文件"
                    min-width="120"
                  >
                    <template slot-scope="scope">
                      <div class="TitleVideo">
                        <div class="imgVideo" style="height: 30px">
                          <!-- <img
                            src="../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                            alt=""
                          /> -->
                          <el-image
                            style="width: 24px; height: 30px"
                            :src="scope.row.url | imageslim(5)"
                            fit="contain"
                            lazy
                          >
                            <div
                              slot="error"
                              class="image-slot"
                              style="text-align: center"
                            >
                              <i
                                style="margin-top: 33px"
                                class="el-icon-picture-outline"
                              ></i>
                            </div>
                          </el-image>
                        </div>
                        <div class="textVideo ">
                          <p :title="scope.row.name">
                            {{ scope.row.name }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="format" label="格式" min-width="80">
                    <template slot-scope="scope">
                      <div>{{ scope.row.format }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="file_size" label="大小" min-width="80">
                    <template slot-scope="scope">
                      <div>{{ scope.row.size }}MB</div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="add_time" label="上传时间">
                    <template slot-scope="scope">
                      {{
                        scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="user" label="上传者" min-width="100">
                    <template slot-scope="scope">
                      <div>{{ scope.row.uname }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </pagination2>

          <div class="dialog-footer">
            <el-button @click="dialogSelectBack">取 消</el-button>
            <el-button type="primary" @click="dialogSelectGo">
              确 定({{ videoIdArr.length }})
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataBase from '@/mixin/dataBase'
import upload from '@cm/base/UploadtextFile'
// import download from 'downloadjs'

import { mapState } from 'vuex'

export default {
  name: 'textFileALL',

  mixins: [dataBase],

  components: {
    upload,
  },

  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'set_name',
        count: 'count',
        icon: 'icon',
      },

      selectid: 0,

      isMenuName: '全部视频',

      videoIdArr: [], //批量移动的视频id数组

      renameVideoId: '',

      renameVideotext: '',

      isRemoveViedoId: [],

      selectMenuId: '',

      deleteMenuName: '',

      deleteMenuId: '',

      reNameid: '',

      isBg: true,

      selectedNum: 0,

      selected: '',

      menuList: [],

      menuListS: [],

      AddMenutext: '',

      renameMenutext: '',

      AddMenudialogVisible: false,

      renameMenudialogVisible: false,

      removeVideodialogVisible: false,

      renameVideodialogVisible: false,

      options: [
        {
          value: '0',
          label: '全部格式',
        },
        {
          value: 'jpg',
          label: 'jpg',
        },
        {
          value: 'jpeg',
          label: 'jpeg',
        },
        {
          value: 'png',
          label: 'png',
        },
      ],

      tableData: [],

      post: {
        type: 4,
        searchType: '1',
        search: '',
        state: '0',
        group_id: 0,
        course: [],
        order: '',
        sort: '',
        format: '0',
      },

      total: 0,

      useStorage: 0,

      jigouStorage: 0,

      leftStorage: 0,

      uploadStart: false,
    }
  },

  props: {
    // 已选择的数据，作用：去重
    datalist2: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    percentage() {
      return this.useStorage === 0
        ? 0
        : Math.floor((this.useStorage / this.jigouStorage) * 100)
    },
    ...mapState(['userInfo']),
    showDownLoadBtn() {
      return this.$store.state.roots.includes(118)
    },
    showDeleteBtn() {
      return this.$store.state.roots.includes(119)
    },
  },

  created() {
    this.getJgInfo()
    //type=3用于获取 文档 菜单
    this.getGroupList(4)
    this.post.course = this.datalist2
  },

  methods: {
    dialogSelectBack() {
      this.$emit('dialogSelectBack')
    },

    dialogSelectGo() {
      this.$parent.$parent.gbDialog_data(this.videoIdArr)
      //   this.$emit('videoIdArr', this.videoIdArr)
    },

    getRowkey(row) {
      return row.images_id
    },

    handleRowClick(row) {
      this.$refs.dialogTable_data.toggleRowSelection(row)
    },

    uploadvideo() {
      this.uploadStart = true
    },

    //下载
    // download(src, fileName) {
    //   var donwn = src + '?attname=' + fileName
    //   window.open(donwn)
    // },

    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },

    cilckGroup(node, data) {
      var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
      span_1.style.background = ''
      this.selectid = data.group_id
      this.isMenuName = data.name
      //选中菜单的id请求菜单数据
      this.post.group_id = data.group_id
    },

    //点击小点弹出的菜单传入菜单的id和第几个
    clickhoverDDD(id, index) {
      var h = this.menuList.length
      for (var i = 0; i < h; i++) {
        document.getElementsByClassName('childchildMenu')[i].style.display =
          'none'
      }
      document.getElementsByClassName('childchildMenu')[index].style.display =
        'block'
    },

    getGroupList(type) {
      //获取树形菜单数据
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: type,
          course: this.datalist2,
        },
        callback: ({ data }) => {
          this.menuList = data
          var data2 = _.merge([], data)
          data2.shift()
          this.menuListS = data2
          this.$nextTick(() => {
            var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
            span_1.style.background = '#ebf8f7'
          })
        },
      })
    },

    //多选后触发
    handleSelectionChange(val) {
      this.videoIdArr = val
    },

    // type: 类型 1 音视频 2 回放管理 3 文档
    // sort:  排序   desc 最新 asc 最旧
    // search:  搜索关键字
    getData(data) {
      this.$http({
        url: '/DataBank/uploaded',
        data,
        isMultipleSelf: 'replacePrev',
        callback: ({ data, count }) => {
          this.total = Number(count)
          this.tableData = data
        },
        error: () => {
          this.total = 0
          this.tableData = []
        },
      })
    },

    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (value.prop == 'file_size') {
        order = 1
      } else if (value.prop == 'file_name') {
        order = 3
      } else {
        order = 2
      }
      this.post.order = order
      this.post.sort = sort
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      this.getData(this.post)
      this.$refs.childData.reset()
      // //type=1用于获取 视频 菜单
      this.getGroupList(4)
    },

    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },
  },
  //过滤器
  filters: {
    getSize(val) {
      // if (val / 1024 >= 1) {
      //   return (val / 1024).toFixed(2) + 'G'
      // } else {
      return val + 'MB'
    },
    getStr(str) {
      function strlen(str) {
        var len = 0
        for (var i = 0; i < str.length; i++) {
          var c = str.charCodeAt(i)
          //单字节加1
          if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            len++
          } else {
            len += 2
          }
        }
        return len
      }
      if (strlen(str) >= 20) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.pagination2Box {
  overflow: auto;
  height: 301px;
  @extend %scroll;
}
.flip-list-move {
  transition: transform 0.35s;
}

::v-deep .dialogVisible2_class {
  &.el-dialog {
    width: 465px;
  }
}

.popover_main_center {
  // margin-bottom: 42px;
  margin-top: 28px;
  display: flex;
  align-items: center;

  .popover_main_center_font1 {
    margin-right: 21px;
    font-size: 14px;
    color: #333333;
  }

  .popover_main_center_font2 {
    text-align: center;
    font-size: 12px;
    color: #333333;
    line-height: 30px;
    width: 74px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    margin-left: 10px;
    cursor: pointer;
  }

  .noclik {
    color: #999999;
    border: 1px solid #eaeaea;
    cursor: no-drop;
  }
}

.dialogVisible2_main {
  .font1 {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

::v-deep .aaaRed {
  width: 166px;
  height: 16px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 16px;
  margin-right: 48px;
}

::v-deep .aaaRedRame {
  width: 166px;
  height: 16px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 16px;
  margin-right: 10px;
}

body {
  background: rgb(245, 245, 245) !important;
}

.el-dropdown-menu__item {
  width: 80px;
}

.adminPage {
  background: rgb(245, 245, 245);
}

::v-deep #app {
  background: rgb(245, 245, 245);
  min-height: 100%;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 16px;
    margin-right: 180px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;
  position: absolute;
  top: 0px;
  left: -60px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.VideoSizeBox {
  height: 100%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin: 10px 0;

    a {
      color: #999;
    }
  }
}

.TitleVideo {
  display: flex;
  align-items: center;
  width: 100%;

  .imgVideo {
    flex-shrink: 0;
    ::v-deep .el-icon-circle-close:before {
      content: '\E78D';
      color: #fff;
    }

    ::v-deep.el-image-viewer__mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
      background: #000;
    }
  }

  .textVideo {
    flex: 1;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      min-width: 168px;
      width: 100%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 20px;
  margin-top: 20px;

  .boxMenu::-webkit-scrollbar {
    display: none;
  }

  .fff {
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 156px;
    overflow: auto;
    height: 396px;
    overflow-x: hidden;
    border: 1px solid #e4e4e4;
    @extend %scroll;

    ::v-deep .el-tree-node__content {
      height: 36px;
    }

    ::v-deep .el-tree-node__expand-icon {
      font-size: 22px;
      display: none;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node__content:hover {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
    }

    .my-custom {
      display: flex;
      padding: 0 10px;
      height: 30px;
      width: 185px;
      justify-content: space-between;

      img {
        width: 14px;
        height: 14px;
        margin-right: 11px;
        margin-top: 7px;
      }

      .label {
        width: 98px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
      }
      .span_2 {
        line-height: 30px;
        text-align: right;
      }
    }
  }

  .rightMenu::-webkit-scrollbar {
    display: none;
  }

  .rightMenu {
    flex: 1;
    position: relative;
    padding-right: 20px;
    overflow-x: hidden;
  }
}

.textFileALL {
  background: #fff;
}

::v-deep .textFileALL .el-table {
  padding: 0 20px;
}

.header {
  display: flex;
  margin-top: 20px;
  padding-right: 20px;
  justify-content: space-between;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .store {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  ::v-deep .input {
    width: 260px;
    .el-input-group__prepend {
      background-color: #fff !important;
    }
    .el-input-group__prepend {
      width: 55px;
    }
  }
}

.btns {
  @extend %btns;
  position: relative;

  p {
    width: 136px;
  }
}

.moreRightMenuAll {
  width: 136px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  position: absolute;
  left: -45px;
  z-index: 9;
  display: none;

  div {
    height: 40px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    p {
      margin-left: 20px;
      line-height: 40px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #4a4a4a;
    }
  }
}
.dialog-footer {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
</style>
