<template>
  <div class="flex-center char-menu2">
    <span @click="select(item.id)" v-for="item in selectTime" :key="item.name" class="item" :class="{ selected: item.id == value }">{{ item.name }}</span>
  </div>
</template>
<script>
export default {
  name: 'selectTimeInterval',

  props: {
    value: {
      type: Number,
      default: 1
    },
    option: Array
  },

  data() {
    return {
      selectTime: this.option
        ? this.option
        : [
            { name: '今日', id: 1 },
            { name: '最近7天', id: 2 },
            { name: '最近30天', id: 3 },
            { name: '全部', id: 4 }
          ]
    }
  },

  methods: {
    select(id) {
      if (id != this.value) {
        this.$emit('input', id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.char-menu2 {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  .item {
    flex: 1;
    font-size: 12px;
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    color: rgba(102, 102, 102, 1);
    border-right: 1px solid rgba(229, 229, 229, 1);
    &:last-child {
      border-right: 0;
    }
    &.selected {
      color: $color;
    }
  }
}
</style>
