<template>
  <div class="promotionRecord">
    <template v-if="$route.path == '/capitalOrder' ||
      $route.name == 'editor' ||
      $route.path == '/capitalAccount'
      ">
      <div class="flexBox" style="margin: 30px 0" v-if="type == 1">
        <flexBox1 :list="headerData(headerInfo)"></flexBox1>
      </div>
      <slot name="default"></slot>
      <div class="promotionHeader">
        <div class="heard_top mt20" v-if="type != 1">
          <span>付款时间</span>
          <el-date-picker size="medium" style="width: 292px" v-model="orderTime" type="daterange" @change="paytimepick"
            value-format="timestamp" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </div>
        <div class="storeSelect mr20 mt20" v-if="type != 1">
          <el-select v-model="postRecord.price_type" placeholder="全部类型">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="storeSelect mt20" v-if="type != 1 && type == 2">
          <el-select v-model="postRecord.status" placeholder="全部状态">
            <el-option v-for="item in options_1" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>

        <el-select size="medium" v-model="postRecord.pay_way" placeholder="" class="ml20 mt20" style="width: 148px"
          v-if="type == 2">
          <el-option label="全部支付方式" value="0"></el-option>
          <el-option label="微信" value="1"></el-option>
          <el-option label="支付宝" value="2"></el-option>
        </el-select>

        <div class="input mt20" v-if="type != 1">
          <el-input :placeholder="postRecord.type == 1 ? '输入订单号搜索' : '输入付款学员名称搜索'
      " v-model="postRecord.search" class="input-with-select">
            <el-select v-model="postRecord.type" slot="prepend" placeholder="推广者">
              <el-option label="订单号" value="1"></el-option>
              <el-option label="付款学员" value="2"></el-option>
            </el-select>
          </el-input>
        </div>
        <div class="search-contain" v-if="type == 1">
          <div class="vm search-item search-item3 mr10">
            <div class="vm select">
              <el-date-picker v-model="postRecord.courseTime"
                @change="selectedTime(arguments[0], ['startTime', 'endTime'])" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" align="right" unlink-panels value-format="timestamp"
                :picker-options="pickerOptions"></el-date-picker>
            </div>
          </div>
        </div>

        <div class="heard_top ml20 mt20" v-if="isNeedPayout"> 
          <span>提现状态</span>
          <el-select v-model="postRecord.is_freeze" slot="prepend" placeholder="请选择">
            <el-option v-for="item in payoutOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex-center">
        <div class="headerMsg flex1" v-if="type != 1">
          <span>累计订单收入(元)：{{ headerInfo.price }}</span>
          <span>累计订单(笔)：{{ headerInfo.all_count }}</span>
        </div>
        <el-button v-if="type != 1" style="margin-bottom: 10px" @click="exportTable" type="primary" size="medium"
          class="mr20">
          导出全部
        </el-button>
      </div>
      <div class="contentVideo">
        <!-- 左侧菜单 -->
        <div class="rightMenu">
          <!-- 右侧内容视频 列表- 分页 -->
          <div class="rightCon">
            <pagination2 :option="postRecord" url="/electronicsData/order" ref="childBackRecord"
              @complete="completeRecord">
              <template v-slot:default="{ tableData }">
                <el-table :data="tableData.list" @sort-change="sortChange" max-height:200 :header-cell-style="{
      background: 'rgba(245,245,245,1)',
      color: '#333333',
    }">
                  <el-table-column prop="file_name" label="订单号" min-width="120" v-if="type != 1">
                    <template slot-scope="scope">
                      <div class="TitleVideo">
                        {{ scope.row.order_number }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="promoterPrice" label="金额(元)" min-width="55px" v-if="type == 1"
                    sortable="custom">
                    <template slot-scope="scope">
                      <p style="color: #f39802">
                        +{{ scope.row.actual_price }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column :label="type == 1 ? '学员' : '付款学员'" min-width="80px">
                    <template slot-scope="scope">
                      <div class="buyerBox cp" @click="studentDetail(scope.row)" title="点击跳转至学员详情页面">
                        <img :src="scope.row.uphoto" alt="" />
                        <p :title="scope.row.uname +
      (scope.row.student_remarks
        ? '(' + scope.row.student_remarks + ')'
        : '')
      " class="student_name">
                          {{
      scope.row.uname +
      (scope.row.student_remarks
        ? '(' + scope.row.student_remarks + ')'
        : '')
    }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="真实姓名">
                    <template slot-scope="{ row }">
                      {{ row.student_remarks || '-' }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="dataName" v-if="type != 3" :label="type == 1 ? '资料' : '资料名称'">
                    <template slot-scope="scope">
                      {{ scope.row.ed_name }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="buyPrice" label="售价(元)" min-width="55px" v-if="type != 1">
                    <template slot-scope="scope">
                      <p>
                        {{
      scope.row.selling_price == 0
        ? '免费'
        : scope.row.selling_price
    }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="promoterPrice" label="实付(元)" min-width="65px" v-if="type != 1"
                    sortable="custom">
                    <template slot-scope="scope">
                      <p>{{ scope.row.actual_price }}</p>
                      <div style="color: #ff3535; font-size: 12px" v-if="scope.row.is_coupon == 1">
                        使用了优惠券
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="buyTime" label="交易时间" v-if="type == 1" sortable="custom">
                    <template slot-scope="scope">
                      {{
      scope.row.order_time
      | formatTimeStamp('yyyy-MM-dd hh:mm')
    }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="buyTime" label="下单时间" sortable="custom" v-if="type != 1">
                    <template slot-scope="scope">
                      {{
      scope.row.order_time
      | formatTimeStamp('yyyy-MM-dd hh:mm')
    }}
                    </template>
                  </el-table-column>

                  <el-table-column prop="bfb" label="状态" v-if="type != 1">
                    <template slot-scope="scope">
                      <p style="text-align: left; color: #46932c" :class="{ red: scope.row.status == 1 }">
                        {{ scope.row.status == 1 ? '未支付' : '支付成功' }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column label="提现状态">
                    <template slot-scope="{ row }">
                      <div v-if="row.is_freeze == 1" style="color: #FF3F3F">
                        不可提现
                      </div>
                      <div v-else-if="row.is_freeze == 2" style="color: #45942C">
                        可提现
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="支付方式">
                    <template slot-scope="{ row }">
                      <div v-if="row.pay_way == 1">微信</div>
                      <div v-if="row.pay_way == 2">支付宝</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" fixed="right" header-align="right">
                    <template slot-scope="scope">
                      <div class="btns1 cp">
                        <p style="text-align: right; color: #0aa29b" @click="seeOrder(scope.row.edo_id)">
                          {{ type == 1 ? '查看订单' : '详情' }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
            <!--分页-->
          </div>
        </div>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
import dataBase from '@/mixin/dataBase'
import flexBox1 from '@/components/flex/flexBox1'
import { payoutOptions } from '@/utils/index'

export default {
  name: 'promotionRecord',

  mixins: [dataBase],

  components: {
    flexBox1,
  },
  props: {
    type: {
      type: Number, //默认是1在全部订单里
      default: 1,
    },
    isNeedPayout: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now()
        // },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            },
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            },
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            },
          },
        ],
      },
      options: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: '1',
          label: '付费',
        },
        {
          value: '2',
          label: '免费',
        },
      ],
      options_1: [
        {
          value: '0',
          label: '全部状态',
        },
        {
          value: '1',
          label: '未支付',
        },
        {
          value: '2',
          label: '支付成功',
        },
      ],
      payoutOptions,
      tableData: [],
      headerInfo: {},
      orderTime: '',
      postRecord: {
        type: '1',
        search: '',
        order: '',
        sort: '',
        price_type: '0',
        status: '0',
        startTime: '',
        endTime: '',
        courseTime: '',
        pay_way: '0',
        is_freeze: 0,
      },
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.postRecord.ed_id = this.$route.params.id
    }
    if (this.type == 1) {
      this.postRecord.price_type = '1'
      this.postRecord.status = '2'
    }
  },

  methods: {
    studentDetail(row) {
      let url = this.$router.resolve({
        path: `/manageStudent/detail?uid=${row.user_id}`,
      })
      this.$store.dispatch('open', url.href)
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.postRecord[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.postRecord[contextName[index]] += 86399
        }
      })
    },
    // 网校的一些基本数据
    headerData(value) {
      return [
        {
          top: '累计收入(元)',
          bottom: value.price || '0.00',
          name: Math.random(),
        },
        {
          top: '订单数量',
          bottom: value.paymentCount || '0',
          name: Math.random(),
        },
      ]
    },
    getData() { },
    seeOrder(id) {
      this.$router.push({
        path: `/capitalOrder/orderData/` + id,
      })
    },
    completeRecord(val) {
      this.headerInfo.paymentCount = val.count
      this.headerInfo = _.assign({}, val.data.find, {
        paymentCount: val.count,
      })
    },
    trimStr(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    },
    // 排序
    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      switch (value.prop) {
        case null:
          break
        case 'promoterPrice':
          order = 1
          break
        case 'buyTime':
          order = 2
          break
      }
      this.postRecord.order = order
      this.postRecord.sort = sort
    },

    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.postRecord.startTime = val[0] / 1000
      this.postRecord.endTime = val[1] / 1000 + 86399
    },

    // 导出
    exportTable() {
      const verify = this.headerInfo.paymentCount

      if (verify > 0) {
        this.$confirm(`确定要导出当前${verify}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(_.assign({}, this.postRecord))

          this.$store.dispatch(
            'open',
            `${location.origin
            }/electronicsData/exportExcel?query=${encodeURIComponent(query)}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.red {
  color: rgb(255, 53, 53) !important;
}

.redYJS {
  color: red;
}

.headerMsg {
  margin-bottom: 20px;

  span {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin: 20px;
  }
}

.ppp {
  width: 90px;
  text-align: left;
}

.zJName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assistant-ps {
  position: fixed;
  top: 18px;
  left: 253px;
  z-index: 9;
}

.el-dropdown-menu__item {
  width: 80px;
}

.isencryptionVideoTypeBg {
  background: #f0f8ff;
}

.encryptionVideoContent {
  width: 100%;
  height: 60%;

  span {
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
  }

  div {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;

    p {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 60px;
      position: relative;

      i {
        position: absolute;
        top: 21px;
        right: 0;
      }
    }
  }
}

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.isbg {
  background: #f0f8ff;
}

.footer {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-bottom: 10px;
  margin-left: 62px;

  .el-button {
    width: 136px;
    margin-top: 20px;
  }
}

.buyerBox {
  display: flex;
  justify-content: flex-start;
  height: 100%;

  .student_name {
    color: blue;
  }

  img {
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }

  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 14px;
    margin-top: 7px;
    margin-left: 10px;
  }
}

.contentVideo {
  position: relative;
  display: flex;
  height: 100%;
  padding-left: 20px;

  .fff {
    width: 250px;
    height: 45px;
    visibility: hidden;
  }

  .leftMenu {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    position: relative;
    min-height: 400px;
    height: calc(100% - 45px);

    .boxMenu {
      margin-top: 36px;
      width: 250px;

      .childMenu {
        width: 250px;
        height: 36px;
        // background: #F0F8FF;
        cursor: pointer;

        img {
          width: 15px;
          height: 14px;
          position: relative;
          top: 12px;
          left: 20px;
        }

        p {
          height: 12px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          // color: #0AA29B;
          position: relative;
          left: 40px;
          bottom: 1px;
        }

        .hoverDDD {
          display: none;
          width: 25px;
          height: 26px;
          margin-left: 210px;
          margin-top: -16px;
          position: relative;
        }

        .childchildMenu {
          position: absolute;
          z-index: 999;
          width: 100px;
          height: 88px;
          right: 0;
          box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          border: 1px solid #f7f7f7;
          display: none;

          div:hover {
            background-color: #f0f8ff;
          }

          div {
            width: 100px;
            height: 44px;
            background: #fff;
            text-align: center;

            span {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #4a4a4a;
              line-height: 44px;
            }
          }
        }
      }
    }

    .footAddMenu {
      position: absolute;
      top: 0;
      width: 250px;
      height: 36px;
      text-align: center;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 11px;
        left: 82px;
      }

      p {
        height: 13px;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 36px;
      }
    }
  }

  .rightMenu {
    flex: 1;
    position: relative;
    overflow-x: hidden;
    padding-right: 20px;
  }
}

.promotionRecord {
  background: #fff;
}

::v-deep .promotionRecord .el-table {
  padding: 0 20px;
}

.promotionHeader {
  display: flex;
  margin-bottom: 20px;
  min-width: 850px;
  padding: 0 20px 0 0;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: -10px;

  .el-input {
    width: 314px;
  }

  .storeSelect:nth-child(1) {
    width: 170px;
  }

  .storeSelect:nth-child(2) {
    width: 170px;
  }

  .el-input--medium .el-input__inner {
    height: 36px;
    line-height: 36px;
  }

  ::v-deep .input {
    margin-left: 20px;

    .el-input-group__prepend {
      width: 65px;
    }
  }

  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
}

.btns {
  @extend %btns;
  position: relative;

  p {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #1b9d97;
    line-height: 14px;
    cursor: pointer;
  }
}

/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}

.search-item {
  margin-bottom: 30px;

  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }

  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;

    .el-input__inner {
      height: 36px;
    }

    .el-input__icon {
      line-height: 36px;
    }

    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }

    .el-range-separator {
      line-height: 29px;
    }
  }
}

::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}

::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}

::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}

::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}

::v-deep .search-item1 {
  .select {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input {
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.heard_top {
  padding: 0 20px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;

  span {
    margin-right: 24px;
    min-width: 56px;
  }

  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }

  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }

    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
</style>
