<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-09-22 11:09:21
 * @LastEditTime: 2023-03-03 16:57:17
 * @LastEditors: cyy 1346967714@qq.com
 * @Description:录播-》管理-》章节管理=》模式修改
 * @FilePath: \teacherdingding.com\src\components\course\modifyMode.vue
-->
<template>
  <div class="modifyMode">
    <el-dialog
      title="修改章节模式"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="解锁模式">
            <el-radio-group v-model="form.chapter_mode">
              <div class="mb30">
                <el-radio label="1">自由模式</el-radio>
                <div class="tip">无任务观看限制，学生可随意观看</div>
              </div>
              <div class="mb30">
                <el-radio label="2">闯关模式</el-radio>
                <div class="tip">
                  完成学习条件后自动解锁下一章节（学习条件在编辑中设置）
                </div>
              </div>
              <div>
                <el-radio label="3">日期模式</el-radio>
                <div class="tip">指定解锁时间或指定解锁周期</div>
                <div class="type_contain" v-if="form.chapter_mode == 3">
                  <div
                    class="item"
                    @click="selectType(1)"
                    :class="{ selectStyle: form.type == 1 }"
                  >
                    <div
                      class="top"
                      :style="
                        form.type == 1 ? 'color: #0aa29b  !important' : ''
                      "
                    >
                      指定解锁时间
                    </div>
                    <div
                      class="bottom"
                      :style="form.type == 1 ? 'color: #0aa29b !important' : ''"
                    >
                      原定时观看功能
                    </div>
                  </div>
                  <div
                    class="item"
                    @click="selectType(2)"
                    :class="{ selectStyle: form.type == 2 }"
                  >
                    <div
                      class="top"
                      :style="
                        form.type == 2 ? 'color: #0aa29b  !important' : ''
                      "
                    >
                      指定解锁周期
                    </div>
                    <div
                      class="bottom"
                      :style="
                        form.type == 2 ? 'color: #0aa29b  !important' : ''
                      "
                    >
                      每 x 天/周 y 解锁1个章节
                    </div>
                  </div>
                </div>
                <div
                  class="fs14 redcolor mt30"
                  v-if="form.type == 1 && form.chapter_mode == 3"
                >
                  提示：可在每一节设置解锁时间
                </div>
                <template v-if="form.type == 2 && form.chapter_mode == 3">
                  <div class="mt10">
                    <span class="title">固定开课时间</span>
                    <el-date-picker
                      size="medium"
                      v-model="form.class_time"
                      type="datetime"
                      placeholder="选择日期时间"
                    ></el-date-picker>
                  </div>
                  <div class="mt10">
                    <span class="title" style="margin-right:30px">
                      解锁周期
                    </span>
                    <span class="title">每</span>
                    <el-input-number
                      size="medium"
                      style="width:158px"
                      v-model="form.unlock_cycle_day"
                      controls-position="right"
                      :min="1"
                      :max="100"
                      :step-strictly="true"
                      :step="1"
                    ></el-input-number>
                    <!-- <span class="title ml10">天</span> -->
                    <el-select
                      v-model="form.time_type"
                      class="select"
                      style="width:50px;margin-left: 5px;"
                    >
                      <el-option label="天" value="1"></el-option>
                      <el-option label="周" value="2"></el-option>
                    </el-select>
                    <el-time-picker
                      v-model="form.unlock_cycle_second"
                      size="medium"
                      placeholder="请选择"
                    ></el-time-picker>
                    <span class="title ml10">解锁1章节</span>
                  </div>
                </template>
              </div>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'modifyMode',

  data() {
    return {
      form: {
        type: 1,
        chapter_mode: '1',
        class_time: '',
        unlock_cycle_day: 1,
        unlock_cycle_second: '',
        time_type: '1',
      },
    }
  },

  props: {
    modifyModeData: {
      type: Object,
      default: () => {},
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.form = _.assign({}, this.modifyModeData, {
      class_time: this.modifyModeData.class_time
        ? this.modifyModeData.class_time * 1000
        : '',
      unlock_cycle_second: this.modifyModeData.unlock_cycle_second
        ? this.modifyModeData.unlock_cycle_second * 1000
        : '',
    })
  },

  methods: {
    selectType(val) {
      this.form.type = val
    },

    async submit() {
      if (this.form.chapter_mode == 3 && this.form.type == 2) {
        if (!this.form.class_time) {
          this.$root.prompt('请选择固定开课时间')
          return
        }

        if (!this.form.unlock_cycle_day) {
          this.$root.prompt('请输入解锁周期')
          return
        }

        if (!this.form.unlock_cycle_second) {
          this.$root.prompt('请选择时间')
          return
        }
      }

      this.form = _.assign({}, this.form, {
        course_id: this.$route.query.course_id,
        class_time: this.form.class_time / 1000,
        unlock_cycle_second: this.form.unlock_cycle_second / 1000,
      })
      await this.$http({
        url: '/Course/setChapterMode',
        data: this.form,
        successMsg: true,
      })
      this.$emit('modeSubmit')
      this.cancel()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.modifyMode {
  ::v-deep .select {
    .el-input__inner {
      border: 0;
      padding: 0 5px;
    }
  }
  .title {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .selectStyle {
    border-color: #0aa29b !important;
  }
  ::v-deep .el-form-item__label {
    line-height: 1.5;
  }

  ::v-deep .el-radio__label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }

  .tip {
    font-size: 12px;
    font-weight: 400;
    color: #6a6a6a;
    margin: 10px 0 0 20px;
  }

  .type_contain {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .item {
      cursor: pointer;
      width: 158px;
      height: 58px;
      border-radius: 4px;
      border: 1px solid #cecece;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .top {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .bottom {
        font-size: 12px;
        font-weight: 400;
        color: #6a6a6a;
        margin-top: 8px;
      }
    }
  }
}
</style>
