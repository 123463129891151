<!--
 * @Descripttion: 课程列表里的单独一项
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-20 15:14:28
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 09:17:22
 -->
<template>
  <div>
    <pagination2 ref="inmychild" :option="post2" url="/Course/courseAdmin">
      <template v-slot:default="{ tableData }">
        <el-table
          @sort-change="sortChange"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="课程名称" min-width="190" fixed="left">
            <template slot-scope="{ row }">
              <div class="course-info">
                <div class="cover">
                  <lazy class="img" :src="row.photo | imageslim"></lazy>
                </div>
                <div class="right">
                  <div class="title">{{ row.name }}</div>
                  <div class="price">
                    <span class="vm p1" v-if="row.price_type == 2">
                      免费
                      <i
                        class="costprice"
                        v-if="row.costprice && row.costprice > 0"
                      >
                        ￥{{ row.costprice }}
                      </i>
                    </span>
                    <span class="vm p1" v-if="row.price_type == 1">
                      ￥{{ row.price }}
                      <i
                        class="costprice"
                        v-if="row.costprice && row.costprice > 0"
                      >
                        ￥{{ row.costprice }}
                      </i>
                    </span>
                    <span class="vm p1" v-if="row.price_type == 3">
                      密码：{{ row.fee_pwd }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="schedule_num"
            label="章节"
            min-width="53"
          ></el-table-column>
          <!-- <el-table-column
            prop="sale_num"
            label="订单"
            min-width="66"
            sortable="custom"
          ></el-table-column> -->
          <el-table-column
            label="浏览次数"
            prop="views"
            min-width="94"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="订单"
            sortable="custom"
            min-width="93"
            prop="totalsales"
          ></el-table-column>
          <el-table-column
            label="累计收入(元)"
            min-width="137"
            prop="courseprice"
            sortable="custom"
          >
            <template slot="header">
              <div
                class="flex-center hidetext"
                style="float: left; margin-top: 5px"
              >
                累计收入(元)
                <helpIcon
                  v-if="post2.course_type == 1"
                  style="margin-left: 6px"
                  prompt="该课程的累计收入，包括已作废、已过期和打赏送礼的金额"
                ></helpIcon>
                <helpIcon
                  v-else
                  style="margin-left: 6px"
                  prompt="该课程的累计收入，包括已作废、已过期的金额"
                ></helpIcon>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="展示到网校" min-width="95">
            <template slot-scope="{ row }">
              <span v-if="row.is_show == 1">已开启</span>
              <span v-if="row.is_show == 2" style="color: #ff3535">已关闭</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="94">
            <template slot-scope="{ row }">
              {{ row.createtime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column label="状态" min-width="54px">
            <template slot-scope="{ row }">
              <span v-if="row.status == 1">已上架</span>
              <span v-else style="color: #ff3535">已下架</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="154" fixed="right">
            <template slot-scope="{ row, $index }">
              <div class="function">
                <span
                  class="vm btn"
                  @click="toEdit(row, 2)"
                  v-if="list.namagerBtn"
                >
                  管理
                </span>
                <span class="vm space" v-if="list.namagerBtn"></span>
                <span class="vm btn" @click="getShareInfo(row)">分享</span>
                <span
                  class="vm space"
                  v-if="list.upDownBtn || list.delBtn"
                ></span>
                <!-- <span
                  class="vm btn"
                  @click="toSection(row)"
                  v-if="post2.course_type != 3 && post2.course_type != 4"
                >
                  上课
                </span>
                <span
                  class="vm space"
                  v-if="post2.course_type != 3 && post2.course_type != 4"
                ></span> -->
                <el-dropdown v-if="list.upDownBtn || list.delBtn">
                  <span class="vm btn">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-if="isJiGouAdmin && list.upDownBtn"
                      @click.native="doControlCourse(row, $index)"
                    >
                      {{ row.status == 1 ? '下架' : '上架' }}课程
                    </el-dropdown-item>
                    <!-- <el-dropdown-item
                      :disabled="row.course_type == 4"
                      @click.native="toEdit(row, 7)"
                    >
                      数据分析
                    </el-dropdown-item> -->
                    <el-dropdown-item
                      v-if="isJiGouAdmin && list.delBtn"
                      @click.native="doDelete(row, $index)"
                    >
                      删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <CourseConfirm
      v-if="downId"
      title="下架课程"
      :isSmallPop="true"
      @cancelFn="toggleShowDownOrUp"
      @save="getDownOrUp(downId, pos, 2)"
    >
      <h4 class="reset-txt">
        下架后已购买的用户仍可以查看，未购买的用户将无法购买，确认下架吗？
      </h4>
    </CourseConfirm>
    <CourseConfirm
      v-if="delId"
      title="删除课程"
      :isSmallPop="true"
      @cancelFn="toggleShowDel"
      @save="getDel(delId, pos, checked)"
    >
      <h4 class="reset-txt">删除后，已购买的用户无法查看，是否删除该课程？</h4>
      <div class="checkbox-wrap" v-show="currentCourseType == 1">
        <el-checkbox label="同时删除回放" v-model="checked"></el-checkbox>
        <!-- <p v-if="checked">删除后无法恢复，请慎重操作</p> -->
      </div>
    </CourseConfirm>
    <!--分享弹窗-->
    <sharePop
      v-if="shareId"
      @closeFn="toggleShareId"
      :shareInfo="shareInfo"
    ></sharePop>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import helpIcon from '@/components/helpIcon'
import CourseConfirm from '@/components/CourseConfirm'
import sharePop from '@/components/coursePop/sharePop'
import API from '@/request/api'

export default {
  name: 'CourseItem',

  props: {
    parentPath: [String],
    post2: [Array, Object],
    list: [Object],
  },

  data() {
    return {
      isShowDownOrUp: true,

      downId: '',

      course_id: '',

      delId: '',

      shareId: '',

      checked: false,

      shareInfo: {},

      // 点击删除时当前course_type
      currentCourseType: '',

      pos: '',
    }
  },

  computed: {
    ...mapState(['isWindowsApp']),
    ...mapGetters(['isJiGouAdmin']),
  },

  components: {
    helpIcon,
    CourseConfirm,
    sharePop,
  },

  methods: {
    //排序
    sortChange(val) {
      this.post2.sort = !val.order
        ? ''
        : val.order === 'ascending'
        ? 'ASC'
        : 'DESC'
      this.post2.order = !val.order ? '' : val.prop
    },

    // 进入管理页面 路由带参数
    toEdit(info, index) {
      this.$router.push({
        path: `${this.parentPath}/EditCourse`,
        query: {
          course_id: info.course_id,
          course_type: info.course_type,
          index: typeof index === 'number' ? '' + index : '2',
        },
      })
    },

    toSection(info) {
      this.$router.push({
        path: `${this.parentPath}/EditCourse`,
        query: {
          course_id: info.course_id,
          index: 2,
          course_type: info.course_type,
        },
      })
    },

    // 删除课程弹窗
    toggleShowDel() {
      this.delId = ''
      this.currentCourseType = ''
      this.checked = false
    },

    // 重新获取数据
    courseListReset() {
      this.$refs.inmychild.reset()
    },

    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },
    
    // 获取分享课程数据
    getShareInfo({ course_id }) {
      const self = this
      self.$http({
        name: API.coursePosterShare_Api.name,
        url: API.coursePosterShare_Api.url,
        data: {
          course_id,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.shareId = course_id
            self.shareInfo = data
          }
        },
      })
    },

    // 删除课程
    getDel(course_id, pos, checked) {
      const self = this
      self.$http({
        name: API.delCourse_Api.name,
        url: API.delCourse_Api.url,
        data: {
          course_id,
          del_record: checked ? 1 : 2,
        },
        callback(res) {
          if (res.code == 200) {
            self.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            self.toggleShowDel()
            self.courseListReset()
          }
        },
      })
    },

    doDelete({ course_id, course_type }, index) {
      this.pos = index
      this.delId = course_id
      this.currentCourseType = course_type
    },

    // 上下架课程
    doControlCourse({ course_id, status }, index) {
      this.pos = index
      if (status == 1) {
        this.downId = course_id
      } else {
        this.getDownOrUp(course_id, this.pos, 1)
      }
    },

    toggleShowDownOrUp() {
      this.downId = ''
    },

    // 上下架课程
    getDownOrUp(course_id, pos, status) {
      const self = this
      self.$http({
        name: API.isShelves_Api.name,
        url: API.isShelves_Api.url,
        data: {
          course_id,
          status,
        },
        callback(res) {
          if (res.code == 200) {
            self.$root.prompt({
              msg: status == 2 ? '下架成功' : '上架成功',
              type: 'success',
            })
            self.toggleShowDownOrUp()
            self.courseListReset()
          }
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.el-table th > .cell {
  padding-left: 5px;
}
/deep/ .el-table .cell {
  padding: 5px;
}
</style>
<style lang="scss" scoped>
.course-info {
  display: flex;
  .cover {
    width: 68px;
    height: 38px;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    margin-left: 8px;
    .title {
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;
      color: rgba(51, 51, 51, 1);
    }
    .price {
      margin-top: 6px;
      .p1 {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        margin-right: 15px;
        color: rgba(255, 53, 53, 1);
        i {
          margin-left: 20px;
          color: #ccc;
        }
        .costprice {
          margin-left: 10px;
          color: #999;
          text-decoration: line-through;
        }
      }
    }
  }
}
.type,
.teacher,
.count,
.sigUp {
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: rgba(51, 51, 51, 1);
}
.function {
  ::v-deep .el-dropdown {
    vertical-align: middle;
  }
  .btn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: rgba(10, 162, 155, 1);
  }
  .space {
    width: 1px;
    height: 12px;
    margin: 0 8px;
    border-right: 1px solid rgba(10, 162, 155, 1);
  }
}
.checkbox-wrap {
  position: absolute;
  left: 20px;
  bottom: 7px;
  &.is-choose {
    bottom: 20px;
  }
  ::v-deep .el-checkbox {
    margin-bottom: 7px;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0aa29b;
    border-color: #0aa29b;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #0aa29b;
  }
  p {
    font-size: 12px;
    color: #ff3535;
  }
}
.reset-txt {
  padding: 10px 20px 0 20px;
  margin-bottom: 70px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
</style>
