<template>
  <div id="selectData">
    <div class="selectCourse">
      <el-button type="primary" class="mr20" @click="chooseData" style="width: 100px" size="medium">
        选择资料
      </el-button>

      <span class="sp_1">
        提示：关联资料后会在课程详情的【资料】模块展示，报名当前课程后，同时获得已关联的资料
      </span>
    </div>
    <!-- {{ datalist }} -->
    <div class="had_course_list">
      <div style="padding: 12px 90px 12px 0;font-size: 14px;color: #333333;font-weight: bold;background: #f5f5f5;">
        <el-row type="flex" class="row-bg">
          <el-col :span="8">文件</el-col>
          <el-col :span="3">格式</el-col>
          <el-col :span="3">文件大小</el-col>
          <el-col :span="3">时长</el-col>
          <el-col :span="7" class="text-center">用户权限设置</el-col>
        </el-row>
      </div>
      <transition-group class="ul" tag="ul" name="flip-list">
        <li class="item" v-for="(item, index) in datalist" :key="item.file_id">
          <!-- <div class="tu_name">
            <div class="tu">
              <img :src="item.img" alt="" />
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
          <div class="course_type">{{ item.format }}</div>
          <div class="price">{{ item.size }}MB</div> -->
          <el-row type="flex" class="" style="width: 100%;height: 36px;display: flex;align-items: center;">
            <el-col :span="8" class="overtext">
              <span>{{ item.name }}</span>
            </el-col>
            <el-col :span="3">{{ item.format }}</el-col>
            <el-col :span="3">{{ item.size }}MB</el-col>
            <el-col :span="3">-</el-col>
            <el-col :span="7">
              <el-radio-group v-model="item.purview_type" @input="setPurviewType(item)">
                <el-radio :label="1">预览及下载</el-radio>
                <el-radio :label="2">仅预览</el-radio>
                <!-- <el-radio :label="3">仅下载</el-radio> -->
              </el-radio-group>
            </el-col>
          </el-row>
          <div class="btns">
            <div class="no_upper" v-if="index == 0">上移</div>
            <div v-else class="cp" @click="upDown(index, index - 1)">上移</div>
            <div class="driver">|</div>

            <div class="no_downer" v-if="index == datalist.length - 1">
              下移
            </div>
            <div v-else class="cp" @click="upDown(index, index + 1)">下移</div>
            <div class="driver">|</div>
            <el-popconfirm title="确定移除该内容？" @confirm="removeSell(item)">
              <div class="cp" slot="reference">移除</div>
            </el-popconfirm>
          </div>
        </li>
      </transition-group>
    </div>

    <!-- 资料选择 -->
    <data-Upload-Select :datalist2="datalist" ref="dataUploadSelect" @dataList="dataList"></data-Upload-Select>

  </div>
</template>

<script>
import dataUploadSelect from '@/components/base/dataUploadSelect/index2'
export default {
  name: 'selectData',
  data() {
    return {
      dialogSelectVisible: false,
      datalist: [],
    }
  },

  mounted() {
    this.getCourseSell()
  },

  components: { dataUploadSelect },

  filters: {
    getCoursetype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '系列课'
          break
      }
      return text
    },
  },

  methods: {
    //   获取文件
    dataList(val) {
      this.datalist.push(...val)
      this.$http({
        url: '/course/addCourseSell',
        data: {
          type: 2,
          course_id: this.$route.query.course_id,
          course: val,
        },
        callback: () => {
          this.getCourseSell()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },

    cancelDialog_data() {
      this.dialogSelectVisible = false
    },

    //  选择文件
    chooseData() {
      this.$refs.dataUploadSelect.chooseCourse_data()
    },

    // 上移下移
    upDown(index, newIndex) {
      const oldData = this.datalist[newIndex]
      this.datalist.splice(newIndex, 1, this.datalist[index])
      this.datalist.splice(index, 1, oldData)

      this.$http({
        url: '/course/moveSell',
        data: {
          id1: this.datalist[index].id,
          id2: this.datalist[newIndex].id,
          type: 2,
        },
        callback: () => { },
      })
    },

    // 移除关联的课程
    removeSell(val) {
      this.$http({
        url: '/course/removeSell',
        data: {
          type: 2,
          id: val.id,
        },
        callback: () => {
          this.getCourseSell()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },

    // 获取已经关联的数据
    getCourseSell() {
      this.$http({
        url: '/course/getCourseSell',
        data: {
          type: 2,
          course_id: this.$route.query.course_id,
        },
        callback: ({ data }) => {
          this.datalist = data.map(v=>Object.assign({},v,{purview_type:Number(v.purview_type)}))
        },
      })
    },

    // 修改预览和下载的用户权限
    setPurviewType(data){
      this.$http({
        url: '/course/setPurviewType',
        data,
        callback: () => {
          this.getCourseSell()
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.overtext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flip-list-move {
  transition: transform 0.35s;
}

#selectData {
  img {
    width: 100%;
    height: 100%;
  }

  .selectCourse {
    margin-bottom: 20px;

    .sp_1 {
      font-size: 12px;
      color: #333333;
      line-height: 12px;
    }
  }

  .had_course_list {
    .ul {
      .item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 13px;

        // .tu_name {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   margin-right: 10px;

        //   .tu {
        //     width: 16px;
        //   }

        //   .name {
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        //     color: #333333;
        //     margin-left: 10px;
        //   }
        // }

        // .course_type {
        //   margin-right: 10px;
        //   color: #333333;
        // }

        // .price {
        //   width: 80px;
        //   margin-left: 20px;
        //   margin-right: 10px;
        //   color: #333333;
        // }

        .btns {
          display: flex;
          color: $color;

          .no_upper,
          .no_downer {
            color: #999999;
          }

          .driver {
            margin: 0 5px;
          }
        }
      }
    }
  }
}
</style>
