<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-08 15:18:40
 * @LastEditTime: 2022-10-08 18:59:57
 * @LastEditors: cyy
 * @Description: 文档 图片 资料   选择
 * @FilePath: \teacherdingding.com\src\components\base\dataUploadSelect\index2.vue
-->
<template>
  <div id="dataUploadSelect">
    <el-dialog
      v-if="dialogSelectVisible_data"
      title="选择资料"
      width="850px"
      :visible="dialogSelectVisible_data"
      @update:visible="v => $emit('update:dialogSelectVisible_data', v)"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancelDialog_data"
    >
      <div slot="title" class="title">添加资料</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="文档" name="2"></el-tab-pane>
        <el-tab-pane label="图片" name="4"></el-tab-pane>
        <el-tab-pane label="课件" name="7"></el-tab-pane>
      </el-tabs>

      <dialog-selectdata
        v-if="activeName == 2"
        @group_id="group_id"
        ref="dialogRef_data"
        :type="2"
        @uploadData="uploadStart = true"
        :isShangchuang="true"
      ></dialog-selectdata>

      <image-data
        v-if="activeName == 4"
        :datalist2="datalist2"
        @dialogSelectBack="cancelDialog_data"
      ></image-data>

      <course-file
        v-if="activeName == 7"
        :datalist2="datalist2"
        @dialogSelectBack="cancelDialog_data"
      ></course-file>
    </el-dialog>
    <upload
      v-if="uploadStart"
      :leftStorage="leftStorage"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      @getJgInfo="getJgInfo"
      :list="groupid"
      :typeselection="1"
      :title="'上传文档(多选)'"
    ></upload>
  </div>
</template>

<script>
import imageData from './components/image'
import upload from '@cm/base/UploadtextFile'
import courseFile from './components/courseFile'
import dialogSelectdata from '@/components/base/dialogSelectData'
export default {
  name: 'dataUploadSelect',

  components: {
    dialogSelectdata,
    upload,
    imageData,
    courseFile,
  },

  data() {
    return {
      activeName: '2',
      groupid: '',
      leftStorage: 0,
      uploadStart: false, //上传弹窗控制
      dialogSelectVisible_data: false,
      dataList: [],
    }
  },

  props: {
    // 已选择的数据，作用：去重
    datalist2: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    group_id(val) {
      this.groupid = val
    },
    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },
    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      this.$refs.dialogRef_data.reset()
      // this.getJigouTeacher()
    },
    complete({
      //上传成功后
      serverId = '',
      file_size = '',
      file_name = '',
      type = '',
      format = '',
    }) {
      const option = {
        sort: this.sum,
        file_id: serverId,
        format: format,
        name: file_name,
        size: this.getFileSize(file_size),
        type: type,
      }
      // if (this.$store.state.userInfo.jg_is_drm == 1) {
      //   this.getSelectlist()
      // }
      this.firstList.push(option)
      this.sum++
    },

    completelist(val) {
      this.complete(val)
    },

    handleClick(val) {
      console.log(val.name)
      if (val.name == 2) {
        this.chooseCourse_data()
      }
    },

    //选择课程
    chooseCourse_data() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible_data = true
      this.activeName = '2'
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef_data.getMenu_data(_this.datalist2, _this.dataId)
        _this.$refs.dialogRef_data.clearSelect_data()
      })
    },

    cancelDialog_data() {
      this.dialogSelectVisible_data = false
    },

    gbDialog_data(list) {
      // console.log(list)
      //搭配组件使用的方法,关闭弹出框----------------
      this.dialogSelectVisible_data = false

      const val = []
      list.forEach(value => {
        val.push(Object.assign({}, value))
      })
      this.dataList = val
      this.$emit('dataList', this.dataList)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px !important;
}
#dataUploadSelect {
  .title {
    font-size: 14px;
    color: #666666;
  }
}
</style>
