<template>
  <div class="order-contain">
    <template>
      <div class="all">
        <div class="heard_top">
          <span>关键词</span>
          <el-input
            size="medium"
            :placeholder="
              post1.searchType == 2 ? '输入学员名称搜索' : '输入订单号搜索'
            "
            v-model="post1.search"
            class="input-with-select"
          >
            <el-select
              v-model="post1.searchType"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options4"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-input>

          <span class="ml30">订单类型</span>
          <el-select
            size="medium"
            v-model="post1.order_type"
            placeholder=" "
            style="width: 160px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <span class="ml30">订单状态</span>
          <el-select
            size="medium"
            v-model="post1.status"
            style="width: 160px"
            placeholder=" "
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>

        <div class="heard_top">
          <span>付款时间</span>
          <!-- :picker-options="pickerOptions" -->
          <el-date-picker
            size="medium"
            style="width: 292px"
            v-model="value1"
            type="daterange"
            @change="paytimepick"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>

          <span class="ml30">失效时间</span>
          <el-date-picker
            size="medium"
            @change="invalidpick"
            value-format="timestamp"
            style="width: 292px"
            v-model="value10"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>

        <div class="heard_top">
          <el-button
            type="primary"
            style="width: 100px"
            class="mr20"
            size="medium"
            @click="screen_click"
          >
            筛选
          </el-button>
          <el-button type="text" size="medium" @click="clear_select">
            清除所有筛选项
          </el-button>
        </div>

        <div class="export">
          <div class="count_money flex-center">
            <div class="mr40">累计收入(元)：{{ alltableData.allPrice }}</div>
            <div>累计订单(笔)：{{ alltableData.count }}</div>
          </div>
          <div>
            <el-button
              type="primary"
              @click="exportTable(1)"
              size="medium"
              v-if="selected.length > 0"
              style="margin-right: 16px"
            >
              导出选中
            </el-button>
            <el-button
              v-if="showExportBtn"
              @click="exportTable(2)"
              type="primary"
              size="medium"
              class="mr20"
            >
              导出全部
            </el-button>
          </div>
        </div>

        <div class="clear"></div>

        <div class="table-contain">
          <pagination2
            ref="child"
            :option="searchOption"
            url="/order/allOrder"
            @complete="alltableData2"
          >
            <template v-slot:default="{ tableData }">
              <el-table
                @sort-change="sortChange"
                @selection-change="handleSelectionChange"
                @select-all="selectAll"
                :data="tableData"
                ref="tablelist"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column type="selection" width="42"></el-table-column>

                <el-table-column label="订单号" prop="number"></el-table-column>

                <el-table-column label="付费学员">
                  <template slot-scope="{ row }">
                    <div style="display: flex">
                      <div>
                        <div class="stundent_photo">
                          <img
                            :src="row.uphoto"
                            width="24px"
                            height="24px"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="student_name">
                        {{ row.uname }}
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="真实姓名">
                  <template slot-scope="{ row }">
                    {{ row.student_remarks || '-' }}
                  </template>
                </el-table-column>

                <el-table-column label="课程" min-width="90px">
                  <template slot-scope="{ row }">
                    <div class="course-info">
                      <!-- <div class="cover">
                        <lazy class="img" :src="row.course_img"></lazy>
                      </div> -->
                      <div class="right">
                        <div class="title">{{ row.course_name }}</div>
                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="售价(元)"
                  prop="costprice"
                  min-width="90"
                ></el-table-column>

                <el-table-column
                  min-width="90"
                  label="实付(元)"
                  prop="courseprice"
                >
                  <template slot-scope="{ row }">
                    <div>￥{{ row.courseprice }}</div>
                    <div
                      style="color: #ff3535; font-size: 12px"
                      v-if="row.cou_id > 0"
                    >
                      使用了优惠码
                    </div>
                    <div
                      style="color: #ff3535; font-size: 12px"
                      v-if="row.order_type == 3"
                    >
                      {{ row.exchange_details_id==0?'使用了兑换码':'使用了提取码' }}
                    </div>
                    <div
                      style="color: #ff3535; font-size: 12px"
                      v-if="row.order_type == 2"
                    >
                      参与了拼团
                    </div>
                    <div
                      style="color: #ff3535; font-size: 12px"
                      v-if="row.fission_id > 0"
                    >
                      参与了裂变活动
                    </div>
                    <div
                      style="color: #ff3535; font-size: 12px"
                      v-if="row.is_coupon == 1"
                    >
                      使用了优惠券
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="付款/失效时间"
                  min-width="120"
                  prop="validity"
                >
                  <template slot="header">
                    <div class="flex-center" style="float: left">
                      <span>付款/失效时间</span>
                      <helpIcon
                        style="margin-left: 6px"
                        prompt="指学员观看课程的有效时间，创建课程时可设置有效期，也可以针对不同订单修改"
                      ></helpIcon>
                    </div>
                  </template>

                  <template slot-scope="{ row }">
                    <div>
                      {{ row.orderTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                    </div>
                    <div>
                      {{ row.validity | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="剩余(天)"
                  min-width="100"
                  sortable="custom"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.days_remaining >= 0">
                      {{ row.days_remaining }}
                    </span>
                    <span v-else>--</span>
                  </template>
                </el-table-column>

                <el-table-column label="状态">
                  <template slot-scope="{ row }">
                    <div v-if="row.status == 1" style="color: #ff3535">
                      未支付
                    </div>
                    <div v-if="row.status == 2" style="color: #46932c">
                      支付成功
                    </div>
                    <div v-if="row.status == 3" style="color: #ff3535">
                      已失效
                    </div>
                    <div v-if="row.status == 4" style="color: #ff3535">
                      已作废
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" fixed="right" min-width="130px">
                  <template slot-scope="{ row }">
                    <div class="btns">
                      <div class="fl">
                        <span class="text" @click="detail(row)">详情</span>
                      </div>

                      <div v-if="row.status == 2 && showValidityBtn" class="fl">
                        <el-divider direction="vertical"></el-divider>
                        <span class="text" @click="edit_time(row)">
                          编辑有效期
                        </span>
                        <el-divider direction="vertical"></el-divider>
                      </div>

                      <div class="fl" v-if="row.status == 1">
                        <el-divider direction="vertical"></el-divider>
                        <span class="text" @click="del(row.order_id)">
                          删除
                        </span>
                      </div>
                      <div class="fl" v-if="row.status == 2 && showUnsetBtn">
                        <span
                          class="text"
                          @click="zuofei(row.$index, row.order_id)"
                        >
                          作废
                        </span>
                      </div>

                      <div v-if="row.status == 4" class="fl">
                        <el-divider direction="vertical"></el-divider>
                        <span
                          class="text"
                          @click="huifu(row.$index, row.order_id)"
                        >
                          恢复
                        </span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>

            <template v-slot:footer>
              <div class="footer">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checked"
                  @change="boxChange($event)"
                ></el-checkbox>
                <span class="ml10">已选择{{ selected.length || 0 }}条</span>
                <el-button
                  v-if="showUnsetBtn"
                  class="ml20"
                  type=""
                  @click="zuofeis"
                  :disabled="
                    selected.length > 0 && !isnobeselect && isstatus1 != 2
                  "
                  size="medium"
                >
                  批量作废
                </el-button>
                <el-button
                  class="ml20"
                  type=""
                  size="medium"
                  :disabled="
                    selected.length > 0 && !isnobeselect && isstatus1 != 1
                  "
                  @click="dels"
                >
                  批量删除
                </el-button>
                <el-button
                  v-if="showValidityBtn"
                  type=""
                  size="medium"
                  :disabled="
                    selected.length > 0 && !isnobeselect && isstatus1 != 2
                  "
                  @click="piliang"
                >
                  批量编辑有效期
                </el-button>
              </div>
            </template>
          </pagination2>
        </div>

        <el-dialog
          title="编辑有效期"
          :visible.sync="dialogVisible"
          width="600px"
        >
          <div class="fs14" slot="title">编辑有效期</div>
          <div class="dialog_body">
            <el-row>
              <el-col :span="8">学员：</el-col>
              <el-col :span="16">
                <span style="color: #333333">{{ changetiem.uname }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">课程：</el-col>
              <el-col :span="16">
                <span style="color: #333333">{{ changetiem.course_name }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">订单有效期：</el-col>
              <el-col :span="16">
                <span class="redcolor">
                  {{
                    changetiem.validity | formatTimeStamp('yyyy-MM-dd hh:mm')
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">剩余：</el-col>
              <el-col :span="16">
                <span class="redcolor" v-if="changetiem.days_remaining">
                  {{ changetiem.days_remaining }}天
                </span>
                <span class="redcolor" v-else>
                  {{ changetiem.days_remaining }}天(已过期)
                </span>
              </el-col>
            </el-row>
            <el-row align="middle" type="flex">
              <el-col :span="8">修改有效期：</el-col>
              <el-col :span="16">
                <el-date-picker
                  style="width: 258px"
                  @change="editpicker"
                  v-model="eidtitme"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  value-format="timestamp"
                  placeholder="点击选择"
                  :picker-options="pickerOptions"
                ></el-date-picker>
                <span
                  class="redcolor ml20"
                  v-if="editpicker_surplus > 0"
                  style="line-height: 40px"
                >
                  剩余{{ editpicker_surplus }}天
                </span>
              </el-col>
            </el-row>
          </div>
          <span slot="footer" class="dialog-footer">
            <div
              v-if="changetiem.edit_time > 0"
              class="fl"
              style="margin-top: 5px"
            >
              <span class="mr30">
                最近修改：{{
                  changetiem.edit_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </span>
              <span>操作人：{{ changetiem.editor }}</span>
            </div>
            <div class="fr">
              <el-button
                size="medium"
                style="width: 74px"
                @click="timepick_cancel"
              >
                取 消
              </el-button>
              <el-button
                size="medium"
                style="width: 74px"
                type="primary"
                @click="editime_submit"
              >
                确 定
              </el-button>
            </div>
            <div style="clear: both"></div>
          </span>
        </el-dialog>

        <el-dialog
          title="批量编辑有效期"
          :visible.sync="dialogVisible2"
          width="600px"
          class="dialogVisible2"
        >
          <div class="fs14" slot="title">批量编辑有效期</div>
          <el-row align="middle" type="flex">
            <el-col :span="5">观看有效期：</el-col>
            <el-col :span="19">
              <el-date-picker
                style="width: 258px"
                @change="editpicker_more"
                v-model="eidtitme_more"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="timestamp"
                placeholder="点击选择时间"
                :picker-options="pickerOptions"
              ></el-date-picker>
              <span
                class="redcolor ml20"
                v-if="editpicker_surplus2 > 0"
                style="line-height: 40px"
              >
                剩余{{ editpicker_surplus2 }}天
              </span>
            </el-col>
          </el-row>
          <div class="dialogVisible2_font1">
            提示：保存后已选的订单的有效期会统一变更新设置的有效时间
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button size="medium" style="width: 74px" @click="more_cancel">
              取 消
            </el-button>
            <el-button
              size="medium"
              style="width: 74px"
              type="primary"
              @click="editime_submit_more"
            >
              确 定
            </el-button>
          </span>
        </el-dialog>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
/**
 * @description: 订单页面
 * @author: 彭明军
 * @update: 彭明军(2019-09-29 17：29)
 */
import helpIcon from '@/components/helpIcon'
// import bg from '@/mixin/background'
export default {
  name: 'order',

  // mixins: [bg],
  data() {
    return {
      // 自定义全选框
      checked: false,
      // 未全部选中的状态
      isIndeterminate: false,
      // 当前选中的个数
      selectedNum: 0,
      // 当前列表数据的长度
      tableLength: 0,
      isstatus1: '',

      eidtitme_more: '',

      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },

      validity3: 0,

      validity4: 0,

      changetiem: {}, //单个编辑有效期选中数据

      eidtitme: '',

      value3: '',

      alltableData: [],

      searchOption: {},
      selected: '',

      //晒选条件
      post1: {},

      isnobeselect: true, //判断是否选择多种状态
      // emptyStatusHeihgt: "",

      value10: '',

      value1: '',

      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '优惠码',
        },
        {
          value: 2,
          label: '拼团',
        },
        {
          value: 3,
          label: '兑换码',
        },
        {
          value: 4,
          label: '裂变海报',
        },

        {
          value: 5,
          label: '优惠券',
        },
        {
          value: 7,
          label: '提取码',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 2,
          label: '支付成功',
        },
        {
          value: 1,
          label: '未支付',
        },
        {
          value: 4,
          label: '已作废',
        },
        {
          value: 3,
          label: '已失效',
        },
      ],

      options3: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '今日订单',
        },
        {
          value: 2,
          label: '近七天',
        },
        {
          value: 3,
          label: '近一个月',
        },
      ],

      options4: [
        {
          value: 2,
          label: '学员',
        },
        {
          value: 3,
          label: '订单号',
        },
      ],
      options5: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '是',
        },
        {
          value: 2,
          label: '否',
        },
      ],

      input: '',

      dialogVisible: false, //单个编辑时间

      dialogVisible2: false, //批量编辑时间

      Visible: false,

      orderlist: [],

      currentPage: 1,

      post: {
        type: '',
        search: '',
        sort: 'desc',
        order_type: '',
        status: '',
        keyword: '',
        search_type: 1,
      },

      allNum: '',

      pageNum: '',

      // 显示加载图标
      // isLoading: false,
      // 没有数据显示
      isNoData: false,

      editpicker_surplus: 0, //单个编辑有效时间---选择时间计算的天数

      editpicker_surplus2: 0, //批量编辑有效时间---选择时间计算的天数

      key: '',
    }
  },

  computed: {
    //编辑有效期
    showValidityBtn() {
      return this.$store.state.roots.includes(139)
    },

    //作废
    showUnsetBtn() {
      return this.$store.state.roots.includes(140)
    },

    //导出
    showExportBtn() {
      return this.$store.state.roots.includes(141)
    },
  },

  watch: {
    selectedNum(val) {
      if (val > 0) {
        if (val == this.tableLength) {
          this.checked = true
          this.isIndeterminate = false
        } else if (val < this.tableLength) {
          this.isIndeterminate = true
          this.checked = false
        }
      } else {
        this.isIndeterminate = false
        this.checked = false
      }
    },
  },

  components: {
    // loading,
    helpIcon,
    // empty
    //  pagination
  },

  created() {
    this.clear_select()
  },

  methods: {
    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    boxChange(val) {
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    editpicker(val) {
      var time = new Date().valueOf()
      this.editpicker_surplus = Math.ceil((val - time) / (1000 * 60 * 60 * 24))
      this.validity3 = val / 1000
    },

    editpicker_more(val) {
      var time = new Date().valueOf()
      this.editpicker_surplus2 = Math.ceil((val - time) / (1000 * 60 * 60 * 24))
      this.validity4 = val / 1000
    },

    timepick_cancel() {
      this.dialogVisible = false
      this.eidtitme = ''
      this.editpicker_surplus = 0
    },

    editime_submit() {
      this.$http({
        url: '/order/setValidity',
        data: {
          ids: this.changetiem.order_id,
          validity: this.validity3,
        },
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
          this.eidtitme = ''
          this.editpicker_surplus = 0
          this.dialogVisible = false
          this.validity3 = 0
          this.$refs.child.reset()
        },
      })
    },

    more_cancel() {
      this.dialogVisible2 = false
      this.eidtitme_more = ''
      this.editpicker_surplus2 = 0
    },

    alltableData2(val) {
      this.alltableData = val
      this.tableLength = val.data && val.data.length
    },

    sortChange(value) {
      this.post1.order = !value.order ? '' : value.order === 'ascending' ? 1 : 2
      this.searchOption = _.assign({}, this.searchOption, {
        order: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },

    handleSelectionChange(val) {
      this.selected = val
      this.selectedNum = val.length
      const isfist = val[0] ? val[0].status : ''
      if (isfist && val.every(item => item.status == isfist)) {
        this.isnobeselect = false
        this.isstatus1 = val[0].status
      } else {
        this.isnobeselect = true
        this.isstatus1 = ''
      }
    },

    //筛选按钮
    //筛选按钮
    screen_click() {
      this.searchOption = _.assign({}, this.post1)
    },

    //清除筛选
    clear_select() {
      this.clearallsarech()
      this.clearallsarech2()
    },

    clearallsarech() {
      this.searchOption = {
        course_id: this.$route.query.course_id,
        search: '',
        searchType: 2, // 搜索类型 1 课程   2学员   3订单号
        small_orderTime: 0,
        large_orderTime: 0,
        small_validity: 0,
        large_validity: 0,
        is_pay: 0,
        small_courseprice: '',
        large_courseprice: '',
        order_type: 0,
        status: 0,
        order: '',
      }
    },

    clearallsarech2() {
      this.post1 = {
        course_id: this.$route.query.course_id,
        search: '',
        searchType: 2, // 搜索类型 1 课程   2学员   3订单号
        small_orderTime: 0,
        large_orderTime: 0,
        small_validity: 0,
        large_validity: 0,
        is_pay: 0,
        small_courseprice: '',
        large_courseprice: '',
        order_type: 0,
        status: 0,
        order: '',
      }
    },

    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.post1.small_orderTime = val[0] / 1000
      this.post1.large_orderTime = val[1] / 1000
    },

    //失效时间
    invalidpick(val) {
      val = val || [0, 0]
      this.post1.small_validity = val[0] / 1000
      this.post1.large_validity = val[1] / 1000
    },

    piliang() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          this.eidtitme_more = ''
          this.editpicker_surplus2 = 0
          this.dialogVisible2 = true
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },

    //批量编辑有效期
    editime_submit_more() {
      const ids = this.selected.map(f => f.order_id)
      this.$http({
        url: '/order/setValidity',
        data: {
          ids: ids,
          validity: this.validity4,
        },
        callback: () => {
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
          this.dialogVisible2 = false
          this.selected = []
          this.validity4 = 0
          this.$refs.child.reset()
        },
      })
    },

    //作废订单
    zuofei(index, val1) {
      this.$confirm(
        '作废后学员已购状态变为未购状态，是否作废订单？',
        '作废订单',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: '/Order/unsetOrder',
          data: {
            ids: [val1],
            type: 1,
          },
          callback: () => {
            this.$root.prompt({
              msg: '作废成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
          error: () => {
            this.$root.prompt('作废失败')
          },
        })
      })
    },

    //批量作废订单
    zuofeis() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          const ids = this.selected.map(f => f.order_id)
          this.$confirm(
            '确定后，学员已购状态变为未购状态，是否作废订单？',
            '批量作废订单',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
            }
          ).then(() => {
            this.$http({
              url: '/Order/unsetOrder',
              data: {
                ids: ids,
                type: 1,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.selected = []
                this.$refs.child.reset()
              },
            })
          })
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },

    //删除订单
    del(val1) {
      this.$confirm('是否删除该订单？', '删除订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      }).then(() => {
        this.$http({
          url: '/Order/unsetOrder',
          data: {
            ids: [val1],
            type: 3,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },

    //批量删除订单
    dels() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          const ids = this.selected.map(f => f.order_id)
          this.$confirm('是否删除该订单？', '批量删除订单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(() => {
            this.$http({
              url: '/Order/unsetOrder',
              data: {
                ids: ids,
                type: 3,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操作成功',
                  type: 'success',
                })
                this.selected = []
                this.$refs.child.reset()
              },
            })
          })
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },

    //恢复订单
    huifu(index, val1) {
      this.$confirm('是否恢复该订单？', '恢复订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      }).then(() => {
        this.$http({
          url: '/Order/unsetOrder',
          data: {
            ids: [val1],
            type: 2,
          },
          callback: () => {
            this.$root.prompt({
              msg: '恢复成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
          error: error => {
            if (error.code == 205) {
              this.$root.prompt(error.info)
            }
          },
        })
      })
    },

    //批量恢复订单
    huifus() {
      if (this.selected.length > 0) {
        if (this.isnobeselect) {
          this.$alert(
            '已选的订单包含了不同的状态，无法完成批量操作！如需批量操作，请勾选同一状态的订单；或者直接筛选出同一类订单',
            '温馨提示',
            {
              confirmButtonText: '知道了',
            }
          )
        } else {
          const ids = this.selected.map(f => f.order_id)
          this.$confirm('是否恢复已作废学员的订单？', '批量恢复订单', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
          }).then(() => {
            this.$http({
              url: '/Order/unsetOrder',
              data: {
                ids: ids,
                type: 2,
              },
              callback: () => {
                this.$root.prompt({
                  msg: '操成功',
                  type: 'success',
                })
                this.selected = []
                this.$refs.child.reset()
              },
              error: error => {
                if (error.code == 205) {
                  this.$root.prompt(error.info)
                }
              },
            })
          })
        }
      } else {
        this.$root.prompt('请先勾选列表数据')
      }
    },

    //订单详情
    detail(row) {
      this.$emit('leave')
      this.$router.push({
        path: `/capitalOrder/detail`,
        query: {
          data_id: row.order_id,
        },
      })
    },

    //单个编辑有效期
    edit_time(val) {
      this.changetiem = val
      this.eidtitme = ''
      this.editpicker_surplus = 0
      this.dialogVisible = true
    },

    // 导出handleClose

    // 导出
    exportTable(type) {
      const verify = type == 1 ? this.selected.length : this.alltableData.count

      const selection =
        this.selected.length > 0 ? this.selected.map(m => m.order_id) : ''
      if (verify > 0) {
        this.$confirm(`确定要导出当前${verify}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          // 所有搜索条件get传递过去
          let query = JSON.stringify(
            _.assign({}, this.post1, {
              ids: type == 1 ? selection : undefined,
            })
          )

          this.$store.dispatch(
            'open',
            `${location.origin}/order/exportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-divider {
  background-color: #1b9d97 !important;
}

.dialogVisible2 {
  & ::v-deep .el-dialog__body {
    height: 200px;
  }

  .dialogVisible2_font1 {
    font-size: 12px;
    margin-top: 18px;
    color: #999999;
    line-height: 20px;
  }
}

.dialog_body {
  .el-row {
    margin-bottom: 20px;
  }
}

.dialog-footer {
  span {
    font-size: 13px;
    color: #666666;
    line-height: 13px;
  }
}

.order-contain {
  .order-summary {
    font-size: 13px;
    line-height: 17px;
    text-align: right;
    padding: 24px 20px 11px;
    // color: rgba(102, 102, 102, 1);
  }
}

.btns {
  // @extend %btns;
  color: #1b9d97;

  span {
    cursor: pointer;
  }
}

.table-contain {
  padding: 0 20px;
  padding-left: 0;
  box-sizing: border-box;
}

.cover {
  width: 64px;
  height: 36px;
  position: relative;

  .img {
    width: 100%;
    height: 100%;
  }
}

.head-right {
  height: 40px;
  width: 920px;
  float: right;
  margin-top: 10px;
}

.course-info {
  display: flex;

  .cover {
    width: 50px;
    height: 30px;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    flex: 1;
    overflow: hidden;
    margin-left: 8px;

    .title {
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;
      color: rgba(51, 51, 51, 1);
    }
  }
}

.pagination-wrap {
  padding: 30px 0;
  text-align: center;
}

.all {
  overflow: hidden;
  padding-top: 20px;
  background: rgba(255, 255, 255, 1);

  .heard_top {
    padding: 20px 20px 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333333;

    span {
      margin-right: 24px;
      min-width: 56px;
    }

    ::v-deep .money_heard_first {
      .el-input-group__append {
        padding: 5px;
        font-size: 14px;
        color: #999999;
        background-color: #fff;
        position: absolute;
        right: 0;
        background: none;
        top: 0;
        height: 100%;
        border: 0;
        line-height: 36px;
        width: auto;
        padding: 0;
        padding-right: 10px;
      }
    }

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }

      .el-input-group__prepend {
        background-color: #fff;
        width: 94px;
      }
    }
  }

  .export {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .count_money {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }
  }

  .footer {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    margin-bottom: 10px;

    .el-button {
      width: 136px;
      margin-top: 20px;
    }
  }

  .stundent_photo {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 10px;

    .img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }

  .student_name {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
  }
}

.nn {
  display: none;
}

.zi1 {
  cursor: pointer;
  float: left;
  text-align: center;
  padding-top: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 19px;
  box-sizing: border-box;
  margin-left: 20px;
  width: 100px;
  height: 36px;
  background: rgba(10, 162, 155, 1);
  border-radius: 4px;
}

.zi2,
.zi3 {
  width: 52px;
  height: 17px;
  font-size: 13px;
  color: rgba(51, 51, 51, 1);
  line-height: 17px;
  margin-top: 9px;
  // margin-left: 220px;
  // margin-right: 10px;
  float: left;
}

.zi3 {
  margin-left: 30px;
}

.el-select {
  float: left;
  border-radius: 4px;
}

.list2 .el-select {
  margin-left: 30px;
  width: 78px;
  float: left;
}

.el-input {
  width: 374px;
  float: left;
  height: 36px;
  border-radius: 0px 4px 4px 0px;
}

.list2 {
  width: 300px;
  float: left;
}

.clear {
  clear: both;
  margin-bottom: 30px;
}

table {
  margin-top: 30px;
  margin-left: 20px;
  width: 1220px;
  border-collapse: collapse;
}

table .head {
  width: 1080px;
  height: 42px;
  background: rgba(245, 245, 245, 1);
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}

.tu1 {
  width: 68px;
  height: 38px;
  background: rgba(110, 110, 110, 1);
  margin-top: 22px;
  margin-left: 18px;
  float: left;
}

.zi4 {
  width: 135px;
  height: 38px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
}

.zi5 {
  width: 130px;
  height: 19px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}

.zi20 {
  width: 72px;
  height: 17px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 53, 53, 1);
  line-height: 17px;
}

.tu2 {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: grey;
  float: left;
}

.zi6 {
  height: 17px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 17px;
  float: left;
  margin-top: 2px;
  margin-left: 10px;
}

.zi7 {
  width: 165px;
  height: 19px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}

.zi8 {
  width: 56px;
  height: 19px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}

.zi9 {
  width: 77px;
  font-size: 14px;
  color: rgba(27, 157, 151, 1);
  line-height: 19px;
}

.zi9 span {
  cursor: pointer;
}

.el-pagination {
  margin-top: 31px;
  margin-bottom: 46px;
}
</style>
