<!--
 * @Descripttion: 课程设置
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 18:04:09
 * @LastEditors: cyy
 * @LastEditTime: 2022-09-13 17:48:54
 -->
<template>
  <div class="course-setting" :class="{ 'is-edit': isEdit }">
    <el-form label-position="left" label-width="130px" :model="courseSettingInfo" ref="courseSettingInfo"
      @submit.native.prevent>
      <!--课程公告-->
      <el-form-item label="课程公告" prop="live_bbs" v-if="courseType != 3 && courseType != 2 && courseType != 4">
        <div class="for-width">
          <el-input v-model.trim="courseSettingInfo.live_bbs" :rows="5" type="textarea" placeholder="填写直播间公告"
            maxlength="255" show-word-limit></el-input>
        </div>
      </el-form-item>
      <!--设置客服-->
      <!-- <el-form-item label="设置客服" prop="customer_id"> -->
      <el-form-item label="设置客服" prop="customer_id" v-show="userInfo.version_id != 1">
        <el-select placeholder="请选择客服" v-model="courseSettingInfo.customer_id">
          <el-option value="">无</el-option>
          <el-option v-for="item in customerOptions" :key="item.customer_id" :label="item.name"
            :value="item.customer_id">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right;margin-left:10px; color: #8492a6; font-size: 13px"
              @click="toggleShowDeleteCustomer(item.customer_id)">
              删除
            </span>
            <span style="float: right; color: #8492a6; font-size: 13px" @click="editCustomer(item.customer_id)">
              编辑
            </span>
          </el-option>
          <el-option value="" @click.native="addKefu">新建客服</el-option>
        </el-select>
      </el-form-item>
      <!--课程有效期-->
      <div class="course_validity">
        <el-form-item label="课程订单有效期" prop="recorded_viedo_validity">
          <el-select placeholder="请选择课程观看有效期" v-if="selectTime==1" v-model="courseSettingInfo.recorded_viedo_validity">
            <el-option label="30天" value="30"></el-option>
            <el-option label="90天" value="90"></el-option>
            <el-option label="180天" value="180"></el-option>
            <el-option label="360天" value="360"></el-option>
            <el-option label="3600天" value="3600"></el-option>
          </el-select>


          <el-input class="dayInput_select" v-if="selectTime==2" type="text" onkeyup="this.value=this.value.replace(/\D/g,'')" v-model="selectTimeInput" @blur='selectTimeInputBlur' placeholder="输入自定义有效期，最高可输入3600">
            <template slot="append">天</template>
          </el-input>
          <div style="color:#FF3535;font-size:12px;line-height: 16px;margin-top:4px">
            指学员报名后的课程学习有效时间，如学员1号报名课程，有效期为7天，到8号学员报名的课程自动失效
          </div>
        </el-form-item>
        <span @click="selectTime=1" v-if="selectTime==2" style="font-size: 13px">选择有效期</span>
        <span @click="selectTime=2" v-if="selectTime==1" style="font-size: 13px">自定义有效期</span>
      </div>
      <!--课程助教-->
      <!-- <el-form-item label="请选择助教" v-if="courseType != 3 && courseType != 2 && courseType != 4">
        <el-select v-model="courseSettingInfo.assistant_ids" multiple placeholder="请选择助教">
          <el-option v-for="item in options" :key="item.uid" :label="item.uname" :value="item.uid"></el-option>
        </el-select>
      </el-form-item> -->
      <!--显示学习人数-->
      <el-form-item label="显示学习人数" v-show="userInfo.version_id != 1">
        <el-radio-group v-model="courseSettingInfo.is_show_sales">
          <el-radio label="1">开启</el-radio>
          <el-radio label="2">关闭</el-radio>
        </el-radio-group>
        <template v-if="courseSettingInfo.is_show_sales == 1">
          <template v-if="userInfo.version_id != 1">
            <span class="custom-num">自定义人数</span>
            <el-input placeholder="输入自定数量，选填" v-model.trim="courseSettingInfo.basesales" type="text"></el-input>
            <p class="num-wrap">
              <span class="txt" :style="
                  `top:${
                    courseType == 2 || courseType == 3 || courseType == 4
                      ? 20
                      : 10
                  }px`
                ">
                开启后课程详情展示：自定义人数+真实人数
              </span>
              <el-checkbox v-model="isFlag" v-if="courseType != 2 && courseType != 3 && courseType != 4">
                同时作用到直播观看人数
              </el-checkbox>
            </p>
          </template>
        </template>
      </el-form-item>
      <!--允许小程序观看-->
      <el-form-item label="允许小程序观看" prop="is_direct" v-show="courseType != 2 && courseType != 4 && courseType != 3">
        <el-radio-group v-model="courseSettingInfo.is_direct">
          <el-radio label="1">开启</el-radio>
          <el-radio label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--引导关注/加微信-->
      <el-form-item label="引导关注/加微信" v-show="userInfo.version_id != 1">
        <el-checkbox v-model="courseSettingInfo.code1">报名后</el-checkbox>
        <span class="cp setting-erweima" @click="isShowSettingCode = 1">
          设置二维码
        </span>
        <el-checkbox v-model="courseSettingInfo.code2" v-show="courseType != 2 && courseType != 4 && courseType != 3">
          进入直播间前
        </el-checkbox>
        <span class="cp setting-erweima" @click="isShowSettingCode = 2"
          v-show="courseType != 2 && courseType != 4 && courseType != 3">
          设置二维码
        </span>
      </el-form-item>
      <!--该课程展示到网校-->
      <el-form-item label="该课程展示到网校" prop="is_show">
        <el-radio-group v-model="courseSettingInfo.is_show">
          <el-radio label="1">开启</el-radio>
          <el-radio label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 展示课程目录 -->
      <el-form-item label="课程目录" prop="lock_type">
        <p>已展示</p>
        <el-radio-group v-model="courseSettingInfo.lock_type">
          <el-radio label="1">显示全部内容</el-radio>
          <el-radio label="2">仅显示试学内容</el-radio>
          <el-radio label="3">隐藏</el-radio>
        </el-radio-group>
        <el-popover placement="right" trigger="hover" width="300">
          <div style="width: 100%">
            <img
              src="~@ass/img/1.4.2.3/img_dsslsl@2x.png"
              style="width: 100%"
              alt=""
            />
          </div>
          <el-button type="text" slot="reference" class="ml-10">
            查看示例
          </el-button>
        </el-popover>
      </el-form-item>
      <div class="success-save" v-if="isEdit">
        <el-button type="success" @click="doEditSave">保存</el-button>
      </div>
      <!-- 保存按钮 -->
    </el-form>
    <newCustomerPop :customer_id="customer_id" :isEdit="isEditCustomer" v-if="isShowCustomer"
      @closeFn="toggleShowCustomer"></newCustomerPop>
    <!-- 删除客服弹窗 -->
    <CourseConfirm title="删除客服" :isSmallPop="true" v-if="isShowDeleteCustomer" @cancelFn="isShowDeleteCustomer = false"
      @save="deleteCustomer">
      <div class="reset-txt">
        是否删除该客服？
      </div>
    </CourseConfirm>
    <!--设置二维码-->
    <settingEwCodePop v-if="isShowSettingCode" :isShowSettingCode="isShowSettingCode" :info="
        courseSettingInfo.course_guide_content[Number(isShowSettingCode) - 1]
      " @getCodeInfo="getCodeInfo" @closeFn="toggleShowErweima"></settingEwCodePop>
    <!--设置二维码-->
    <loading v-if="isShowLoading" leftPos="0"></loading>
  </div>
</template>
<script>
  import API from '@/request/api'
  import newCustomerPop from '@/components/coursePop/newCustomerPop'
  import settingEwCodePop from '@/components/coursePop/settingEwCodePop'
  import CourseConfirm from '@/components/CourseConfirm'
  import {
    mapState
  } from 'vuex'
  import loading from '@/components/LoadGif'

  export default {
    name: 'CourseSetting',
    props: {
      isEdit: {
        type: Boolean,
        default: false
      },
      courseType: [String, Number],
      alreadyCopyTime: {
        type: [String, Number],
        default: 0
      }
    },
    components: {
      newCustomerPop,
      CourseConfirm,
      settingEwCodePop,
      loading
    },
    computed: {
      getObjNum() {
        let length = 0
        try {
          let obj = {
            recorded_viedo_validity: this.courseSettingInfo
              .recorded_viedo_validity,
            is_show: this.courseSettingInfo.is_show
          }
          if (this.courseType != 3 && this.courseType != 2) {
            if (this.courseType != 4) {
              obj['live_bbs'] = this.courseSettingInfo.live_bbs
              obj['assistant_ids'] = this.courseSettingInfo.assistant_ids
            }
          }
          if (
            this.courseType != 2 &&
            this.courseType != 4 &&
            this.courseType != 3
          ) {
            obj['is_direct'] = this.courseSettingInfo.is_direct
          }
          if (this.userInfo.version_id != 1) {
            obj['flag'] =
              Number(this.courseSettingInfo.code1) +
              Number(this.courseSettingInfo.code2) >=
              1
            obj['customer_id'] = this.courseSettingInfo.customer_id
            obj['is_show_sales'] = this.courseSettingInfo.is_show_sales
          }
          for (let i in obj) {
            if (
              (typeof obj[i] === 'string' && obj[i] != '') ||
              obj[i].length > 0 ||
              (typeof obj[i] === 'boolean' && obj[i])
            ) {
              length += 1
            }
          }
        } catch (e) {
          console.error(e)
        }
        return length
      },
      ...mapState(['userInfo'])
    },
    watch: {
      courseSettingInfo: {
        handler() {
          if (!this.isEdit) {
            this.$emit('titleChange', 3, this.getObjNum)
          }
        },
        deep: true
      },
      isFlag() {
        this.courseSettingInfo.basesales_show_live = this.isFlag ? 1 : 2
      },
      'courseSettingInfo.basesales_show_live'() {
        this.isFlag = this.courseSettingInfo.basesales_show_live == 1
      },
      'courseSettingInfo.basesales'() {
        let reg = /^\d+(?:\.?|$)/
        let newValue = this.courseSettingInfo.basesales.match(reg)
        this.courseSettingInfo.basesales = newValue ?
          newValue[0].indexOf('.') > -1 ?
          newValue[0].split('.')[0] :
          newValue[0] :
          ''
        if (this.courseSettingInfo.basesales.length > 5) {
          this.courseSettingInfo.basesales = this.courseSettingInfo.basesales.slice(
            0,
            5
          )
        }
      },
      alreadyCopyTime() {
        this.getLocalstorageData()
      }
    },
    data() {
      return {
        selectTimeInput: '',
        selectTime: 1,
        coursePaths: [{
            path: '/courseLive'
          },
          {
            path: '/coursePrivate'
          },
          {
            path: '/courseRecord'
          },
          {
            path: '/courseSeries'
          }
        ],
        courseSettingInfo: {
          live_bbs: '', //直播间公告
          customer_id: '', //客服人员id
          recorded_viedo_validity: '3600', //视频观看有效期
          is_show_sales: '1', //是否显示报名人数
          basesales: '', //自定义报名人数
          basesales_show_live: '2', //同时作用到直播间
          is_direct: '1', //是否允许小程序观看
          is_show: '1', //是否展示在网校上
          lock_type: '1', //是否显示试看目录
          // 新加助教人员id
          assistant_ids: [],
          course_guide_content: [],
          code1: false, //二维码1
          code2: false //二维码2
        },
        isFlag: false,
        isShowDeleteCustomer: false,
        isShowCustomer: false,
        isShowSettingCode: '', //是否显示设置二维码弹窗
        options: [],
        customerOptions: [],
        tempGuide: [],
        isEditCustomer: true,
        isShowLoading: false
      }
    },
    mounted() {
      if (!this.isEdit) {
        this.getLocalstorageData()
      } else {
        this.getEditSettingInfo()
      }
      // 只有当不是免费版时才去请求
      if (this.userInfo.version_id != 1) {
        this.getCustomerList()
      }
      if (this.courseType != 3) {
        this.getJigouAssistant()
      }
    },
    provide() {
      return {
        getCustomerList: this.getCustomerList
      }
    },
    methods: {
      selectTimeInputBlur(){
        if(this.selectTimeInput>3600){
          this.$message({
            message:'最高可输入3600',
            type:'error'
          })
          this.selectTimeInput=3600
        }
      },
      getDefaultEwm(type) {
        const self = this
        self.$http({
          name: API.getDefaultCode_Api.name,
          url: API.getDefaultCode_Api.url,
          callback({
            code,
            data
          }) {
            if (code == 200) {
              if (self.courseSettingInfo.course_guide_content.length == 0) {
                self.courseSettingInfo.course_guide_content = [{
                    qr_code_url: data,
                    valid_portion: '1',
                    content: '长按识别二维码，关注公众号',
                    status: type == 1 ? 1 : 2
                  },
                  {
                    qr_code_url: data,
                    valid_portion: '2',
                    content: '长按识别二维码，关注公众号',
                    status: 2
                  }
                ]
                if (type == 2) {
                  self.courseSettingInfo.code1 = false
                  self.courseSettingInfo.code2 = false
                } else {
                  self.courseSettingInfo.code1 = true
                }
              } else {
                if (
                  self.courseSettingInfo.course_guide_content[0].valid_portion ==
                  2
                ) {
                  self.courseSettingInfo.course_guide_content.reverse()
                }
                self.courseSettingInfo.code1 =
                  self.courseSettingInfo.course_guide_content[0]['status'] == 1
                self.courseSettingInfo.code2 =
                  self.courseSettingInfo.course_guide_content[1]['status'] == 1
              }
            }
          }
        })
      },
      // 获取缓存数据
      getLocalstorageData() {
        if (localStorage.getItem('copyCourseInfo')) {
          const localObj = JSON.parse(localStorage.getItem('copyCourseInfo'))
          const targetObj = {
            live_bbs: localObj.live_bbs, //直播间公告
            customer_id: localObj.customer_id == 0 ? '' : localObj.customer_id, //客服人员id
            recorded_viedo_validity: localObj.recorded_viedo_validity, //视频观看有效期
            is_show_sales: localObj.is_show_sales, //是否显示报名人数
            basesales: localObj.basesales, //自定义报名人数
            basesales_show_live: localObj.basesales_show_live, //同时作用到直播间
            is_direct: localObj.is_direct, //是否允许小程序观看
            is_show: localObj.is_show, //是否展示在网校上
            // 新加助教人员id
            assistant_ids: localObj.assistant_ids == '' ?
              [] :
              localObj.assistant_ids.split(','),
            course_guide_content: localObj.course_guide_content ?
              JSON.parse(JSON.stringify(localObj.course_guide_content)) :
              []
          }
          //自定义有效期
          if(targetObj.recorded_viedo_validity!=30&&targetObj.recorded_viedo_validity!=90&&targetObj.recorded_viedo_validity!=180&&targetObj.recorded_viedo_validity!=360){
            // if(targetObj.recorded_viedo_validity=='12'){
            this.selectTime=2
            this.selectTimeInput=targetObj.recorded_viedo_validity
            targetObj.recorded_viedo_validity = '360'
          }
          //自定义有效期
          
          if (this.courseType == localObj.course_type) {
            this.courseSettingInfo = Object.assign({},
              this.courseSettingInfo,
              targetObj
            )
          }
          // 只有当不是免费版时才去请求
          if (this.userInfo.version_id != 1) {
            this.sureLength(
              this.courseSettingInfo.course_guide_content ?
              this.courseSettingInfo.course_guide_content :
              []
            )
          }
          localStorage.removeItem('copyCourseInfo')
        } else {
          // 只有当不是免费版时才去请求
          if (this.userInfo.version_id != 1) {
            this.getDefaultEwm(1)
          }
        }
      },
      // 判断course_guide_content长度
      sureLength() {
        this.getDefaultEwm(2)
      },
      addKefu() {
        this.isEditCustomer = false
        this.toggleShowCustomer()
      },
      toggleShowCustomer() {
        this.isShowCustomer = !this.isShowCustomer
      },
      // 切换删除弹框的显示
      toggleShowDeleteCustomer(customer_id) {
        this.isShowDeleteCustomer = !this.isShowDeleteCustomer
        this.deleteCustomer_id = customer_id
      },
      // 切换显示设置二维码弹窗
      toggleShowErweima() {
        this.isShowSettingCode = ''
      },
      // 编辑客服
      editCustomer(customer_id) {
        this.isEditCustomer = true
        this.customer_id = customer_id
        this.toggleShowCustomer()
      },
      // 编辑时获取课程设置信息
      getEditSettingInfo() {
        const self = this
        const course_id = this.$route.query.course_id
        self.isShowLoading = true
        self.$http({
          name: API.editCourseSetting_Api.name,
          url: `${API.editCourseSetting_Api.url}?course_id=${course_id}`,
          callback({
            code,
            data
          }) {
            if (code == 200) {
              self.isShowLoading = false
              if (data.customer_id == 0) {
                data.customer_id = ''
              }
              if (data.assistant_ids && data.assistant_ids[0] == '') {
                data.assistant_ids = []
              }
              if (!data.course_guide_content) {
                data.course_guide_content = []
              }
              self.courseSettingInfo = Object.assign({},
                self.courseSettingInfo,
                data
              )
              if (self.userInfo.version_id != 1) {
                self.sureLength(self.courseSettingInfo.course_guide_content)
              }
              if(data.recorded_viedo_validity!=30&&data.recorded_viedo_validity!=90&&data.recorded_viedo_validity!=180&&data.recorded_viedo_validity!=360){
                self.selectTime=2
                self.selectTimeInput=data.recorded_viedo_validity
                self.courseSettingInfo.recorded_viedo_validity = '360'
              }
            }
          }
        })
      },
      // 编辑时保存  //在课程设置时候保存时触发
      doEditSave() {
        const self = this
        const course_id = this.$route.query.course_id
        self.courseSettingInfo['course_id'] = course_id
        //保存时判断是否自定义天数
        if(self.selectTime==2){
          self.courseSettingInfo.recorded_viedo_validity = self.selectTimeInput
        }

        // 设置引导二维码
        if (this.courseSettingInfo.basesales < 0) {
          this.$root.prompt('请输入正确人数')
          return
        }
        let courseGuideArr = []
        if (this.userInfo.version_id != 1) {
          if (!this.courseSettingInfo.code1 && !this.courseSettingInfo.code2) {
            this.courseSettingInfo['course_guide_content'][0]['status'] = 2
            this.courseSettingInfo['course_guide_content'][1]['status'] = 2
          } else if (!this.courseSettingInfo.code1) {
            this.courseSettingInfo['course_guide_content'][0]['status'] = 2
            this.courseSettingInfo['course_guide_content'][1]['status'] = 1
          } else if (!this.courseSettingInfo.code2) {
            this.courseSettingInfo['course_guide_content'][0]['status'] = 1
            this.courseSettingInfo['course_guide_content'][1]['status'] = 2
          } else {
            this.courseSettingInfo['course_guide_content'][0]['status'] = 1
            this.courseSettingInfo['course_guide_content'][1]['status'] = 1
          }
          courseGuideArr = JSON.parse(
            JSON.stringify(this.courseSettingInfo['course_guide_content'])
          )
        }
        // 处理助教数组
        self.$http({
          name: API.editCourseSetting_Api.name,
          url: API.editCourseSetting_Api.url,
          data: Object.assign({}, self.courseSettingInfo, {
            assistant_ids: self.courseSettingInfo.assistant_ids.join(','),
            course_guide_content: courseGuideArr
          }),
          callback({
            code
          }) {
            if (code == 200) {
              self.$root.prompt({
                msg: '保存成功',
                type: 'success'
              })
              self.tempGuide = []
              self.courseSettingInfo['course_guide_content'] = []
              self.getEditSettingInfo()
            }
          }
        })
      },
      // 获取设置后的二维码信息
      getCodeInfo(info, type) {
        this.courseSettingInfo.course_guide_content[
          Number(type) - 1
        ] = Object.assign({}, {
            valid_portion: type
          },
          this.courseSettingInfo.course_guide_content[Number(type) - 1], {
            status: this.courseSettingInfo.course_guide_content[Number(type) - 1][
              'status'
            ]
          },
          info
        )
      },
      // 获取机构助教
      getJigouAssistant() {
        const self = this
        self.options = []
        self.$http({
          name: API.getJigouAssistant_Api.name,
          url: API.getJigouAssistant_Api.url,
          callback({
            code,
            data
          }) {
            if (code == 200) {
              self.options = data
            }
          }
        })
      },
      // 获取机构客服列表
      getCustomerList() {
        const self = this
        self.$http({
          name: API.getCustomerList_Api.name,
          url: API.getCustomerList_Api.url,
          callback(res) {
            if (res.code == 200) {
              self.customerOptions = res.data
            }
          }
        })
      },
      // 删除客服
      deleteCustomer() {
        const self = this
        self.$http({
          name: API.delCustomer_Api.name,
          url: API.delCustomer_Api.url,
          data: {
            customer_id: self.deleteCustomer_id
          },
          callback(res) {
            if (res.code == 200) {
              self.getCustomerList()
              self.$root.prompt({
                msg: '删除成功',
                type: 'success'
              })
              self.isShowDeleteCustomer = !self.isShowDeleteCustomer
              self.courseSettingInfo.customer_id = ''
            }
          }
        })
      }
    }
  }

</script>
<style lang="scss" scoped>
::v-deep .dayInput_select{
  .el-input-group__append {
        padding: 5px;
        font-size: 14px;
        color: #999999;
        background-color: #fff;
        position: absolute;
        right: 0;
        background: none;
        top: 0;
        height: 100%;
        border: 0;
        line-height: 36px;
        width: auto;
        padding: 0;
        padding-right: 10px;
      }

}
  .course_validity {
    display: flex;

    span {
      margin-left: 20px;
      margin-top: 10px;
      cursor: pointer;
      color: #0aa29b;
    }
  }

  .course-setting {
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: #1da9a2;
      background: #1da9a2;
    }

    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    ::v-deep input[type='number'] {
      -moz-appearance: textfield;
    }

    ::v-deep .el-radio__input.is-checked+.el-radio__label {
      color: #1da9a2;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #1da9a2;
      border-color: #1da9a2;
    }

    ::v-deep .el-checkbox__inner:hover {
      border-color: #1da9a2;
    }

    .for-width {
      ::v-deep .el-textarea {
        width: 618px;
      }
    }

    ::v-deep .el-input,
    ::v-deep .el-textarea__inner {
      width: 618px;
    }

    ::v-deep .el-checkbox {
      margin-right: 10px;
    }

    ::v-deep .el-textarea__inner:focus,
    ::v-deep .el-select .el-input.is-focus .el-input__inner,
    ::v-deep .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #1da9a2;
    }

    ::v-deep .el-checkbox__label {
      font-size: 12px;
    }

    ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #666666;
    }

    ::v-deep .el-form-item {
      &:first-child {
        .letter-limit {
          position: absolute;
          left: 578px;
          font-size: 12px;
          color: #999;
        }
      }
    }

    .setting-erweima {
      margin-right: 40px;
      font-size: 12px;
      color: rgba(10, 162, 155, 1);
      line-height: 16px;
    }

    .custom-num {
      margin: 0 30px;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }

    .custom-num+ ::v-deep.el-input {
      width: 140px;
      font-size: 12px;
    }

    .num-wrap {
      position: absolute;
      top: -20px;
      left: 424px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .txt {
        position: relative;
        top: 10px;
        font-size: 12px;
        color: rgba(255, 53, 53, 1);
      }

      .el-checkbox {
        position: relative;
        top: -10px;
      }
    }

    .success-save {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 7px 0 7px 208px;
      box-sizing: border-box;
      box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
      text-align: center;
      background: #fff;

      ::v-deep .el-button--success {
        width: 100px;
        color: #fff;
        background-color: #0aa29b;
        border-color: #0aa29b;
      }
    }
  }

  .is-edit {
    padding-top: 30px;

    ::v-deep .el-form-item {
      margin-bottom: 30px;
      padding-left: 0;
    }
  }

  .reset-txt {
    padding: 10px 20px 0 20px;
    margin-bottom: 70px;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    // line-height: 24px;
  }

  .saveButton-wrap {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 7px 0 7px 208px;
    box-sizing: border-box;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
    background: #fff;

    .addContentButton {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #0aa29b;
      color: #0aa29b;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: none;
      margin: 0;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      padding: 12px 30px;
      font-size: 14px;
      border-radius: 4px;
    }

    .saveButton {
      margin: 0 auto;
    }
  }

</style>
