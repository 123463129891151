<template>
  <fade-animation>
    <div class="tishi-box">
      <loading v-show="isLoading" pos="absolute" leftPos="0"></loading>
      <div class="courseLeft">
        <div class="tip">从课程添加</div>
        <!-- {{ durationDataBat }}course_id -->
        <div class="search">
          <el-input
            placeholder="输入课程名称搜索"
            v-model="requestParam.title"
            @keyup.enter.native="doSeach"
          ></el-input>
        </div>
        <div ref="scrollTopList" class="scroll-list simple-scrollar">
          <div
            class="courseList cp"
            v-for="(item, index) in resultInfo"
            :class="{ activeStatus: activeId == item.course_id }"
            @click.stop="
              searchSchedule(item.status, item.course_id, item.course_type)
            "
            :key="index"
            :style="`opacity:${item.status == 1 ? '1' : '0.5'}`"
          >
            <div class="mainPic">
              <img :src="item.photo" alt />
            </div>
            <div class="intro">
              <div class="title">{{ item.name }}</div>
              <div class="active">
                {{ item.status == 1 ? '已上架' : '已下架' }}
              </div>
            </div>
          </div>
          <div class="empty-wrap" style="height: 277px" v-show="isShowEmpty">
            <empty :isOld="false" :positionCenter="true"></empty>
          </div>
          <!--底部加载-->
          <infinite-loading
            @infinite="getList"
            spinner="spiral"
          ></infinite-loading>
        </div>
      </div>

      <div
        class="detail"
        :style="`overflow:${
          list2 != undefined && list2.length > 0 ? 'scroll' : 'hidden'
        }`"
      >
        <i class="el-icon-close cp" style="z-index: 80" @click="closeFn"></i>
        <div v-if="list2 != undefined && list2.length > 0">
          <div class="courseItem" v-for="(item, index) in list2" :key="index">
            <template v-if="item.is_directory == 1">
              <div class="chapterTitle">{{ item.name }}</div>
              <div
                class="courseDetail"
                v-for="(item2, index2) in item.children"
                :key="index2"
              >
                <div class="courseName">
                  {{ item2.startTime | formatTimeStamp('MM/dd') }}
                  {{ item2.startTime | formatTimeStamp('hh:mm') }} -
                  {{ item2.endTime | formatTimeStamp('hh:mm') }}
                  {{ item2.name }}
                </div>
                <div
                  class="addButton"
                  :class="{ addedButton: courseList[item2.correlation_id] }"
                  @click="addCourseList(item2)"
                >
                  {{ courseList[item2.correlation_id] ? '已' : '' }}添加
                </div>
              </div>
            </template>
            <template v-else>
              <div class="courseDetail">
                <div class="courseName">
                  {{ item.startTime | formatTimeStamp('MM/dd') }}
                  {{ item.startTime | formatTimeStamp('hh:mm') }} -
                  {{ item.endTime | formatTimeStamp('hh:mm') }} {{ item.name }}
                </div>
                <div
                  class="addButton"
                  :class="{ addedButton: courseList[item.correlation_id] }"
                  @click="addCourseList(item)"
                >
                  {{ courseList[item.correlation_id] ? '已' : '' }}添加
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="empty-wrap" v-show="isShowEmpty2">
          <empty :isOld="false" :positionCenter="true"></empty>
        </div>
        <!--底部加载-->
        <!-- <infinite-loading
          @infinite="schedule"
          spinner="spiral"
        ></infinite-loading>-->
      </div>
      <div class="btn-wrap">
        <button class="account-login-btn cancel-btn" @click="closeFn">
          取消
        </button>
        <button class="account-login-btn" @click.stop="submit">确定</button>
      </div>
    </div>
  </fade-animation>
</template>
<script>
import fadeAnimation from '@/components/FadeAnimation'
import API from '@/request/api'
import empty from '@/components/Empty'
import InfiniteLoading from '@/components/scrollLoad'
import pageOverflow from '@/mixin/pageOverflow'
import loading from '@/components/LoadGif'
// import { InfiniteScroll } from "element-ui";

export default {
  // directives: { InfiniteScroll },
  data() {
    return {
      requestParam: {
        course_type: '',
        price_type: '',
        status: '',
        title: '',
        course_id: '',
        is_sell: '2',
        page: 1,
      },
      requestParam2: {
        course_id: '',
        course_type: '',
        page: 1,
      },
      resultInfo: [],
      list2: [],
      activeId: '',
      courseList: {},
      // 显示空状态
      isShowEmpty: false,
      isLoading: false,
      isShowEmpty2: false,
    }
  },
  mixins: [pageOverflow],
  props: {
    durationDataBat: {
      type: Array,
    },
    course_type: [Number, String],
    editCourseId: [Number, String],
  },
  components: {
    fadeAnimation,
    InfiniteLoading,
    empty,
    loading,
  },
  created() {
    this.requestParam.course_type = this.course_type
    this.isShowEmpty2 = true
    if (this.requestParam.course_type == 4) {
      this.requestParam.is_sell = 2
    }
  },
  methods: {
    submit() {
      const tempObj = Object.keys(this.courseList)
      const result = []
      if (tempObj.length > 0) {
        tempObj.forEach((key) => {
          const item = this.courseList[key]
          const model = {
            correlation_type: 2,
          }
          result.push(_.assign({}, item, model))
        })
      }
      this.$emit('getTimeData', result)
      this.$emit('compelet')
      this.closeFn()
    },
    closeFn() {
      this.$emit('closeFn')
    },
    //关键字搜索
    doSeach(e) {
      const keyCode = window.event ? e.keyCode : e.which
      if (keyCode == 13) {
        if (this.finiteState) {
          this.requestParam.page = 1
          this.activeId = ''
          this.resultInfo = []
          this.list2 = []
          this.$nextTick(this.finiteState.reset)
        }
      }
    },
    getList($state) {
      const self = this
      if (self.editCourseId) {
        self.requestParam.course_id = self.editCourseId
      }
      const data = self.requestParam
      self.finiteState = $state
      self.$http({
        name: API.searchCourse_Api.name,
        url: API.searchCourse_Api.url,
        isMultipleSelf: 'replacePrev',
        data,
        callback(res) {
          if (res.code == 200) {
            self.requestParam.page += 1
            self.isShowEmpty = false
            self.resultInfo = self.resultInfo.concat(res.data)
            self.$nextTick($state.loaded)
          }
        },
        error({ code }) {
          if (code == 204) {
            if (self.requestParam.page == 1) {
              self.isShowEmpty = true
              self.resultInfo = []
            }
            $state.loaded()
            $state.complete()
          }
        },
      })
    },
    searchSchedule(status, course_id, course_type) {
      if (status == 2) {
        return
      }
      if (this.activeId == course_id) {
        return
      }
      this.list2 = []
      this.requestParam2.course_id = course_id
      this.requestParam2.course_type = course_type
      this.activeId = course_id
      this.schedule()
    },

    //章节列表
    schedule() {
      const self = this
      const data = self.requestParam2
      // const data = _.assign({}, self.requestParam2, { course_id: 8115 });
      // self.finiteState2 = $state;
      if (!data.course_id) {
        return
      }
      self.isLoading = true
      self.$http({
        name: API.searchSchedule_Api.name,
        url: API.searchSchedule_Api.url,
        isMultipleSelf: 'replacePrev',
        data,
        callback(res) {
          if (res.code == 200) {
            self.isLoading = false
            self.isShowEmpty2 = false
            if (res.data != undefined && res.data.length > 0) {
              self.list2 = res.data
              console.log(res)
            } else {
              self.list2 = []
            }
          }
        },
        error({ code }) {
          if (code == 204) {
            self.list2 = []
            self.isShowEmpty2 = true
            self.isLoading = false
          }
        },
      })
    },
    // 从课程添加
    addCourseList(item) {
      if (this.courseList[item.correlation_id]) {
        this.$delete(this.courseList, [item.correlation_id])
        return
      }
      // console.log(item)
      // console.log(this.resultInfo)
      const nn = this.durationDataBat.map((v) => v.content_id)
      const ii = nn.filter((v) => v == item.content_id)
      // console.log(ii)
      if (ii.length > 0) {
        this.$message({
          message: '请勿重复关联章节',
          type: 'warning',
        })
        return
      }
      this.courseList = Object.assign({}, this.courseList, {
        [item.correlation_id]: _.assign({}, item, { correlation_id: '' }),
      })
      // console.log(this.courseList)
    },
  },
}
</script>
<style lang="scss" scoped>
.tishi-box {
  position: fixed;
  display: flex;
  width: 800px;
  height: 518px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  .courseLeft {
    width: 218px;
    .scroll-list {
      height: 414px;
      overflow-y: scroll;
    }
    // overflow-y: scroll;
    .tip {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      padding: 14px 20px 20px 20px;
      box-sizing: border-box;
    }
    .search {
      height: 36px;
      margin-bottom: 10px;
      padding: 0 20px 9px 20px;
      box-sizing: border-box;
      ::v-deep .el-input__inner:focus {
        outline: none;
        border-color: #0aa29b;
      }
    }
    .courseList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      .mainPic {
        width: 68px;
        height: 38px;

        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .intro {
        width: 138px;

        .title {
          width: 109px;
          font-size: 14px;

          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .active {
          font-size: 12px;

          color: rgba(153, 153, 153, 1);
          // margin-top: 6px;
        }
      }
    }
    .activeStatus {
      background: #f5f5f5;
    }
  }
  .courseLeft::-webkit-scrollbar {
    width: 4px;
    background-color: lightgray;
    // background-color: #f5f5f5;
  }

  .detail {
    flex: 1;
    background: rgba(245, 245, 245, 1);
    height: 85%;
    overflow-y: scroll;
    padding-top: 20px;
    i {
      position: absolute;
      top: 14px;
      right: 20px;
    }
    .empty-wrap {
      position: relative;
      height: 440px;
    }
    .courseItem {
      padding: 14px 20px 0px 20px;
      box-sizing: border-box;
      .chapterTitle {
        width: 440px;
        font-size: 14px;

        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .courseDetail {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        height: 26px;
        line-height: 26px;
        .courseName {
          width: 440px;
          font-size: 14px;

          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .addButton {
          width: 52px;
          height: 26px;
          font-size: 12px;
          cursor: pointer;
          color: rgba(10, 162, 155, 1);
          line-height: 26px;
          text-align: center;
          border-radius: 2px;
          border: 1px solid rgba(10, 162, 155, 1);
        }
        .addedButton {
          width: 52px;
          height: 26px;
          line-height: 25px;
          cursor: pointer;
          text-align: center;
          background: rgba(10, 162, 155, 1);
          border-radius: 2px;
          font-size: 12px;

          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .detail::-webkit-scrollbar {
    width: 4px;
    background-color: lightgray;
    // background-color: #f5f5f5;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  // div {
  //   display: flex;
  //   align-items: center;
  //   justify-content: flex-end;
  //   span {
  //     font-size: 14px;
  //     color: rgba(27, 157, 151, 1);
  //     line-height: 19px;
  //     cursor: pointer;
  //   }
  //   button {
  //     width: 68px;
  //   }
  // }
}
.btn-wrap {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(245, 245, 245, 1);
  width: 542px;
  height: 15%;
  span {
    font-size: 14px;
    color: rgba(27, 157, 151, 1);
    line-height: 19px;
    cursor: pointer;
  }
  button {
    width: 68px;
  }
  padding: 0 20px;
}
.small-pop {
  width: 422px;
}
.account-login-btn {
  cursor: pointer;
  border: 0;
  color: #fff;
  width: 100%;
  height: 36px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  background: #0aa29b;
  border-radius: 2px;
}
.cancel-btn {
  margin-right: 14px;
  background: #fff;
  border: 1px solid rgba(234, 234, 234, 1);
  color: rgba(102, 102, 102, 1);
}
</style>
