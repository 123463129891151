<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2019-12-03 16:31:49
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-15 00:10:15
 -->
<template>
  <InfiniteLoading @infinite="infinite">
    <div slot="no-more">
      <div v-if="empty" class="empty" :class="{ noPosition }">
        <img class="img" src="https://img.dingdingkaike.com/img_kzt_kcgl@2x.png?imageslim" />
        <div class="text">{{ text }}</div>
      </div>
    </div>
    <div slot="error" slot-scope="{ trigger }">
      <i @click="trigger">加载出错了，请点击重新尝试...</i>
    </div>
  </InfiniteLoading>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'vue-infinite-loading',

  components: {
    InfiniteLoading
  },

  props: {
    empty: Boolean,
    text: {
      type: String,
      default: '暂无内容'
    },
    noPosition: Boolean
  },

  methods: {
    infinite($state) {
      this.$emit('infinite', $state)
    }
  }
}
</script>
<style lang="scss" scoped>
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
  &.noPosition {
    position: static;
    transform: none;
    padding: 2rem 0;
  }
  > .img {
    width: 8.4rem;
    height: 8.4rem;
    margin: 0 auto;
  }
  > .text {
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.1rem;
    margin-top: 1.35rem;
    white-space: nowrap;
    color: rgba(153, 153, 153, 1);
  }
}
</style>
