<template>
  <div class="posterTemplate posterTemplate8">
    <img src="https://img.dingdingkaike.com/FiUV6MkmT7pYRzXiS7gfjPywyMny?imageslim" alt="背景图" class="bg-img" />
    <div>
      <p class="jgName">
        <span class="t1">{{ posterData.jg_title | str }}</span>
      </p>
      <div class="block-1">
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
        <p class="t6">
          <!-- <span class="t8">{{posterData.time}}&nbsp;</span> -->
          <!-- <span class="t7" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">
            {{ posterData.cou_endtime }}前报名
            {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}
          </span> -->
        </p>
      </div>
      <div class="block-2">
        <div class="right">
          <div class="right-t">
            <img class="t1" :src="posterData.uphoto" />
            <div class="right-t-r">
              <p class="t2">{{ posterData.uname | unameStr }}</p>
              <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
            </div>
          </div>
          <div class="right-b">
            <div>
              <p class="t1">长按识别二维码</p>
              <p class="t2">{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
            </div>
          </div>
        </div>
        <div class="left">
          <div class="erweima" ref="qrcode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },
  name: 'posterTemplate8',
  filters: {
    str(value) {
      return value.length > 4 ? `${value.slice(0, 4)}...` : value
    },
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(3.05 * 3, this.$refs.qrcode);
    this.$emit('createEwm', 3, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.ellipsis-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.posterTemplate8 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 29px;
    right: 29px;
    top: 85px;
  }
  .block-1 {
    margin-top: 8px;
    .t4 {
      width: 242px;
      height: 135px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      height: 36px;
      font-size: 13px;
      position: relative;
      line-height: 18px;
      margin-top: 10px;
      word-break: break-all;
    }
    .t6 {
      color: #999;
      display: flex;
      font-size: 10px;
      margin-top: 8px;
      align-items: center;
      .t7 {
        width: 133px;
        color: #fff;
        font-size: 9px;
        text-align: center;
        line-height: 16px;
        white-space: nowrap;
        border-radius: 11px;
        background-color: #ff0000;
      }
      .t8 {
        flex: 1;
        margin-right: 14px;
      }
    }
  }
  .block-2 {
    position: relative;
    display: flex;
    padding: 41px 0 0 0;
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 1px;
      .right-t {
        display: flex;
        align-items: center;
        > .t1 {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .right-t-r {
          color: #999;
          margin-left: 10px;
          .t2 {
            width: 108px;
            font-size: 10px;
            line-height: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .t3 {
            font-size: 11px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .right-b {
        font-size: 0;
        margin-top: 6px;
        > div {
          padding: 2px;
          display: inline-block;
          border: 1px dashed #ff6c00;
          p {
            color: #ff6c00;
            font-size: 10px;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
          }
          .t2 {
            margin-left: 4px;
          }
        }
      }
    }
    .left {
      margin-left: 0.3rem;
      .erweima {
        width: 61px;
        height: 61px;
      }
    }
  }
  .jgName {
    color: #999;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    > .t1 {
      position: relative;
      line-height: 0.6rem;
      &::before {
        position: absolute;
        left: -0.4rem;
        top: 50%;
        content: '';
        width: 3px;
        height: 3px;
        background-color: #999;
        border-radius: 50%;
      }
      &::after {
        position: absolute;
        right: -0.4rem;
        top: 50%;
        content: '';
        width: 3px;
        height: 3px;
        background-color: #999;
        border-radius: 50%;
      }
    }
  }
}
</style>
