export const BTypeOption = [
    {
        "value": "个人销售自产农副产品",
        "label": "个人销售自产农副产品"
    },
    {
        "value": "个人销售家庭手工业产品",
        "label": "个人销售家庭手工业产品"
    },
    {
        "value": "个人利用自己的技能从事便民劳务活动",
        "label": "个人利用自己的技能从事便民劳务活动"
    },
    {
        "value": "个人从事零星小额交易活动",
        "label": "个人从事零星小额交易活动"
    }
]
// export const BTypeOption = [
//     {
//         "value": "personal_agricultural",
//         "label": "个人销售自产农副产品"
//     },
//     {
//         "value": "personal_handicraft",
//         "label": "个人销售家庭手工业产品"
//     },
//     {
//         "value": "personal_skills_service",
//         "label": "个人利用自己的技能从事便民劳务活动"
//     },
//     {
//         "value": "personal小额交易",
//         "label": "个人从事零星小额交易活动"
//     }
// ]

export const IdCardOption = [
    {
        "value": "中国大陆居民身份证",
        "label": "中国大陆居民身份证"
    },
    {
        "value": "中国港澳台来往内地通行证",
        "label": "中国港澳台来往内地通行证"
    },
    {
        "value": "中国永居证",
        "label": "中国永居证"
    },
    {
        "value": "其他国家或地区居民护照",
        "label": "其他国家或地区居民护照"
    },
]
export const PersonIdCardOption = [
    {
        "value": "中国大陆居民身份证",
        "label": "中国大陆居民身份证"
    },
    {
        "value": "中国港澳台来往内地通行证",
        "label": "中国港澳台来往内地通行证"
    },
]