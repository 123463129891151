/*
 * @Descripttion: 接口地址
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-26 11:29:50
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:27:56
 */
const API = {
  // ivan
  // 获取机构的分类列表
  getSetList_Api: {
    url: '/CourseSet/getSetList',
    name: 'getSetList'
  },
  // 创建分类
  addCourseSet_Api: {
    name: 'addCourseSet',
    url: '/CourseSet/addCourseSet'
  },
  // 编辑分类时 获取信息
  editCourseSet_Api: {
    name: 'editCourseSet',
    url: '/CourseSet/editCourseSet'
  },
  // 删除分类
  delCourseSet_Api: {
    name: 'delCourseSet',
    url: '/CourseSet/delCourseSet'
  },
  // 获取课程列表
  courseAdmin_Api: {
    name: 'courseAdmin',
    url: '/Course/courseAdmin'
  },
  // 上下架课程
  isShelves_Api: {
    name: 'isShelves',
    url: '/Course/isShelves'
  },
  // 管理删除课程
  delCourse_Api: {
    name: 'delCourse',
    url: '/Course/delCourse'
  },
  // 评论列表
  editCourseReview_Api: {
    name: 'editCourseReview',
    url: '/Course/editCourseReview'
  },
  // 订单列表
  allOrder_Api: {
    name: 'allOrder',
    url: '/Order/allOrder'
  },
  //作废订单 删除订单 恢复订单
  unsetOrder_Api: {
    name: 'unsetOrder',
    url: '/Order/unsetOrder'
  },
  // 分享课程
  coursePosterShare_Api: {
    name: 'coursePosterShare',
    url: '/Share/coursePosterShare'
  },
  // 更改评论是否需要审核
  updateComment_Api: {
    name: 'updateComment',
    url: '/Course/updateComment'
  },
  // 删除评论
  delCourseReview_Api: {
    name: 'delCourseReview',
    url: '/Course/delCourseReview'
  },
  // 批量显示、批量隐藏、单个显示、单个隐藏
  statusCourseReview_Api: {
    name: 'statusCourseReview',
    url: '/Course/statusCourseReview'
  },
  // 课程管理 一课程查询
  searchCourse_Api: {
    name: 'searchCourse',
    url: '/Course/searchCourse'
  },
  // 上课观看地址
  getPlayUrl_Api: {
    name: 'getPlayUrl',
    url: '/Course/getPlayUrl'
  },
  // 重置口令
  resetCommand_Api: {
    name: 'resetCommand',
    url: '/Course/resetCommand'
  },
  //创建客服
  insertCustomer_Api: {
    name: 'insertCustomer',
    url: '/Customer/insertCustomer'
  },
  // 获取机构客服列表
  getCustomerList_Api: {
    name: 'getCustomerList',
    url: '/Customer/getCustomerList'
  },
  // 编辑客服
  editCustomer_Api: {
    name: 'editCustomer',
    url: '/Customer/editCustomer'
  },
  // 删除客服 Customer/delCustomer
  delCustomer_Api: {
    name: 'delCustomer',
    url: '/Customer/delCustomer'
  },

  // 获取机构老师
  getJigouTeacher_Api: {
    name: 'getJigouTeacher',
    url: '/Course/getJigouTeacher'
  },
  // 上传视频文件
  uploadVideoFile_Api: {
    name: '',
    url: '/Course/uploadVideoFile'
  },
  // 课程管理-回放视频
  playBackCourse_Api: {
    name: 'playBackCourse',
    url: '/Course/playBackCourse'
  },
  // 课程管理-章节列表
  searchSchedule_Api: {
    name: 'searchSchedule',
    url: '/Course/searchSchedule'
  },
  // 课程管理-回放视频片段
  getScheduleLiveRecording_Api: {
    name: 'getScheduleLiveRecording',
    url: '/Course/getScheduleLiveRecording'
  },
  // 获取机构助教
  getJigouAssistant_Api: {
    name: 'getJigouAssistant',
    url: '/Organ/getJigouAssistant'
  },
  // 编辑 获取/保存基础信息
  editCourseBase_Api: {
    name: '/editCourseBase',
    url: '/Course/editCourseBase'
  },
  // 编辑 获取/保存课程设置
  editCourseSetting_Api: {
    name: 'editCourseSetting',
    url: '/Course/editCourseSetting'
  },
  // 编辑时获取课程分类
  getCourseSet_Api: {
    name: 'getCourseSet',
    url: '/CourseSet/getCourseSet'
  },
  // 关联内容
  editCourseSell_Api: {
    name: 'editCourseSell',
    url: '/Course/editCourseSell'
  },
  getGuideInfo_Api: {
    name: 'getGuideInfo',
    url: '/Course/getGuideInfo'
  },
  // 编辑章节/内容
  editScheduleInfo_Api: {
    name: 'seriesEditScheduleInfo',
    url: '/Course/seriesEditScheduleInfo'
  },
  // 删除关联内容
  delCourseSell_Api: {
    name: 'delCourseSell',
    url: '/Course/delCourseSell'
  },
  // 删除内容管理（系列课）
  delCourseSeries_Api: {
    name: 'delCourseSeries',
    url: '/Course/delCourseSeries'
  },
  // 获取默认二维码
  getDefaultCode_Api: {
    name: 'getDefaultCode',
    url: '/Organ/getDefaultCode'
  },
  // ivan
  // 创建课程
  createCourse_Api: {
    name: 'createCourse',
    url: 'Course/createCourse'
  },
  // 公开课查询
  openClassList_Api: {
    name: 'openClassList',
    url: '/openClass/openClassList'
  },
  
  // 店铺认证/修改认证
  certActivation_Api: {
    name: 'activation',
    url: '/certification/activation'
  },
  // 获取认证数据
  certGetActivation_Api: {
    name: 'getActivation',
    url: '/certification/getActivation'
  },
  // 获取机构管理员手机号
  certGetJigouMobile_Api: {
    name: 'getJigouMobile',
    url: '/certification/getJigouMobile'
  },
  // 获取审核状态
  certGetStatus_Api: {
    name: 'getStatus',
    url: '/certification/getStatus'
  },

}
export default API
