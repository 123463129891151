<template>
  <div :style="`margin-bottom: -25px;${editCourseId ? 'padding-bottom: 54px;' : ''}`
    ">
    <template v-if="courseType == 3 && !isMoving && !isSortStart">
      <div class="mode_type">
        <div class="mode">
          当前章节模式：{{
            modifyModeData.chapter_mode == 1
            ? '自由模式'
            : modifyModeData.chapter_mode == 2
              ? '闯关模式'
              : '日期模式'
          }}
        </div>
        <el-button type="text" class="ml30" @click="modestatus = true">
          修改模式
        </el-button>
      </div>

      <div class="mode_tip">
        {{
          modifyModeData.chapter_mode == 1
          ? '无任务观看限制，学生可随意观看'
          : modifyModeData.chapter_mode == 2
            ? '完成学习条件后自动解锁下一章节（学习条件在编辑中设置）'
            : '指定解锁时间或指定解锁周期'
        }}
      </div>
    </template>

    <!--顶部添加章节-->
    <div v-if="!isSortStart && !isMoving" class="header">
      <div class="leftBox">
        <div class="newTimeButton"
          @click="resetEditData(), (courseType == 3 ? toggleshowVideoSection() : toggleCreateSh())">
          添加章节
        </div>
        <el-button style="width: 126px;  " class="ml10" type="primary" @click="chooseCourse()" v-if="courseType == 3">
          批量添加
        </el-button>
        <div class="addParentTitleBtn" @click="addParentTitle">添加父标题</div>
        <div class="fromCourseButton" v-if="courseType != 3">
          <i class="vm text" @click="showFromCourseAdd = true">关联章节</i>
          <i class="vm help" @click.stop="showJoinPs = !showJoinPs"></i>
          <div class="help-ps" v-if="showJoinPs" @click.stop :style="`${editCourseId ? 'left: 113px;top: 0;' : ''}`">
            可将已创建的课程章节关联在当前该课程，关联后，同一个关联的章节学员可在一个直播间上课
          </div>
        </div>

        <el-button v-if="courseType == 3" style="width: 106px;  " type="text" @click="courseMove">
          批量移动
        </el-button>
      </div>

      <!-- <div class="tips">
        提示：拖拽章节可上下排序；添加了父标题，可将小节拖入父标题内
        <template v-if="isEdit">
          ，如章节被其他课程关联， 删除后会自动取消关联
        </template>
      </div> -->

      <div>
        <el-button @click="sortStartClick" style="width: 126px; color: #1b9d97; border-color: #1b9d97" class="mr20">
          章节排序
        </el-button>

        <!-- <div class="buttonAdds" >
          <p>批量添加</p>
        </div> -->
      </div>
    </div>
    <div v-else-if="isMoving" class="flex-center" style="justify-content:space-between">
      <div class="text2">
        提示：您正在进行批量移动操作，请勾选需要移动的子章节
      </div>
      <div class="flex-center">
        <el-input @keyup.enter.native="toSearch" size="medium" style="width:218px" placeholder="输入名称搜索" v-model="search"
          class="mr20"></el-input>
        <el-button type="primary" class="mr20 " @click="toSearch">
          搜索
        </el-button>
      </div>
    </div>
    <div v-else class="sort-tip">
      <div class="text">
        提示：拖拽章节进行上下排序；添加了父标题，可将小节拖入父标题内
        ，如章节被其他课程关联， 删除后会自动取消关联
      </div>
    </div>
    <!--章节列表-->

    <pagination2 :option="post" url="/Course/editScheduleInfo" ref="child" @complete="getEditData">
      <template v-slot:default>
        <!-- {{ tableData }} -->
        <div id="sortable-contain">
          <div v-for="(value, key) in durationDataBat" :key="value.id" class="sort-schedule-item" :data-key="key">
            <!--章节目录-->
            <div v-if="value.is_directory == 1 && value.name" class="parent-title" :style="`${hideChildContainIds.indexOf(value.id) > -1
              ? 'margin-bottom:10px;'
              : ''
              }`
              ">
              <span v-if="value.children && value.children.length > 0"
                @click="publicFnHandle({ key, isChildSchedule: false }, 0)" class="draw-icon"
                :class="{ none: hideChildContainIds.indexOf(value.id) > -1 }"></span>
              <span class="title">{{ value.name }}</span>
              <div :class="{ hidden: isSortStart || isMoving }" class="btns">
                <span class="btn" title="编辑" @click="publicFnHandle({ key, isChildSchedule: false }, 1)">
                  编辑
                </span>
                <span class="space">|</span>
                <span class="btn" title="删除" @click="publicFnHandle({ key, isChildSchedule: false }, 2)">
                  删除
                </span>
              </div>
            </div>
            <!--子章节-->
            <div :style="`${value.is_directory == 1 && value.name
              ? 'margin-left:20px;'
              : ''
              }${value.children && value.children.length > 0
                ? 'padding: 5px 0 0;'
                : ''
              }`
              " :class="{
    'nested-sortable':
      (value.children && value.children.length > 0) ||
      value.is_directory == 1,
  }" :data-key="key" v-show="hideChildContainIds.indexOf(value.id) === -1">
              <div class="contain-shedule" v-for="(item, index) in value.is_directory == 2
                ? [value]
                : value.children" :key="item.id" @click="moveCheckChange($event, item, key, index)"
                :class="isMoving ? 'cp' : ''">
                <div class="sort-child-schedule">
                  <div v-if="!isMoving && !isSortStart" class="newschedule" @click="
                    courseType == 3
                      ? toggleshowVideoSection(
                        item.correlation_id,
                        item.psl_id,
                        2
                      )
                      : toggleCreateSh(item.correlation_id, 2)
                    ">
                    在此添加章节
                  </div>
                  <div v-if="!isMoving && !isSortStart" class="newschedule2" @click="
                    courseType == 3
                      ? toggleshowVideoSection(
                        item.correlation_id,
                        item.psl_id,
                        1
                      )
                      : toggleCreateSh(item.correlation_id, 1)
                    ">
                    在此添加章节
                  </div>
                  <el-checkbox v-if="isMoving" :value="item.checked" :ref="`checkbox${key}${index}`"
                    :key="`${key}${index}`" @change="moveCheckChange($event, item, key, index)"></el-checkbox>
                  <!-- 回放图片 -->
                  <div v-if="courseType == 1 && item.live_mode == 1" class="replay-icon" :class="{
                    active: item.live_recall == 1,
                    hidden: isSortStart || isMoving,
                  }"></div>
                  <div class="try-it-icon" :class="{
                    active: item.is_play==1
                  }"></div>
                  <!-- 定时图片 -->
                  <!-- <div
                    v-if="courseType == 3 && item.startTime"
                    class="timing-icon"
                    :class="{ hidden: isSortStart }"
                  ></div> -->
                  <!-- <div class="time" v-if="courseType == 3">{{ item.file_name }}</div>
                  <div class="time" v-else>{{ item.startTime }}</div> -->
                  <div class="type" v-if="courseType == 1 && isSpeedLive">
                    {{
                      item.live_mode == 1
                      ? '普通'
                      : item.live_mode == 2
                        ? '极速'
                        : '关联公开课'
                    }}模式
                  </div>
                  <div class="name" style="display: flex;" :class="{
                          lookPlay:
                            item.is_play == 1 &&
                            (courseType == 1 || courseType == 3) &&
                            (item.live_mode == 1 || courseType == 3),
                        }">
                    <span class="text hidetext " :title="item.name" style="flex: 1;">
                      {{ item.name }}
                    </span>
                    <span class="text hidetext" :title="item.open_class_name" style="flex: 1;">
                      {{ item.open_class_name }}
                    </span>
                    <span class="look-icon" v-if="item.play_time">
                      {{ item.play_time }}
                    </span>
                  </div>
                  <span class="time_show" v-if="item.unlocking_time && courseType == 3">
                    解锁时间： {{ item.unlocking_time | formatTimeStamp }}
                  </span>
                  <span class="time_show" v-else-if="courseType != 3">
                    上课时间: {{ item.startTime }}
                  </span>
                  <div class="classHour" :class="{ blue: !item.audio_id, red: item.audio_id }" v-if="courseType == 3">
                    <template>{{ item.type|tagName }}</template>
                  </div>
                  <div class="classHour" v-if="courseType != 3">
                    {{ item.classHourTm }}
                  </div>
                  <div class="teacher-name">
                    {{
                      teacherInfo[item.teacher_id]
                      ? teacherInfo[item.teacher_id].uname
                      : '未选择老师'
                    }}
                  </div>
                  <!-- {{ item.checked }} -->
                  <div :class="{ hidden: isSortStart || isMoving }" class="btns">
                    <template v-if="item.correlation_type == 1">
                      <template v-if="isEdit && courseType != 3 && item.correlation_id">
                        <template v-if="item.open_class_id > 0">
                          <classesBegin type="4" :item="item" class="btn">
                            <span title="进入教室">进入教室</span>
                          </classesBegin>
                          <span class="space">|</span>
                          <span class="btn" @click="
                            $router.push(
                              `/publicClass/detail/${item.open_class_id}/1`
                            )
                            ">
                            详情
                          </span>
                          <span class="space">|</span>
                        </template>
                        <template v-else>
                          <classesBegin type="1" :item="item" class="btn">
                            <span title="进入教室">进入教室</span>
                          </classesBegin>
                          <span class="space">|</span>
                          <span class="btn" title="邀请" @click="
                            publicFnHandle(
                              {
                                key,
                                isChildSchedule: value.children.length > 0,
                                index,
                              },
                              5,
                              item.live_mode
                            )
                            ">
                            邀请
                          </span>
                          <span class="space">|</span>
                        </template>
                      </template>
                      <template v-if="courseType == 3">
                        <span class="btn" title="编辑" @click="
                          publicFnHandle(
                            {
                              key,
                              isChildSchedule: value.children.length > 0,
                              index,
                            },
                            3
                          )
                          ">
                          编辑
                        </span>
                        <span class="space">|</span>
                        <span class="btn" @click="
                          publicFnHandle(
                            {
                              key,
                              isChildSchedule: value.children.length > 0,
                              index,
                            },
                            4
                          )
                          ">
                          {{ item.open_class_id > 0 ? '取消关联' : '删除' }}
                        </span>
                      </template>
                      <el-dropdown v-if="courseType != 3">
                        <span class="btn" title="更多">更多</span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-if="item.live_mode == 1">
                            <p title="查看回放" @click="watchBackVideo(value, index)">
                              查看回放
                            </p>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <p title="编辑" @click="
                              publicFnHandle(
                                {
                                  key,
                                  isChildSchedule: value.children.length > 0,
                                  index,
                                },
                                3
                              )
                              ">
                              编辑
                            </p>
                          </el-dropdown-item>

                          <el-dropdown-item>
                            <p title="删除" @click="
                              publicFnHandle(
                                {
                                  key,
                                  isChildSchedule: value.children.length > 0,
                                  index,
                                },
                                4
                              )
                              ">
                              {{ item.open_class_id > 0 ? '取消关联' : '删除' }}
                            </p>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                    <template v-else>
                      <span class="btn join-btn" title="取消关联" @click="
                        publicFnHandle(
                          {
                            key,
                            isChildSchedule: value.children.length > 0,
                            index,
                          },
                          6
                        )
                        ">
                        取消关联
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </pagination2>

    <!-- 批量移动父标题弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="422px" :close-on-click-modal="false" v-if="dialogVisible">
      <div slot="title">
        <span class="mr30">移动到</span>
        已选 {{ checkId.length }} 节
      </div>
      <el-form label-position="left">
        <el-form-item label="父标题">
          <el-select v-model="psl_id" size="medium" style="width:295px" class="ml20" placeholder="请选择">
            <el-option v-for="item in optionsFather" :key="item.id" :label="item.name"
              :value="item.correlation_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="moveSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!--添加 编辑 章节-->
    <newSchedule v-if="showCreateSh" :editData="editData" :courseType="courseType" @close="toggleCreateSh"
      @save="addSchedule(arguments[0]), compelet(arguments[0])"></newSchedule>

    <!--添加父标题-->
    <transition name="fade">
      <div v-if="showParentTitle" class="fixed addParentTitleContain">
        <div class="content">
          <div class="title">{{ editOption ? '编辑' : '添加' }}父标题</div>
          <div class="input">
            <input v-model="parenTitle" class="ip" maxlength="30" @keyup.enter="saveParentTitle"
              placeholder="填写父标题，如：第一章" />
            <span class="max">{{ parenTitle.length }}/30</span>
          </div>
          <div class="draw">
            <el-checkbox v-model="is_unfold" :true-label="1" :false-label="2" :checked="is_unfold == 1">
              默认展开
            </el-checkbox>
            <div class="help" @click.stop="drawPs = !drawPs"></div>
            <div class="help-ps" v-if="drawPs" @click.stop>
              勾选后，在课程详情页显示父标题为默认展开状态
            </div>
          </div>
          <div class="btns">
            <button class="vm b1" @click="addParentTitle(), (drawPs = false)">
              取消
            </button>
            <button class="vm b2" @click="saveParentTitle">保存</button>
          </div>
        </div>
      </div>
    </transition>
    <!--父标题删除-->
    <CourseConfirm v-if="removeParentTitle" title="删除父标题" :isSmallPop="true" @cancelFn="toggleremoveParentTitle"
      @save="removeParentTitleStart">
      <h4 class="reset-txt">是否删除该父标题！</h4>
      <div class="checkbox-wrap" :class="{ 'is-choose': !removeAllSchedule }">
        <el-checkbox label="同时删除标题内的节" v-model="removeAllSchedule"></el-checkbox>
        <p v-if="removeAllSchedule">删除后无法恢复，请慎重操作</p>
      </div>
    </CourseConfirm>
    <!--上课邀请-->
    <invitePop :live_mode="live_mode2" :courseType="courseType" :editData="editData" v-if="editCourseId && showInvitePop"
      @close="; (showInvitePop = false), resetEditData()" @updateEdit="setScheduleInfo"></invitePop>
    <!--课程关联-->
    <fromCourseAdd v-if="showFromCourseAdd" :durationDataBat="durationDataBat" :course_type="courseType"
      :editCourseId="editCourseId" @closeFn="showFromCourseAdd = false"
      @getTimeData="addSchedule(arguments[0]), compelet(arguments[0])"></fromCourseAdd>
    <!--上传编辑视频-->
    <editSection v-if="showVideoSection" @closeFn="toggleshowVideoSection"
      @complete="addSchedule(arguments[0]), compelet(arguments[0])" :editData="editData" :psl_id="psl_id"></editSection>
    <!-- <loading v-show="isNoData" leftPos="0"></loading> -->
    <!--编辑页面 取消 保存-->
    <div v-if="editCourseId && (isSortStart || isMoving)" class="lh edit-button">
      <button class="vm cancel mr20" @click="editCancel">取消</button>
      <button class="vm save" @click="sortSave" v-if="!isMoving">
        保存
      </button>
      <button v-else class="vm save" @click="showDialog">
        {{ `移动到(${checkId.length})` }}
      </button>
    </div>
    <fileListAndFileUpload v-if="dialogSelectVisible" title1="批量添加" @close="dialogSelectVisible = false" :selection="2"
      :teacherInfo="teacherInfo" @complete="gbDialog_data"></fileListAndFileUpload>

    <!-- 修改模式 -->
    <modify-mode v-if="modestatus" @modeSubmit="modeSubmit" :modifyModeData="modifyModeData"
      :dialogstatus.sync="modestatus"></modify-mode>
  </div>
</template>
<script>
import modifyMode from './modifyMode'
import fileListAndFileUpload from '@/components/fileListAndFileUpload'
import { mapState } from 'vuex'
// 创建编辑直播 小班章节
import newSchedule from '@/components/coursePop/newSchedule'
// 父标题删除
import CourseConfirm from '@/components/CourseConfirm'
// 上课邀请
import invitePop from '@/components/coursePop/invitePop'
// 课程关联
import fromCourseAdd from '@/components/coursePop/FromCourseAdd'
// request
import API from '@/request/api'
// 排序
import Sortable from 'sortablejs'
// 上传编辑视频
import editSection from '@/components/coursePop/editSection'
// import loading from '@/components/LoadGif'
import { mapGetters } from 'vuex'

export default {
  name: 'AddSection',

  props: {
    isEdit: Boolean,
    editCourseId: String,
    propsDurationData: Array,
    courseType: [Number, String],
  },

  components: {
    modifyMode,
    // loading,
    invitePop,
    editSection,
    newSchedule,
    fromCourseAdd,
    CourseConfirm,
    fileListAndFileUpload,
  },

  data() {
    return {
      father: [],
      reset: true,
      checkId: [],
      psl_id: '',
      optionsFather: [],
      isMoving: false,
      modifyModeData: {},
      modestatus: false,

      search: '',
      post: {
        search: '',
        all: 2,
        course_id: this.editCourseId,
        course_type: this.courseType,
      },

      //是否有进入控制台的权限
      consoleInfostatus: false,

      dialogSelectVisible: false, //控制选择课程

      secondList: [],

      aa: false,

      live_mode2: 20,

      // 显示关联章节提示
      showJoinPs: false,

      dialogVisible: false,

      // 编辑的数据
      template: {
        correlation_id: '',
        jg_id: '',
        course_id: '',
        type: '',
        correlation_type: 1,
        correlation_target_id: '',
        content_id: '',
        sort: '',
        is_directory: 2,
        psl_id: '',
        is_unfold: 1,
        c_time: '',
        d_time: '',
        sl_id: '',
        name: '',
        startTime: '',
        endTime: '',
        classHour: '',
        teacher_id: '',
        live_recall: 2,
        is_play: 2,
        student_command: '',
        teacher_command: '',
        assistant_command: '',
        children: [],
      },

      editData: {},

      parenTitle: '',

      is_unfold: 1,

      // 关联课程弹窗
      showFromCourseAdd: false,

      // 上课邀请弹窗
      showInvitePop: false,

      // 开启 关闭 创建章节
      showCreateSh: false,

      // 添加父标题
      showParentTitle: false,

      // 删除父标题
      removeParentTitle: false,
      removeAllSchedule: false,

      // 章节列表
      durationData: [],
      durationDataBat: [],

      // 老师列表
      teacherInfo: {},

      // sortable 顶层父类实例
      parentSort: {},

      // sortable 子类实例
      childSort: [],

      // 隐藏子章节
      hideChildContainIds: [],

      // 上传编辑视频弹窗
      showVideoSection: false,

      // 默认展开提示
      drawPs: false,
    }
  },
  filters:{
    tagName(val){
      let str = ''
      switch (Number(val)) {
        case 1:
          str = '视频'
          break;
        case 2:
          str = '音频'
          break;
        case 3:
          str = '回放'
          break;
      }
      return str
    },
  },
  watch: {
    durationData: {
      handler(value) {
        this.$emit('titleChange', 2, value.length)
      },
    },
  },

  computed: {
    // checkId() {
    //   const ids = this.durationDataBat
    //     .filter(item => item.checked)
    //     .map(item2 => item2.correlation_id)
    //   return ids
    // },

    editOption() {
      return Object.keys(this.editData).length > 0
        ? this.editData.editOption
        : null
    },
    ...mapState(['isWindowsApp', 'userInfo']),
    ...mapGetters(['isVersionFree', 'isSpeedLive']),

    // 是否开始了排序
    isSortStart() {
      return !!this.parentSort.destroy
    },
  },

  created() {
    this.getJigouTeacher()
    // if (this.editCourseId) {
    //   // 录播 直播
    //   this.getEditData()
    // }

    window.addEventListener('click', this.hideHelpPs)
  },

  destroyed() {
    window.removeEventListener('click', this.hideHelpPs)
  },

  mounted() {
    if (this.propsDurationData && this.propsDurationData.length > 0) {
      this.durationData = _.concat(
        [],
        this.transScheduleData(this.propsDurationData)
      )
      this.refreshList()
    }
    this.$emit('leaveConfirm', 1)
    this.getDirectoryList()
  },

  methods: {
    consoleInfo(val) {
      return new Promise(resolve => {
        this.$http({
          url: '/console/checkTeacher',
          mute: true,
          data: {
            open_class_id: val,
          },
          callback: () => {
            this.consoleInfostatus = false

            resolve()
          },
          error: () => {
            this.consoleInfostatus = true
            resolve()
          },
        })
      })
    },

    //控制台
    consoleClick(open_class_id) {
      this.consoleInfo(open_class_id).then(() => {
        if (this.consoleInfostatus) {
          this.$root.prompt('直播老师和该公开课助教老师可进入控制台！')
        } else {
          let url = this.$router.resolve({
            path: '/OpenClass_openClassLive/' + open_class_id,
          })
          this.$store.dispatch('open2', { type: 7, url: url.href })

          if (this.$store.state.isWindowsApp) {
            this.sendToWindowsSizeInOpenClass({ width: 300, height: 653 })
          }
        }
      })
    },

    showDialog() {
      if (this.checkId.length == 0) {
        this.$root.prompt('请先选择章节')
        return
      }
      this.getDirectoryList()
      this.dialogVisible = true
    },

    toSearch() {
      this.post.search = this.search
    },

    // 更改checkBox的数据
    moveCheckChange(val, item, key, index) {
      if (item.isChild) {
        this.durationDataBat[key].children[index].checked = !this
          .durationDataBat[key].children[index].checked
      } else {
        this.durationDataBat[key].checked = !this.durationDataBat[key].checked
      }

      this.durationDataBat = JSON.parse(JSON.stringify(this.durationDataBat))
      this.$forceUpdate()

      this.father = []
      this.getCheck(this.durationDataBat)
      this.checkId = this.father
        .filter(item => item.checked)
        .map(item2 => item2.correlation_id)
    },

    // 去除父节点的数据
    getCheck(val) {
      val.forEach(item => {
        if (item.children.length > 0) {
          this.getCheck(item.children)
        } else {
          this.father.push(item)
        }
      })
    },

    // 开始批量移动
    async moveSubmit() {
      await this.$http({
        url: '/Course/moveSchedule',
        data: {
          psl_id: this.psl_id,
          correlation_ids: this.checkId,
        },
        successMsg: true,
      })
      this.checkId = []
      this.psl_id = ''
      this.$refs.child.reset()
      this.dialogVisible = false
      this.isMoving = false
    },

    async getDirectoryList() {
      const { data } = await this.$http({
        url: '/Course/getDirectoryList',
        data: { course_id: this.$route.query.course_id },
      })
      this.optionsFather = data
    },

    modeSubmit() {
      this.$refs.child.reset()
    },

    gbDialog_data(list, teacherId, psl_id) {
      this.secondList = []
      // console.log(list)
      //搭配组件使用的方法,关闭弹出框----------------
      this.dialogSelectVisible = false
      list.forEach(value => {
        value.teacher_id = teacherId
        value.psl_id = psl_id
        value.video_type = '1'
        value.duration = value.chapter_hour || value.duration
        value.file_size = (value.video_size || value.size) / 1024
        value.name = value.file_name.slice(0, 100)
        this.secondList.push(Object.assign({}, value))
      })
      this.compelet(this.secondList)
    },
    cancelDialog_data() {
      this.dialogSelectVisible = false
    },
    //选择课程
    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
    },
    watchBackVideo(value, index) {
      let id = ''
      if (value.children.length > 0) {
        id = value.children[index].sl_id
      } else {
        id = value.sl_id
      }
      this.$router.push({
        path: '/courseLive/EditCourse/watchBackVideo/' + id,
      })
    },
    hideHelpPs() {
      this.showJoinPs = false
      this.drawPs = false
    },

    // 获取机构老师
    getJigouTeacher() {
      const self = this
      self.$http({
        name: API.getJigouTeacher_Api.name,
        url: API.getJigouTeacher_Api.url,
        callback({ data: { teacherInfo } }) {
          const result = {}
          _.forIn(teacherInfo, item => {
            result[item.uid] = item
          })
          self.teacherInfo = result
        },
        error(error) {
          if (error.code == 204) {
            self.teacherInfo = []
          }
        },
      })
    },

    // 重置编辑的数据
    resetEditData() {
      this.editData = {}
      this.parenTitle = ''
      this.is_unfold = 1
      this.removeAllSchedule = false
    },

    // 添加 编辑章节弹窗
    toggleCreateSh(val, type) {
      try {
        this.correlation_id = val ? val : undefined
        this.selectType = type
      } catch (e) {
        console.log(e)
      }
      this.showCreateSh = !this.showCreateSh
      if (!this.showCreateSh) {
        this.resetEditData()
      }
    },

    // 添加章节
    addSchedule(value) {
      const newValue = this.transScheduleData(value)

      if (this.editOption) {
        this.setScheduleInfo(newValue[0])
      } else {
        this.durationData.push(...newValue)
        this.refreshList()
      }
      // this.editSave()
    },

    compelet(val) {
      this.editSave(this.transScheduleData(val))
    },

    // 章节 公共 的转换函数
    transScheduleData(value) {
      return value.map(v => {
        let item = _.merge({}, v, {
          id: v.id ? v.id : Math.random(),
        })
        // 如果不是目录
        if (item.is_directory != 1) {
          // 转换成时分的o格式
          let newClassHour = []
          let originClassHour = {
            t1: 0,
            t2: 0,
          }

          if (item.classHour && item.classHour > 0) {
            const leftTime = item.endTime - item.startTime

            const leftHour = Math.floor(leftTime / 3600)

            originClassHour = {
              t1: leftHour,
              t2: Math.floor((leftTime - leftHour * 3600) / 60),
            }

            newClassHour[0] =
              originClassHour.t1 > 0 ? `${originClassHour.t1}小时` : ''
            newClassHour[1] =
              originClassHour.t2 > 0 ? `${originClassHour.t2}分钟` : ''
          }

          // 录播名称
          let file_name = ''
          if (this.courseType == 3) {
            file_name = item.video_url && /.+\/(.+\.mp4)$/i.exec(item.video_url)
            file_name = item.file_name || (file_name ? file_name[1] : '')
          }

          item = _.assign({}, item, {
            startTime:
              item.startTime && item.startTime != 0
                ? this.$options.filters.formatTimeStamp(
                  item.startTime,
                  'yyyy/MM/dd hh:mm'
                )
                : '',
            endTime:
              item.endTime && item.endTime != 0
                ? this.$options.filters.formatTimeStamp(
                  item.endTime,
                  'yyyy/MM/dd hh:mm'
                )
                : '',
            classHourTm: newClassHour.join(''),
            originClassHour,
            file_name,
          })
        }

        // 递归获取设置数据
        if (Array.isArray(item.children) && item.children.length > 0) {
          item.children = this.transScheduleData(item.children)
        }
        return _.merge({}, this.template, item, {
          // children is array
          children: Array.isArray(item.children) ? item.children : [],
        })
      })
    },

    // 添加父标题弹窗
    addParentTitle() {
      this.showParentTitle = !this.showParentTitle
      if (!this.showParentTitle) {
        this.resetEditData()
      }
    },

    // 上传 编辑视频弹窗
    toggleshowVideoSection(val, psl_id, type) {
      try {
        this.correlation_id = val ? val : undefined
        this.psl_id = psl_id
        this.selectType = type
      } catch (e) {
        console.log(e)
      }
      this.showVideoSection = !this.showVideoSection
      if (!this.showVideoSection) {
        this.resetEditData()
      }
    },

    // 公共的转换
    publicFnHandle(options, type, live_mode) {
      this.live_mode2 = live_mode
      // if (live_mode == 2) {
      //   this.$message('极速模式暂不支持上课邀请')
      //   return false
      // }
      const { key, index, isChildSchedule } = options
      if (isChildSchedule) {
        this.editData = _.merge(
          {
            editOption: options,
          },
          this.template,
          this.durationData[key].children[index]
        )
      } else {
        this.editData = _.merge(
          {
            editOption: options,
          },
          this.template,
          this.durationData[key]
        )
      }

      switch (type) {
        // 子章节收缩展示
        case 0:
          {
            const i = this.hideChildContainIds.indexOf(this.editData.id)
            if (i > -1) {
              this.hideChildContainIds.splice(i, 1)
            } else {
              this.hideChildContainIds.push(this.editData.id)
            }
            this.resetEditData()
          }
          break
        // 父标题编辑
        case 1:
          this.parenTitle = this.editData.name
          this.is_unfold = this.editData.is_unfold
          this.addParentTitle()
          break
        // 父标题删除
        case 2:
          this.toggleremoveParentTitle()
          break
        // 子章节编辑
        case 3:
          this.courseType == 3
            ? this.toggleshowVideoSection()
            : this.toggleCreateSh()
          break
        // 子章节上课邀请
        case 5:
          this.showInvitePop = true
          break
        // 子章节删除
        // 子章节取消关联
        case 4:
        case 6:
          {
            this.$confirm(
              type == 6
                ? '是否取消该内容？'
                : '是否要删除该章节？删除后不可恢复！',
              type == 6 ? '取消关联' : '删除文件',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }
            ).then(() => {
              const v = isChildSchedule
                ? this.durationData[key].children.splice(index, 1)
                : this.durationData.splice(key, 1)
              // 添加 correlation_id 编辑删除使用
              this.resetEditData()
              this.refreshList()
              this.delCourseSchedule(v)
            })
          }
          break
      }
      this.editData = _.assign({}, this.editData, {
        chapter_mode: this.modifyModeData.chapter_mode,
        type: this.modifyModeData.type,
      })
    },
    //删除
    delCourseSchedule(val) {
      this.$http({
        url: '/course/delCourseSchedule',
        data: val[0],
        callback: ({ info }) => {
          this.$root.prompt({
            type: 'success',
            msg: info,
          })
          this.$refs.child.reset()
        },
        error: () => { },
      })
    },
    // 保存父标题
    saveParentTitle() {
      if (this.editOption) {
        this.editSave(
          this.setScheduleInfo({
            name: this.parenTitle,
            is_directory: 1,
            is_unfold: this.is_unfold,
          })
        )
      } else {
        const val = _.merge({}, this.template, {
          name: this.parenTitle,
          is_directory: 1,
          is_unfold: this.is_unfold,
          id: Math.random(),
        })
        this.durationData.push(val)
        this.editSave(val)
      }
      this.addParentTitle()
      this.refreshList()
    },

    // 是否删除父标题
    toggleremoveParentTitle() {
      this.removeParentTitle = !this.removeParentTitle
      if (!this.removeParentTitle) {
        this.resetEditData()
      }
    },

    // 开始删除父标题
    removeParentTitleStart() {
      let val
      if (this.removeAllSchedule) {
        // 获取需要删除的 correlation_id
        val = this.durationData.splice(this.editOption.key, 1)
      } else {
        val = this.durationData.splice(
          this.editOption.key,
          1,
          ...this.durationData[this.editOption.key].children.map(item =>
            _.assign({}, item, {
              psl_id: 0,
            })
          )
        )
        val[0].children = []
      }
      this.toggleremoveParentTitle()
      this.removeAllSchedule = false
      this.resetEditData()
      this.refreshList()
      this.delCourseSchedule(val)
    },

    // 保存编辑后的数据
    setScheduleInfo(value) {
      const { key, index, isChildSchedule } = this.editOption

      const newValue = _.assign({}, this.editData, value)
      if (isChildSchedule) {
        this.durationData[key].children.splice(index, 1, newValue)
      } else {
        this.durationData.splice(key, 1, newValue)
      }
      this.resetEditData()
      this.refreshList()
      return newValue
    },

    // 批量移动
    courseMove() {
      this.isMoving = true
      this.post = _.assign({}, this.post, { all: 3 })
      this.$emit('leaveConfirm', 1)
    },

    sortStartClick() {
      this.post = _.assign({}, this.post, { all: 1 })
      this.$emit('leaveConfirm', 2)
    },

    // 取消编辑
    editCancel() {
      this.$confirm('取消操作将丢失已编辑的内容，是否确定？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.post = _.assign({}, this.post, { all: 2 })
        this.$emit('leaveConfirm', 1)
        this.isMoving = false
        this.checkId = []
      })
    },

    getSortData(val) {
      return val.map(item => {
        if (Array.isArray(item.children)) {
          item.children = this.getSortData(item.children)
        }
        return {
          is_directory: item.is_directory,
          correlation_id: item.correlation_id,
          children: item.children,
        }
      })
    },

    // 排序保存
    sortSave() {
      this.editSave(
        this.getSortData(this.durationData),
        '/course/sortCourseSchedule'
      )
    },

    /**拖拽逻辑 start */
    sortStart() {
      // 最顶层的"父类" 初始化拖拽事件
      this.parentSort = new Sortable(
        document.getElementById('sortable-contain'),
        {
          group: 'sortable-contain',
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          forceFallback: this.isPcWx,

          // 开始拖动大层级 防止 出现嵌入三级情况
          onStart: evt => {
            // 如果是目录就不能拖拽进子类中
            if (this.durationData[evt.oldIndex].is_directory == 1) {
              // 设置拖拽作用域为子类中...
              this.setGroupName('nested-sortable')
            }
          },

          // 结束拖动 小层级可以相互进行拖动 和 拖出去
          onEnd: () => {
            // 设置拖拽作用域为整个区域 父类...
            this.setGroupName('sortable-contain')
          },

          // 父类列表中的相互拖动
          onUpdate: ({ newIndex, oldIndex }) => {
            const { nId, oId } = this.getMoveIndex(newIndex, oldIndex)

            this.durationData.splice(nId, 0, this.durationData[oldIndex])
            this.durationData.splice(oId, 1)
            this.refreshList(true)
          },

          // 父类下面的章节拖动到子类 或者拖动到顶层去
          onRemove: this.childItemDragEachOther,
        }
      )

      // 初始化"子类" 拖拽事件
      this.initChildrenSort()
    },

    // 设置层级
    setGroupName(name) {
      this.childSort.forEach(function (item) {
        item.option('group', name)
      })
    },

    // 初始化"子类" 拖拽事件
    initChildrenSort() {
      var nestedSortables = [].slice.call(
        document.getElementsByClassName('nested-sortable')
      )
      nestedSortables.forEach(item => {
        this.childSort.push(
          new Sortable(item, {
            group: 'sortable-contain',
            animation: 150,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            forceFallback: this.isPcWx,

            // 列表内拖动
            onEnd: () => {
              // 设置拖拽作用域为整个区域 父类...
              this.setGroupName('sortable-contain')
            },

            // 子类列表中的相互拖动
            onUpdate: ({ newIndex, oldIndex, from }) => {
              const key = from.dataset.key

              const { nId, oId } = this.getMoveIndex(newIndex, oldIndex)

              this.durationData[key].children.splice(
                nId,
                0,
                this.durationData[key].children[oldIndex]
              )
              this.durationData[key].children.splice(oId, 1)
              this.refreshList(true)
            },

            // 子类拖动到其他列表  或者拖动到顶层去
            onRemove: this.childItemDragEachOther,
          })
        )
      })
    },

    // 获取列表相互拖拽 index
    getMoveIndex(newIndex, oldIndex) {
      const newEqOld = newIndex > oldIndex
      return {
        nId: newEqOld ? newIndex + 1 : newIndex,
        oId: newEqOld ? oldIndex : oldIndex + 1,
      }
    },

    /**
     * @description 列表子数据相互拖拽
     * 顶层拖动到列表中
     * 子章节拖动到顶层
     * 子章节不同列表相互拖动
     *
     * PS: 同列表的数据 相互拖动不会执行这个方法
     */
    childItemDragEachOther({ to, from, newIndex, oldIndex }) {
      const toIndex = to.dataset.key
      const fromIndex = from.dataset.key

      // 条件一: 顶层拖动到列表中
      const t1 = !fromIndex && fromIndex != 0
      // 条件二 子章节拖动到顶层
      const t2 = !toIndex && toIndex != 0
      // 条件三 子章节不同列表相互拖动
      // const t3 = fromIndex && toIndex;

      if (t1) {
        const fromData1 = this.durationData[oldIndex]
        this.durationData[toIndex].children.splice(newIndex, 0, fromData1)
        this.durationData.splice(oldIndex, 1)
      } else if (t2) {
        const fromData2 = this.durationData[fromIndex].children.splice(
          oldIndex,
          1
        )[0]
        this.durationData.splice(newIndex, 0, fromData2)
      } else {
        const fromData3 = this.durationData[fromIndex].children.splice(
          oldIndex,
          1
        )[0]
        this.durationData[toIndex].children.splice(newIndex, 0, fromData3)
      }
      this.refreshList(true)
    },

    // 销毁sortable所有实例
    destroySortable() {
      if (this.parentSort.destroy) {
        this.parentSort.destroy()
      }
      this.childSort.forEach(function (item) {
        item.destroy()
      })
      this.parentSort = {}
      this.childSort = []
    },

    // 获取当前排序数据备份 刷新数据列表
    refreshList(isSort) {
      // 销毁排序
      this.destroySortable()
      document.getElementById('sortable-contain').innerHTML = ''
      this.durationDataBat = []
      this.$nextTick(() => {
        // 完全深度备份数据
        this.durationDataBat = _.concat(
          [],
          this.durationData.map(item => _.merge({}, item))
        )
        this.tranformData()
        if (isSort) {
          this.$nextTick(() => {
            this.sortStart()
          })
        }
      })
    },

    tranformData() {
      this.durationDataBat.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(item => {
            item.isChild = true
            item.checked = false
          })
        } else {
          item.checked = false
          item.isChild = false
        }
      })
    },

    /**编辑区域 */

    // 获取直播数据
    getEditData(e) {
      if (e && e.data) {
        this.durationData = []
        this.durationDataBat = []
        this.modifyModeData = e.modeInfo
        const newData = e.data.map(item =>
          _.assign(
            {},
            item,
            e.order_num
              ? {
                order_num: e.order_num,
              }
              : {}
          )
        )
        // this.isNoData = false
        this.addSchedule(newData)
        // 如果是1 说明正在排序
        if (this.post.all == 1) {
          setTimeout(() => {
            this.sortStart()
          }, 100)
        }
      }
    },

    // 保存编辑
    editSave(val, url = '/Course/addSchedule') {
      this.$http({
        url,
        data: {
          course_id: this.editCourseId,
          course_type: this.courseType,
          courseList: Array.isArray(val) ? val : [val],
          correlation_id: this.correlation_id,
          direction: this.selectType,
        },
        callback: () => {
          this.resetEditData()
          this.durationData = []
          this.post = _.assign({}, this.post, { all: 2 })
          this.$emit('leaveConfirm', 1)
          this.$refs.child.reset()
          this.$root.prompt({
            msg: '保存成功！',
            type: 'success',
          })

          this.$emit('updateheader2')
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.addsVideotitle {
  height: 14px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 18px;
}

.courseTypeTip {
  width: 684px;
  height: 13px;
  font-size: 13px;
  font-family: MicrosoftYaHei;
  color: #707070;
  line-height: 13px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.buttonAdds {
  width: 126px;
  height: 42px;
  background: #0aa29b;
  border-radius: 4px;
  text-align: center;
  margin-top: 1px;
  margin-right: 20px;
  cursor: pointer;

  p {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 42px;
  }
}

::v-deep.el-dropdown-menu {
  width: 136px;

  p {
    width: 115px;
  }
}

.mode_tip {
  font-size: 12px;
  color: #666666;
  margin-bottom: 20px;
}

.mode_type {
  display: flex;
  align-items: center;

  .mode {
    font-size: 16px;
    color: #363636;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 42px;
  line-height: 42px;

  .leftBox {
    display: flex;
  }

  %btn {
    width: 126px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #0aa29b;
  }

  .newTimeButton {
    @extend %btn;
    color: #ffffff;
    background: rgba(10, 162, 155, 1);
  }

  .addParentTitleBtn {
    @extend %btn;
    color: $color;
    margin-left: 10px;
  }

  .fromCourseButton {
    font-size: 0;
    position: relative;

    .text {
      font-size: 14px;
      cursor: pointer;
      margin-left: 20px;
      font-style: normal;
      color: rgba(10, 162, 155, 1);
    }
  }

  @media screen and (max-width: 1400px) {
    .tips {
      max-width: 500px;
      line-height: 18px;
      margin-top: 5px;
    }
  }
}

.text2 {
  display: inline-block;
  border-radius: 8px;
  font-size: 13px;
  color: #ff3535;
  padding: 0 14px;
  background: #fff2f2;
  line-height: 36px;
}

.sort-tip {
  font-size: 0;
  text-align: center;

  .text {
    display: inline-block;
    border-radius: 8px;
    font-size: 13px;
    color: #ff3535;
    padding: 0 14px;
    background: #fff2f2;
    line-height: 36px;
  }
}

.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
  background: url('~@ass/img/1.1.2/help.png');
}

.help-ps {
  position: absolute;
  left: 97px;
  top: -37px;
  width: 326px;
  z-index: 3;
  font-size: 12px;
  padding: 7px 12px;
  color: rgba(74, 74, 74, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  line-height: 16px;
  border: 1px solid rgba(230, 230, 230, 1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
}

/**添加父标题 */
.addParentTitleContain {
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);

  .content {
    width: 422px;
    height: 204px;
    border-radius: 4px;
    position: absolute;
    padding: 16px 20px;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 1);

    .title {
      font-size: 18px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }

    .input {
      display: flex;
      margin-top: 20px;
      align-items: center;
      border: 1px solid rgba(232, 232, 232, 1);

      .ip {
        flex: 1;
        border: 0;
        font-size: 14px;
        line-height: 19px;
        height: 40px;
        margin: 0 10px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;

        &::-webkit-input-placeholder {
          color: rgba(204, 204, 204, 1);
        }
      }

      .max {
        font-size: 12px;
        line-height: 16px;
        padding-right: 10px;
        color: rgba(153, 153, 153, 1);
      }
    }

    .draw {
      display: flex;
      margin-top: 14px;
      align-items: center;
      position: relative;
    }

    .btns {
      font-size: 0;
      margin-top: 20px;
      text-align: right;

      .b1,
      .b2 {
        width: 74px;
        height: 36px;
        font-size: 14px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
      }

      .b1 {
        color: #808080;
        background: transparent;
        border: 1px solid rgba(218, 218, 218, 1);
      }

      .b2 {
        color: #fff;
        border: $color;
        margin-left: 20px;
        background: $color;
      }
    }
  }
}

/**删除父标题 */
.reset-txt {
  padding: 10px 20px 0 20px;
  margin-bottom: 70px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}

.checkbox-wrap {
  position: absolute;
  left: 20px;
  bottom: 7px;

  &.is-choose {
    bottom: 20px;
  }

  ::v-deep .el-checkbox {
    margin-bottom: 7px;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0aa29b;
    border-color: #0aa29b;
  }

  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0aa29b;
  }

  p {
    font-size: 12px;
    color: #ff3535;
  }
}

/**编辑页面 */
.edit-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: white;
  padding: 7px 0 7px 208px;
  box-sizing: border-box;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);

  .cancel,
  .save {
    width: 100px;
    height: 40px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
  }

  .cancel {
    color: #0aa29b;
    background: transparent;
    border: 1px solid rgba(204, 204, 204, 1);
  }

  .save {
    color: #fff;
    background: #0aa29b;
    border: 1px solid #0aa29b;
  }
}

.hidden {
  visibility: hidden !important;
}
</style>
<style lang="scss">
/**章节列表 */
#sortable-contain {
  padding: 20px 20px 25px 0;
}

.sort-schedule-item {
  .parent-title {
    margin-bottom: 15px;
    height: 32px;
    display: flex;
    padding: 0 14px;
    align-items: center;
    border-radius: 1px;
    background: rgba(245, 245, 245, 1);
    border: 1px solid rgba(230, 230, 230, 1);

    .draw-icon {
      width: 22px;
      height: 34px;
      background: url('~@ass/img/1.1.2/ico_zksq-bottom.png') no-repeat;
      background-position: left center;

      &.none {
        width: 22px;
        height: 34px;
        background: url('~@ass/img/1.1.2/ico_zksq.png') no-repeat;
        background-position: left center;
      }
    }

    .title {
      flex: 1;
      height: 19px;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      color: rgba(51, 51, 51, 1);
    }
  }

  .nested-sortable {
    padding: 5px 0;
  }

  .btns {
    display: flex;
    align-items: center;

    .btn {
      font-size: 13px;
      line-height: 16px;
      color: $color;
      cursor: pointer;
    }

    .join-btn {
      width: 65px;
    }

    .space {
      height: 16px;
      margin: 0 5px;
      color: $color;
      font-size: 1px;
      line-height: 16px;
    }
  }
}

.contain-shedule {

  /**子章节 */
  .sort-child-schedule {
    margin-bottom: 20px;
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 1px;
    border: 1px solid rgba(230, 230, 230, 1);

    &:hover {
      border-color: #0aa29b;
    }

    .newschedule {
      position: absolute;
      left: 0;
      bottom: -21px;
      font-size: 12px;
      color: #0aa29b;
      line-height: 14px;
      display: none;
      width: 100%;
      cursor: pointer;
      line-height: 22px;
      vertical-align: middle;
    }

    .newschedule2 {
      position: absolute;
      left: 0;
      top: -19px;
      font-size: 12px;
      color: #0aa29b;
      line-height: 14px;
      display: none;
      width: 100%;
      cursor: pointer;
      line-height: 22px;
      vertical-align: middle;
    }

    &:hover .newschedule2 {
      display: block;
    }

    &:hover .newschedule {
      display: block;
    }

    .newschedule:hover {
      display: block;
    }

    .replay-icon {
      width: 12px;
      height: 18px;
      margin-right: 10px;
      background: url('~@ass/img/1.1.2/ico_hf_wkq@2x.png');
      background-size: 100%;

      &.active {
        background: url('~@ass/img/1.1.2/ico_hf_kq@2x.png');
        background-size: 100%;
      }
    }

    .try-it-icon {
      width: 16px;
      height: 18px;
      margin-right: 10px;
      background: url('~@ass/img/1.4.51/try-it-not.png');
      background-size: 100%;
      background-repeat: no-repeat;
      &.active {
        background: url('~@ass/img/1.4.51/try-it-yes.png');
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    .timing-icon {
      width: 12px;
      height: 20px;
      margin-right: 10px;
      background: url('~@ass/img/1.1.2/ico_sk_wkq-ds2.png');
    }

    .time {
      font-size: 13px;
      color: rgba(128, 128, 128, 1);
      line-height: 16px;
    }

    .type {
      padding: 0 5px;
      height: 16px;
      line-height: 16px;
      margin-left: 20px;
      border-radius: 1px;
      text-align: center;
      border: 1px solid rgba(27, 157, 151, 1);
      font-size: 10px;
      color: rgba(27, 157, 151, 1);
    }

    .name {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 30px;
      overflow: hidden;

      .text {
        font-size: 13px;
        color: rgba(51, 51, 51, 1);
        line-height: 19px;
        margin-left: 10px;
      }
    }

    .teacher-name,
    .classHour {
      font-size: 13px;
      margin-right: 60px;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;

      &.blue {
        color: #2060c9;
      }

      &.red {
        color: #ce3f67;
      }
    }

    .teacher-name {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 20px;
    }

    .classHour {
      min-width: 70px;
      margin-right: 0;
    }

    .time_show {
      margin-right: 40px;
      font-size: 14px;
      line-height: 34px;
      color: #333333;
      align-self: flex-end;
    }

    .look-icon {
      line-height: 10px;
      background: #ff4747;
      color: #fff;
      padding: 4px 3px;
      margin-left: 5px;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      white-space: nowrap;
    }
  }
}
</style>
