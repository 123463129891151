// 离开页面提示信息
export default {
  beforeRouteLeave(to, from, next) {
    try {
      if (
        (from.name != 'EditCourse1' &&
          from.name != 'EditCourse2' &&
          from.name != 'EditCourse3') ||
        this.leaveConfirm === 1
      ) {
        next()
      } else {
        setTimeout(() => {
          this.beforeLeaveConfirm().then(() => {
            this.leaveConfirm = 1
            this.$router.push({ path: to.fullPath })
          })
        }, 0)
        next(false)
      }
    } catch (e) {
      console.log(e)
    }
  },

  methods: {
    beforeLeaveConfirm() {
      return this.$confirm(
        '此时离开将丢失已编辑的内容，是否离开？',
        '温馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
    }
  }
}
