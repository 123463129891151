<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-07-11 17:06:01
 * @LastEditTime: 2022-07-11 17:20:46
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\coursePromotion\exportPop.vue
-->
<template>
  <div id="openingfunction">
    <el-dialog
      title="提示"
      class="dialogVisible"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      width="420px"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="select_type">
          <div class="name">格式选择</div>
          <div class="radio">
            <el-radio-group v-model="type">
              <p>
                <el-radio :label="1">
                  csv（纯文本格式，导出速度快，推荐）
                </el-radio>
              </p>
              <p class="mt20">
                <el-radio :label="2">
                  excel（支持表情符号、导出速度一般）
                </el-radio>
              </p>
            </el-radio-group>
          </div>
        </div>
        <div class="fs14 mt30">确定要导出{{ count }}条数据？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="submit">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'openingfunction',
  data() {
    return {
      type: 1,
    }
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.type)
      this.cancel()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
#openingfunction {
  ::v-deep .dialogVisible {
    .el-dialog__footer {
      padding-top: 0;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      .select_type {
        display: flex;
        .name {
          font-size: 14px;
          margin-right: 20px;
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
