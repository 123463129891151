<template>
  <div id="noOpen">
    <!-- <div class="hearder">
      <div class="tu" v-if="list.status == 1">
        <img :src="list.src" alt="" />
      </div>
      <div class="tu2" v-if="list.status == 2">
        <img :src="list.src" alt="" />
      </div>

      <div class="tu3" v-if="list.status == 3">
        <img :src="list.src" alt="" />
      </div>
      <div class="right">
        <div class="right_font1">{{ list.title }}</div>
        <div class="right_font2">
          {{ list.describe }}
        </div>
        <div class="right_button">
          <el-button
            type="primary"
            size="medium"
            @click="kaitong"
            style="width:112px"
          >
            {{ list.button_name }}
          </el-button>
          <div class="price" v-if="list.money">
            ￥{{ list.money }}
            <span
              class="red"
              style="font-size:14px;marign-left:5px;font-weight: bold;"
            >
              {{ list.tip }}
            </span>
          </div>
          <div class="strick" v-if="list.strike">￥{{ list.strike }}</div>
        </div>
      </div>
    </div> -->
    <div class="introduction">
      <div class="introduction_font1">功能介绍</div>
      <img style="width:100%" :src="list.function_tu" alt="" />
    </div>
    <openingfunction
      v-if="status"
      :dialogstatus.sync="status"
    ></openingfunction>
  </div>
</template>
<script>
import openingfunction from '@/components/copyrightprotect/openingfunction'
export default {
  name: 'noOpen',
  data() {
    return {
      status: false,
      dialogVisible: false
    }
  },
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    openingfunction
  },
  methods: {
    kaitong() {
      this.status = true
    }
  }
}
</script>
<style lang="scss" scoped>
#noOpen {
  margin: 20px 0;
}
.hearder {
  background-color: #fff;
  padding: 20px;
  display: flex;
  .tu,
  .tu2,
  .tu3 {
    width: 131px;
    height: 131px;
    background: #3299ff;
    border-radius: 20px;
    img {
      margin: 16px auto;
    }
  }
  .tu2 {
    background: #ff6c32;
    img {
      margin-top: 26px;
    }
  }
  .tu3 {
    background-color: #30b031;
    img {
      margin-top: 26px;
    }
  }

  .right {
    margin-left: 30px;
    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .right_font2 {
      margin-top: 14px;
      margin-bottom: 50px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }
    .right_button {
      display: flex;
      align-items: flex-end;
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }
      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}
.introduction {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  .introduction_font1 {
    margin-bottom: 20px;
    font-size: 14px;
    color: #666666;
    line-height: 14px;
  }
  img {
    margin: 0 auto;
  }
}
</style>
