<template>
  <div class="posterTemplate posterTemplate4">
    <img src="https://img.dingdingkaike.com/FnjHyx5p3ydTM0CAa3QqzN_Q3jy7?imageslim" alt="背景图" class="bg-img" />
    <div>
      <div class="block-2">
        <div class="left">
          <div class="left-t">
            <img class="t1" :src="posterData.uphoto" />
            <div class="left-t-r">
              <p class="t2">{{ posterData.uname | unameStr }}</p>
              <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
            </div>
          </div>
          <div class="left-b">
            <p>长按识别二维码 {{type==='2' ? '立即领取' : '立即加入学习'}}</p>
          </div>
          <!-- 优惠提示 -->
          <!-- <p class="t6" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名
          {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
        </div>
        <div class="right">
          <div class="erweima" ref="qrcode"></div>
        </div>
      </div>
      <div class="block-1">
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
        <!-- <p class="t6">{{ posterData.time }}</p> -->
      </div>
      <p class="jgName">{{ posterData.jg_title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate4',
  filters: {
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(2.675 * 3, this.$refs.qrcode)
    this.$emit('createEwm', 2.4, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.posterTemplate4 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 29px;
    right: 29px;
    top: 43px;
  }
  .block-1 {
    margin-top: 72px;
    .t4 {
      width: 242px;
      height: 135px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      margin-top: 7px;
      line-height: 25px;
      font-size: 13px;
      font-weight: bold;
      word-break: break-all;
    }
    .t6 {
      color: #999;
      font-size: 10px;
      line-height: 26px;
    }
  }
  .block-2 {
    position: relative;
    display: flex;
    .left {
      flex: 1;
      position: relative;
      font-weight: 700;
      > .left-t {
        display: flex;
        align-items: center;
        .t1 {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }
        .left-t-r {
          flex: 1;
          p {
            padding-left: 10px;
            line-height: 15px;
          }
          .t2 {
            font-size: 10px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 140px;
          }
          .t3 {
            font-size: 11px;
            color: #999;
          }
        }
      }
      > .left-b {
        font-size: 0;
        p {
          display: inline-block;
          color: #333;
          padding: 0 4px;
          margin-top: 10px;
          font-size: 10px;
          line-height: 20px;
          text-align: center;
          box-sizing: border-box;
          border: 1px dashed #97bace;
        }
      }
      .t6 {
        position: absolute;
        bottom: -19px;
        left: 0;
        width: 143px;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 15px;
        white-space: nowrap;
        border-radius: 11px;
        background-color: #c23230;
      }
    }
    .right {
      padding: 17px 5px 5px;
      margin-top: -10px;
      background: url('../../../assets/img/1.1.2/qr_bg_yp.png') no-repeat center/100% 100%;
      background-size: contain;
      .erweima {
        width: 54px;
        height: 54px;
        padding: 1px;
        box-sizing: border-box;
        border: 2px solid #8dd6ff;
      }
    }
  }
  .jgName {
    color: #999;
    font-size: 11px;
    line-height: 25px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .ellipsis-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
