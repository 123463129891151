<template>
  <div id="addmember">
    <el-dialog
      title="开通功能"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title">
        <div class="title_style">
          <div class="font1">添加学员(多选)</div>
          <div class="font2" v-if="type == 1 || type == 4">
            添加学员后，即成为会员并可拥有对应课程的学习权限，无需购买课程
          </div>
          <div class="font2" v-if="type == 2">添加学员后，学员无需购买作业</div>
        </div>
      </div>
      <div class="main">
        <div class="header" v-if="type == 1">
          <div class="guige">会员规格</div>
          <el-select
            style="width: 350px"
            v-model="sure.spec_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.spec_id"
              :label="`${item.vaild_day}天`"
              :value="item.spec_id"
            ></el-option>
          </el-select>
        </div>

        <div class="header" v-if="type == 4">
          <div class="guige">有效期</div>
          <el-radio-group v-model="validityType">
            <el-radio label="1">
              长期有效（3650天）
            </el-radio>
            <el-radio label="2">
              <div style="color:#333333">
                自定义天数
                <el-input-number
                  :disabled="validityType != 2"
                  class="ml10 mr10 "
                  v-model="validity"
                  controls-position="right"
                  size="medium"
                  placeholder="输入天数"
                  style="width:136px"
                  :step="1"
                  :min="1"
                  :max="3650"
                  :step-strictly="true"
                  :precision="0"
                ></el-input-number>
                天
              </div>
            </el-radio>
          </el-radio-group>
        </div>

        <div class="bottom">
          <div class="selectGroup">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
            >
              <el-menu-item
                v-for="(item, index) in grouplist"
                :key="item.gid"
                :index="'' + index"
                @click="isSelect(item)"
              >
                <div class="flex-center">
                  <img
                    style="margin-right: 6px"
                    src="../../assets/img/1.4.0/icon_xybs.png"
                    alt=""
                  />
                  <span slot="title" class="font1">
                    {{ item.gname }}({{ item.count }})
                  </span>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="tablelist">
            <div class="search">
              <el-input
                style="width: 198px"
                v-model="post.search"
                placeholder="输入学员/真实姓名搜索"
              ></el-input>
            </div>
            <pagination2
              :option="post"
              url="/courseMember/groupUserList"
              ref="childDialogBox"
              :allFn="true"
            >
              <template v-slot:default="{ tableData }">
                <div class="pagination2">
                  <el-table
                    ref="dialogTable"
                    @row-click="handleRowClick"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :row-key="getRowkey"
                    :data="tableData"
                    style="width: 100%"
                  >
                    <el-table-column
                      label=""
                      :reserve-selection="true"
                      type="selection"
                    ></el-table-column>
                    <el-table-column label="学员">
                      <template slot-scope="{ row }">
                        <div class="membership">
                          <div class="tu">
                            <img
                              style="width: 22px; height: 22px"
                              :src="row.uphoto"
                              alt=""
                            />
                          </div>
                          <div class="right">{{ row.uname }}</div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="真实姓名">
                      <template slot-scope="{ row }">
                        <span v-if="row.student_remarks">
                          {{ row.student_remarks }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="手机号码">
                      <template slot-scope="{ row }">
                        <span v-if="row.umobile">
                          {{ row.umobile }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="注册时间"
                      prop="address"
                      min-width="90px"
                    >
                      <template slot-scope="{ row }">
                        {{ row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </pagination2>
          </div>
        </div>

        <div class="btns">
          <el-button type="" size="medium" style="width: 86px" @click="cancel">
            取消
          </el-button>
          <el-button
            type="primary"
            size="medium"
            style="width: 86px"
            @click="submit"
          >
            确定({{ listSelect.length > 0 ? listSelect.length : 0 }})
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'addmember',
  data() {
    return {
      // 有效期
      validityType: '1',
      validity: '',

      //会员
      sure: {
        spec_id: '',
        uids: [],
      },
      //作业本
      sure2: {
        uids: [],
        workbook_id: this.$route.params.id,
      },
      post: {
        workbook_id:
          this.type == 2
            ? this.$route.query.member_id || this.$route.params.id
            : undefined,
        gid: 0,
        search: '',
        is_workbook: this.type == 2 ? 1 : undefined,
        m_id: this.type == 4 ? this.member_id : undefined,
        graphic_id: this.member_id,
      },
      listSelect: [], //右侧列表选中的数据
      activeIndex: '0', //左侧默认选中
      grouplist: [], //左侧列表
      tableData: [], //右侧列表数据
      //搜索关键字
      searchKey: '',
      //控制弹窗的显示
      dialogVisible: false,
      //会员规格的选择
      options: [],
      value: '',
    }
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    // 1是会员添加学员 2是作业本添加学员 3是公开课的 4 选择的数据 5直接抛出数据
    type: {
      type: Number,
      default: 1,
    },
    //添加学员提交的控制器
    url: {
      type: String,
      default: '/courseMember/addUser',
    },
  },
  created() {
    this.studentGroup()
    if (this.type == 1) {
      this.courseMemberSpec()
    }
  },
  computed: {
    member_id() {
      return (
        this.$route.query.member_id ||
        this.$route.params.id ||
        this.$route.query.id
      )
    }, 
  },
  methods: {
    getRowkey(row) {
      return row.uid
    },

    handleRowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    //点击确认
    async submit() {
      if (this.type == 4) {
        if (this.validityType == 2 && !this.validity) {
          this.$root.prompt('请输入天数')
          return
        }

        if (this.validityType == 1) {
          this.validity = 0
        }
        const val = { time: this.validity, list: this.listSelect }
        this.$emit('addmemberList', val)
      } else if (this.type == 5) {
        this.$emit('addmemberList', this.listSelect)
      } else {
        if (this.listSelect.length > 0) {
          if (!this.sure.spec_id && this.type == 1) {
            this.$root.prompt({
              msg: '请选择会员规格！',
            })
            return
          } else {
            await this.tijiao()
          }
        } else {
          this.$emit('update:dialogstatus', false)
        }
      }
    },

    tijiao() {
      this.$http({
        url: this.url,
        data: this.type == 1 ? this.sure : this.sure2,
        callback: ({ code }) => {
          if (code == 200) {
            this.$emit('reset')
          }
        },
      })
    },
    //选各种的数据
    handleSelectionChange(val) {
      this.listSelect = val
      switch (this.type) {
        case 1:
          this.sure.uids = val.map(m => m.uid)
          break
        case 2:
          this.sure2.uids = val.map(m => m.uid)
          break
      }
    },
    //选中左侧分组
    isSelect(item) {
      this.post.gid = item.gid
    },

    //规格列表获取
    courseMemberSpec() {
      this.$http({
        url: '/courseMember/courseMemberSpec',
        data: {
          member_id: this.member_id,
        },
        callback: ({ data }) => {
          this.options = data
        },
      })
    },

    //左侧列表的获取
    studentGroup() {
      this.$http({
        url: '/courseMember/studentGroup',
        data: {
          workbook_id: this.type == 2 ? this.member_id : undefined,
          m_id: this.type == 4 ? this.member_id : undefined,
          is_workbook: this.type == 2 ? 1 : undefined,
          graphic_id: this.member_id,
        },
        callback: ({ data }) => {
          this.grouplist = data
        },
      })
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: inherit;
}
.el-menu-item.is-active {
  background-color: #ecf5ff;
}

#addmember {
  ::v-deep .pagination {
    padding: 10px 0;
  }
  .pagination2 {
    @extend %scroll;
    height: 228px;
    overflow: hidden;
    overflow-y: auto;
  }
  .tablelist {
    .membership {
      display: flex;
      align-items: center;
      .tu {
        width: 22px;
        height: 22px;
        border-radius: 2px;
        margin-right: 10px;
      }
      .right {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
  }

  ::v-deep .el-table__header-wrapper {
    height: 42px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog {
    height: 518px;
  }
  .title_style {
    display: flex;
    align-items: center;
    .font1 {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
    .font2 {
      font-size: 12px;
      margin-left: 10px;
      color: #ff3535;
      line-height: 16px;
    }
  }
  .main {
    padding: 20px;
    .header {
      display: flex;
      align-items: center;
      .guige {
        margin-right: 30px;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
    .bottom {
      display: flex;
      margin-top: 20px;
      .selectGroup {
        height: 334px;
        width: 214px;

        ::v-deep .el-menu {
          height: 334px;
          @extend %scroll;
          overflow-y: auto;
          overflow-x: hidden;
          border: solid 1px #e6e6e6;
        }
        ::v-deep .el-menu-item:hover,
        .el-menu-item:focus {
          background-color: #f0f8ff;
        }
        ::v-deep .el-menu-item {
          height: 36px;
          line-height: 36px;
        }
        .font1 {
          font-size: 12px;
          color: #333333;
        }
      }
      .tablelist {
        width: calc(100% - 200px);
        .search {
          margin-bottom: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .pagenation {
        margin-top: 20px;
        text-align: center;
      }
    }
    .btns {
      text-align: right;
      margin-top: 10px;
    }
  }
}
</style>
