import { render, staticRenderFns } from "./flexBox1.vue?vue&type=template&id=f8bc01ac&scoped=true&"
import script from "./flexBox1.vue?vue&type=script&lang=js&"
export * from "./flexBox1.vue?vue&type=script&lang=js&"
import style0 from "./flexBox1.vue?vue&type=style&index=0&id=f8bc01ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8bc01ac",
  null
  
)

export default component.exports