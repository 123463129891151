<template>
  <div class="posterTemplate posterTemplate6">
    <img src="https://img.dingdingkaike.com/FpJ1jlR5C046EPX7JQi-LF8cZzMG?imageslim" alt="背景图" class="bg-img" />
    <div>
      <div class="block-header">
        <img class="t1" :src="posterData.uphoto" />
        <div class="right">
          <p class="t2">{{ posterData.uname | unameStr }}</p>
          <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
        </div>
      </div>
      <div class="block-1">
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
      </div>
      <div class="block-2">
        <div class="right">
          <!-- <div class="right-t" v-if="posterData.time">
            {{posterData.time}}
          </div> -->
          <div class="right-b">
            <p class="t1">长按识别二维码</p>
            <p class="t2">{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
          </div>
        </div>
        <div class="left">
          <div class="erweima" ref="qrcode"></div>
        </div>
      </div>
      <!-- <p class="tips" v-if=" posterData.pre_way == 1|| posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名
        {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
      <p class="jgName">{{ posterData.jg_title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate6',
  filters: {
    nameStr(str) {
      return str.length > 16 ? `${str.slice(0, 16)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(2.55 * 3, this.$refs.qrcode)
    this.$emit('createEwm', 2.55, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.posterTemplate6 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 28px;
    right: 28px;
    top: 31px;
  }
  .block-1 {
    padding: 10px 10px 0 10px;
    .t4 {
      width: 224px;
      height: 125px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      height: 36px;
      margin-top: 6px;
      position: relative;
      font-size: 13px;
      line-height: 16px;
      word-break: break-all;
      // white-space: nowrap;
      // width: 100%;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  .block-2 {
    position: relative;
    display: flex;
    padding: 7px 10px 0;
    .right {
      flex: 1;
      .right-t {
        color: #999;
        font-size: 11px;
        line-height: 15px;
      }
      .right-b {
        display: flex;
        font-weight: 600;
        margin-top: 20px;
        p {
          color: #333;
          font-size: 11px;
          line-height: 15px;
        }
        > .t2 {
          margin-left: 5px;
        }
      }
    }
    .left {
      .erweima {
        width: 51px;
        height: 51px;
        padding: 2px;
        margin-top: -14px;
        border: 3px solid #4558f2;
      }
    }
  }
  .block-header {
    display: flex;
    align-items: center;
    margin-bottom: 92px;
    > .t1 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .right {
      padding-left: 14px;
      > .t2 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 108px;
        color: #fff;
        font-size: 10px;
        line-height: 15px;
      }
      > .t3 {
        color: #fff;
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
  .tips {
    position: absolute;
    bottom: 24px;
    left: 50%;
    width: 143px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 15px;
    margin-left: -71.5px;
    white-space: nowrap;
    border-radius: 11px;
    background-color: #f43333;
  }
  .jgName {
    color: #959ef7;
    font-size: 12px;
    margin-top: 33px;
    line-height: 21px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
