<!--
 * @Descripttion: 空状态
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 09:58:18
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-12-06 18:06:20
 -->
<template>
  <div class="empty" :class="{ positionCenter }" :style="`position:${isFixed ? 'fixed' : str}`">
    <img v-if="isOld" src="https://img.dingdingkaike.com/img_kzt_kcgl@2x.png?imageslim" />
    <img v-else class="new-empty" src="../assets/img/1.1.2/ico_ksj@2x.png" alt="" />
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: 'Empty',

  props: {
    text: {
      type: String,
      default: '暂无内容'
    },
    isOld: {
      type: Boolean,
      default: true
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    str: {
      type: String,
      default: 'relative'
    },
    positionCenter: Boolean
  }
}
</script>
<style lang="scss" scoped>
.empty {
  img {
    width: 200px;
    margin: 0 auto;
  }
  .new-empty {
    width: 77px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 18px;
    text-align: center;
    line-height: 22px;
    color: rgba(153, 153, 153, 1);
  }
  &.positionCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
