<template>
  <div class="absolute" ref="line"></div>
</template>
<script>
import echarts from 'echarts'

export default {
  name: 'pieChart',

  props: {
    options: Object,
    istime: Boolean,
  },

  watch: {
    options: {
      handler(value) {
        if (Object.keys(value).length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this.offResize()
    this.myChart.dispose()
    this.myChart = null
  },

  methods: {
    onResize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    // 获取option参数
    getOptionData() {
      return _.merge(
        {},
        {
          title: {},
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            itemGap: 30,
            orient: 'horizontal',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          toolbox: {},
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: 10,
              color: '#999999',
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#999999',
              },
            },
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            max: (value) => {
              return value.max > 4 ||
                (this.options.yAxis && this.options.yAxis.minInterval === null)
                ? null
                : value.max + 4
            },
            splitLine: {
              show: true,
              lineStyle: {
                // 使用深浅的间隔色
                type: 'dashed',
                color: '#E6E6E6',
              },
            },
            axisLine: {
              show: false,
              onZero: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              inside: false,
              fontSize: 9,
              color: '#999999',
              align: 'right',
              verticalAlign: 'bottom',
              
              formatter(value) {
                if (value >= 1000 && value < 10000) {
                  value = value / 1000 + '千'
                } else if (value >= 10000 && value < 10000000) {
                  value = value / 10000 + '万'
                } else if (value >= 10000000 && value < 1000000000) {
                  value = value / 10000000 + '千万'
                } else if (value >= 1000000000) {
                  value = value / 1000000000 + '亿'
                }
                return value
              },
            },
          },
        },
        this.options
      )
    },

    init() {
      const myChart = (this.myChart = echarts.init(this.$refs.line))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      const option = this.getOptionData()

      myChart.setOption(option)
    },
  },
}
</script>
