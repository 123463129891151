<template>
  <div
    @click="upload"
    style="position: relative"
    :class="{ shuiyin: status == 2 }"
  >
    <slot></slot>
    <input
      v-if="isPcWx"
      type="file"
      ref="pcWxUpload"
      :accept="`${isUploadAll ? 'image/*' : 'image/png, image/jpeg'}`"
      class="pcWxUploadBtn"
      :multiple="max && max > 1 ? 'multiple' : false"
      @change="pcWxUpload"
    />
    <div @click.stop>
      <!-- <cropper v-if="isShowCropperComponent" :option="cropperOption" :aspectRatio="aspectRatio" :value="isShowCropperComponent" @callback="cropperCbk" @destroy="cropperCancel"></cropper> -->
    </div>
    <div
      class="upImg"
      v-if="isShowLittleIcon && status == 1"
      @click.stop="uploadHeadImg"
    >
      <img src="../assets/img/ico_ghtx@2x.png" alt="上传图片" />
    </div>
    <div
      class="upImg"
      v-if="isShowLittleIcon && status == 2"
      @click="uploadHeadImg"
    >
      <el-button
        type=""
        style="
          width: 130px;
          height: 50px;
          position: absolute;
          left: 74px;
          bottom: 13px;
        "
        v-if="mybuttonstatus == 0"
      >
        上传水印
      </el-button>
    </div>
  </div>
</template>
<script>
import * as qiNiu from 'qiniu-js'
import jweixin from '@/assets/js/jweixin-1.40'
// import cropper from '@/components/Cropper';

export default {
  name: 'JweixinUpload',

  // components: { cropper },

  data() {
    return {
      isPcWx: true,
      subscription: {},
      cropperCbk: () => {},
      isShowCropperComponent: false,
      list: this.detailImages || [],
    }
  },

  watch: {
    isShowCropperComponent() {
      this.$emit('showCropperComponent')
    },
  },

  props: {
    mybuttonstatus: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 1,
    },
    isShowLittleIcon: Boolean,
    max: Number,
    token: String,
    isUploadAll: {
      type: Boolean,
      default: true,
    },
    ext: {
      type: String,
      default: '',
    },
    detailImages: {
      type: Array,
      default() {
        return []
      },
    },
    isSortIcon: {
      type: Boolean,
      default: false,
    },
    aspectRatio: Number,
    cropperOption: Object,
    isLoadCropper: Boolean,
  },

  created() {
    // this.$root.$http({
    //   succeedOnce: true,
    //   url: `/Index/weixinInfo?url=${encodeURIComponent(
    //     location.href.split("#")[0]
    //   )}`,
    //   name: "getWxConfigData",
    //   callback: ({ data }) => {
    //     this.WxConfig(data);
    //   },
    //   errorMessage: "获取微信服务器数据失败，上传图片功能不可用"
    // });
  },

  methods: {
    uploadHeadImg() {
      this.$el.querySelector('.pcWxUploadBtn').click()
    },
    // 微信分享
    WxConfig({ appId, timestamp, nonceStr, signature }) {
      jweixin.config({
        debug: !1,
        appId,
        timestamp,
        nonceStr,
        signature,
        jsApiList: ['uploadImage', 'chooseImage', 'getLocalImgData'],
      })
    },

    // PC input file微信上传
    pcWxUpload(event) {
      if (this.isPcWx || this.max) {
        const files = Array.prototype.slice.call(
          event.target.files,
          0,
          this.max
        )
        this.$emit('uploadStatus', files.length)
        Array.prototype.forEach.call(files, (item) =>
          this.qiNiuUpload(item, Symbol())
        )
      }
      this.$refs.pcWxUpload.value = ''
    },

    upload() {
      if (!this.isPcWx && this.max) {
        const self = this
        self.loadingCbk(true)
        self.cancelAndroidUploadXhr()
        const times = setTimeout(self.loadingCbk, 1500)
        jweixin.chooseImage({
          count: self.max, // 默认9
          sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success({ localIds }) {
            self.$emit('uploadStatus', localIds.length)
            if (self.isIPhone) {
              self.iphoneUpload(localIds)
            } else {
              self.androidUpload(localIds)
            }
            self.loadingCbk(times)
          },
          fail() {
            self.loadingCbk(times)
          },
          cancel() {
            self.loadingCbk(times)
          },
        })
      }
    },

    loadingCbk(value) {
      if (typeof value === 'number') {
        clearTimeout(value)
      }
      this.$root.toggleLoading({
        isShow: typeof value === 'boolean' ? !!value : false,
        loadingText: '正在处理...',
      })
    },

    // 苹果使用base编码图片
    iphoneUpload(localIds) {
      const self = this
      localIds.forEach((item) => {
        jweixin.getLocalImgData({
          localId: item, // 图片的localID
          success({ localData }) {
            // localData是图片的base64数据，可以用img标签显示
            self.qiNiuUpload(self.base64ToFile(localData), Symbol())
          },
        })
      })
    },

    cancelAndroidUploadXhr() {
      if (this.$root.uploadOneXhr) {
        this.$root.uploadOneXhr.abort()
        this.$root.uploadOneXhr = null
        delete this.$root.uploadOneXhr
      }
    },

    // 安卓需上传到服务器
    androidUpload(localIds) {
      const self = this
      const next = () => {
        localIds.shift()
        if (localIds.length > 0) {
          uploadImage(localIds[0])
        }
      }
      const uploadImage = (id) => {
        jweixin.uploadImage({
          // 需要上传的图片的本地ID，由chooseImage接口获得
          localId: id,
          // 默认为1，显示进度提示
          isShowProgressTips: 0,
          success(res) {
            // 返回图片的服务器端ID
            const serverId = res.serverId
            self.$root.uploadOneXhr = self.$root.$http({
              silenceError: true,
              url: '/Share/imgChange',
              isMultipleSelf: 'all',
              name: 'uploadWxImage',
              data: { media_id: serverId },
              callback({ data }) {
                self.qiNiuUpload(self.base64ToFile(data), Symbol())
              },
              errorCallback() {
                self.$root.promptInfo('上传失败，请重试', 2000)
              },
            })
            next()
          },
        })
      }
      uploadImage(localIds[0])
    },

    base64ToFile(value) {
      const arr = value.split(',')
      let mime
      let b64Str
      if (arr.length < 2) {
        mime = 'image/jpg'
        b64Str = atob(arr[0])
      } else {
        mime = arr[0].match(/:(.*?);/)[1]
        b64Str = atob(arr[1])
      }
      let n = b64Str.length
      let u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = b64Str.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },

    // 取消裁剪
    cropperCancel() {
      this.list = []
      this.isShowCropperComponent = false
      this.$emit('reset')
    },

    qiNiuUpload(file, localId) {
      const self = this

      // 增加一个item
      let template = {
        url: '',
        error: '',
        id: localId,
        progress: '',
      }
      self.list.push(template)
      if (!/\.jpg|\.jpeg|\.png/.test(file.name)) {
        // this.$root.prompt('支持格式.jpg, .jpeg , .png格式')
        template.error = `支持格式.jpg, .jpeg , .png格式`
        this.$emit('uploadStatus', -1, 'fail')
        this.$emit('error', this.list, '支持格式.jpg, .jpeg , .png格式')
        return false
      }
      if (
        file.size > 1024 * 1024 * 2 &&
        !this.isShowLittleIcon &&
        this.isSortIcon
      ) {
        template.error = `图片大于2M`
        this.$emit('uploadStatus', -1, 'error')
        this.$emit('error', this.list, '文件大于2M，请重新选择！')
        return true
      }
      if (file.size > 1024 * 1024 * 5 && !this.isShowLittleIcon) {
        template.error = `图片大于5M`
        this.$emit('uploadStatus', -1, 'error')
        this.$emit('error', this.list, '图片大于5M')
        return true
      }
      if (file.size > 1024 * 1024 * 7) {
        template.error = `图片大于7M`
        this.$emit('uploadStatus', -1, 'error')
        this.$emit('error', this.list, '图片大于7M')
        return true
      }

      let subObject = {
        next: this.next.bind(this, template),

        error: this.error.bind(this, template),

        complete: this.complete.bind(this, template),
      }

      // 调用sdk上传接口获得相应的observable，控制上传和暂停
      const ext = file.type.split('/')[1] || 'jpg'
      const fileExt = `${`${
        Number(this.$store.state.userInfo.uid) + Math.random()
      }`.replace('.', '-')}.${ext}`
      const newFileName = `teacher/image/${
        this.$store.state.userInfo.jg_id
      }/${this.$options.filters.formatTimeStamp(
        new Date() / 1000,
        'yyyyMMddhhmmss'
      )}/${fileExt}`

      const complete = (value) => {
        const observable = qiNiu.upload(
          value,
          newFileName,
          self.token,
          { fname: '', params: {} },
          {
            retryCount: 3,
            useCdnDomain: true,
            disableStatisticsReport: true,
          }
        )
        self.subscription[localId] = observable.subscribe(subObject)
        self.$emit('getSubscription', self.subscription)
      }

      qiNiu
        .compressImage(file, {
          quality: 1,
          maxWidth: 1656,
        })
        .then((res) => {
          if (res.base64 && this.isLoadCropper) {
            this.isShowCropperComponent = {
              base64: res.base64,
              type: file.type,
            }
            this.cropperCbk = (dist) => {
              complete(
                typeof dist === 'string' ? this.base64ToFile(dist) : dist
              )
              this.isShowCropperComponent = false
            }
          } else {
            complete(res.dist)
          }
        })
        .catch(() => {
          complete(file)
        })
    },

    next(template, { total: { percent } }) {
      template.progress = Math.floor(percent)
      this.$emit('progress', this.list)
    },

    error(template, { code }) {
      if (Number(code) === 401) {
        this.$emit('getToken')
      }
      template.error = `上传失败${code}`
      this.$emit('uploadStatus', -1, 'error')
      this.$emit('error', this.list)
    },

    complete(template, { key }) {
      template.url = `https://f.dingdingkaike.com.cn/${key}`
      this.$emit('uploadStatus', -1)
      this.$emit('complete', this.list)
    },
  },
}
</script>
<style lang="scss">
.shuiyin {
  height: 50px;
  width: 110px;
}
.upImg {
  position: absolute;
  width: 26px;
  height: 26px;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.pcWxUploadBtn {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
