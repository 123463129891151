var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CourseConfirm',{attrs:{"title":`${
      _vm.isChild == 1
        ? '编辑子分类'
        : _vm.isChild == 2
        ? '新建子分类'
        : _vm.editId
        ? '编辑分类'
        : '新建分类'
    }`,"isShowBtn":true},on:{"cancelFn":_vm.closeFn,"save":_vm.saveSort}},[_c('div',{staticClass:"inner-slot high-zindex"},[_c('el-form',{attrs:{"model":_vm.ruleForm2,"label-width":"100px","label-position":"left"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[(_vm.activeType != 1)?_c('el-form-item',{attrs:{"label":"选择父类","prop":"set_pid"}},[_c('el-select',{attrs:{"placeholder":"请选择父类"},model:{value:(_vm.ruleForm2.set_pid),callback:function ($$v) {_vm.$set(_vm.ruleForm2, "set_pid", $$v)},expression:"ruleForm2.set_pid"}},[_vm._l((_vm.options),function(item){return [(_vm.editId !== item.set_id)?_c('el-option',{key:item.set_id,attrs:{"label":item.set_name,"value":item.set_id}}):_vm._e()]})],2)],1):_vm._e(),(_vm.isChild)?_c('el-form-item',{attrs:{"label":"父分类","prop":"nameSort"}},[[_c('div',[_vm._v(_vm._s(_vm.nameSort))])]],2):_vm._e(),_c('el-form-item',{attrs:{"label":_vm.isChild ? '子分类名称' : '分类名称',"prop":"set_name"}},[_c('div',{staticClass:"el-input-wrap"},[_c('el-input',{attrs:{"placeholder":_vm.isChild
                ? '请输入子分类名称，推荐4个字符以内'
                : '请输入分类名称，推荐4个字符以内',"maxlength":"8","show-word-limit":""},model:{value:(_vm.ruleForm2.set_name),callback:function ($$v) {_vm.$set(_vm.ruleForm2, "set_name", $$v)},expression:"ruleForm2.set_name"}})],1)]),_c('el-form-item',{attrs:{"label":_vm.isChild ? '子分类图标' : '分类图标'}},[_c('div',{staticClass:"course-pic-wrap"},[_c('img',{attrs:{"src":_vm.ruleForm2.icon,"alt":""}}),_c('div',{staticClass:"p-wrap"},[_c('p',{staticClass:"txt"},[_vm._v("建议尺寸200*200px，JPG、PNG格式， 图片小于2M")]),_c('div',{staticClass:"btn-wrap"},[_c('el-button',{staticClass:"btn",attrs:{"type":"info","size":"small","plain":""}},[_vm._v(" 更换图标 ")]),_c('change-photoimg',{ref:"popo",staticClass:"changePhotoimg",attrs:{"size":2},on:{"complete":_vm.completephoto}})],1)])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }