<!--
 * @Descripttion: 一键复制弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 09:45:29
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-18 17:38:11
 -->
<template>
  <CourseConfirm title="快速复制课程信息和设置项" :isShowBtn="false" @cancelFn="closeFn">
    <loading leftPos="0" v-show="isShowLoading"></loading>
    <div class="inner-slot">
      <div class="input-wrap">
        <el-input placeholder="输入直播名称搜索" v-model="requestParam.search" @keyup.enter.native="doSeach" v-if="isOpenClassLive"></el-input>
        <el-input placeholder="输入课程名称搜索" v-model="requestParam.title" @keyup.enter.native="doSeach" v-else></el-input>
      </div>
      <div class="list simple-scrollar" v-if="!isShowEmpty">
        <div class="item" :class="{ 'gray-status': item.status == 2 }" v-for="item in resultInfo.data" :key="item.course_id">
          <div class="item1 info">
            <div class="cover">
              <lazy class="img" :src="item.photo"></lazy>
              <!-- <div class="shangjia">已下架</div> -->
            </div>
            <div class="right">
              <div class="title">{{ item.name }}</div>
              <div class="price">
                <span class="vm p1">
                  <div class="money" v-if="isOpenClassLive">
                    <span class="redcolor" v-if="item.play_type != 2">
                      <span v-if="item.play_price > 0">
                        ￥{{ item.play_price }}
                      </span>
                      <span v-else>免费</span>
                    </span>
                    <span class="redcolor" v-else>
                      密码：{{ item.play_pwd }}
                    </span>
                  </div>
                  <i v-else>{{ item.totalsales }}人报名</i>
                </span>
              </div>
            </div>
          </div>
          <div class="item2" v-if="!isOpenClassLive">
            <div class="vm count item2-3">
              {{ item.status == 1 ? '已上架' : '已下架' }}
            </div>
          </div>
          <div :class="['right-copy-btn',isOpenClassLive&&'showCopy']">
            <el-button size="small" @click="copyData(item)">一键复制</el-button>
          </div>
        </div>
      </div>
      <div class="empty-wrap" v-show="isShowEmpty">
        <empty :isOld="false" :positionCenter="true" str="absolute"></empty>
      </div>
      <div class="pagination-wrap" v-show="resultInfo.allNum > 15">
        <el-pagination
          background
          :page-size="15"
          layout="prev, pager, next"
          :page-count="Number(resultInfo.pageNum)"
          :total="Number(resultInfo.allNum)"
          @current-change="getChangeCurrent"
          hide-on-single-page
        >
        </el-pagination>
      </div>
    </div>
  </CourseConfirm>
</template>
<script>
import CourseConfirm from '@/components/CourseConfirm'
import empty from '@/components/Empty'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'
import loading from '@/components/LoadGif'

export default {
  name: 'copyPop',
  props: {
    course_type: [String, Number],
    // 1公开课 2直播课
    type: [String, Number],
    // 判断是否直播
    isOpenClassLive:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      requestParam: {},
      resultInfo: {},
      isShowLoading: true,
      isShowEmpty: false
    }
  },
  mixins: [pageOverflow],
  components: {
    CourseConfirm,
    empty,
    loading
  },
  watch:{
    ['requestParam.search']:{
      handler(){
        if(this.isOpenClassLive){
          this.doSeach()
        }
      },
    }
  },
  created() {
    if(this.isOpenClassLive){
      // 直播
      this.requestParam = {
        type: this.type,
        search: '',
        page: 1,
      }
    }else{
      // 课程
      this.requestParam = {
        course_type: this.course_type,
        price_type: '',
        status: '',
        title: '',
        page: 1,
      }
    }
    this.getList()
  },
  methods: {
    closeFn() {
      this.$emit('closeFn')
    },
    // 一键复制
    copyData(data) {
      if(this.isOpenClassLive){
        this.$emit('copySuccess',data)
      }else{
        this.getContentGuide(data)
      }
    },
    // 获取课程引导内容
    getContentGuide(item) {
      const self = this
      self.$http({
        name: API.getGuideInfo_Api.name,
        url: API.getGuideInfo_Api.url,
        data: {
          course_id: item.course_id
        },
        callback(res) {
          if (res.code == 200) {
            item['course_guide_content'] = res.data.course_guide_content
            self.getCourseSet(item)
          }
        }
      })
    },
    // 获取课程所在分类列表
    getCourseSet(info) {
      const self = this
      self.$http({
        name: API.getCourseSet_Api.name,
        url: API.getCourseSet_Api.url,
        data: {
          course_id: info.course_id
        },
        callback(res) {
          if (res.code == 200) {
            info['course_set_id'] = res.data
            localStorage.setItem('copyCourseInfo', JSON.stringify(info))
            self.$emit('copySuccess')
            self.$root.prompt({
              msg: '一键复制成功',
              type: 'success'
            })
            self.closeFn()
          }
        }
      })
    },
    //关键字搜索
    doSeach(e) {
      const keyCode = window.event ? e&&e.keyCode||0 : e.which
      if (keyCode == 13) {
        this.requestParam.page = 1
        this.getList()
      } else if(this.isOpenClassLive){
        this.requestParam.page = 1
        this.getList()
      }
    },
    // 下一页
    getChangeCurrent(value) {
      this.requestParam.page = value
      this.getList()
    },
    getList() {
      const self = this
      const data = self.requestParam
      self.isShowLoading = true
      self.$http({
        name: self.isOpenClassLive?API.openClassList_Api.name:API.searchCourse_Api.name,
        url: self.isOpenClassLive?API.openClassList_Api.url:API.searchCourse_Api.url,
        data,
        callback(res) {
          if (res.code == 200) {
            self.isShowLoading = false
            self.isShowEmpty = false
            self.resultInfo = res
          }
        },
        error({ code }) {
          if (code == 204) {
            self.isShowLoading = false
            self.isShowEmpty = true
            self.resultInfo = {}
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.inner-slot {
  min-width: 603px;
  .input-wrap {
    width: 218px;
    height: 40px;
    margin: 28px 20px 10px 0;
    float: right;
    position: relative;
    z-index: 82;
  }
}
.list {
  clear: both;
  height: 400px;
  overflow-y: scroll;
}
.item {
  font-size: 0;
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
  &:hover {
    .right-copy-btn {
      display: block;
    }
  }
  &:first-child {
    margin-top: -10px;
  }
  .info {
    display: flex;
    align-items: center;
    .cover {
      width: 68px;
      height: 38px;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
      }
      .shangjia {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 62px;
        height: 62px;
        font-size: 12px;
        font-weight: bold;
        line-height: 62px;
        color: white;
        border-radius: 50%;
        background: black;
        text-align: center;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.8);
      }
    }
    .right {
      flex: 1;
      max-width: 410px;
      margin-left: 8px;
      margin-right: 167px;
      .title {
        width: 280px;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        // min-height: 34px;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(51, 51, 51, 1);
      }
      .price {
        margin-top: 6px;
        white-space: nowrap;
        .p1 {
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          margin-right: 15px;
          color: rgba(255, 53, 53, 1);
          i {
            color: #ccc;
          }
        }
        .tag {
          width: 16px;
          height: 13px;
          margin-right: 5px;
        }
        .tag0 {
          background: url('../../assets/img/courseAdmin/ico_yhq.png') no-repeat;
        }
        .tag1 {
          background: url('../../assets/img/courseAdmin/ico_pk.png') no-repeat;
        }
        .tag2 {
          background: url('../../assets/img/courseAdmin/ico_liebian.png') no-repeat;
        }
        .tag3 {
          background: url('../../assets/img/courseAdmin/ico_dhm.png') no-repeat;
        }
      }
    }
  }
  .type,
  .teacher,
  .count,
  .sigUp {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: #999999;
  }
  .right-copy-btn {
    position: absolute;
    right: 20px;
    display: none;
    ::v-deep .el-button {
      margin: 30px 0 28px;
      border: 1px solid #0aa29b;
      color: #0aa29b;
    }
  }
  .showCopy {
    display: block;
  }
}
.gray-status {
  background: #f7f7f7;
}
.pagination-wrap {
  padding: 20px 0 0;
  text-align: center;
}
.empty-wrap {
  height: 400px;
  position: relative;
}
</style>
