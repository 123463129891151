<template>
    <div class="container">
        <el-form ref="form" label-position="left" :rules="rules" :model="form">
            <el-form-item label="证件类型" prop="type" label-width="100px">
                <span v-if="type=='individual'">中国大陆居民身份证</span>
                <el-select class="selectBtn" v-model="form.id_type" placeholder="请选择" v-if="type!='individual'">
                    <el-option
                        v-for="item in PersonIdCardOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="证件照片" prop="imgs" label-width="100px">
                <div class="creImgBox">
                    <div class="columnBox imgUpload">
                        <div class="columnBox imgCenter" @click="selectImg(0)">
                            <div v-if="!form.imgs[0]" class="item columnBox back" :key="key1">
                                <i class="photo"></i>
                                <p>上传证件照片（人像面）</p>
                            </div>
                            <div v-else>
                                <el-image class="cp" :src="form.imgs[0]" fit="fill">
                                </el-image>
                            </div>
                        </div>
                        <div class="columnBox imgCenter" @click="selectImg(1)" :key="key1 + 1">
                            <div v-if="!form.imgs[1]" class="item columnBox front">
                                <i class="photo"></i>
                                <p>上传证件照片（国徽面）</p>
                            </div>
                            <div v-else>
                                <el-image class="cp" :src="form.imgs[1]" fit="fill">
                                </el-image>
                            </div>
                        </div>
                        <change-photoimg :size="2" @complete="completephoto" class="changePhotoimg" ref="changePhotoimg"
                            v-show="false">
                        </change-photoimg>
                    </div>
                    <div class="columnBox imgtext">
                        <p class="content">证件照片需为服片或者彩色扫描件；</p>
                        <p class="content">格式要求：jpg、jpeg、png格式照片，大小不超过2M</p>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="证件姓名" prop="id_name" label-width="100px">
                <el-input class="inputBtn" v-model="form.id_name" placeholder="请输入证件姓名"></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="id_number" label-width="100px">
                <el-input class="inputBtn" v-model="form.id_number" :disabled="isEditCtf" placeholder="请输入证件号码"></el-input>
            </el-form-item>
            <el-form-item label="证件有效期" prop="date" label-width="100px">
                <div class="columnBox expirationDate" :class="[form.id_long_term==2&&'block']">
                    <el-radio-group v-model="form.id_long_term">
                        <el-radio :label="1">非长期有效</el-radio>
                        <el-radio :label="2">长期有效</el-radio>
                    </el-radio-group>
                    <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" align="right" unlink-panels value-format="timestamp"
                        :picker-options="pickerOptions" v-if="form.id_long_term==1"></el-date-picker>
                </div>
            </el-form-item>
            <el-form-item label="证件地址" prop="id_address" label-width="100px">
                <el-input class="inputBtn" type="textarea" placeholder="请填写详细地址，例：12号楼8层801室" v-model="form.id_address"
                    maxlength="80" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }">
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import {
    PersonIdCardOption,
} from './mock.js'
export default {
    components: {
        changePhotoimg,
    },
    props: {
        isEdit: Boolean,
        info: Object,
        type:String,
        certStatus:Number,
    },
    data() {
        const validateDateRange = (rule, value, callback) => {
            const isLongTerm = this.form.id_long_term
            const date = this.form.date
            if(Number(isLongTerm)==2){
                callback();
            } else if (date && date.length === 2) {
                const startDate = new Date(date[0] * 1000); // 将时间戳转换为Date对象  
                const endDate = new Date(date[1] * 1000);
                if (startDate > endDate) {
                    callback(new Error('开始日期不能晚于结束日期'));
                } else {
                    // 这里可以添加其他业务逻辑验证  
                    callback();
                }
            } else {
                callback(new Error('请选择有效的日期范围'));
            }
        };
        const validateImgs = (rule, value, callback) => {
            const [img1, img2] = this.form.imgs
            if (!img1 || !img2) {
                callback(new Error('请上传证件照片'));
            } else {
                callback();
            }
        }
        // const validateIdCard = (rule, value, callback) => {
        //     const regex = /^(\d{15}$)|^\d{17}([0-9]|X)$/;
        //     if (regex.test(value)) {
        //         callback();
        //     } else {
        //         callback(new Error('请输入有效的身份证号码'));
        //     }
        // }
        return {
            pickerOptions: {},
            imgType: 0,
            form: {
                type: '中国大陆居民身份证',
                imgs: [null, null],
                id_type:'中国大陆居民身份证',
                id_name: '',
                id_number: '',
                id_long_term: 1,
                date: [],
                id_address: '',
            },
            rules: {
                type: [
                    { required: true, },
                ],
                imgs: [
                    {
                        type: 'array',
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    },
                    { validator: validateImgs, trigger: 'blur' }
                ],
                id_name: [
                    { required: true, message: '请输入证件姓名', trigger: 'blur' },
                ],
                region: [
                    { required: true, message: '请选择证件照片', trigger: 'blur' },
                ],
                id_number: [
                    { required: true, message: '请输入身份证号码', trigger: 'blur' },
                    // { validator: validateIdCard, trigger: 'blur' }
                ],
                date: [
                    { required: true,validator: validateDateRange, trigger: 'change' },
                ],
                expirationDate: [
                    { required: true, message: '请输入证件号码', trigger: 'blur' },
                ],
                id_address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
            },
            key1: 0,
            PersonIdCardOption:PersonIdCardOption,
        }
    },
    computed:{
        isEditCtf(){
            return this.type=='edit-person'&&this.certStatus!=3
        }
    },
    watch:{
        info:{
            handler(){
                this.setForm()
            },
            // immediate:true,
        }
    },
    created() {
    },
    methods: {
        setForm(){
            for (const key in this.form) {
                const filter = ['type','imgs','date','id_long_term']
                if(!filter.includes(key)) this.form[key] = this.info[key]||''
                
            }
            this.form = Object.assign(this.form,{
                id_long_term:Number(this.info.id_long_term),
                imgs: [this.info.id_photo_back,this.info.id_photo_front], // 如果info中没有imgs，则使用[null, null]作为默认值  
                date: Number(this.info.id_long_term)==1?[this.info.id_start_time*1000,this.info.id_end_time*1000]:[], // 如果info中没有date，则使用空数组作为默认值  
            })
        },
        // 添加图片
        completephoto(val) {
            this.key1 += 1
            switch (this.imgType) {
                case 0:
                    this.form.imgs[0] = val
                    break;
                case 1:
                    this.form.imgs[1] = val
                    break;
            }
        },
        selectImg(type) {
            // 编辑认证，不能修改证件
            if(this.isEditCtf) return
            this.imgType = type
            this.$refs.changePhotoimg.changePicture()
        },
        submit() {
            return new Promise((res, rej) => {
                this.$refs.form.validate((valid) => {
                    console.log(valid, this.form);
                    if (valid) {
                        res(this.form)
                    } else {
                        rej(false)
                    }
                })

            })
        },
    }
}
</script>

<style scoped lang="scss">
@import '../css/upload.scss';
.container {
    .green {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #0AA29B;
        line-height: 16px;
        text-align: left;
        font-style: normal;
    }

    .cp {
        cursor: pointer;
    }

    .inputBtn {
        max-width: 500px;
    }

    .columnBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .expirationDate {
        padding-top: 12px;
        &.block {
            display: flex;
        }
    }

    #changePhotoimg {}


}
</style>