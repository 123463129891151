<template>
  <header class="header">
    <img
      style="margin-top: 7px"
      @click="homePage"
      class="vm logo"
      :src="
        customJgInfo.adminPageLogo ||
        (customJgInfo.isCustome
          ? ''
          : 'https://img.dingdingkaike.com/logo@3x.png')
      "
    />
    <!-- <el-button style="margin-right:20px;width:158px" type="text">
      退出登录
    </el-button> -->
    <div class="right" v-if="status == 1">
      <div class="buttom" @click.stop="exit">退出登录</div>
    </div>
    <div class="right" v-else-if="status == 2">
      <div class="account">当前登录账号：{{ userInfo.umobile }}</div>
      <div class="buttom" @click.stop="exit">退回登录</div>
    </div>
  </header>
</template>
<script>
import { mapState } from 'vuex'
import { logOut } from '@ass/js/cookie'
export default {
  name: 'my-header',

  props: {
    location: Boolean,
    status: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState(['isWindowsApp', 'userInfo']),
    customJgInfo() {
      return this.$store.state.customJgInfo
    },
  },

  methods: {
    // 退出登录
    exit() {
      // 清除客户端cookie
      if (this.isWindowsApp) {
        try {
          this.$store.dispatch('sendToWindowsMsg', {
            type: 4,
          })
        } catch (e) {
          console.log(e)
        }
      }
      // 也清除浏览器cookie
      // this.$store.commit("setUserInfo");
      logOut()
    },

    homePage() {
      // if (this.customJgInfo.isCustome) return
      // if (this.location) {
      //   this.$store.dispatch('open', 'https://www.dingdingkaike.cn')
      // } else {
      //   this.$router.replace({
      //     path: this.$store.getters.homePath,
      //   })
      // }
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  background: #fff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
  .right {
    display: flex;
    align-items: center;
    .account {
      cursor: auto;
      font-size: 14px;
      color: #333333;
    }
    .buttom {
      text-align: center;
      font-size: 14px;
      color: #ff3535;
      background: #ffffff;
      padding: 0 20px;
      font-weight: bold;
      line-height: 52px;
    }
  }
  .logo {
    height: 40px;
    margin: 0 20px;
    cursor: pointer;
  }
}
</style>
