<template>
  <my-upload
    :max="max"
    :ext="ext"
    :token="token"
    :isShowLittleIcon="isShowLittleIcon"
    :isUploadAll="isShowLittleIcon"
    :isSortIcon="isSortIcon"
    @getToken="getToken"
    @error="error"
    @reset="dataReset"
    @complete="complete"
    @uploadStatus="uploadStatus"
    @getSubscription="getSubscription"
    @showCropperComponent="$emit('showCropperComponent')"
    :isLoadCropper="isLoadCropper"
    :aspectRatio="aspectRatio"
    :cropperOption="cropperOption"
    :style="`${
      (uError || uProgress) && !isSortIcon && !membership
        ? 'background:rgb(204, 204, 204);border:0;'
        : ''
    }${result ? 'border:0;' : ''}`"
    class="vm"
  >
    <template v-if="isShowLittleIcon">
      <img
        v-show="result"
        class="uploadOneImgRes"
        :src="result"
        :class="{ 'be-circle': borderRa, 'be-circle2': borderRa2 }"
      />
    </template>
    <slot v-if="!uploading || !isShowLittleIcon"></slot>
    <slot name="show"></slot>
    <i
      class="uploadOneRemoveBtn"
      v-if="showRemoveBtn"
      @click.stop="remove"
      v-show="uploading"
    ></i>
    <div class="showUploadPs" v-show="uProgress">上传中...</div>
  </my-upload>
</template>
<script>
/**
 *  上传中
 *    进度 > 0 并且 上传结果不存在
 *  上传失败
 *    错误信息存在
 *  上传成功
 *    URl存在
 *
 *    只要接收到开始上传上状态 清空result(保证进入上传中状态)
 *    如果发生错误 显示错误信息
 *    如果成功 就显示图片
 *    <slot>一般都是上传初始状态 比如上传按钮
 *    删除按钮 上传中 上传失败 上传成功 但是初始的时候是不显示
 *    一般在上传中应该有个背景色
 *    初始化默认给result 赋值 并且监听value 保证赋值成功
 */
import myUpload from '@/components/JweixinUpload'

export default {
  name: 'UploadOneImgComponent',

  components: {
    myUpload,
  },

  props: {
    uid: [String, Number],
    value: String,
    ext: {
      type: String,
      default: '',
    },
    isShowLittleIcon: {
      type: Boolean,
      default: true,
    },
    editUserInfo: {
      type: Boolean,
      default: true,
    },
    isRestore: Boolean,
    showRemoveBtn: {
      type: Boolean,
      default: false,
    },
    aspectRatio: Number,
    removeStart: Boolean,
    isLoadCropper: Boolean,
    cropperOption: Object,
    borderRa: {
      type: Boolean,
      default: false,
    },
    borderRa2: {
      type: Boolean,
      default: false,
    },
    isSortIcon: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '/User/editUserSimpleInfo',
    },
    membership: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLintPicture: true, //是否是图像的格式错误的提示，false是，true不是
      max: 1,
      token: '',
      uError: '',
      uProgress: '',
      subscription: {},
      result: this.value || '',
    }
  },

  watch: {
    value() {
      if (!this.result) this.result = this.value
    },

    removeStart() {
      this.reset()
    },
  },

  computed: {
    uploading() {
      return !!(this.result || this.uError || this.uProgress)
    },
  },

  created() {
    this.getToken()
  },

  methods: {
    // 获取上传token
    getToken() {
      const msg = '获取上传凭证失败，上传功能不可用，请刷新重试'

      this.$root.$http({
        errorMessage: msg,
        isMultipleSelf: 'all',
        name: 'getQiniuToken',
        url: '/Index/getQiniuToken',
        callback: ({ data }) => {
          this.token = data
          this.$emit('setToken', data)
        },
      })
    },

    uploadStatus(value, msg) {
      const status = value > 0 ? 'loading' : msg ? 'fail' : 'success'
      if (status === 'fail') {
        this.uProgress = ''
        this.isLintPicture = false
      }
      //如果开始上传 就初始化一些数据
      if (status === 'loading') {
        this.max = 0
        this.result = ''
        this.uProgress = 1
      }
      this.$emit('uploading', {
        status,
      })
    },

    error(value, msg) {
      const result = value
      this.uError = msg || '上传失败!'
      // 引用属性的删除
      result.splice(-1)
      if (!this.isShowLittleIcon) {
        this.uProgress = false
        if (this.isLintPicture) {
          this.$emit('errorHappen', true)
        } else {
          this.$emit('errorHappen', false)
        }
      }
      // 如果出现错误，恢复到旧图片
      if (this.isRestore) {
        this.uProgress = ''
        this.result = this.value
      }
      this.commit('')
    },

    complete(value) {
      const result = value
      this.result = value[0].url
      // 解决成功了 但是进度还是每到100%的BUG
      this.uProgress = ''
      this.commit(value[0].url)
      // 引用属性的删除
      result.splice(-1)
    },

    remove() {
      this.reset()
      this.commit('')
      if (this.$root.uploadOneXhr) {
        this.$root.uploadOneXhr.abort()
        this.$root.uploadOneXhr = null
        delete this.$root.uploadOneXhr
      }
    },

    getSubscription(value) {
      this.reset()
      this.subscription = value
    },

    // 裁剪数据重置
    dataReset() {
      this.reset()
      // 可以进行上传
      this.max = 1
      this.result = this.value
    },

    reset() {
      this.result = ''
      this.uError = ''
      this.uProgress = ''
      const symbols = Object.getOwnPropertySymbols(this.subscription)
      if (symbols && symbols[0]) {
        const temp = this.subscription[symbols[0]]
        if (temp && 'unsubscribe' in temp) {
          temp.unsubscribe()
        }
        this.subscription = {}
      }
      this.$emit('uploading', {
        status: '',
      })
    },

    commit(value) {
      // 可以进行上传
      this.max = 1
      if (this.uError) {
        this.$root.prompt(this.uError, 2000)
      } else {
        // this.$emit("update:value", value);
        // 将获得的图片地址发给后台
        let self = this
        if (self.isShowLittleIcon && self.editUserInfo) {
          // 修改用户头像
          self.$http({
            url: this.url,
            data: {
              uid: self.uid,
              uphoto: value,
            },

            callback: ({ code }) => {
              this.$emit('update:value', value)
              if (code == 200 && !this.url) {
                this.$store.dispatch('getUserInfo')
              }
            },
          })
        } else {
          this.$emit('errorHappen', false)
          self.$emit('getResultImg', value)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.showUploadPs {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #909399;
  border-radius: 4px;
  background: #f4f4f5;
  border: 1px solid #dcdfe6;
}
.uploadOneRemoveBtn {
  position: absolute;
  right: -1.1rem;
  top: -1.1rem;
  width: 2rem;
  height: 1.5rem;
  z-index: 9;
  // background: url('#{$c2tImg}CustomerServiceSetting/ico_ewm_szkf@2x.png')no-repeat center;
  // background-size: 1rem;
}
.uploadOneImgRes {
  display: block;
  width: 100%;
  height: 100%;
}
.be-circle {
  width: 100%;
  // height: 60%;
  margin: 0 auto;
  border-radius: 50%;
}
.be-circle2 {
  width: 100%;
  // height: 60%;
  margin: 0 auto;
}
</style>
