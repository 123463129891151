<!--
 * @Descripttion: 基本信息
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 10:09:54
 * @LastEditors: cyy
 * @LastEditTime: 2022-08-30 18:59:44
 -->
<template>
  <div>
    <div class="basic-info-wrap" :class="{ 'is-edit': isEdit }">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        label-position="right"
        @submit.native.prevent
      >
        <!--课程名称-->
        <el-form-item label="课程名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入课程名称"
            maxlength="50"
            show-word-limit
            autosize
          ></el-input>
        </el-form-item>
        <!--课程简介-->
        <el-form-item label="课程简介">
          <el-input
            v-model.trim="ruleForm.new_cabout"
            :rows="6"
            type="textarea"
            class="textarea"
            placeholder="请输入课程简介"
            maxlength="140"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!-- 选择助教 -->
        <el-form-item label="课程助教" v-if="courseType == 1">
          <el-select
            clearable
            multiple
            :disabled="ruleForm.status == 1"
            filterable
            v-model="ruleForm.assistant_ids"
            style="width: 523px"
            placeholder="请选择助教"
          >
            <el-option
              v-for="item in teacherOptions"
              :key="item.uid"
              :label="item.uname"
              :value="item.uid"
            >
              <div
                style="
                  float: left;
                  height: 22px;
                  width: 22px;
                  border-radius: 50%;
                  margin-top: 6px;
                  background: grey;
                "
              >
                <img
                  style="height: 22px; width: 22px"
                  :src="item.uphoto"
                  alt=""
                />
              </div>
              <div style="float: left; margin-left: 10px; font-size: 13px">
                {{ item.uname }}
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <!--课程分类-->
        <el-form-item label="课程分类">
          <el-cascader
            :key="keyValue"
            filterable
            placeholder="请选择课程分类"
            v-model="ruleForm.course_set_id"
            :options="options"
            :props="{ multiple: true, checkStrictly: true }"
            clearable
            :debounce="1000"
            @focus="cascaderFocus"
            @change="changeCascader"
            :filter-method="dataFilter"
            ref="cascader"
          ></el-cascader>
          <span class="new-add-sort cp" @click.stop="toggleShowAddSort">
            新建分类
          </span>
        </el-form-item>
        <!--收费方式-->
        <el-form-item label="收费方式" prop="price_type">
          <el-radio-group v-model="ruleForm.price_type">
            <el-radio label="2" :disabled="ruleForm.is_action">免费</el-radio>
            <el-radio label="3" :disabled="ruleForm.is_action">密码</el-radio>
            <el-radio label="1">付费（大于100元可开启分期支付）</el-radio>
            <!-- <el-radio label="按时段(敬请期待)" disabled></el-radio>
            <el-radio label="按课时(敬请期待)" disabled></el-radio>
            <el-radio label="按班级(敬请期待)" disabled></el-radio> -->
          </el-radio-group>
          <div class="password-wrap" v-if="ruleForm.price_type == 3">
            <el-form-item prop="fee_pwd" label="设置密码">
              <div class="password-container">
                <el-input
                  placeholder="设置8位数内观看密码"
                  size="small"
                  v-model.trim="ruleForm.fee_pwd"
                  maxlength="8"
                ></el-input>
              </div>
            </el-form-item>
          </div>

          <div class="has-price" v-if="ruleForm.price_type == 2">
            <div
              class="password-wrap price-wrap"
              style="paddin-top: 0; padding-bottom: 0"
            >
              <el-form-item label="划线价" prop="costpriceFree">
                <div class="password-container">
                  <!-- <el-input placeholder="输入划线价格，选填" v-model="ruleForm.costprice"></el-input>   -->
                  <input
                    class="my-input"
                    ref="costpriceFree"
                    placeholder="输入价格"
                    type="text"
                    @input="toNumber"
                    @blur="getPriceFreeValue"
                  />
                </div>
                <span>元</span>
              </el-form-item>
            </div>
            <p>
              提示：划线价可以和实际价格形成对比，比如 ￥99
              <span style="text-decoration: line-through">199</span>
            </p>
          </div>

          <div class="has-price" v-if="ruleForm.price_type == 1">
            <div class="password-wrap price-wrap" style="padding-bottom: 0">
              <el-form-item label="价格" prop="price">
                <div class="password-container">
                  <!-- <el-input placeholder="输入价格" v-model="ruleForm.price"></el-input> -->
                  <input
                    class="my-input"
                    ref="price"
                    placeholder="输入价格"
                    type="text"
                    @input="toNumber"
                    @blur="getPriceValue"
                  />
                </div>
                <span>元</span>
              </el-form-item>
            </div>
            <div
              class="password-wrap price-wrap"
              style="paddin-top: 0; padding-bottom: 0"
            >
              <el-form-item label="划线价" prop="costprice">
                <div class="password-container">
                  <!-- <el-input placeholder="输入划线价格，选填" v-model="ruleForm.costprice"></el-input>   -->
                  <input
                    class="my-input"
                    ref="costprice"
                    placeholder="输入价格"
                    type="text"
                    @input="toNumber"
                    @blur="getCostPriceValue"
                  />
                </div>
                <span>元</span>
              </el-form-item>
            </div>

            <div
              class="password-wrap price-wrap"
              style="paddin-top: 0; padding-bottom: 0"
            >
              <el-form-item label="说明文字" prop="costprice">
                <div slot="label" class="flex-center">
                  说明文字
                  <el-popover placement="top-start" width="400" trigger="hover">
                    <div>
                      <img src="@ass/img/1.4.18/img_smwzsl.png" alt="" />
                    </div>
                    <template slot="reference">
                      <slot name="text"></slot>
                      <div class="vm help" :class="{ icon2 }"></div>
                    </template>
                    <!-- <el-button slot="reference">
                      hover 激活
                    </el-button> -->
                  </el-popover>
                </div>
                <el-input
                  show-word-limit
                  class="my-input"
                  maxlength="10"
                  placeholder="输入文字，留空不显示文字"
                  type="text"
                  style="width:250px"
                  v-model="ruleForm.costprice_explain"
                ></el-input>
                <span>元</span>
              </el-form-item>
            </div>
            <p>
              提示：划线价可以和实际价格形成对比，比如 ￥99
              <span style="text-decoration: line-through">199</span>
            </p>
            <huabeiIssue
              style="padding: 0px 0 20px 80px; margin-top: 8px"
              v-if="ruleForm.price_type == 1"
              :price="endprice"
              :is_installment_3.sync="ruleForm.is_installment_3"
              :is_installment_6.sync="ruleForm.is_installment_6"
              :is_installment_12.sync="ruleForm.is_installment_12"
            ></huabeiIssue>
          </div>
          <div
            v-if="ruleForm.price_type == 2 || ruleForm.price_type == 3"
            class="password-wrap"
            style="
              paddin-top: 0;
              padding-bottom: 0;
              white-space: nowrap;
              padding-left: 0;
            "
          >
            <el-form-item label="报名按钮" prop="costpriceFree">
              <el-input
                style="width: 176px"
                :placeholder="
                  ruleForm.price_type == 2 ? '立即学习' : '密码报名'
                "
                type="text"
                maxlength="6"
                show-word-limit
                v-model.trim="ruleForm.button_name"
              />
            </el-form-item>
          </div>
        </el-form-item>
        <!--小班人数-->
        <el-form-item label="小班人数" prop="order_num" v-if="courseType == 2">
          <el-select
            style="width: 380px"
            class="select"
            placeholder="请选择课程观看有效期"
            v-model="ruleForm.order_num"
          >
            <el-option label="1对1" value="1"></el-option>
            <el-option label="1对2" value="2"></el-option>
            <el-option label="1对3" value="3"></el-option>
            <el-option label="1对4" value="4"></el-option>
            <el-option label="1对5" value="5"></el-option>
            <el-option label="1对6" value="6"></el-option>
            <el-option
              label="1对12（敬请期待）"
              value="48"
              disabled
            ></el-option>
            <el-option
              label="1对20（敬请期待）"
              value="48"
              disabled
            ></el-option>
          </el-select>
          <el-tooltip placement="bottom-start">
            <div slot="content" style="font-size: 12px; line-height: 20px">
              <p>资费标准单价：0.05元（0.5叮豆）/分钟/人</p>
              <p>小班1v1：0.05元/分钟</p>
              <p>小班1v2：0.10元/分钟</p>
              <p>小班1v3：0.15元/分钟</p>
              <p>小班1v4：0.20元/分钟</p>
              <p>小班1v5：0.25元/分钟</p>
              <p>小班1v6：0.30元/分钟</p>
              <p>举例说明：您创建一个6人小班课堂，课时60分钟，小班</p>
              <p>课计费单价为0.05元/分钟/人，如果所有学员都上课满</p>
              <p>时，那么整堂结</p>
              <p>算为：6人*60分钟*0.05元/人/分钟=18元（180个叮豆）</p>
            </div>
            <div class="tariffStandard">资费标准</div>
          </el-tooltip>
        </el-form-item>
        <!--小班人数-->
        <!--课程封面-->
        <el-form-item label="课程封面" prop="photo">
          <div class="course-pic-wrap">
            <img :src="ruleForm.photo" alt="" />
            <div class="p-wrap">
              <p class="txt">建议尺寸800*450px，JPG、PNG格式， 图片小于5M</p>
              <div class="btn-wrap">
                <!-- <uploadImg
                  :isShowLittleIcon="false"
                  @getResultImg="getResultImg"
                  @setToken="setToken"
                  @errorHappen="errorHappen"
                  ext="?imageView2/1/w/828/h/466"
                >
                </uploadImg> -->

                <div class="btn" plain>更换封面</div>
                <change-photoimg
                  :size="5"
                  @complete="completephoto"
                  class="changePhotoimg"
                ></change-photoimg>
                <span class="warning" v-if="isShowWarning">图片过大</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--课程详情-->
        <el-form-item label="课程详情">
          <editor
            ref="editor"
            class="editor"
            v-model="ruleForm.content"
          ></editor>
        </el-form-item>
        <!-- <div class="success-save" v-if="isEdit">
          <el-button type="success" @click.stop="doEditSave">保存</el-button>
        </div> -->
        <div class="saveButton-wrap" v-if="isEdit">
          <el-button type="success" @click="doEditSave">保存</el-button>
        </div>
      </el-form>
    </div>
    <loading v-if="isShowLoading" leftPos="0"></loading>
    <addSortPop v-if="isShowAddSort" @closeFn="toggleShowAddSort" ></addSortPop>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
// import uploadImg from '@/components/UploadOneImg'
import addSortPop from '@/components/coursePop/addSortPop'
import API from '@/request/api'
// import tool from '@ass/js/tool'
import loading from '@/components/LoadGif'
import huabeiIssue from '@cm/huabeiIssue'
import editor from '@cm/editor'

export default {
  name: 'BasicInfo',

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    courseType: [String, Number],
    alreadyCopyTime: {
      type: [String, Number],
      default: 0,
    },
  },

  components: {
    // uploadImg,
    editor,
    addSortPop,
    loading,
    huabeiIssue,
    changePhotoimg,
  },
  data() {
    // 验证密码
    const yzPassword = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9]{1,8}$/.test(value)) {
        callback(new Error('请输入数字或者字母'))
      } else {
        callback()
      }
    }

    return {
      icon2: '',
      keyValue: 0,
      endprice: '',
      teacherOptions: [],
      coursePaths: [
        {
          path: '/courseLive',
        },
        {
          path: '/coursePrivate',
        },
        {
          path: '/courseRecord',
        },
        {
          path: '/courseSeries',
        },
      ],

      // 新建分类弹窗
      isShowAddSort: false,

      // 图片上传过大
      isShowWarning: false,

      ruleForm: {
        // 付费说明
        costprice_explain: '',

        // 课程名称
        name: '',

        // 课程简介
        new_cabout: '',

        cabout: '',

        // course_type课程类型 1直播 2小班 3录播 4系列
        course_type: 1,

        //收费方式
        price_type: '2',
        // price_type: "1",

        // 课程分类图片
        photo: 'https://img.dingdingkaike.com/img_zbkfm@2x.png',

        // 报名密码
        fee_pwd: '',

        // 价格
        price: '',

        // 划线价格
        costprice: '',

        // 课程分类选择id
        course_set_id: [],

        // 编辑器课程详情
        content: '',

        order_num: '1',

        is_action: false,

        is_installment_3: '2',

        button_name: '',

        is_installment_6: '2',

        is_installment_12: '2',
      },

      rules: {
        name: [
          {
            required: true,
            message: '请输入课程名称',
            trigger: 'blur',
            validator(rule, value, callback) {
              if (!value.trim()) {
                callback(new Error('请输入课程名称'))
              } else {
                callback()
              }
            },
          },
        ],

        // new_cabout: [
        //   {
        //     required: true,
        //     message: '请输入课程简介',
        //     trigger: 'blur',
        //   },
        //   {
        //     min: 0,
        //     max: 140,
        //     message: '',
        //     trigger: 'blur',
        //   },
        // ],

        price_type: [
          {
            required: true,
            message: '请选择收费方式',
            trigger: 'change',
          },
        ],

        photo: [
          {
            required: true,
          },
        ],

        fee_pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            validator: yzPassword,
            trigger: 'blur',
          },
        ],

        price: [
          {
            required: true,
            message: '请输入价格',
            trigger: 'blur',
          },
        ],
        order_num: [
          {
            required: true,
          },
        ],
      },
      options: [],

      textTxt: '',

      oldSetId: [],
      isShowLoading: false,
    }
  },

  computed: {
    getObjNum() {
      let obj = {
        name: this.ruleForm.name,
        new_cabout: this.ruleForm.new_cabout,
        course_set_id: this.ruleForm.course_set_id,
        costprice_explain: this.ruleForm.costprice_explain,
        price_type: this.ruleForm.price_type,
        photo: this.ruleForm.photo,
        content: this.textTxt,
      }
      if (this.courseType == 2) {
        obj['order_num'] = this.ruleForm.order_num
      }
      let length = 0
      for (let i in obj) {
        if (obj[i] != '' || obj[i].length > 0) {
          length += 1
        }
      }
      return length
    },
  },

  watch: {
    options() {
      this.keyValue++ //只要监听到数据源发生变化 ，改变keyValue的值，达到重新渲染的效果
    },
    ruleForm: {
      handler() {
        if (!this.isEdit) {
          this.$emit('titleChange', 1, this.getObjNum)
        }
        if (this.ruleForm.price_type == 1) {
          this.$nextTick(() => {
            this.$refs.price.value = this.ruleForm.price
            this.$refs.costprice.value = this.ruleForm.costprice
          })
        }
        if (this.ruleForm.price_type == 2) {
          this.$nextTick(() => {
            this.$refs.costpriceFree.value = this.ruleForm.costprice
          })
        }
      },
      deep: true,
    },
    alreadyCopyTime() {
      this.getLocalstorageData()
    },
  },

  created() {
    if (this.courseType == 1) {
      this.getJigouTeacher()
    }
    this.ruleForm.course_type = this.courseType
    if (this.courseType == 2) {
      this.ruleForm.photo = 'https://img.dingdingkaike.com/img_xbkfm@2x.png'
    } else if (this.courseType == 3) {
      this.ruleForm.photo = 'https://img.dingdingkaike.com/img_lbk@.png'
    } else if (this.courseType == 4) {
      this.ruleForm.photo = 'https://img.dingdingkaike.com/img_xlkfm@2x.png'
    }
    if (!this.isEdit) {
      this.getLocalstorageData()
    } else {
      this.getEditInfo()
    }
    this.getSetList2()
  },

  mounted() {
    setTimeout(() => {
      try {
        this.textTxt = this.editor.txt.text()
      } catch (e) {
        this.textTxt = ''
      }
      this.$emit('titleChange', 1, this.getObjNum)
    }, 500)
  },

  provide() {
    return {
      getSetList2: this.getSetList2,
    }
  },

  // 清楚一键复制的数据
  // destroyed() {
  //   if (localStorage.getItem('copyCourseInfo')) {
  //     localStorage.removeItem('copyCourseInfo')
  //   }
  // },

  methods: {
    cascaderFocus() {
      let obj = {}
      obj.stopPropagation = () => {}
      try {
        this.$refs.cascader.clearValue(obj)
      } catch (err) {
        this.$refs.cascader.handleClear(obj)
      }
    },

    changeCascader(val) {
      if (val.length == 0) {
        this.cascaderFocus()
      }
    },

    dataFilter(node, val) {
      // console.log('val', val, 'node', node)
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    // 获取机构助教老师
    getJigouTeacher() {
      const self = this
      self.$http({
        name: API.getJigouTeacher_Api.name,
        url: '/Organ/getJigouAssistant',
        callback({ data }) {
          self.teacherOptions = data
        },
        error(error) {
          if (error.code == 204) {
            self.teacherOptions = []
          }
        },
      })
    },

    //更换图片的结果
    completephoto(val) {
      this.ruleForm.photo = this.$options.filters.imageslim(val, 3)
    },

    showToast() {
      if (this.ruleForm.is_action) {
        this.$root.prompt('该课程有营销活动进行中，无法修改价格')
      }
    },

    // 获取缓存数据
    getLocalstorageData() {
      if (localStorage.getItem('copyCourseInfo')) {
        const localObj = JSON.parse(localStorage.getItem('copyCourseInfo'))
        const targetObj = {
          // 课程名称
          name: localObj.name,
          // 课程简介
          new_cabout: localObj.new_cabout,

          // course_type课程类型 1直播 2小班 3录播 4系列
          course_type: localObj.course_type,
          //收费方式
          price_type: localObj.price_type,
          // 课程分类图片
          photo: localObj.photo,
          // 报名密码
          fee_pwd: localObj.fee_pwd,
          // 价格
          price: Number(localObj.price) <= 0 ? '' : localObj.price,
          // 划线价格
          costprice: Number(localObj.costprice) <= 0 ? '' : localObj.costprice,
          // 付费说明
          costprice_explain: localObj.costprice_explain,
          // 编辑器课程详情
          content: localObj.content,
          order_num: localObj.order_num ? localObj.order_num : '1',
          course_set_id: localObj.course_set_id,
          assistant_ids: localObj.assistant_ids,
          is_installment_3: localObj.is_installment_3,
          is_installment_6: localObj.is_installment_6,
          is_installment_12: localObj.is_installment_12,
        }
        if (this.courseType == localObj.course_type) {
          this.ruleForm = Object.assign({}, this.ruleForm, targetObj)
        }
        if (this.$refs.editor) {
          this.$refs.editor.html(
            `
              ${
                this.ruleForm.cabout ? this.ruleForm.cabout : ''
              }
              ${
                // tool.detailText(this.ruleForm.content)
                this.ruleForm.content
              }
            `
          )
          this.textTxt = this.$refs.editor.text()
        }
        this.$nextTick(() => {
          if (this.ruleForm.price_type == 1) {
            if (this.ruleForm.price > 0) {
              this.$refs.price.value = this.ruleForm.price
              this.endprice = this.ruleForm.price
            }
            if (this.ruleForm.costprice > 0) {
              this.$refs.costprice.value = this.ruleForm.costprice
            }
          }
          if (this.ruleForm.price_type == 2) {
            if (this.ruleForm.costprice > 0) {
              this.$refs.costpriceFree.value = this.ruleForm.costprice
            }
          }
        })
      }
    },
    getPriceValue() {
      this.ruleForm.price = this.$refs.price.value
      this.endprice = this.$refs.price.value
    },

    getCostPriceValue() {
      this.ruleForm.costprice = this.$refs.costprice.value
    },
    getPriceFreeValue() {
      this.ruleForm.costprice = this.$refs.costpriceFree.value
    },

    // 验证金额
    toNumber(event) {
      const target = event.target
      let newValue = target.value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
      newValue = newValue ? newValue[0] : ''
      let formattedValue = newValue
      if (newValue) {
        if (newValue.indexOf('.') > 7) {
          formattedValue =
            newValue.split('.')[0].slice(0, 7) +
            '.' +
            newValue.split('.')[1].slice(0, 2)
        } else {
          formattedValue = newValue.slice(
            0,
            newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
          )
        }
      }
      target.value = formattedValue
    },

    // 编辑时，获取基础信息
    getEditInfo() {
      const self = this
      const course_id = this.$route.query.course_id
      self.isShowLoading = true
      self.$http({
        name: API.editCourseBase_Api.name,
        url: `${API.editCourseBase_Api.url}?course_id=${course_id}`,
        callback({ code, data }) {
          if (code == 200) {
            self.isShowLoading = false
            if (!data.order_num || data.order_num < 1) {
              data.order_num = '1'
            }
            if (Number(data.price) <= 0) {
              data.price = ''
            }
            if (Number(data.costprice) <= 0) {
              data.costprice = ''
            }
            self.ruleForm = Object.assign({}, self.ruleForm, data)
            if (self.$refs.editor) {
              self.$refs.editor.html(
                `
                  ${
                    self.ruleForm.cabout ? self.ruleForm.cabout : ''
                  }
                  ${
                    // tool.detailText(self.ruleForm.content)
                    self.ruleForm.content
                  }
                `
              )
            }
            self.getCourseSet()
            self.$nextTick(() => {
              if (self.ruleForm.price_type == 1) {
                if (self.ruleForm.price > 0) {
                  self.$refs.price.value = self.ruleForm.price
                  self.endprice = self.ruleForm.price
                }
                if (self.ruleForm.costprice > 0) {
                  self.$refs.costprice.value = self.ruleForm.costprice
                }
              }
            })
          }
        },
      })
    },

    // 编辑时保存
    doEditSave() {
      const self = this
      // 验证
      if (!this.ruleForm.name.trim()) {
        this.$root.prompt('请输入课程名称')
        return
      }
      // if (!this.ruleForm.new_cabout) {
      //   this.$root.prompt('请输入课程简介')
      //   return
      // }
      if (this.ruleForm.price_type == 3 && !this.ruleForm.fee_pwd) {
        this.$root.prompt('请输入观看密码')
        return
      } else if (this.ruleForm.price_type == 1 && !this.ruleForm.price) {
        this.$root.prompt('请输入价格')
        return
      }
      if (
        this.ruleForm.price_type == 3 &&
        this.ruleForm.fee_pwd &&
        !/^[a-zA-Z0-9]{1,8}$/.test(this.ruleForm.fee_pwd)
      ) {
        this.$root.prompt('请输入有效密码')
        return
      }
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^[1-9]$)|(^\d\.[1-9]{1,2}$)|(^\d\.[0]{1}[1-9]{1}$|(^\d\.[1-9]{1}[0]{1}$)$)/
      if (this.ruleForm.price_type == 1 && !reg.exec(this.ruleForm.price)) {
        this.$root.prompt('请输入有效的价格')
        return
      }
      if (
        this.ruleForm.price_type == 1 &&
        this.ruleForm.costprice &&
        !reg.exec(this.ruleForm.costprice)
      ) {
        this.$root.prompt('请输入有效的划线价')
        return
      }
      if (this.ruleForm.price_type != 3) {
        this.ruleForm.fee_pwd = ''
      }
      if (this.ruleForm.price_type != 1) {
        this.ruleForm.price = ''
        // this.ruleForm.costprice = ''
      }
      if (this.ruleForm.price_type != 1 && this.ruleForm.price_type != 2) {
        this.ruleForm.costprice = ''
      }
      this.ruleForm.cabout = ''
      // course_set_id备份
      const nowTemp = JSON.parse(JSON.stringify(this.ruleForm.course_set_id))
      // course_set_id 拉平数组
      const nowArr = this.getFlatArray(nowTemp)
      // 旧的course_set_id拉平数组
      const oldId = this.getFlatArray(this.oldSetId)

      // add_set : 新添加的分类id（数组）
      // remove_set : 移除的分类id（数组）
      // 交集
      const allHad = oldId.filter(v => {
        return nowArr.includes(v)
      })
      // 被删除
      const remove_set = oldId.filter(item => {
        return allHad.indexOf(item) == -1
      })
      // 新增加
      const add_set = nowArr.filter(item => {
        return allHad.indexOf(item) == -1
      })
      this.ruleForm['remove_set'] = remove_set
      this.ruleForm['add_set'] = add_set

      const course_id = this.$route.query.course_id
      self.ruleForm['course_id'] = course_id
      self.$http({
        name: API.editCourseBase_Api.name,
        url: API.editCourseBase_Api.url,
        data: self.ruleForm,
        callback({ code }) {
          if (code == 200) {
            self.$root.prompt({
              msg: '保存成功',
              type: 'success',
            })
            self.$emit('updateheader')
            // self.$router.replace(self.coursePaths[Number(self.courseType) - 1]);
            self.getEditInfo()
            self.getSetList2()
          }
        },
      })
    },

    getFlatArray(resArr) {
      let temp = []
      if (resArr.length > 0) {
        resArr.forEach(item => {
          if (item.length > 1) {
            temp.push(item[1])
          } else {
            temp.push(item[0])
          }
        })
      }
      return temp
    },

    // 获取分类列表
    getSetList2() {
      const self = this
      self.options = []
      self.$http({
        name: API.getSetList_Api.name,
        url: API.getSetList_Api.url,
        callback({ code, data }) {
          if (code == 200) {
            data.forEach(item => {
              let temp = {}
              temp = {
                value: item.set_id,
                label: item.set_name,
                children: [],
              }
              if (item.children && item.children.length > 0) {
                item.children.forEach(cItem => {
                  temp['children'].push({
                    value: cItem.set_id,
                    label: cItem.set_name,
                  })
                })
              }
              self.options.push(temp)
            })
          }
        },
      })
    },

    // 编辑时获取分类列表
    getCourseSet() {
      const self = this
      const course_id = this.$route.query.course_id
      self.$http({
        name: API.getCourseSet_Api.name,
        url: API.getCourseSet_Api.url,
        data: {
          course_id,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.ruleForm.course_set_id = JSON.parse(JSON.stringify(data))
            self.oldSetId = JSON.parse(JSON.stringify(data))
          }
        },
      })
    },

    // 切换显示新建分类
    toggleShowAddSort() {
      this.getSetList2()
      this.isShowAddSort = !this.isShowAddSort
    },

    printLog(title, info) {
      window.console && console.log(title, info)
    },

    // 获取修改后的封面图
    getResultImg(img_url) {
      this.ruleForm.photo = img_url
    },

    // 上传图片过大
    errorHappen(flag) {
      this.isShowWarning = flag
    },
  },
}
</script>
<style lang="scss" scoped>
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('~@ass/img/1.2.9/ico_wh@2x.png');
  background-size: 100%;
  &.icon2 {
    z-index: 100;
    width: 8px;
    height: 13px;
    background: url('~@ass/img/1.2.9/ico_xljt.png');
    background-size: 100%;
  }
}
.basic-info-wrap {
  span.new-add-sort {
    margin-left: 10px;
    font-size: 12px;
    color: #1b9d97;
  }

  ::v-deep .el-form-item {
    width: 750px;

    &:first-child,
    &:nth-child(2) {
      width: 750px;
    }

    margin-bottom: 30px;

    .letter-limit {
      position: absolute;
      left: 388px;
      font-size: 12px;
      color: #999;
    }
  }

  ::v-deep .el-form-item__content {
    margin-left: 95px !important;
  }

  ::v-deep .el-radio-group {
    width: 655px;
    padding-left: 10px;
    background: #f5f5f5;
    box-sizing: border-box;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #1da9a2;
    background: #1da9a2;
  }
  ::v-deep .el-radio {
    padding: 10px 0;
    margin-right: 20px;
  }

  ::v-deep .el-radio__label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #1da9a2;
  }

  ::v-deep.el-textarea__inner:focus,
  ::v-deep.el-select .el-input.is-focus .el-input__inner,
  ::v-deep.el-input.is-active .el-input__inner,
  ::v-deep.el-input__inner:focus {
    border-color: #1da9a2;
  }

  .success-save {
    padding-bottom: 20px;
    text-align: center;

    ::v-deep .el-button--success {
      width: 100px;
      color: #fff;
      display: inline-block;
      background-color: #0aa29b;
      border-color: #0aa29b;
    }
  }

  .course-pic-wrap {
    width: 518px;
    display: flex;
    justify-content: space-between;

    img {
      width: 220px;
      height: 124px;
    }

    .p-wrap {
      .txt {
        margin-bottom: 22px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 16px;
      }

      .btn-wrap {
        position: relative;
        .changePhotoimg {
          height: 40px !important;
          position: absolute;
          top: 0;
        }
        .btn {
          border: 1px #d3d4d6 solid;
          position: absolute;
          top: 0;
          padding: 0 20px;
          background-color: #f4f4f5;
          font-size: 14px;
          color: #909399;
          border-radius: 4px;
          cursor: pointer;
        }
        .warning {
          margin-left: 10px;
          font-size: 12px;
          color: rgba(255, 53, 53, 1);
          line-height: 16px;
        }
      }
    }
  }

  .editor {
    width: 688px;
  }

  .password-wrap {
    display: flex;
    background: #f5f5f5;
    padding: 10px 10px;

    .title {
      font-size: 14px;
      color: #333;

      i {
        margin: 0 5px;
        color: #ff3535;
      }
    }

    .password-container {
      width: 176px;
      display: inline-block;

      .my-input {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;

        &::-webkit-input-placeholder {
          color: #ccc;
        }

        &:focus {
          border-color: #1da9a2;
        }
      }
    }

    ::v-deep .el-form-item {
      margin-bottom: 15px;
    }

    .input-wrap {
      width: 176px;

      ::v-deep .el-input__inner {
        padding-right: 15px;
      }
    }
  }

  .price-wrap {
    ::v-deep .el-form-item__content {
      // width: 200px !important;
      margin-left: 60px !important;
    }

    ::v-deep .el-form-item__label {
      width: 100px !important;
      text-align: left;
    }

    span {
      margin-left: 10px;
      z-index: 5;
    }
  }

  .has-price {
    background: #f5f5f5;

    .title {
      margin-right: 28px;
    }

    p {
      padding-left: 80px;
      font-size: 12px;
      color: #999;
    }
  }
}

.saveButton-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 7px 0 7px 208px;
  box-sizing: border-box;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  text-align: center;
  z-index: 3;

  .saveButton {
    width: 100px;
    margin: 0 auto;
  }
}

.saveButton-wrap ::v-deep.el-button--success {
  width: 100px;
  color: #fff;
  display: inline-block;
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.is-edit {
  padding: 30px 0 40px;
}

.tariffStandard {
  position: absolute;
  right: -30px;
  top: 0;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: rgba(27, 157, 151, 1);
  line-height: 19px;
  cursor: pointer;
}
</style>
