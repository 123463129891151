<template>
  <div class="posterTemplate posterTemplate5">
    <img src="https://img.dingdingkaike.com/Fj7DwpDjGU0nWK1w67-vzvqaXAYd?imageslim" alt="背景图" class="bg-img" />
    <div>
      <div class="block-header">
        <img class="t1" :src="posterData.uphoto" />
        <div class="right">
          <p class="t2">{{ posterData.uname | unameStr }}</p>
          <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
        </div>
      </div>
      <div class="block-1">
        <p class="t6">
          <!-- <i class="t8" v-show="posterData.time"></i> -->
          <!-- <span class="t7">{{ posterData.time }}</span> -->
          <!-- <i class="t9" v-show="posterData.time"></i> -->
        </p>
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
      </div>
      <div class="block-2">
        <div class="left">
          <div class="erweima" ref="qrcode"></div>
        </div>
        <div class="right">
          <p class="t1">长按识别二维码</p>
          <p class="t2">{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
        </div>
      </div>
      <!-- <p class="tips" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">
        <i>{{ posterData.cou_endtime }}前报名{{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元`
          : `可享${posterData.discount}折优惠` }}</i>
      </p> -->
      <p class="jgName">{{ posterData.jg_title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate5',
  filters: {
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(6, this.$refs.qrcode)
    this.$emit('createEwm', 2.3, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.posterTemplate5 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 50%;
    right: 0;
    top: 66px;
    width: 223px;
    padding: 0 10px;
    margin-left: -111.5px;
    box-sizing: border-box;
  }
  .block-1 {
    .t4 {
      width: 203px;
      height: 113px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      height: 36px;
      font-size: 13px;
      margin-top: 10px;
      overflow: hidden;
      line-height: 18px;
      position: relative;
      word-break: break-all;
      padding: 0 10px 0 15px;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // text-overflow: ellipsis;
      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        content: '';
        width: 4px;
        height: 100%;
        background: #c23230;
      }
    }
    .t6 {
      display: flex;
      padding: 22px 0 12px 0;
      justify-content: center;
      align-items: center;
    }
    .t7 {
      position: relative;
      top: 0.5px;
      height: 9px;
      padding: 0 8px;
      font-size: 10px;
      line-height: 10px;
      font-weight: 400;
      color: #333;
    }
    .t8,
    .t9 {
      width: 3px;
      height: 3px;
      background: #c23230;
      transform: rotate(45deg);
    }
  }
  .block-2 {
    position: relative;
    display: flex;
    padding: 23px 0;
    align-items: center;
    .right {
      flex: 1;
      margin-left: 15px;
      font-weight: 600;
      p {
        color: #c23230;
      }
      > .t1 {
        font-size: 11px;
        line-height: 16px;
      }
      > .t2 {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .left {
      margin-left: 15px;
      .erweima {
        width: 45px;
        height: 45px;
        padding: 1px;
        border: 3px solid #c23230;
      }
    }
  }
  .block-header {
    display: flex;
    padding-top: 10px;
    align-items: center;
    > .t1 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .right {
      padding-left: 15px;
      > .t2 {
        color: #333;
        font-size: 11px;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 108px;
      }
      > .t3 {
        color: #999;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
  .tips {
    position: absolute;
    left: 0;
    font-size: 0;
    width: 100%;
    bottom: 23px;
    text-align: center;
    i {
      height: 16px;
      color: #fff;
      font-size: 10px;
      padding: 0 10px;
      line-height: 18px;
      text-align: center;
      border-radius: 20px;
      white-space: nowrap;
      display: inline-block;
      background-color: #ffa200;
    }
  }
  .jgName {
    color: #dc9999;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    padding: 12px 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
</style>
