<template>
  <div class="posterTemplate posterTemplate7">
    <img src="https://img.dingdingkaike.com/FtvdPexG6FaIVTQRWTJhY_uoFD2J?imageslim" alt="背景图" class="bg-img" />
    <div>
      <div class="block-header">
        <img class="t1" :src="posterData.uphoto" />
        <div class="right">
          <p class="t2">{{ posterData.uname | unameStr }}</p>
          <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
        </div>
      </div>
      <!-- <p class="tips" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名{{
        posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
      <p class="jgName">
        <span class="t1">
          {{ posterData.jg_title | str }}
        </span>
      </p>
      <div class="block-1">
        <img class="t4" :src="posterData.photo | imageslim(3)" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
      </div>
      <div class="block-2">
        <div class="right">
          <div>
            <!-- <div class="right-t" v-if="posterData.time"><p>{{posterData.time}}</p></div> -->
            <div class="right-b">
              <p class="t1">长按识别二维码</p>
              <p class="t2">{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
            </div>
          </div>
        </div>
        <div class="left">
          <div class="erweima" ref="qrcode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate7',
  filters: {
    str(value) {
      return value.length > 4 ? `${value.slice(0, 4)}...` : value
    },
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(3.1 * 3, this.$refs.qrcode);
    this.$emit('createEwm', 3, this.$refs.qrcode)
  }
}
</script>
<style lang="scss" scoped>
.posterTemplate7 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    left: 38px;
    right: 38px;
    top: 50px;
  }
  .block-1 {
    margin-top: 11px;
    .t4 {
      width: 223px;
      height: 124px;
      border-radius: 4px;
    }
    .t5 {
      color: #333;
      height: 36px;
      position: relative;
      font-size: 13px;
      line-height: 18px;
      margin-top: 14px;
      word-break: break-all;
    }
  }
  .block-2 {
    position: relative;
    display: flex;
    padding: 29px 0 0 0;
    .right {
      flex: 1;
      font-size: 0;
      text-align: right;
      padding-right: 5px;
      > div {
        display: inline-block;
      }
      .right-t {
        position: relative;
        p {
          color: #999;
          z-index: 3;
          position: relative;
          padding: 2px 4px;
          font-size: 10px;
          background-color: #fff;
          border: 1px solid #fdc408;
        }
        &:after {
          content: '';
          position: absolute;
          right: -2px;
          bottom: -2px;
          width: 95%;
          height: 85%;
          background: #fada26;
          border-color: #fada26;
          border-style: solid;
          border-radius: 1px;
          border-width: 0 5px 4px 0;
        }
      }
      .right-b {
        margin-top: 10px;
        font-weight: 600;
        text-align: center;
        p {
          color: #333;
        }
        > .t1 {
          font-size: 11px;
          line-height: 16px;
        }
        > .t2 {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
    .left {
      margin-left: 26px;
      .erweima {
        width: 62px;
        height: 62px;
        position: relative;
        &::after {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          width: 100%;
          height: 100%;
          border-right: 4px solid #efefef;
          border-bottom: 4px solid #efefef;
        }
      }
    }
  }
  .block-header {
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
    > .t1 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .right {
      position: relative;
      padding-left: 14px;
      > .t2 {
        color: #333;
        font-size: 10px;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 108px;
      }
      > .t3 {
        color: #999;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
  .tips {
    position: absolute;
    left: 68px;
    top: 36px;
    color: #ff0000;
    font-size: 10px;
  }
  .jgName {
    color: #999;
    font-size: 12px;
    text-align: center;
    margin-top: 22px;
    > .t1 {
      position: relative;
      &::before,
      &::after {
        position: absolute;
        left: -8px;
        top: 50%;
        content: '';
        width: 3px;
        height: 3px;
        background-color: #999;
        border-radius: 50%;
      }
      &::after {
        position: absolute;
        left: auto;
        right: -8px;
      }
    }
  }
}
.ellipsis-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
