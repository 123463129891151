<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-11 16:06:26
 * @LastEditTime: 2022-06-14 17:05:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 课程分类选择
 * @FilePath: \teacher.dingdingkaike.com\src\components\base\selectcourseType.vue
-->
<template>
  <div class="selectcourseType">
    <el-dialog
      :title="title"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      @close="cancel"
    >
      <div class="dialogVisible_contain">
        <el-tree
          size="small"
          show-checkbox
          :data="data"
          node-key="set_id"
          :check-strictly="true"
          :default-checked-keys="[default_key]"
          :props="defaultProps"
          @check="treeCheckChange"
          ref="tree"
        ></el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium" style="width: 74px">
          取消
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="sure"
          style="width: 74px"
        >
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isselect: {},
      data: [],
      defaultProps: {
        children: 'children',
        label: 'set_name',
      },
    }
  },

  props: {
    dialogstatus: {
      tyep: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '选择课程分类(单选)',
    },
    default_key: String,
  },

  created() {
    this.getdata()
  },

  methods: {
    getdata() {
      this.$http({
        url: '/courseSet/newGetSetList',
        callback: ({ code, data }) => {
          if (code == 200) {
            this.data = data
          }
        },
      })
    },
    sure() {
      if (Object.keys(this.isselect).length == 0) {
        this.$root.prompt('请先选择分类！')
        return
      }
      this.$emit('typeselectval', this.isselect)
      this.cancel()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('close')
    },

    treeCheckChange(item) {
      const selectedKeys = this.$refs.tree.getCheckedKeys()
      let checkVal = []
      if (selectedKeys.length > 0) {
        checkVal = [item.set_id]
      }
      this.$refs.tree.setCheckedKeys(checkVal)
      this.isselect = item
    },

    complete() {
      this.$emit('complete', this.$refs.tree.getCheckedKeys())
      this.cancel()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
  height: 356px;
  overflow: auto;
  @extend %scroll;
}
::v-deep .el-dialog__footer {
  padding-top: 0;
}
::v-deep .dialogVisible_contain {
  .el-checkbox__inner {
    border-radius: 50%;
    background: #bbbbbb;
  }
  .el-tree-node__content {
    margin-bottom: 10px;
  }
  .el-checkbox__input .el-checkbox__inner::after {
    -webkit-transform: rotate(45deg) scaleY(1);
    transform: rotate(45deg) scaleY(1);
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #1da9a2;
  }
  .el-checkbox__inner:hover {
    border-color: #bbbbbb;
  }
  .el-tree-node.is-checked .el-tree-node__children .el-checkbox {
    visibility: hidden;
  }
}
</style>
