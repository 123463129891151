<!--
 * @Descripttion: 选择视频弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 09:45:29
 * @LastEditors: cyy 1346967714@qq.com
 * @LastEditTime: 2023-04-26 11:27:16
 -->
<template>
  <div>
    <el-dialog
      v-show="!showDataBaseConfirm"
      :close-on-click-modal="false"
      :title="editData.video_url ? '编辑章节' : '添加章节'"
      :visible.sync="visible"
      :before-close="closeFn"
      width="800px"
      top="5vh"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="inner-slot"
        label-position="left"
        @submit.native.prevent
      >
        <div class="encryption" v-show="ruleForm.video_url">
          <div class="encryption_top">
            <div class="left">
              <template v-if="!ruleForm.audio_id">
                <!-- 已加密 -->
                <img
                  v-if="ruleForm.is_video_encryption == 1"
                  src="../../assets/img/1.3.8/icon_yjmbs.png"
                  alt=""
                />
                <!-- 加密中 -->
                <img
                  v-else-if="
                    ruleForm.is_video_encryption == 3 || ruleForm.radio == 1
                  "
                  src="../../assets/img/1.3.8/icon_jmzbs.png"
                  alt=""
                />
                <!-- 未加密 -->
                <img
                  v-else
                  src="../../assets/img/1.3.8/icon_wjmbs.png"
                  alt=""
                />
              </template>
              <div class="left_font1">{{ ruleForm.file_name }}</div>
            </div>
            <!-- {{ ruleForm }} -->
            <div class="right">
              <div
                class="vm upload-text"
                style="color: #999"
                v-if="
                  ruleForm.file_delete_time > 0 ||
                    ruleForm.is_video_encryption == 1 ||
                    ruleForm.is_video_encryption == 3
                "
                @click="
                  $root.prompt(
                    ruleForm.is_video_encryption == 1
                      ? '视频已加密，无法直接播放'
                      : ruleForm.is_video_encryption == 3
                      ? '视频加密中，无法直接播放'
                      : '该视频已被删除'
                  )
                "
              >
                播放
              </div>
              <div
                class="vm upload-text"
                @click="previewSrc = ruleForm.zmvidoe_url || ruleForm.video_url"
                v-else-if="ruleForm.zmvidoe_url || ruleForm.video_url"
              >
                播放
              </div>
              <div @click="delshanchu" class="vm upload-text">移除重传</div>
            </div>
          </div>
          <div class="filesize">
            <div>{{ (ruleForm.file_size * 1024).toFixed(2) }}MB</div>
            <template v-if="ruleForm.duration">
              <div style="margin: 0px 10px">|</div>
              <div>
                {{ ruleForm.duration | formatTimeLength }}
              </div>
            </template>
            <template v-if="ruleForm.zm_spec">
              <div style="margin: 0px 10px">|</div>
              <div>
                <span v-if="ruleForm.zm_spec == 1">高清(720p)</span>
                <span v-if="ruleForm.zm_spec == 2">蓝光(1080p)</span>
                <span v-if="ruleForm.zm_spec == 3">流畅(360p)</span>
                <span v-if="ruleForm.zm_spec == 4">标清(480p)</span>
              </div>
            </template>
          </div>
          <template v-if="!ruleForm.audio_id">
            <div class="protect">
              <div class="protect_font1" style="font-weight: bold; color: #333">
                视频加密保护
              </div>
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="开启后，可以选择保护方案，保存章节后会对视频文件进行自动加密保护；如关闭保护或未选择方案，视频文件不会加密，以普通方式上传"
              ></helpIcon>
              <el-switch
                @change="videoencryption_click"
                v-model="videoencryption"
                active-value="1"
                inactive-value="0"
              ></el-switch>
              <div class="flex-center" v-if="status1">
                <el-select
                  @visible-change="visibleChange"
                  style="width: 211px; margin: 0px 15px 0 30px"
                  size="medium"
                  v-model="ruleForm.video_safe_id"
                  placeholder="请选择保护方案"
                >
                  <el-option
                    v-for="item in videoSafeInfo"
                    :key="item.template_id"
                    :label="item.template_name"
                    :value="item.template_id"
                    :disabled="item.status != 1"
                  ></el-option>
                </el-select>
                <el-button type="text" size="small" @click="newly1">
                  新建方案
                </el-button>
              </div>
            </div>

            <div class="protect" style="margin-top: 11px">
              <div class="protect_font1" style="font-weight: bold; color: #333">
                品牌保护
              </div>
              <helpIcon
                style="margin-left: 6px; margin-right: 58px"
                prompt="开启后，可以选择保护方案，保存章节后会对视频文件进行自动添加品牌保护；如关闭保护或未选择方案，视频文件不会加密，以普通方式上传"
              ></helpIcon>
              <el-switch
                active-value="1"
                inactive-value="2"
                v-model="brandvalue"
                @change="brandvalue_click"
              ></el-switch>
              <div class="flex-center" v-if="status2">
                <el-select
                  @visible-change="visibleChange"
                  style="width: 211px; margin: 0px 15px 0 30px"
                  size="medium"
                  v-model="ruleForm.brand_safe_id"
                  placeholder="请选择保护方案"
                >
                  <el-option
                    v-for="item in brandSafeInfo"
                    :key="item.template_id"
                    :label="item.template_name"
                    :value="item.template_id"
                    :disabled="item.status != 1"
                  ></el-option>
                </el-select>
                <el-button type="text" size="small" @click="newly2">
                  新建方案
                </el-button>
              </div>
            </div>
          </template>
        </div>

        <div class="demo-ruleForm">
          <!-- 父标题 -->
          <el-form-item label="父标题">
            <el-select
              v-model="ruleForm.psl_id"
              size="medium"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionsFather"
                :key="item.id"
                :label="item.name"
                :value="item.correlation_id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!--章节名称-->
          <el-form-item label="章节名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入章节名称"
              maxlength="100"
              show-word-limit
              size="medium"
            ></el-input>
          </el-form-item>

          <!--上课老师-->
          <el-form-item label="上课老师" prop="teacher_id">
            <el-select
              size="medium"
              filterable
              v-model="ruleForm.teacher_id"
              style="width: 100%"
              placeholder="请输入老师名称或选择上课老师"
            >
              <el-option
                v-for="item in options"
                :key="item.uid"
                :label="item.uname"
                :value="item.uid"
              >
                <div
                  style="
                    float: left;
                    height: 22px;
                    width: 22px;
                    border-radius: 50%;
                    margin-top: 6px;
                    background: grey;
                  "
                >
                  <img
                    style="height: 22px; width: 22px"
                    :src="item.uphoto"
                    alt=""
                  />
                </div>
                <div style="float: left; margin-left: 10px; font-size: 13px">
                  {{ item.uname }}
                </div>
              </el-option>
            </el-select>
            <template v-if="userInfo.version_id != 1 && ruleForm.audio_id">
              <el-checkbox
                v-model="ruleForm.is_play"
                true-label="1"
                false-label="2"
              >
                章节试看
              </el-checkbox>
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="在一个付费课程，开启章节试看，学员在购买前可以试看这个章节，没有设置试看的不能观看"
              ></helpIcon>
            </template>
          </el-form-item>
          <template>
            <!--定时观看-->
            <el-form-item
              label="解锁时间"
              v-if="ruleForm.chapter_mode == 3 && ruleForm.type == 1"
            >
              <div class="selectTimeCm">
                <div class="time-pluging">
                  <el-date-picker
                    size="small"
                    v-model="startTime"
                    type="datetime"
                    format="yyyy/MM/dd HH:mm"
                    value-format="yyyy/MM/dd HH:mm"
                    placeholder="选择日期时间"
                    default-time="00:00:00"
                    v-if="selectTimeCm == 1"
                  ></el-date-picker>
                  <input
                    v-else
                    v-model="startTime"
                    type="datetime-local"
                    v-focus
                    style="
                    color: rgb(51, 51, 51);
                    display: block;
                    height: 40px;
                    padding-left: 15px;
                    width: 100%;
                    box-sizing: border-box;
                    border: 1px solid rgb(220, 223, 230);
                    line-height: initial;
                  "
                  />
                </div>
                <div class="ps">
                  如选择时间没反应，请尝试点击
                  <i @click="selectTimeCm = selectTimeCm == 1 ? 2 : 1">切换</i>
                </div>
              </div>
            </el-form-item>
            <!-- <el-form-item v-if="userInfo.version_id != 1">
              <el-checkbox v-model="is_play" :checked="ruleForm.is_play == 1">
                章节试看
              </el-checkbox>
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="在一个付费课程，开启章节试看，学员在购买前可以试看这个章节，没有设置试看的不能观看"
              ></helpIcon>
            </el-form-item>
            <div style="height: 20px" v-else></div> -->
          </template>
          <template v-if="ruleForm.audio_id">
            <el-form-item
              label="文稿"
              prop="manuscripts"
              class="manuscript-item"
            >
              <editor class="editor" v-model="ruleForm.manuscripts"></editor>
            </el-form-item>
          </template>
          <div class="title">高级设置</div>
          <el-form-item label="章节试看" v-if="!ruleForm.audio_id">
            <el-radio-group v-model="ruleForm.is_play">
              <el-radio label="1">开启</el-radio>
              <el-radio label="2">关闭</el-radio>
            </el-radio-group>
            <template v-if="ruleForm.is_play == 1">
              <span class="tips  ml30">视频前</span>
              <el-input-number
                v-model="ruleForm.test_time"
                controls-position="right"
                :step="1"
                style="width:157px"
                class="ml10 mr10"
                :step-strictly="true"
                :min="0"
                :max="999"
                size="medium"
              ></el-input-number>
              <span class="tips">
                分钟为试看范围（0-999），0表示整节试看
              </span>
            </template>
          </el-form-item>

          <el-form-item label="学习完成条件">
            <span class="tips ">播放进度达到</span>
            <el-input-number
              v-model="ruleForm.conditions"
              controls-position="right"
              :step="1"
              style="width:157px"
              class="ml10 mr10"
              :step-strictly="true"
              :min="1"
              :max="100"
              size="medium"
            ></el-input-number>
            <span class="tips">
              %时，即视为完成（1-100）
            </span>
          </el-form-item>

          <el-form-item label="倍速设置" v-if="!ruleForm.audio_id">
            <el-radio-group v-model="ruleForm.multiple_setting">
              <el-radio label="1">允许</el-radio>
              <el-radio label="2">禁止</el-radio>
            </el-radio-group>
            <span class="tips ml40" style="color:#999999">
              设置禁止时，未学习完成用户禁止使用倍速功能，学完后可正常使用
            </span>
          </el-form-item>

          <el-form-item label="拖动进度条" v-if="!ruleForm.audio_id">
            <el-radio-group v-model="ruleForm.progress_setting">
              <el-radio label="1">允许</el-radio>
              <el-radio label="2">禁止</el-radio>
            </el-radio-group>
            <span class="tips ml40" style="color:#999999">
              设置禁止时，未学习完成用户禁止拖动进度条，学完后可正常拖动
            </span>
          </el-form-item>

          <div style="text-align: right">
            <template v-if="!ruleForm.audio_id">
              <span
                class="red"
                style="font-size: 12px; color: #ff3535; line-height: 16px;margin-right:82px"
              >
                提示：开启保护后，点击保存后开始加密，加密中学生无法观看视频
              </span>
            </template>
            <el-button @click="closeFn" style="margin-left: 162px">
              取消
            </el-button>
            <el-button type="primary" @click="editSuccess">保存</el-button>
          </div>
        </div>
      </el-form>
      <!--视频预览-->
      <previewVideo
        v-if="previewSrc"
        :src="previewSrc"
        @close="previewSrc = ''"
      ></previewVideo>
    </el-dialog>
    <!--资料库添加-->
    <fileListAndFileUpload
      v-if="showDataBaseConfirm"
      @complete="complete"
      @close="dataBaseConfirm"
    ></fileListAndFileUpload>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import API from '@/request/api'
import tool from '@ass/js/tool'
import editor from '@cm/editor'
// 视频预览
import previewVideo from './previewVideo'
import pageOverflow from '@/mixin/pageOverflow'
import fileListAndFileUpload from '@cm/fileListAndFileUpload'
import helpIcon from '@/components/helpIcon'
export default {
  name: 'editSection',

  props: {
    editData: Object,
    psl_id: String,
  },

  mixins: [pageOverflow],

  data() {
    return {
      optionsFather: [],
      // 老师列表
      options: [],
      brandSafeInfo: [],
      videoSafeInfo: [],
      videoencryption_value: '',
      status1: false,
      videoencryption: '0',
      brandvalue: '0',
      status2: false,
      status: this.$store.state.userInfo.jg_is_drm,
      ruleForm: _.assign(
        {
          video_safe_id: '',
          brand_safe_id: '',
          manuscripts: '',
          is_play: '2',
          test_time: '',
          conditions: 100,
          multiple_setting: '1',
          progress_setting: '1',
        },
        this.editData,

        {
          temp_file_size:
            this.editData.temp_file_size || this.editData.file_size || 0,
          psl_id: this.editData.psl_id || this.psl_id,
        }
      ),
      startTime: '', // 定时观看时间
      is_play: false,
      rules: {
        video_url: [{ required: true, message: '请上传视频' }],
        name: [{ required: true, message: '请输入章节名称' }],
        teacher_id: [{ required: true, message: '请选择上课老师' }],
      },

      // 视频预览src
      previewSrc: '',

      visible: true,

      showDataBaseConfirm: !this.editData.video_url,

      // 选择时间组件类别
      selectTimeCm: 1,
    }
  },

  computed: {
    ...mapState(['userInfo']),
  },

  filters: {
    changeValue(value) {
      const size = value
      if (size >= 1) {
        return tool.toFixed(`${Number(size)}`, 2) + 'G'
      } else {
        return tool.toFixed(`${Number(size) * 1024}`, 2) + 'MB'
      }
      // return tool.toFixed(`${Number(size) * 1024}`, 2) + 'MB'
    },
  },

  watch: {
    selectTimeCm(value) {
      this.startTime = this.startTime || ''
      if (value == 2) {
        this.startTime = this.startTime.replace(/\//g, '-').replace(' ', 'T')
      } else {
        this.startTime = this.startTime.replace(/-/g, '/').replace('T', ' ')
      }
    },
  },

  components: {
    previewVideo,
    fileListAndFileUpload,
    helpIcon,
    editor,
  },

  created() {
    if (this.ruleForm.video_safe_id) {
      this.status1 = true
      this.videoencryption = '1'
    }
    if (this.ruleForm.brand_safe_id) {
      this.status2 = true
      this.brandvalue = '1'
    }
    if (this.editData.startTime) {
      this.startTime = this.editData.startTime
    }

    if (this.$store.state.userInfo.jg_is_drm == 1) {
      this.getSelectlist()
    }
    this.getDirectoryList()
    this.getJigouTeacher()
  },
  methods: {
    visibleChange(val) {
      if (val) {
        this.getSelectlist()
      }
    },

    async getDirectoryList() {
      const { data } = await this.$http({
        url: '/Course/getDirectoryList',
        data: { course_id: this.$route.query.course_id },
      })
      this.optionsFather = data
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.uploadStart = false
      this.getJigouTeacher()
    },
    delshanchu() {
      // this.ruleForm.video_url = ''
      // this.ruleForm.file_name = ''
      // this.ruleForm.temp_file_size = ''
      // ;(this.videoencryption = ''),
      //   (this.brandvalue = ''),
      //   (this.status1 = false)
      // this.status2 = false
      // //视频保护回归默认

      // this.ruleForm.video_safe_id = ''

      // //品牌保护回归默认

      // this.ruleForm.brand_safe_id = ''
      this.showDataBaseConfirm = true
    },

    newly1() {
      let details1 = this.$router.resolve({
        path: '/videoSafe/video/newly1',
        query: {
          name: '新建',
          status: 1,
        },
      })
      this.$store.dispatch('open', details1.href)
    },

    newly2() {
      let details = this.$router.resolve({
        path: '/videoSafe/brand/newly2',
        query: {
          name: '新建',
          status: 1,
        },
      })
      this.$store.dispatch('open', details.href)
    },

    getSelectlist() {
      this.$http({
        url: '/VideoSafeTemplate/selectSafe',
        data: {},
        callback: ({ data: { brandSafeInfo, videoSafeInfo }, code }) => {
          if (code == 200) {
            this.brandSafeInfo = brandSafeInfo
            this.videoSafeInfo = videoSafeInfo
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },

    // 视频加密
    videoencryption_click(val) {
      // 没开通去跳转
      // const status = this.$store.state.userInfo.jg_is_drm
      // if (status == 2) {
      //   this.videoencryption = 2
      //   let status_s1 = this.$router.resolve({
      //     path: '/videoSafe/video',
      //   })
      //   this.$store.dispatch('open', status_s1.href)
      //   return
      // }

      // 七牛的视频 不能开启版权保护
      if (this.isQiNiuVideo(this.ruleForm.video_url)) {
        this.$root.prompt({
          msg:
            '版权保护系统上线后暂不支持加密之前上传的视频，如需加密请重新上传！ ',
        })
        this.videoencryption = 2
        return
      }

      //没有加密的视频不能开启版权保护
      // if (this.ruleForm.radio == 2 || this.ruleForm.is_video_encryption == 2) {
      //   this.$root.prompt({
      //     msg: '当前视频未加密，无法开启视频加密保护和品牌保护！ ',
      //   })
      //   this.videoencryption = 2
      //   return
      // }

      // 显示选择列表
      if (val == 1) {
        this.status1 = true
        const vidioallstatus = this.videoSafeInfo.some(e => e.status == 1)
        const first_open = this.videoSafeInfo.find(f => f.status == 1)
        if (!vidioallstatus) {
          this.ruleForm.video_safe_id = ''
        } else {
          this.ruleForm.video_safe_id = first_open.template_id
        }
      } else {
        this.ruleForm.video_safe_id = ''
        this.status1 = false
      }
    },

    // 品牌保护
    brandvalue_click(val) {
      // 没开通去跳转
      // const status = this.$store.state.userInfo.jg_is_drm
      // if (status == 2) {
      //   this.brandvalue = 2
      //   let status_s1 = this.$router.resolve({
      //     path: '/videoSafe/brand',
      //   })
      //   this.$store.dispatch('open', status_s1.href)
      //   return
      // }

      // 七牛的视频 不能加密
      if (this.isQiNiuVideo(this.ruleForm.video_url)) {
        this.$root.prompt({
          msg:
            '版权保护系统上线后暂不支持加密之前上传的视频，如需加密请重新上传！ ',
        })
        this.brandvalue = 2
        return
      }

      //没有加密的视频不嫩开启版权保护
      // if (this.ruleForm.radio == 2 || this.ruleForm.is_video_encryption == 2) {
      //   // console.log(this.ruleForm.radio, this.ruleForm.is_video_encryption)
      //   this.$root.prompt({
      //     msg: '当前视频未加密，无法开启视频加密保护和品牌保护！ ',
      //   })
      //   this.brandvalue = 2
      //   return
      // }

      // 显示选择列表
      if (val == 1) {
        this.status2 = true
        const brandallstatus = this.brandSafeInfo.some(e => e.status == 1)
        const first_open = this.brandSafeInfo.find(f => f.status == 1)
        if (!brandallstatus) {
          this.ruleForm.brand_safe_id = ''
        } else {
          this.ruleForm.brand_safe_id = first_open.template_id
        }
      } else {
        this.ruleForm.brand_safe_id = ''
        this.status2 = false
      }
    },

    // 是否是七牛的视频
    isQiNiuVideo(url) {
      return /^https?:\/\/img.dingdingkaike/.test(url)
    },

    dataBaseConfirm() {
      this.showDataBaseConfirm = !this.showDataBaseConfirm
      // 如果是空数据
      if (
        !this.showDataBaseConfirm &&
        !this.ruleForm.zmvidoe_url &&
        !this.ruleForm.video_url
      ) {
        this.closeFn()
      }
    },

    closeFn(done) {
      this.$emit('closeFn')
      typeof done === 'function' && done()
    },

    // 上传视频完成
    complete({
      // serverId = '',
      classHour = '',
      url = '',
      file_size = '',
      file_name = '',
      is_video_encryption = 0,
      zm_spec = '',
      zmvidoe_url = '',
      radio = 0,
      video_id,//视频
      audio_id,//音频
      recording_id,//回放
    }) {
      const option = {
        file_delete_time: 0,
        // video_id: serverId,
        video_type: 1,
        duration: classHour,
        file_name,
        is_video_encryption,
        file_size: file_size / 1024 / 1024 / 1024,
        video_url: url,
        zmvidoe_url,
        temp_file_size: file_size / 1024 / 1024 / 1024,
        zm_spec,
        radio,
        video_id,
        audio_id,
        // 回放
        recording_id,
      }
      this.ruleForm = _.assign({}, this.ruleForm, option)
      this.getDirectoryList()
      if (this.$store.state.userInfo.jg_is_drm == 1) {
        this.getSelectlist()
      }
    },

    //  点击保存时
    editSuccess() {
      if (
        (!this.ruleForm.video_safe_id && this.videoencryption === '1') ||
        (!this.ruleForm.brand_safe_id && this.brandvalue === '1')
      ) {
        this.$confirm(
          '当前开启了视频保护，但未选择保护方案，保存后，对应的保护功能不会生效，是否确定？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.editSuccess2()
          })
          .catch(() => {})
      } else {
        this.editSuccess2()
      }
    },

    editSuccess2() {
      // this.ruleForm['is_play'] = this.is_play ? 1 : 2
      this.ruleForm['startTime'] = Math.floor(
        +new Date(
          this.selectTimeCm == 1
            ? this.startTime
            : this.startTime.replace(/-/g, '/').replace('T', ' ')
        ) / 1000
      )
      this.$nextTick(() => {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit('complete', [_.assign({}, this.editData, this.ruleForm)])
            this.$emit('compelet', [
              _.assign({}, this.editData, this.ruleForm),
            ]), //完成的回调
              this.$emit('closeFn')
          } else {
            return false
          }
        })
      })
    },

    // 获取机构老师
    getJigouTeacher() {
      const self = this
      self.$http({
        name: API.getJigouTeacher_Api.name,
        url: API.getJigouTeacher_Api.url,
        callback({ data: { teacherInfo } }) {
          self.options = teacherInfo
          const noteacher = self.options.filter(
            f => f.uid == self.ruleForm.teacher_id
          ).length

          if (self.ruleForm.teacher_id && noteacher === 0) {
            self.ruleForm.teacher_id = ''
          }

          // if (noteacher == 0) {
          //   self.ruleForm.teacher_id = ''
          // }
        },
        error(error) {
          if (error.code == 204) {
            self.options = []
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// .Deficiency {
//   height: 50px;
//   width: 100px;
//   position: absolute;
//   left: 349px;
//   top: 113px;
// }
::v-deep .demo-ruleForm {
  .el-form-item {
    margin-bottom: 20px !important;
    .tips {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}
.title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333333;
}
::v-deep .el-dialog__body {
  padding: 20px;
}
.tianjia {
  position: absolute;
  height: 51px;
  width: 100px;
  // background-color: red;
  top: 117px;
  left: 345px;
  cursor: pointer;
}
.encryption {
  padding: 18px 20px;
  background: #f5f5f5;
  margin-bottom: 30px;
  .encryption_top {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      img {
        width: 35px;
        height: 18px;
        margin-right: 10px;
      }
      .left_font1 {
        color: #333333;
      }
    }
    .right {
      display: flex;
    }
  }
  .filesize {
    margin-bottom: 22px;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    line-height: 12px;
  }
  .protect {
    height: 36px;
    display: flex;
    align-items: center;
  }
}
.inner-slot {
  padding-bottom: 25px;
  .video-head {
    text-align: center;
    line-height: initial;
    .video-pic {
      width: 51px;
      height: 65px;
      display: inline-block;
      margin-bottom: 5px;
      background: url('~@ass/img/1.1.2/ico_spwjbs@2x (1).png') no-repeat
        center/100% 100%;
    }
    .video-name {
      font-size: 14px;
      color: #4a4a4a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 85%;
      margin: 0 auto;
    }
    .video-size {
      font-size: 12px;
      color: #9b9b9b;
    }
    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        font-size: 14px;
        color: #0aa29b;
      }
    }
  }
  .letter-limit {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: #999;
  }
  ::v-deep .el-form {
    padding-top: 30px;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input .el-input__count .el-input__count-inner {
    position: absolute;
    bottom: -15px;
    right: -10px;
    width: 50px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 30px;
    &:first-child {
      .el-input__inner {
        // padding-right: 50px;
      }
    }
    // &:nth-child(3) {
    //   margin-bottom: 0px;
    // }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
}
.upload-contain {
  font-size: 0;
  text-align: center;
}
.upload-text {
  font-size: 14px;
  line-height: 19px;
  margin: 0 15px;
  cursor: pointer;
  color: rgba(10, 162, 155, 1);
}
.selectTimeCm {
  display: flex;
  .time-pluging {
    flex: 1;
  }
  .ps {
    color: $red;
    font-size: 14px;
    margin-left: 20px;
    i {
      color: $color;
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
    }
  }
}
::v-deep .manuscript-item .el-form-item__label {
  text-align: left;
}
</style>
