<template>
  <div class="container">
    <el-form ref="form" label-position="left" :rules="rules" :model="form" v-if="!isOnlyPhoneCode">
      <el-form-item label="开户名称" prop="bank_user_name" label-width="100px">
        <div class="columnBox">
          <el-input class="inputBtn" v-model="form.bank_user_name" placeholder="请输入开户名称"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="银行名称" prop="bank_name" label-width="100px">
        <div class="columnBox">
          <el-input class="inputBtn" v-model="form.bank_name" placeholder="选择银行名称"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="开户地址" prop="bank_address" label-width="100px">
        <div class="columnBox">
          <el-input class="inputBtn" v-model="form.bank_address" placeholder="选择开户地址"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="开户支行" prop="bank_branch" label-width="100px">
        <div class="columnBox">
          <el-input class="inputBtn" v-model="form.bank_branch" placeholder="选择开户支行"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bank_number" label-width="100px">
        <div class="columnBox">
          <el-input class="inputBtn" v-model="form.bank_number" placeholder="请输入银行卡号"></el-input>
          <p class="content">银行账户主体必须与经营主体保持一致，否则无法提现</p>
        </div>
      </el-form-item>
      <el-form-item label="手机号" prop="phone_number" label-width="100px">
        <el-input class="inputBtn" placeholder="用于提现的手机号" v-model.trim="form.phone_number" max="32"
          :disabled="false"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code" label-width="100px">
        <el-input style="width: 20%" placeholder="请输入短信验证码" v-model.trim="form.code" max="32"></el-input>
        <el-button ref="yz_btn" style="margin-left: 15px;" type="success" @click="getPhoneCode" :disabled="getPhoneCodeDate == '获取短信验证码' || getPhoneCodeDate == '验证中'
      ? false
      : true
      ">
          {{ getPhoneCodeDate }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 只显示验证码 -->
    <el-form ref="form" label-position="left" :rules="rules" :model="form" v-if="isOnlyPhoneCode">
      <el-form-item label="短信验证码" prop="code" label-width="100px">
        <el-input style="width: 20%" placeholder="请输入短信验证码" v-model.trim="form.code" max="32"></el-input>
        <el-button ref="yz_btn" style="margin-left: 15px;" type="success" @click="getPhoneCode" :disabled="getPhoneCodeDate == '获取短信验证码' || getPhoneCodeDate == '验证中'
      ? false
      : true
      ">
          {{ getPhoneCodeDate }}
        </el-button>
        <p>验证码将发送到管理员手机号：+86-{{adminPhoneNumber}}，请注意查收</p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import API from '@/request/api'
import background from '@/mixin/background'
import tencentCaptcha from '@/mixin/tencentCaptcha'
export default {
  mixins: [background, tencentCaptcha],
  props: {
    isEdit: Boolean,
    info: Object,
    isOnlyPhoneCode: Boolean,
  },
  data() {
    const validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'));
      }
      if (value.length !== 4) {
        return callback(new Error('验证码长度为4位'));
      }
      callback();
    };
    return {
      pickerOptions: {},
      form: {
        bank_user_name: '',
        bank_name: '',
        bank_address: '',
        bank_branch: '',
        bank_number: '',
        phone_number: '',
        code: '',
      },
      rules: {
        bank_user_name: [
          { required: true, message: '请输入开户名称', trigger: 'blur' },
        ],
        bank_name: [
          { required: true, message: '请选择银行名称', trigger: 'blur' },
        ],
        bank_address: [
          { required: true, message: '请输入开户地址', trigger: 'blur' },
        ],
        bank_branch: [
          { required: true, message: '请选择开户支行', trigger: 'blur' },
        ],
        bank_number: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' },
          { pattern: /^[0-9]{16,19}$/, message: '银行卡号格式不正确', trigger: 'blur' }, // 假设银行卡号为16-19位数字  
        ],
        phone_number: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { validator: validateCode, trigger: 'blur' }, // 假设银行卡号为16-19位数字  
        ],
      },
      getPhoneCodeDate: '获取短信验证码', //控制发送验证码时间
      // 管理员手机号码
      adminPhoneNumber:null,
    }
  },
  created() {
    if(this.isOnlyPhoneCode) this.getPhone()
  },
  methods: {
    setForm(){
      for (const key in this.form) {
        const filter = ['code']
        if(!filter.includes(key)) this.form[key] = this.info[key]||''
      }
    },
    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    },
    //获取手机验证码
    getPhoneCode() {
      if (!/^1[2-9]\d{9}$/.test(this.form.phone_number)&&!this.isOnlyPhoneCode) {
        this.$message({
          message: '请输入正确手机号',
          type: 'warning',
        })
        return false
      }
      if (!/^1[2-9]\d{9}$/.test(this.adminPhoneNumber)&&this.isOnlyPhoneCode) {
        this.$message({
          message: '请输入正确手机号',
          type: 'warning',
        })
        return false
      }
      try {
        this.Captcha.show()
      } catch (e) {
        this.$root.prompt('加载验证码失败，请检查网络或刷新重试')
      }
    },
    tencentCaptchaCallback(res) {
      let that = this
      this.isLoading = true
      const phone = !this.isOnlyPhoneCode?that.form.phone_number:this.adminPhoneNumber
      that.$http({
        name: 'PhoneCodeInfo',
        url: '/Message/sendVerification',
        data: {
          mch_mobile: phone,
          type: 17,
          Ticket: res.ticket,
          Randstr: res.randstr,
        },
        callback({ code }) {
          that.isLoading = false
          if (code == 200) {
            let time = 60 //设置重新发送验证码时间
            that.run = setInterval(function () {
              that.getPhoneCodeDate = time + '秒'
              time--
              if (time <= 0) {
                that.getPhoneCodeDate = '获取短信验证码'
                time = 60
                clearInterval(that.run)
              }
            }, 1000)
          }
        },
        error: () => {
          this.isLoading = false
          that.getPhoneCodeDate = '获取短信验证码'
        },
      })
    },
    getPhone(){
      // 获取管理员手机号码
      this.$http({
          name: API.certGetJigouMobile_Api.name,
          url: API.certGetJigouMobile_Api.url,
          data: {},
          callback:(res)=>{
              this.adminPhoneNumber = Number(res.data)
              console.log("this.adminPhoneNumber",this.adminPhoneNumber);
          },
      })
    },
    submit() {
      return new Promise((res, rej) => {
        this.$refs.form.validate((valid) => {
          console.log(valid, this.form);
          if (valid) {
            res(this.form)
          } else {
            rej(false)
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.el-button {
  width: 140px;
  margin-left: 30px;
  color: #fff;
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:hover {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:focus {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.container {
  .inputBtn {
    max-width: 500px;
  }

  .selectBtn {
    max-width: 230px;
  }

  .columnBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .expirationDate {}

  .content {
    font-size: 14px;
    color: #898989;
    line-height: 19px;
    text-align: left;
    font-style: normal;
  }
}
</style>