<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-10-19 16:11:52
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\components\copyrightprotect\openingfunction.vue
-->
<template>
  <div id="openingfunction">
    <el-dialog
      title="开通功能"
      class="dialogVisible"
      width="370px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="dialogVisible_bottom_font1">扫码联系客户经理开通</div>
        <img
          style="width: 150px; height: 150px"
          alt=""
          src="@ass/img/1.4.0/img_kfewm.png"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'openingfunction',
  data() {
    return {
      dialogVisible: false,
    }
  },
  // computed:{
  //   dialogVisible(){
  //     return this.dialogstatus
  //   }
  // },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>
<style lang="scss" scoped>
#openingfunction {
  .dialogVisible {
    ::v-deep .el-dialog {
      height: 416px;
      z-index: 999999;
    }
    ::v-deep .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    ::v-deep .el-dialog__header {
      padding-top: 14px;
    }
    .dialogVisible_bottom {
      margin: 0 auto;
      .dialogVisible_bottom_font1 {
        margin-top: 40px;
        font-size: 16px;
        text-align: center;
        color: #4a4a4a;
        line-height: 21px;
        margin-bottom: 65px;
      }
      img {
        margin: 0 auto;
        // margin-left: 9px;
      }
    }
  }
}
</style>
