<!--
 * @Descripttion: 直播课等弹窗外壳
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-06 11:47:08
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 15:52:04
 -->
<template>
  <el-dialog
    :width="width"
    :title="title"
    :visible="visible"
    :before-close="cancelFn2"
    @closed="cancelFn"
    :close-on-click-modal='false'
  >
    <slot slot="title" name="biaoti"></slot>
    <div
      :class="{
        'tishi-box': !isFromShare,
        'tishi-box2': isFromShare,
        'small-pop': isSmallPop,
        'no-paddingbototm': noPaddingBottom
      }"
    >
      <slot></slot>
      <div v-if="isShowBtn" class="btn-wrap">
        <button
          class="account-login-btn cancel-btn"
          @click="cancelFn2"
          v-if="iskecheng"
        >
          取消
        </button>
        <button v-else class="account-login-btn cancel-btn" @click="cancelFn">
          取消
        </button>
        <button class="account-login-btn" @click.stop="submit">确定</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    iskecheng: [Number, String],
    title: String,
    isShowBtn: {
      type: Boolean,
      default: true
    },
    noPaddingBottom: {
      type: Boolean,
      default: false
    },
    isSmallPop: {
      type: Boolean,
      default: false
    },
    isFromShare: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'fit-content'
    }
  },
  data() {
    return {
      visible: true
    }
  },

  methods: {
    submit() {
      this.$emit('save')
    },
    cancelFn2() {
      this.visible = false
      setTimeout(() => {
        this.$emit('cancelFn2')
      }, 300)
    },
    cancelFn() {
      this.visible = false
      setTimeout(() => {
        this.$emit('cancelFn')
      }, 300)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0;
}
.tishi-box {
  padding: 20px 0;
  background: #fff;
  h3 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }
    button {
      width: 68px;
    }
  }
  .btn-wrap {
    padding: 0 20px;
  }
}
.tishi-box2 {
  padding: 20px 0;
  background: #fff;
  h3 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }
    button {
      width: 68px;
    }
  }
  .btn-wrap {
    padding: 0 20px;
  }
}
.no-paddingbototm {
  padding-bottom: 0;
}
.small-pop {
  width: 422px;
}
.account-login-btn {
  cursor: pointer;
  border: 0;
  color: #fff;
  width: 100%;
  height: 36px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  background: #0aa29b;
  border-radius: 2px;
}
.cancel-btn {
  margin-right: 14px;
  background: #fff;
  border: 1px solid rgba(234, 234, 234, 1);
  color: rgba(102, 102, 102, 1);
}
</style>
