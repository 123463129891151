<template>
  <!-- 选择电子资料 -->
  <div v-if="MenuTreeData">
    <div class="selectDialogBox">
      <div class="titleBefor" v-if="type != 2">
        <span>(多选)</span>
        <span v-if="type != 2">
          裂变活动仅支持付费课程；已被拼团和优惠券活动关联的课程无法选择
        </span>
      </div>
      <div class="headerSelect">
        <div>
          <el-button
            type="primary"
            v-if="isShangchuang"
            @click="$emit('uploadData')"
            style="width: 96px"
          >
            本地上传
          </el-button>
        </div>
        <div class="flex-center">
          <div class="storeDialogBox">
            <el-select
              v-model="DialogBoxPost.format"
              size="medium"
              placeholder="全部条件"
            >
              <el-option
                v-for="item in DialogBoxOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="inputDialogBox">
            <div class="input">
              <el-input
                size="medium"
                :placeholder="
                  DialogBoxPost.searchType == 1 ||
                  DialogBoxPost.searchType == ''
                    ? '请输入文档名称搜索'
                    : '请输入上传者名称搜索'
                "
                v-model="DialogBoxPost.search"
                class="input-with-select"
              >
                <el-select
                  v-model="DialogBoxPost.searchType"
                  slot="prepend"
                  placeholder="文档"
                >
                  <el-option label="文档" value="1"></el-option>
                  <el-option label="上传者" value="2"></el-option>
                </el-select>
                <!-- 搜索 -->
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div class="leftMenu">
          <el-tree
            :data="MenuTreeData"
            node-key="id"
            :default-checked-keys="[5]"
            :props="defaultProps"
            :expand-on-click-node="false"
            :highlight-current="true"
            :check-on-click-node="true"
          >
            <span
              class="my-custom"
              slot-scope="{ node, data }"
              @click="handleNodeClick(node, data)"
            >
              <div style="display: flex">
                <img
                  :src="
                    require('../../assets/img/1.4.0.2/icon_wjj备份 9@2x.png')
                  "
                  class="tree-icon"
                  alt=""
                />
                <!-- <i :class="[data.icon,'tree-icon']"></i> -->
                <span class="label" :title="data.name">
                  {{ data.name }}
                </span>
              </div>
              <span class="span_2" style="color: rgb(165 162 162)">
                {{ data.count ? data.count : '0' }}
              </span>
            </span>
          </el-tree>
        </div>
        <div class="rightContent">
          <pagination2
            :option="DialogBoxPost"
            url="/DataBank/uploaded"
            ref="childDialogBox"
            :allFn="true"
          >
            <template v-slot:default="{ tableData }">
              <div class="pagination2Box">
                <el-table
                  :data="tableData"
                  ref="dialogTable_data"
                  :row-key="getRowkey"
                  @row-click="handleRowClick"
                  @selection-change="DialogBoxSelectionChange"
                  max-height:200
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    :selectable="selectionEvent"
                    width="55"
                  ></el-table-column>
                  <el-table-column prop="name" label="文档" min-width="120">
                    <template slot-scope="scope">
                      <div class="className">
                        <img
                          src="../../assets/img/1.4.0.2/icon_zlbs@2x.png"
                          alt=""
                        />
                        <p :title="scope.row.name">{{ scope.row.name }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="format" label="格式">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.format }}
                      </p>
                    </template>
                  </el-table-column>

                  <el-table-column prop="size" label="大小">
                    <template slot-scope="scope">
                      <div class="titleActivit">
                        <p>{{ scope.row.size }}MB</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="user" label="上传者" min-width="100">
                    <template slot-scope="scope">
                      <div>{{ scope.row.uname }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column prop="add_time" label="上传时间">
                    <template slot-scope="scope">
                      {{
                        scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </pagination2>

          <div class="dialog-footer">
            <div></div>
            <div>
              <el-button @click="dialogSelectBack">取 消</el-button>
              <el-button type="primary" @click="dialogSelectGo">
                确 定({{ listSave.length }})
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hadarr: [],
      listSave: [],
      listAll: [],
      MenuTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'set_name',
        count: 'count',
        icon: 'icon',
      },
      DialogBoxPost: {
        course: [],
        type: 3,
        group_id: '0',
        search: '',
        format: '', //格式
        searchType: '', //搜索1.文档名 2上传者名称
        ids: '', //已经选择过的资料id数组
        price_type: '0',
        // fission_id: '',
      },
      DialogBoxOptions: [
        {
          value: '0',
          label: '全部类型',
        },
        {
          value: 'ppt/pptx',
          label: 'ppt/pptx',
        },
        {
          value: 'doc/docx',
          label: 'doc/docx',
        },
        {
          value: 'xls/xlsx',
          label: 'xls/xlsx',
        },
        {
          value: 'pdf',
          label: 'pdf',
        },
        {
          value: 'zip',
          label: 'zip',
        },
        {
          value: 'rar',
          label: 'rar',
        },
        {
          value: 'xmind',
          label: 'xmind',
        },
        {
          value: 'txt',
          label: 'txt',
        },
      ],
    }
  },
  props: {
    //  1是裂变海报
    //  2是会员
    type: {
      type: Number,
      default: 1,
    },
    //是否需要本地上传
    isShangchuang: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.groupList()
  },

  methods: {
    getRowkey(row) {
      return row.file_id
    },
    handleRowClick(row) {
      this.$refs.dialogTable_data.toggleRowSelection(row)
    },
    reset() {
      this.getMenu_data(this.hadarr)
      this.$refs.childDialogBox.reset()
    },

    getMenu_data(arr) {
      this.hadarr = arr
      this.DialogBoxPost.group_id = 0
      let arr_arr = []
      // 处理父组件传来的数据
      for (let value of arr.values()) {
        arr_arr.push(value.file_id)
      }
      this.DialogBoxPost.course = arr
      this.groupList()
    },

    groupList() {
      //获取树形菜单数据
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: 3,
          course: this.hadarr,
        },
        callback: ({ data }) => {
          this.MenuTreeData = data

          this.$nextTick(() => {
            var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
            span_1.style.background = '#ebf8f7'
          })
        },
      })
    },

    handleNodeClick(node, data) {
      var span_1 = document.querySelectorAll('.el-tree-node__content')[0]
      // span_1..add("is-current")
      span_1.style.background = ''
      //点击弹出的左侧菜单
      this.DialogBoxPost.group_id = data.group_id
      this.$emit('group_id', data.group_id)
    },
    DialogBoxSelectionChange(val) {
      //点击选择暂存选项数组
      this.listSave = val
    },
    selectionEvent(row) {
      //免费和已经关联过的课程选项不可选控制
      // console.log(row,index+'----------')
      if (this.type == 1) {
        if (row.price == 0 || row.is_ash == 1) {
          return false
        } else {
          return true
        }
      } else if (this.type == 2) {
        return true
      }
    },
    clearSelect_data() {
      this.listSave = []
      this.$refs.dialogTable_data.clearSelection()
    },
    dialogSelectBack() {
      this.listSave = []
      this.$refs.dialogTable_data.clearSelection()

      this.$parent.$parent.cancelDialog_data()
    },

    dialogSelectGo() {
      //点击确定将暂存的数组添加到form里
      //调用父组件的关闭dialog框并将选择的课程list数据传过去
      this.$parent.$parent.gbDialog_data(this.listSave)
      for (let value of this.listSave.values()) {
        this.listAll.push(value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .input {
  // margin-left: 20px;

  .el-input-group__prepend {
    width: 55px;
  }
}

.selectDialogBox {
  height: 460px;
  position: relative;
  margin-bottom: 10px;

  .titleBefor {
    position: absolute;
    width: 552px;
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
    top: -40px;
    left: 77px;
  }

  .leftMenu {
    width: 200px;
    overflow: auto;
    height: 396px;
    overflow-x: hidden;
    border: 1px solid #e4e4e4;
    @extend %scroll;

    ::v-deep .el-tree-node__content {
      height: 36px;
    }

    ::v-deep .el-tree-node__expand-icon {
      font-size: 22px;
      display: none;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node__content:hover {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
    }

    .my-custom {
      display: flex;
      padding: 0 10px;
      height: 30px;
      width: 185px;
      justify-content: space-between;

      img {
        width: 14px;
        height: 14px;
        margin-right: 11px;
        margin-top: 7px;
      }

      .label {
        width: 98px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
      }
      .span_2 {
        line-height: 30px;
        text-align: right;
      }
    }
  }
  .headerSelect {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    ::v-deep .el-input-group__prepend {
      background-color: #fff !important;
    }
    .storeDialogBox {
      width: 160px;
      margin-right: 10px;
    }

    .el-input {
      width: 260px;
    }
  }
  .pagination {
    padding: 10px;
  }
  .rightContent {
    width: 100%;
    height: 398px;

    .pagination2Box {
      overflow: auto;
      height: 301px;
      @extend %scroll;

      ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #e5e5e5;
      }

      // border-bottom: 1px solid #E4E4E4;
      .className {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          width: 24px;
          height: 30px;
        }

        p {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 3px;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
</style>
