<!--
 * @Descripttion: 新建分类弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 11:19:03
 * @LastEditors: cyy
 * @LastEditTime: 2022-06-16 15:08:18
 -->
<template>
  <CourseConfirm
    :title="
      `${
        isChild == 1
          ? '编辑子分类'
          : isChild == 2
          ? '新建子分类'
          : editId
          ? '编辑分类'
          : '新建分类'
      }`
    "
    :isShowBtn="true"
    @cancelFn="closeFn"
    @save="saveSort"
  >
    <div class="inner-slot high-zindex">
      <el-form
        :model="ruleForm2"
        label-width="100px"
        label-position="left"
        @submit.native.prevent
      >
        <!-- 选择父类 -->
        <el-form-item label="选择父类" prop="set_pid" v-if="activeType != 1">
          <el-select v-model="ruleForm2.set_pid" placeholder="请选择父类">
            <template v-for="item in options">
              <el-option
                v-if="editId !== item.set_id"
                :key="item.set_id"
                :label="item.set_name"
                :value="item.set_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>

        <!-- 1.3.9.8新需求,不支持选择父类 -->
        <el-form-item label="父分类" prop="nameSort" v-if="isChild">
          <template>
            <div>{{ nameSort }}</div>
          </template>
        </el-form-item>

        <!--父类标题-->
        <el-form-item
          :label="isChild ? '子分类名称' : '分类名称'"
          prop="set_name"
        >
          <div class="el-input-wrap">
            <el-input
              v-model="ruleForm2.set_name"
              :placeholder="
                isChild
                  ? '请输入子分类名称，推荐4个字符以内'
                  : '请输入分类名称，推荐4个字符以内'
              "
              maxlength="8"
              show-word-limit
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item :label="isChild ? '子分类图标' : '分类图标'">
          <div class="course-pic-wrap">
            <img :src="ruleForm2.icon" alt="" />
            <div class="p-wrap">
              <p class="txt">建议尺寸200*200px，JPG、PNG格式， 图片小于2M</p>
              <div class="btn-wrap">
                <!-- <uploadImg
                  :isShowLittleIcon="false"
                  @getResultImg="getResultImg"
                  :isSortIcon="true"
                  ext="?imageView2/1/w/200/h/200"
                > -->
                <!-- <div class="btn1" @click="open"></div> -->
                <el-button type="info" size="small" class="btn" plain>
                  更换图标
                </el-button>
                <change-photoimg
                  ref="popo"
                  :size="2"
                  @complete="completephoto"
                  class="changePhotoimg"
                ></change-photoimg>
                <!-- </uploadImg> -->
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </CourseConfirm>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import CourseConfirm from '@/components/CourseConfirm'
// import uploadImg from '@/components/UploadOneImg'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'

export default {
  name: 'addSortPop',

  props: {
    photourl: [String],
    editId: [String, Number],
    activeType: {
      type: [String, Number],
      default: 3,
    },
    isChild: [String, Number], //0为新建子分类,1为编辑子分类
  },

  mixins: [pageOverflow],

  data() {
    return {
      nameSort: '',
      ruleForm2: {
        set_pid: '0',
        set_name: '',
        icon: 'https://img.dingdingkaike.com/ico_mrfl.png',
      },
      options: [
        {
          set_id: '0',
          set_name: '无',
        },
      ],
    }
  },

  components: {
    CourseConfirm,
    changePhotoimg,
  },

  computed: {},

  watch: {
    photourl() {
      this.ruleForm2.icon = this.photourl
    },

    options: {
      handler() {
        if (this.isChild == 2) {
          //当点击添加子分类,则
          this.ruleForm2.set_pid = this.editId
          for (let value of this.options.values()) {
            if (value.set_id == this.editId) {
              this.nameSort = value.set_name
            }
          }
        } else {
          for (let value of this.options.values()) {
            if (value.set_id == this.ruleForm2.set_pid) {
              this.nameSort = value.set_name
            }
          }
        }
      },
      deep: true,
    },

    editId: {
      handler() {
        if (this.editId && /^[0-9]*$/.test(Number(this.editId))) {
          if (this.isChild != 2) {
            //如果是点击添加子分类则是新建子分类,不需要获取数据
            this.edit(this.editId)
          }
        }
      },
      immediate: true,
    },
  },

  created() {
    // this.edit(this.editId)
    if (this.isChild == 2 || !this.isChild) {
      this.getSetList()
    }
  },

  inject: ['getSetList2'],

  methods: {
    completephoto(val) {
      this.ruleForm2.icon = val
    },
    open() {
      this.$emit('open')
    },
    closeFn() {
      this.$emit('closeFn')
    },
    getResultImg(img_url) {
      this.ruleForm2.icon = `${img_url.split('?')[0]}?imageView2/1/w/200/h/200`
    },

    // 编辑分类
    edit(editId) {
      const self = this
      self.$http({
        name: API.editCourseSet_Api.name,
        url: `${API.editCourseSet_Api.url}?set_id=${editId}`,
        callback({ code, data }) {
          if (code == 200) {
            self.ruleForm2 = data
            self.getSetList()
          }
        },
      })
    },

    // 获取分类列表
    getSetList() {
      const self = this
      self.$http({
        name: API.getSetList_Api.name,
        url: API.getSetList_Api.url,
        data: {
          is_parent: 1,
        },
        callback({ code, data }) {
          if (code == 200) {
            self.options = [...self.options, ...data]
          }
        },
      })
    },

    // 创建分类
    addCourseSet() {
      const self = this
      self.$http({
        name: API.addCourseSet_Api.name,
        url: API.addCourseSet_Api.url,
        data: self.ruleForm2,
        successMsg: true,
        callback({ code }) {
          if (code == 200) {
            self.getSetList2()

            //1.3.9.8新需求,新建成功后刷新分页的数据
            self.$emit('childClickFix')
          }
        },
      })
    },

    // 点击确认
    saveSort() {
      if (this.ruleForm2.set_name.trim() == '') {
        this.$root.prompt('请输入分类名称')
        return
      }
      this.addCourseSet()
      this.$emit('closeFn')

      //1.3.9.8新需求,新建成功后刷新分页的数据
      this.$emit('childClickFix')
    },
  },
}
</script>
<style lang="scss" scoped>
.inner-slot {
  padding: 30px 20px 182px;

  .el-input-wrap {
    width: 518px;
  }

  ::v-deep .el-select,
  ::v-deep .el-input__inner {
    width: 518px;
  }

  ::v-deep .el-input__inner {
    padding-right: 40px;
  }

  ::v-deep .el-form-item {
    .letter-length {
      position: absolute;
      left: 488px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
    }
  }

  .course-pic-wrap {
    display: flex;

    img {
      width: 78px;
      height: 78px;
    }

    .p-wrap {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      margin-left: 30px;
      .btn-wrap {
        position: relative;
        .changePhotoimg {
          position: absolute;
          top: 0;
          height: 32px !important;
          margin-left: -2px;
          // background-color: red;
          // top: 0;
        }
        .btn1 {
          position: absolute;
          top: 0;
          height: 35px;
          width: 80px;
          z-index: 20;
          cursor: pointer;
        }
        .btn {
          position: absolute;
          top: 0;
        }
      }
      .txt {
        margin-bottom: 30px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 16px;
      }
    }
  }
}
</style>
