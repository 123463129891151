<template>
  <div id="addpicture">
    <el-dialog
      :title="pictureTitle"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :modal-append-to-body="false"
      @close="cancel"
    >
      <div slot="title">
        <div class="title_style">
          <div class="font1">{{ pictureTitle }}</div>
        </div>
      </div>
      <div class="main">
        <div class="header">
          <div>
            <el-button
              type="primary"
              size="medium"
              @click="$emit('locationUpload')"
            >
              本地上传
            </el-button>
            <el-button
              type="text"
              size="medium"
              class="ml20"
              style="margin-right: 86px"
              @click="renew"
            >
              刷新列表
            </el-button>
          </div>
          <div>
            <el-select
              v-if="type == 1"
              class="mr20"
              style="width: 160px"
              size="medium"
              v-model="post.format"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-input
              v-if="type == 3"
              v-model="post.search"
              placeholder="输入视频名称搜索"
            ></el-input>

            <el-input
              v-else
              style="width: 318px"
              size="medium"
              v-model="post.search"
              class="input-with-select"
              :placeholder="
                post.searchType == 1 || post.searchType == ''
                  ? '请输入文件名称搜索'
                  : '请输入上传者名称搜索'
              "
            >
              <el-select
                size="medium"
                v-model="post.searchType"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="文件" value="1"></el-option>
                <el-option label="上传者" value="2"></el-option>
              </el-select>
            </el-input>
          </div>
        </div>
        <div class="bottom">
          <div class="selectGroup">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
            >
              <el-menu-item
                v-for="(item, index) in grouplist"
                :key="item.group_id"
                :index="'' + index"
                @click="isSelect(item)"
              >
                <div class="flex-center" style="justify-content: space-between">
                  <div class="flex-center">
                    <img
                      style="margin-right: 6px; width: 15px; height: 14px"
                      src="@ass/img/1.4.0.6/icon_wjj.png"
                      alt=""
                    />

                    <span slot="title" class="font1">
                      {{ item.name }}
                    </span>
                  </div>
                  <span slot="title" class="font3">
                    {{ item.count }}
                  </span>
                </div>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="tablelist">
            <div class="pagination2">
              <pagination2
                :option="post"
                url="/DataBank/uploaded"
                ref="childDialogBox"
                :allFn="true"
              >
                <template v-slot:default="{ tableData }">
                  <el-table
                    ref="dialogTable"
                    @row-click="handleRowClick"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :data="tableData"
                    style="width: 100%"
                  >
                    <!-- <el-table-column label width="35">
                      <template slot-scope="scope">
                        <el-radio
                          :label="
                            scope.row.video_id ||
                              scope.row.images_id ||
                              scope.row.audio_id
                          "
                          v-model="radioId"
                          :disabled="
                            scope.row.video_id
                              ? scope.row.is_video_encryption === '1' ||
                                scope.row.is_video_encryption === '3'
                              : false
                          "
                        ></el-radio>
                      </template>
                    </el-table-column> -->

                    <el-table-column
                      width="45"
                      label=""
                      type="selection"
                    ></el-table-column>

                    <el-table-column label="文件" min-width="150px">
                      <template slot-scope="{ row }">
                        <div class="membership" v-if="type != 3">
                          <div class="tu">
                            <el-image
                              v-if="type == 1 || (type == 3 && row.video_img)"
                              :src="row.url || row.video_img"
                              fit="contain"
                            ></el-image>
                            <img
                              v-if="type == 3 && !row.video_img"
                              src="~@ass/img/1.4.1.2/icon_spbs.png"
                            />
                            <div
                              v-if="type == 2"
                              style="height: 100%; width: 100%"
                            >
                              <img
                                src="~@ass/img/1.4.0.4/icon_yptb@2x.png"
                                alt=""
                              />
                            </div>
                          </div>

                          <div class="right" :title="row.name || row.file_name">
                            {{ row.name || row.file_name }}
                          </div>
                        </div>
                        <div class="TitleVideo" v-else>
                          <div class="imgVideo">
                            <img
                              :src="
                                row.video_img
                                  ? row.video_img
                                  : require('@ass/img/1.3.9/icon_spbs.png')
                              "
                              alt=""
                            />
                            <div
                              class="encryption"
                              v-if="row.zm_spec && row.zm_spec != 5"
                            >
                              <span>{{ row.zm_spec | getzm_spec }}</span>
                              <!-- <span v-if="scope.row.zm_spec == 1">720p</span>
                          <span v-else-if="scope.row.zm_spec == 2">1080p</span>
                          <span v-else>-</span> -->
                            </div>
                            <div class="videoTime">
                              <p>
                                {{ row.chapter_hour | formatTimeLength }}
                              </p>
                            </div>
                          </div>
                          <div class="textVideo">
                            <p :title="row.file_name">
                              {{ row.file_name }}
                            </p>
                            <h4>上传者: {{ row.uname }}</h4>
                            <h5>
                              <img
                                v-if="row.is_video_encryption == 1"
                                src="~@ass/img/1.3.9.4/icon_dg.png"
                                alt=""
                              />
                              <img
                                v-else-if="row.is_video_encryption == 2"
                                src="~@ass/img/1.3.9.4/icon_cw.png"
                                alt=""
                              />
                              <img
                                style="
                                  height: 10px;
                                  width: 10px;
                                  margin-top: 2px;
                                "
                                v-else
                                src="~@ass/img/1.3.9.4/icon_zmz.png"
                                alt=""
                              />
                              <span v-if="row.is_video_encryption == 1">
                                已加密
                              </span>
                              <span v-if="row.is_video_encryption == 2">
                                未加密
                              </span>
                              <span v-if="row.is_video_encryption == 3">
                                加密中
                              </span>

                              <!-- 1未转码  2已转码  3转码中 -->
                              <img
                                class="ml20"
                                v-if="row.transcode_status == 2"
                                src="~@ass/img/1.3.9.4/icon_dg.png"
                                alt=""
                              />
                              <img
                                class="ml20"
                                v-else-if="row.transcode_status == 1"
                                src="~@ass/img/1.3.9.4/icon_cw.png"
                                alt=""
                              />
                              <img
                                v-else
                                class="ml20"
                                style="
                                  height: 10px;
                                  width: 10px;
                                  margin-top: 2px;
                                "
                                src="~@ass/img/1.3.9.4/icon_zmz.png"
                                alt=""
                              />
                              <span v-if="row.transcode_status == 2">
                                视频已转码
                              </span>
                              <span v-if="row.transcode_status == 1">
                                视频未转码
                              </span>
                              <span v-if="row.transcode_status == 3">
                                视频转码中
                              </span>
                            </h5>
                          </div>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="格式" v-if="type != 3">
                      <template slot-scope="{ row }">
                        <span v-if="row.format">
                          {{ row.format }}
                        </span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>

                    <el-table-column label="大小" v-if="type != 3">
                      <template slot-scope="{ row }">{{ row.size }}MB</template>
                    </el-table-column>

                    <el-table-column label="上传者" v-if="type != 3">
                      <template slot-scope="{ row }">
                        {{ row.uname }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="上传时间"
                      prop="address"
                      min-width="90px"
                    >
                      <template slot-scope="{ row }">
                        {{
                          (row.c_time || row.add_time)
                            | formatTimeStamp('yyyy-MM-dd hh:mm')
                        }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </pagination2>
            </div>
          </div>
        </div>

        <div class="btns">
          <el-button type="" size="medium" style="width: 86px" @click="cancel">
            取消
          </el-button>
          <el-button
            type="primary"
            size="medium"
            style="width: 86px"
            @click="submit"
          >
            确定({{ listSelect.length }})
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'addpicture',
  data() {
    return {
      listSelect: [],

      video: [], //视频选中

      audio: [], //音频选中

      list: [], //图像选中的数据

      radioId: '', //被选选中的值

      uploadStart: false, //本地上传的弹窗

      post: {
        type: this.type == 1 ? 4 : this.type == 2 ? 5 : 1,
        search: '',
        searchType: '1',
        format: 0,
        group_id: '',
      },

      activeIndex: '0', //左侧默认选中

      grouplist: [], //左侧列表

      tableData: [], //右侧列表数据

      //搜索关键字
      searchKey: '',

      //控制弹窗的显示
      dialogVisible: false,

      //图片规格
      options: [
        {
          value: 0,
          label: '全部格式',
        },
        {
          value: 'jpg',
          label: 'jpg',
        },
        {
          value: 'jpeg',
          label: 'jpeg',
        },
        {
          value: 'png',
          label: 'png',
        },
      ],

      value: 0, //g格式
    }
  },
  filters: {
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
        default:
          '-'
      }
      return text
    },
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },

    count: Number,

    pictureTitle: {
      type: String,
      default: '',
    },

    //1图片 2音频 //3视频
    type: {
      type: Number,
      default: 1,
    },

    // 图片大小
    size: {
      type: Number,
      default: 5,
    },
  },

  created() {
    this.studentGroup()
  },

  computed: {
    member_id() {
      return this.$route.query.member_id
    },
  },

  methods: {
    //刷新列表
    renew() {
      this.studentGroup()
      this.$refs.childDialogBox.reset()
    },

    //列表的行点击
    handleRowClick(row) {
      // if (
      //   row.video_id &&
      //   (row.is_video_encryption == '1' || row.is_video_encryption == '3')
      // ) {
      //   return false
      // }
      // this.radioId = row.video_id || row.images_id || row.audio_id

      // this.handleSelectionChange([row])
      this.$refs.dialogTable.toggleRowSelection(row)
    },

    //点击确认
    submit() {
      if (this.listSelect.length + this.count > 10) {
        this.$root.prompt('插入的内容总数最多10个！')
        return
      }
      if (this.listSelect.length > 0) {
        if (this.type == 1) {
          this.$emit('list', this.list)
        } else if (this.type == 2) {
          this.$emit('audio', this.audio)
        } else {
          this.$emit('video', this.video)
        }
      }
      this.$emit('update:dialogstatus', false)
    },

    //选各种的数据
    handleSelectionChange(val) {
      switch (this.type) {
        case 1:
          this.list = val
          break
        case 2:
          this.audio = val
          break
        case 3:
          this.video = val
      }

      this.listSelect = val
    },

    //选中左侧分组
    isSelect(item) {
      this.post.group_id = item.group_id
      this.$emit('group_id', item.group_id)
    },

    //左侧列表的获取
    studentGroup() {
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: this.type == 1 ? 4 : this.type == 2 ? 5 : 1,
        },
        callback: ({ data }) => {
          this.grouplist = data
        },
      })
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  padding-left: 11px;
}
::v-deep .el-image {
  height: 100%;
  width: 100%;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.el-menu-item.is-active {
  background-color: #ecf5ff;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  background: #aaaaaa;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.TitleVideo {
  display: flex;

  .imgVideo {
    background: #000;
    margin-right: 10px;
    width: 141px;
    height: 80px;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    img {
      border-radius: initial;
      margin: 0 auto;
      width: auto;
      height: 100%;
      margin: 0 auto;
    }

    .encryption {
      position: absolute;
      top: 0;
      width: 45px;
      height: 22px;
      border-radius: 0px 0px 11px 0px;
      overflow: hidden;
      background: #fa6400;

      span {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 18px;
        margin-left: 4px;
      }
    }

    .videoTime {
      width: 100%;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      text-align: center;

      p {
        height: 12px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .textVideo {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
      min-width: 168px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #333333;
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h4 {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 15px;
      font-weight: normal;
    }

    h5 {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 14px;
      margin-top: 13px;
      font-weight: normal;
      img {
        margin-right: 6px;
        height: 8px;
        width: 8px;
      }
    }
  }
}
#addpicture {
  .pagination2 {
    height: 288px;
    overflow: hidden;
    overflow-y: auto;
  }
  .tablelist {
    .membership {
      display: flex;
      align-items: center;
      .tu {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        img {
          height: 40px;
          width: 100%;
          object-fit: contain;
        }
      }
      .right {
        width: calc(100% - 50px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333333;
        line-height: 15px;
      }
    }
  }

  ::v-deep .el-table__header-wrapper {
    height: 42px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog {
    height: 518px;
  }
  .title_style {
    display: flex;
    align-items: center;
    .font1 {
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
  }
  .main {
    padding: 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .guige {
        margin-right: 30px;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }

      ::v-deep .input-with-select {
        .el-input__inner {
          color: #333333;
        }
        .el-input-group__prepend {
          background-color: #fff;
          width: 60px;
        }
      }
    }
    .bottom {
      display: flex;
      margin-top: 20px;
      .selectGroup {
        height: 334px;
        width: 214px;

        ::v-deep .el-menu {
          height: 334px;
          overflow-y: auto;
          overflow-x: hidden;
          border: solid 1px #e6e6e6;
        }
        ::v-deep .el-menu-item:hover,
        .el-menu-item:focus {
          background-color: #f0f8ff;
        }
        ::v-deep .el-menu-item {
          height: 36px;
          line-height: 36px;
        }
        .font1 {
          font-size: 12px;
          color: #333333;
        }
        .font3 {
          font-size: 12px;
          color: #999999;
          line-height: 12px;
        }
      }
      .tablelist {
        width: calc(100% - 200px);
        .search {
          margin-bottom: 10px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .pagenation {
        margin-top: 20px;
        text-align: center;
      }
    }
    .btns {
      text-align: right;
      margin-top: 10px;
    }
  }
}
</style>
