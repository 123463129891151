<!--
 * @Descripttion: 上课邀请弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 14:24:10
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:18:22
 -->
<template>
  <div>
    <CourseConfirm
      title="上课邀请"
      @cancelFn="closeFn"
      :isShowBtn="false"
      width="800px"
    >
      <template v-slot:biaoti>
        <div slot="title" width="800px">
          <el-tabs v-model="activeName">
            <el-tab-pane label="海报邀请" name="1" v-if="live_mode != 2">
              <span style="color: rgba(102, 102, 102, 1); font-size: 12px">
                提示：右键复制图片或截图发送给学员，已报名扫码可直接进入直播间；未报名会先提示学员去报名课程
              </span>
              <div class="top_header">
                <div class="zi1" v-show="courseType != 2 && live_mode != 2">
                  微信h5观看
                  <el-popover
                    placement="top-start"
                    width="327"
                    trigger="hover"
                    content="10秒左右延迟，助教支持禁言、踢人、拉黑和发送图片、推送课程；适合大型公开课、活动直播等场景"
                  >
                    <i
                      style="color: #cccccc"
                      class="el-icon-question"
                      slot="reference"
                    ></i>
                  </el-popover>
                </div>
                <div class="zi2">
                  小程序观看
                  <el-popover
                    placement="top-start"
                    width="327"
                    trigger="hover"
                    content="1-2秒延迟，助教支持禁言、踢人、拉黑，适合K12教学、VIP课、引流课等直播场景，延迟低，还支持连麦互动"
                  >
                    <i
                      style="color: #cccccc"
                      class="el-icon-question"
                      slot="reference"
                    ></i>
                  </el-popover>
                </div>
              </div>
              <div class="bottom_container">
                <template v-if="courseType != 2 && live_mode != 2">
                  <div class="tu1_contianer">
                    <div class="tu1" id="poster11">
                      <div class="course_img1">
                        <img :src="datalist.photo" alt />
                      </div>
                      <div class="course_name1">
                        《{{ datalist.course_name }}》{{ datalist.name }}
                      </div>
                      <div class="bottom">
                        <div class="left">
                          <div>上课时间</div>
                          <div
                            style="
                              margin: 12px 0 42px 0;
                              font-weight: bold;
                              font-size: 14px;
                            "
                          >
                            {{
                              datalist.startTime | formatTimeStamp(' MM/dd ')
                            }}
                            {{ datalist.startTime | formatTimeStamp('hh:mm') }}
                          </div>
                          <div>长按识码进入直播间</div>
                        </div>
                        <div class="right">
                          <div class="tu_code">
                            <div
                              id="qrcode"
                              style="height: 100%; width: 100%"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="result-pic" v-if="resultPic">
                        <img :src="resultPic" />
                      </div>
                    </div>
                    <div class="tu1_bottom">
                      <a download="poster.jpg" :href="resultPic">下载海报</a>
                    </div>
                  </div>
                </template>
                <div class="tu2_contianer">
                  <div class="tu2" id="poster22">
                    <div class="course_img2">
                      <img :src="datalist.photo" alt />
                    </div>
                    <div class="course_name2">
                      《{{ datalist.course_name }}》{{ datalist.name }}
                    </div>
                    <div class="bottom">
                      <div class="left">
                        <div>上课时间</div>
                        <div
                          style="
                            margin: 12px 0 42px 0;
                            font-weight: bold;
                            font-size: 14px;
                          "
                        >
                          {{ datalist.startTime | formatTimeStamp(' MM/dd ') }}
                          {{ datalist.startTime | formatTimeStamp('hh:mm') }}
                        </div>
                        <div>长按识码进入直播间</div>
                      </div>
                      <div class="right">
                        <div class="tu_code">
                          <img :src="small" width="100" height="100" alt />
                        </div>
                      </div>
                    </div>
                    <div class="result-pic2" v-if="resultPic2">
                      <img :src="resultPic2" />
                    </div>
                  </div>
                  <div class="tu2_bottom">
                    <a download="poster2.jpg" :href="resultPic2">下载海报</a>
                  </div>
                </div>
              </div>
              <!-- <div class="download_bottom">
                <div class="download1">11</div>
                <div>
                </div>
                <div class="download1">33</div>
              </div> -->

              <div class="tishi55" v-if="complete_erweima">
                海报生成中，请等候…
              </div>
            </el-tab-pane>
            <el-tab-pane label="链接邀请" name="2">
              <span
                style="
                  color: rgba(102, 102, 102, 1);
                  font-size: 12px;
                  font-size: 14px;
                "
              >
                提示：复制链接发送给学员，打开链接进入直播间；未报名需先报名课程；适合大型公开课、活动直播等场景
              </span>
              <div class="bottom2">
                <div
                  style="
                    margin-top: 50px;
                    font-size: 14px;
                    color: rgba(51, 51, 51, 1);
                  "
                >
                  观看地址
                </div>
                <div class="addess">{{ datalist.url }}</div>
                <el-button
                  type="primary"
                  class="in-green cp for-copy"
                  :data-clipboard-text="datalist.url"
                >
                  复制链接
                </el-button>
                <div class="bottom_erweima">
                  <div class="left" v-if="courseType != 2 && live_mode != 2">
                    <div>
                      直播间二维码
                      <span class="cp xiazai" @click="downEwm">下载</span>
                    </div>
                    <div
                      class="erweima1"
                      id="qrcode2"
                      style="height: 100%; width: 100%"
                    ></div>
                  </div>
                  <div class="right" v-if="live_mode != 2">
                    <div>
                      直播间小程序码
                      <span class="cp xiazai" @click="xcx_erwima">
                        <!-- <a download="poster3.jpg" :href="small"> -->
                        下载
                        <!-- </a> -->
                      </span>
                    </div>
                    <div class="erweima1">
                      <img :src="small" width="138" height="138" alt />
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="口令邀请" name="3" v-if="live_mode != 2">
              <div class="inner-slot">
                <span style="color: rgba(102, 102, 102, 1); font-size: 12px">
                  提示：使用口令邀请，学员访问链接输入口令即可观看，无需登录和购买，该章节结束后链接自动失效
                </span>
                <ul class="invite-list">
                  <template v-for="(item, index) in info">
                    <li class="invite-item" :key="index">
                      <h4>
                        <div style="width: 69px">{{ item.name }}</div>
                        <br />
                        <template v-if="index != 1">
                          <span v-if="item.limit > 0">
                            上限{{ item.limit }}人
                          </span>
                        </template>
                        <template v-else>
                          <span v-if="item.limit > 0 && courseType == 1">
                            上限{{ item.limit }}人
                          </span>
                        </template>
                      </h4>
                      <div
                        class="invite-content"
                        style="text-align: center"
                        v-if="index == 1 && courseType == 2"
                      >
                        <h3 class="tishi" v-if="index == 1 && courseType == 2">
                          小班课暂不支持助教
                        </h3>
                      </div>
                      <div class="invite-content" v-else>
                        <div class="top-content">
                          <span class="address" v-if="index == 0">
                            观看地址：
                            <a
                              class="links"
                              :href="links['student_url']"
                              target="_blank"
                            >
                              {{ links['student_url'] }}
                            </a>
                          </span>
                          <span class="address" v-if="index == 1">
                            观看地址：
                            <a
                              class="links"
                              :href="links['assistant_url']"
                              target="_blank"
                            >
                              {{ links['assistant_url'] }}
                            </a>
                          </span>
                          <span class="address" v-if="index == 2">
                            {{
                              courseType == 1
                                ? '直播地址：电脑打开链接下载直播助手开课>>'
                                : '直播地址：请使用谷歌浏览器打开链接输入口令进入>>'
                            }}
                            <a
                              class="links"
                              :href="links['teacher_url']"
                              target="_blank"
                            >
                              {{ links['teacher_url'] }}
                            </a>
                          </span>
                          <span
                            class="in-green cp for-copy"
                            v-if="index == 2"
                            style="font-weight: bold"
                            :data-clipboard-text="`直播地址：${links['teacher_url']}`"
                          >
                            复制链接
                          </span>
                          <span
                            class="in-green cp for-copy"
                            v-if="index == 0"
                            style="font-weight: bold"
                            :data-clipboard-text="`观看地址：${links['student_url']} 观看口令：${item.kouling}`"
                          >
                            复制链接+口令
                          </span>
                          <span
                            style="font-weight: bold"
                            class="in-green cp for-copy"
                            v-if="index == 1"
                            :data-clipboard-text="`观看地址：${links['assistant_url']} 观看口令：${item.kouling}`"
                          >
                            复制链接+口令
                          </span>
                          <span
                            style="font-weight: bold"
                            class="in-green cp for-copy"
                            v-if="courseType != 1 && index == 2"
                            :data-clipboard-text="`直播地址：${links['teacher_url']} 观看口令：${item.kouling}`"
                          >
                            复制链接+口令
                          </span>
                        </div>
                        <div class="bottom-content">
                          <span v-if="index != 2">
                            观看口令：{{ item.kouling }}
                            <i
                              class="in-green cp"
                              @click="
                                sl_id = `${
                                  courseType == 1 ? item.sl_id : item.sp_id
                                }&${item.type}`
                              "
                            >
                              重置
                            </i>
                          </span>
                          <template v-else>
                            <span v-if="courseType == 1"></span>
                            <span v-else>
                              观看口令：{{ item.kouling }}
                              <i
                                class="in-green cp"
                                @click="
                                  sl_id = `${
                                    courseType == 1 ? item.sl_id : item.sp_id
                                  }&${item.type}`
                                "
                              >
                                重置
                              </i>
                            </span>
                          </template>
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </template>
    </CourseConfirm>
    <CourseConfirm
      v-if="sl_id"
      title="重置口令"
      :isSmallPop="true"
      @cancelFn="sl_id = ''"
      @save="reset"
    >
      <h4 class="reset-txt">重置口令会使原口令失效，是否确认重置？</h4>
    </CourseConfirm>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import ClipboardJS from 'clipboard'
import QRCode from '@/assets/js/qrcode'
import CourseConfirm from '@/components/CourseConfirm'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'

export default {
  name: 'invitePop',
  props: {
    live_mode: [Number, String],
    editData: {
      type: Object,
    },
  },
  mixins: [pageOverflow],
  data() {
    return {
      complete_erweima: true,
      templateId: 11,
      resultPic: '',
      resultPic2: '',
      activeName: this.editData.live_mode == 2 ? '2' : '1',
      links: {},
      sl_id: '',
      courseType: this.editData.sl_id
        ? '1'
        : this.editData.sp_id
        ? '2'
        : this.$route.query.course_type,
      newEditData: _.assign({}, this.editData),
      order_num: 0,
      datalist: {},
      small: '',
    }
  },
  watch: {
    editData: {
      handler() {
        this.getLinks()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    info() {
      return [
        {
          sl_id: this.newEditData.sl_id,
          type: 1,
          name: '邀请学生',
          kouling: this.newEditData.student_command,
          limit: this.order_num,
          sp_id: this.newEditData.sp_id,
        },
        {
          sl_id: this.newEditData.sl_id,
          type: 3,
          name: '邀请助教',
          kouling: this.newEditData.assistant_command,
          limit: 0,
          sp_id: this.newEditData.sp_id,
        },
        {
          sl_id: this.newEditData.sl_id,
          type: 2,
          name: '邀请老师',
          kouling: this.newEditData.teacher_command,
          limit: this.courseType == 1 ? 0 : 1,
          sp_id: this.newEditData.sp_id,
        },
      ]
    },
  },
  components: {
    CourseConfirm,
  },
  mounted() {
    // this.create()

    this.getPosterInvitation()
    this.initCopy()
    // this.getLinks();
  },
  methods: {
    //小程序二维码下载
    xcx_erwima() {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = this.small
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob)
          this.download(url, 'erweima.jpg')
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },
    getPosterInvitation() {
      this.$http({
        url: '/Share/posterInvitation',
        data: {
          correlation_id: this.newEditData.correlation_id,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.datalist = data
            this.small = data.small
            this.createEwm()
            setTimeout(() => {
              this.$nextTick(() => {
                if (this.live_mode != 2) {
                  this.create()
                  this.create2()
                }
              })
            }, 50)
          }
        },
      })
    },
    closeFn() {
      this.$emit('close')
    },

    // 生成二维码
    createEwm() {
      const size = 20 * 5
      const node = document.getElementById('qrcode')
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.datalist.url,
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }

      const node2 = document.getElementById('qrcode2')
      if (node2) {
        this.qrcode2 = new QRCode(node2, {
          text: this.datalist.url,
          width: 138,
          height: 138,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
    },
    //生成海报
    create() {
      window.scrollTo(-10, -10)
      const poster11 = document.getElementById('poster11')
      if (!poster11) {
        console.info('无poster11节点')
        return
      }
      html2canvas(poster11, {
        useCORS: true,
        logging: false,
      })
        .then((canvas) => {
          this.resultPic = canvas.toDataURL('image/png')
          this.complete_erweima = false
        })
        .catch((res) => {
          console.log(res)
        })
      this.initCopy()
    },

    create2() {
      window.scrollTo(-10, -10)
      const poster22 = document.getElementById('poster22')
      if (!poster22) {
        console.info('无poster22节点')
        return
      }
      html2canvas(poster22, {
        useCORS: true,
        logging: false,
      })
        .then((canvas) => {
          this.resultPic2 = canvas.toDataURL('image/png')
          this.complete_erweima = false
        })
        .catch((res) => {
          console.log(res)
        })
      // this.initCopy()
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },
    // 获取地址
    getLinks() {
      const self = this
      self.$http({
        name: API.getPlayUrl_Api.name,
        url: API.getPlayUrl_Api.url,
        data: {
          correlation_id: self.newEditData.correlation_id,
        },
        callback({ data, order_num }) {
          self.links = data
          // console.log(self.links)
          self.order_num = order_num
        },
      })
    },
    // 重置口令
    reset() {
      const self = this
      const temp = self.sl_id.split('&')
      self.$http({
        name: API.resetCommand_Api.name,
        url: API.resetCommand_Api.url,
        data: {
          type: temp[1],
          id: temp[0],
          course_type: self.courseType,
        },
        callback({ code, command }) {
          if (code == 200) {
            switch (Number(temp[1])) {
              case 1:
                self.newEditData.student_command = command
                break
              case 2:
                self.newEditData.teacher_command = command
                break
              case 3:
                self.newEditData.assistant_command = command
                break
            }
            self.$emit('updateEdit', self.newEditData)
            self.sl_id = ''
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #0aa29b;
}
.xiazai {
  margin-left: 18px;
  color: #1b9d97;
}
.bottom_container {
  clear: both;
  height: 368px;
  padding-left: 57px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
// .download_bottom {
//   margin-top: 25px;
//   .download1 {
//     float: left;
//   }
//   .download2 {
//     float: right;
//   }
// }

.tishi55 {
  margin-top: 40px;
  font-size: 12px;
  color: #ff4747;
  text-align: center;
}
.top_header {
  clear: both;
  margin-bottom: 10px;
  margin-top: 23px;
  height: 19px;
  display: flex;
  justify-content: center;
}
.tu1_contianer {
  margin-right: 128px;
  .tu1_bottom {
    font-size: 13px;
    color: #1b9d97;
    line-height: 60px;
    text-align: center;
    padding-right: 21px;
    a {
      text-decoration: none;
      color: #0aa29b;
    }
  }
}
.tu1 {
  position: relative;
  width: 274px;
  height: 360px;
  float: left;
  background: url(../../assets/img/1.3.6/img_wxh5bg.png) no-repeat;
  .course_img1 {
    border-radius: 4px;
    width: 234px;
    height: 132px;
    margin: 12px 20px;
    img {
      width: 234px;
      height: 132px;
    }
  }
  .course_name1 {
    font-size: 13px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 17px;
    margin: 10px 20px;
    height: 71px;
  }
  .bottom {
    padding: 10px;
    .left {
      float: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
    }
    .right {
      float: right;
      .tu_code {
        width: 100px;
        height: 100px;
      }
    }
  }
  .download1 {
    position: absolute;
    bottom: 0;
    left: 50%;
  }
}
.left-part {
  width: 100%;
  height: 459px;
  position: relative;
}
.result-pic {
  width: 274px;
  height: 368px;
  position: absolute;
  top: 0px;
  left: 0px;
  img {
    width: 100%;
    height: 100%;
  }
}
.result-pic2 {
  width: 274px;
  height: 368px;
  position: absolute;
  top: 0px;
  right: 0px;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.tishi-box {
  padding: 20px 0;
  background: #fff;
  h3 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }
    button {
      width: 68px;
    }
  }
  .btn-wrap {
    padding: 0 20px;
  }
}
.tishi-box2 {
  padding: 20px 0;
  background: #fff;
  h3 {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
  }
  p {
    padding: 40px 0 87px;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    line-height: 21px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgba(27, 157, 151, 1);
      line-height: 19px;
      cursor: pointer;
    }
    button {
      width: 68px;
    }
  }
  .btn-wrap {
    padding: 0 20px;
  }
}
.no-paddingbototm {
  padding-bottom: 0;
}
.small-pop {
  width: 422px;
}
.account-login-btn {
  cursor: pointer;
  border: 0;
  color: #fff;
  width: 100%;
  height: 36px;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
  background: #0aa29b;
  border-radius: 2px;
}
.cancel-btn {
  margin-right: 14px;
  background: #fff;
  border: 1px solid rgba(234, 234, 234, 1);
  color: rgba(102, 102, 102, 1);
}
.tu2 {
  position: relative;
  width: 274px;
  height: 360px;
  float: right;
  margin-right: 70px;

  background: url(../../assets/img/1.3.6/img_xcxbg.png) no-repeat;

  .course_img2 {
    border-radius: 4px;
    width: 234px;
    height: 132px;
    margin: 12px 20px;
    img {
      width: 234px;
      height: 132px;
    }
  }
  .course_name2 {
    font-size: 12px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    margin: 10px 20px;
    line-height: 17px;
    height: 71px;
  }
  .bottom {
    padding: 10px;
    .left {
      float: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
    }
    .right {
      float: right;
      .tu_code {
        width: 100px;
        height: 100px;
      }
    }
  }
}
.tu2_bottom {
  margin-top: 25px;
  text-align: center;
  font-size: 13px;
  color: #1b9d97;
  line-height: 60px;
  padding-right: 64px;
}
.zi1,
.zi2 {
  width: 100px;
  float: left;
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.zi1 {
  margin-right: 310px;
}
.bottom2 {
  height: 442px;
  .addess {
    width: 620px;
    height: 90px;
    padding: 11px;
    margin: 13px 20px 0 0;
    background: rgba(248, 248, 248, 1);
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    float: left;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    line-height: 18px;
    margin-bottom: 40px;
  }
  .el-button {
    float: left;
    margin-bottom: 20px;
  }
  .bottom_erweima {
    clear: both;
    display: flex;
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    margin-top: 20px;
    .erweima1 {
      margin-top: 26px;
    }

    .left {
      margin-right: 100px;
    }
  }
}

.inner-slot {
  .invite-list {
    padding: 50px 0;
    .invite-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      h4 {
        font-size: 14px;
        color: #333;
        span {
          font-size: 12px;
          color: #fa6400;
        }
      }
      .invite-content {
        width: 800px;
        padding: 20px;
        background: #f5f5f5;
        box-sizing: border-box;
        .top-content,
        .bottom-content {
          display: flex;
          align-content: center;
          justify-content: space-between;
        }
        .top-content {
          margin-bottom: 30px;
          .address {
            display: inline-block;
            width: 566px;
          }
          .links {
            margin-left: 0;
            color: #0aa29b;
            text-decoration: none;
          }
        }
        span {
          font-size: 12px;
          color: #333;
          i {
            margin-left: 25px;
          }
        }
        .in-green {
          color: #0aa29b;
        }
        .tishi {
          font-size: 1.55rem;
          font-weight: bold;
          color: rgba(204, 204, 204, 1);
          line-height: 2.05rem;
        }
      }
    }
  }
}
.reset-txt {
  padding: 10px 20px 47px 20px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
</style>
