var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment-wrap"},[_c('div',{staticClass:"control-box"},[_c('div',{staticClass:"left-box"},[_c('el-checkbox',{attrs:{"label":"选中本页"},on:{"change":_vm.getAllCheck},model:{value:(_vm.isCheckedAll),callback:function ($$v) {_vm.isCheckedAll=$$v},expression:"isCheckedAll"}}),_c('span',{staticClass:"cp setting-erweima",staticStyle:{"margin-left":"30px"},on:{"click":function($event){return _vm.statusCourseReview(
            _vm.isCheckedAll ? _vm.allreview_ids : _vm.review_ids,
            2,
            'all'
          )}}},[_vm._v(" 批量显示 ")]),_c('span',{staticClass:"cp setting-erweima",on:{"click":function($event){return _vm.statusCourseReview(
            _vm.isCheckedAll ? _vm.allreview_ids : _vm.review_ids,
            3,
            'all'
          )}}},[_vm._v(" 批量隐藏 ")])],1),_c('div',{staticClass:"right-box"},[_c('span',[_vm._v("发布评论需审核")]),_c('el-switch',{attrs:{"active-color":"#0AA29B","inactive-color":"#ccc"},on:{"change":_vm.getIsReview},model:{value:(_vm.is_review),callback:function ($$v) {_vm.is_review=$$v},expression:"is_review"}}),_c('div',{staticClass:"select-wrap"},[_c('el-select',{attrs:{"placeholder":"全部"},on:{"change":_vm.getStatus},model:{value:(_vm.requestParam.status),callback:function ($$v) {_vm.$set(_vm.requestParam, "status", $$v)},expression:"requestParam.status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)]),_vm._m(0),(
      _vm.resultInfo != undefined && _vm.resultInfo.data && _vm.resultInfo.data.length > 0
    )?_c('ul',_vm._l((_vm.resultInfo.data),function(item,index){return _c('li',{key:index,staticClass:"item"},[_c('div',{staticClass:"checkbox-wrap"},[_c('el-checkbox',{on:{"change":(checked) => _vm.getReviewIdSet(checked, item.review_id, index)},model:{value:(_vm.flagArr[index]),callback:function ($$v) {_vm.$set(_vm.flagArr, index, $$v)},expression:"flagArr[index]"}})],1),_c('div',{staticClass:"item1 info"},[_c('div',{staticClass:"cover"},[_c('lazy',{staticClass:"img",attrs:{"src":item.uphoto}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.uname))])])]),_c('div',{staticClass:"item2"},[_c('div',{staticClass:"vm type item2-1"},[_vm._v(" "+_vm._s(item.student_remarks || '-')+" ")]),_c('div',{staticClass:"vm type item2-1"},[_c('div',{staticClass:"inner-title"},[_vm._v(_vm._s(item.content))])]),_c('div',{staticClass:"vm teacher item2-2"},[_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(item.createtime,'yyyy-MM-dd hh:mm'))+" ")]),(item.status == 3)?_c('div',{staticClass:"vm count item2-3"},[_vm._v("已隐藏")]):_vm._e(),(item.status == 2)?_c('div',{staticClass:"vm count item2-3"},[_vm._v("已显示")]):_vm._e(),(item.status == 1)?_c('div',{staticClass:"vm count item2-3"},[_vm._v("待处理")]):_vm._e()]),_c('div',{staticClass:"function item6"},[_c('span',{staticClass:"vm btn",on:{"click":function($event){return _vm.statusCourseReview(
              item.review_id,
              item.status == 1 || item.status == 3 ? 2 : 3,
              'single'
            )}}},[_vm._v(" "+_vm._s(item.status == 1 || item.status == 3 ? '显示' : '隐藏')+" ")]),_c('span',{staticClass:"vm space"}),_c('span',{staticClass:"vm btn",on:{"click":function($event){$event.stopPropagation();_vm.delId = `${item.review_id}&${index}`}}},[_vm._v(" 删除 ")])])])}),0):_vm._e(),(_vm.isNoData)?_c('div',{staticStyle:{"position":"relative","height":"500px"}},[_c('empty',{attrs:{"isOld":false,"positionCenter":true,"str":"absolute"}})],1):_vm._e(),_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"leftPos":"0"}}),(_vm.delId)?_c('CourseConfirm',{attrs:{"title":"删除评论","isSmallPop":true},on:{"cancelFn":_vm.toggleShowDel,"save":function($event){return _vm.getDel(_vm.delId)}}},[_c('h4',{staticClass:"reset-txt"},[_vm._v("是否删除该评论内容？")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resultInfo.allNum > 15),expression:"resultInfo.allNum > 15"}],staticClass:"pagination-wrap"},[_c('el-pagination',{attrs:{"background":"","page-size":15,"layout":"prev, pager, next","page-count":Number(_vm.resultInfo.pageNum),"total":Number(_vm.resultInfo.allNum),"hide-on-single-page":""},on:{"current-change":_vm.getChangeCurrent}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-list"},[_c('p',{staticClass:"p1"},[_vm._v("用户")]),_c('div',{staticClass:"title-inner"},[_c('p',{staticClass:"p2"},[_vm._v("真实姓名")]),_c('p',{staticClass:"p2"},[_vm._v("评论内容")]),_c('p',{staticClass:"p3"},[_vm._v("评论时间")]),_c('p',{staticClass:"p4"},[_vm._v("状态")])]),_c('p',{staticClass:"p5"},[_vm._v("操作")])])
}]

export { render, staticRenderFns }