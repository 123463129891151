<template>
  <div class="posterTemplate posterTemplate1">
    <div>
      <div>
        <img class="t1" :src="posterData.uphoto" />
        <p class="t2">{{ posterData.uname | unameStr }}</p>
        <p class="t3">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
        <img class="t4" :src="posterData.photo" />
        <p class="t5">{{ posterData.name | nameStr }}</p>
        <div class="bottom">
          <div class="erweima" ref="qrcode"></div>
          <div class="text">
            <!-- <div class="t1">2019-11-23</div> -->
            <div class="t2">
              <p>长按识别二维码</p>
              <p>{{type==='2' ? '立即领取' : '立即加入学习'}}</p>
            </div>
          </div>
        </div>
        <!-- <p class="t6" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名
          {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
      </div>
      <p class="jgName">{{ posterData.jg_title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    posterData: Object,
    surplusHeight: Number,
    type:{
      type:String,
      default:'1'
    }
  },

  name: 'posterTemplate1',
  filters: {
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 10)}...` : str
    }
  },
  mounted() {
    // this.$parent.createEwm(16, this.$refs.qrcode);
    this.$emit('createEwm', 3, this.$refs.qrcode)
  }
}
</script>
<style lang="scss">
.posterTemplate1 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  box-sizing: border-box;
  background-color: #7a99db;
  > div {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    > div {
      position: relative;
      border-radius: 4px;
      padding: 1px 10px;
      background-color: #fff;
      > .t1 {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin: -19px auto 0;
        border: 2px solid #fff;
      }
      > .t2 {
        font-size: 14px;
        margin-top: 3px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      > .t3 {
        color: #666;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
      }
      > .t4 {
        width: 242px;
        height: 135px;
        margin-top: 8px;
        border-radius: 4px;
      }
      > .t5 {
        // height: 36px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin-top: 12px;
        word-break: break-all;
        color: rgba(51, 51, 51, 1);
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      > .t6 {
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 143px;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 15px;
        margin-left: -71.5px;
        white-space: nowrap;
        border-radius: 11px;
        background-color: #ff0000;
      }
      > .bottom {
        display: flex;
        margin-top: 18px;
        align-items: center;
        padding-bottom: 13px;
        .text {
          flex: 1;
          margin-left: 22px;
          .t1 {
            color: #666;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
          }
          .t2 {
            font-size: 12px;
            line-height: 17px;
            margin-top: 25px;
            color: rgba(29, 169, 162, 1);
          }
        }
        .erweima {
          width: 80px;
          height: 80px;
          * {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    > .jgName {
      color: #cfdeff;
      font-size: 13px;
      text-align: center;
      padding: 21px 0 17px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
