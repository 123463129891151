function FromVerify(value) {
  let msg = false
  let other = {}
  value.some(item => {
    const regExp = item.regExp.split('|')
    return regExp.some(child => {
      msg = this[child](item) ? false : item[`${child}Msg`] || item.msg || 'error'
      if (msg) {
        other = {
          name: item.name,
          type: child
        }
      }
      return msg
    })
  })
  return Object.assign({ msg }, other)
}

FromVerify.prototype.iphone = function(item) {
  return /^1[2-9]\d{9}$/.test(item.value)
}

FromVerify.prototype.number = function(item) {
  const value = item.value
  if (value === null || value === undefined || value === '') {
    return false
  }
  const regex = new RegExp('^\\d+(\\.\\d+)?$')
  if (!regex.test(value)) {
    return false
  }
  return item.number0 ? true : parseFloat(value) > 0
}

FromVerify.prototype.max = function(item) {
  if (item.max.eq) {
    return item.value * 100 <= item.max.value * 100
  } else {
    return item.value * 100 < item.max * 100
  }
}

FromVerify.prototype.min = function(item) {
  if (item.min.eq) {
    return item.value * 100 >= item.min.value * 100
  } else {
    return item.value * 100 > item.min * 100
  }
}

FromVerify.prototype.minLen = function(item) {
  return item.value && item.value.length > item.minLen
}

FromVerify.prototype.eq = function(item) {
  return item.value === item.eq
}

FromVerify.prototype.required = function(item) {
  return Array.isArray(item.value) ? item.value.length > 0 : !!item.value
}

FromVerify.prototype.email = function(item) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(item.value)
}

export { FromVerify }
