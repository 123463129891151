<template>
  <!-- 选择音视频,建议使用本组件 -->
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :append-to-body="true"
    :close-on-click-modal="false" width="800px">
    <div slot="title">
      {{ title }}
      <span class="ml10 redcolor">{{ prompt }}</span>
    </div>

    <el-tabs v-if="showAudioMenu" @tab-click="tabClick" v-model="post.active">
      <el-tab-pane label="视频" name="1"></el-tab-pane>
      <el-tab-pane label="音频" name="2"></el-tab-pane>
      <el-tab-pane label="直播课回放" name="3"></el-tab-pane>
      <el-tab-pane label="公开课回放" name="4"></el-tab-pane>
    </el-tabs>

    <el-tabs v-if="tabList.length > 0" v-model="tabselect" @tab-click="tabselectChange">
      <el-tab-pane v-for="(item, index) in tabList" :label="item" :name="String(index + 1)" :key="item"></el-tab-pane>
    </el-tabs>

    <div class="selectTeacher mb10" v-if="selection == 2">
      <p class="p2">父标题</p>
      <el-select v-model="psl_id" size="medium" style="width: 100%" placeholder="请选择">
        <el-option v-for="item in optionsFather" :key="item.id" :label="item.name"
          :value="item.correlation_id"></el-option>
      </el-select>
    </div>

    <div class="selectTeacher mb10" v-if="selection == 2">
      <p class="p1">
        上课老师
      </p>
      <el-select size="medium" filterable v-model="teacher_id" style="width: 100%" placeholder="请输入老师名称或选择上课老师">
        <el-option v-for="item in teacherInfo" :key="item.uid" :label="item.uname" :value="item.uid">
          <div style="
              float: left;
              height: 22px;
              width: 22px;
              border-radius: 50%;
              margin-top: 6px;
              background: grey;
            ">
            <img style="height: 22px; width: 22px" :src="item.uphoto" alt="" />
          </div>
          <div style="float: left; margin-left: 10px; font-size: 13px">
            {{ item.uname }}
          </div>
        </el-option>
      </el-select>
    </div>

    <div class="header-box">
      <el-button v-if="post.active != 3 && post.active != 4" type="primary" size="medium"
        @click="$emit('localUpload', post)">
        本地上传
      </el-button>
      <el-button type="text" size="medium" class="ml20" @click="renew">
        刷新列表
      </el-button>
      <div class="header">
        <template v-if="post.active == 1">
          <el-select v-if="!jimiType" style="width: 160px; margin-right: 10px" v-model="post.is_video_encryption"
            placeholder="请选择" size="medium">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 160px; margin-right: 10px" v-model="post.state" placeholder="请选择" size="medium">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </template>
        <el-input style="width: 198px" size="medium" v-model.trim="post.search" placeholder="输入文件名称搜索"></el-input>
      </div>
    </div>
    <div class="selectDialogBox">
      <div class="leftMenu">
        <el-menu :default-active="post.group_index">
          <el-menu-item v-for="(item, index) in MenuTreeData" :index="`${index}`"
            :key="post.active == 1 ? item.video_id : item.audio_id" @click="handleNodeClick(item, index)">
            <div class="my-custom">
              <div style="display: flex">
                <img :src="require('../assets/img/1.4.0.2/icon_wjj备份 9@2x.png')" class="tree-icon" alt="" />
                <span class="label" :title="item.name">
                  {{ item.name }}
                </span>
              </div>
              <span class="span_2" style="color: rgb(165 162 162)">
                {{ item.count ? item.count : '0' }}
              </span>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
      <div style="width: 560px">
        <pagination2 :option="post" url="/DataBank/uploaded" ref="child" :key="post.active" :allFn="true">
          <template v-slot:default="{ tableData }">
            <div class="pagination2Box">
              <el-table ref="table" @selection-change="handleSelectionChange" @row-click="rowClick"
                @sort-change="sortChange" :data="tableData" :header-cell-style="{
    background: 'rgba(245,245,245,1)',
    color: '#333333',
  }" :row-key="getRowkey">
                <el-table-column type="selection" :reserve-selection="true" v-if="selection == 2"></el-table-column>
                <el-table-column label width="35" v-else>
                  <template slot-scope="scope">
                    <el-radio :label="scope.row.video_id ||
    scope.row.audio_id ||
    scope.row.recording_id
    " :disabled="post.active == 1 && scope.row.transcode_status == 3
    " :value="selectionId[0]">
                      &nbsp;
                    </el-radio>
                  </template>
                </el-table-column>

                <el-table-column prop="file_name" label="文件" min-width="220" sortable="custom">
                  <template slot-scope="scope">
                    <div class="TitleVideo">
                      <div class="imgVideo">
                        <lazy class="img" :src="scope.row.video_img ? scope.row.video_img
    : post.active == 3 || post.active == 4 ? require('../assets/img/1.3.9/icon_spbsR.png')
      : require('../assets/img/1.3.9/icon_spbs.png')
    " alt="" />
                        <div class="encryption" v-if="scope.row.zm_spec && scope.row.zm_spec != 5">
                          <span>{{ scope.row.zm_spec | getzm_spec }}</span>
                          <!-- <span v-if="scope.row.zm_spec == 1">720p</span>
                          <span v-else-if="scope.row.zm_spec == 2">1080p</span>
                          <span v-else>-</span> -->
                        </div>
                        <div class="videoTime">
                          <p>
                            {{
    (scope.row.chapter_hour || scope.row.duration)
    | formatTimeLength
  }}
                          </p>
                        </div>
                      </div>
                      <div class="textVideo">
                        <p :title="scope.row.file_name">
                          {{ scope.row.file_name || scope.row.name }}
                        </p>
                        <h4 v-if="post.type == 1 || post.type == 5">上传者: {{ scope.row.uname }}</h4>
                        <h4 v-else-if="scope.row.course_name">
                          课程: {{ scope.row.course_name }}
                        </h4>
                        <h5 v-if="post.active == 1 || post.active == 3 || post.active == 4">
                          <!-- <template v-if="post.active != 4">
                            <img v-if="scope.row.is_video_encryption == 1" src="../assets/img/1.3.9.4/icon_dg.png"
                              alt="" />
                            <img v-else-if="scope.row.is_video_encryption == 2" src="../assets/img/1.3.9.4/icon_cw.png"
                              alt="" />
                            <img style="height: 10px; width: 10px; margin-top: 2px" v-else
                              src="../assets/img/1.3.9.4/icon_zmz.png" alt="" />
                            <span v-if="scope.row.is_video_encryption == 1">
                              已加密
                            </span>
                            <span v-if="scope.row.is_video_encryption == 2">
                              未加密
                            </span>
                            <span v-if="scope.row.is_video_encryption == 3">
                              加密中
                            </span>
                          </template> -->

                          <!-- 1未转码  2已转码  3转码中 -->
                          <span :class="post.active != 4 ? 'ml20' : ''" class="flex-center">
                            <img v-if="scope.row.transcode_status == 2" src="../assets/img/1.3.9.4/icon_dg.png"
                              alt="" />
                            <img v-else-if="scope.row.transcode_status == 1" src="../assets/img/1.3.9.4/icon_cw.png"
                              alt="" />
                            <img v-else style="height: 10px; width: 10px; margin-top: 2px"
                              src="../assets/img/1.3.9.4/icon_zmz.png" alt="" />
                            <span v-if="scope.row.transcode_status == 2">
                              已转码
                            </span>
                            <span v-if="scope.row.transcode_status == 1">
                              未转码
                            </span>
                            <span v-if="scope.row.transcode_status == 3">
                              转码中
                            </span>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="大小/分辨率">
                  <template slot-scope="{ row }">
                    <div>
                      {{ row.size | getSize }}
                    </div>
                    <div v-if="row.zm_spec">
                      <span v-if="row.zm_spec == 1">720P</span>
                      <span v-else>1080P</span>
                    </div>
                    <div v-else>-</div>
                  </template>
                </el-table-column> -->
                <el-table-column prop="add_time" sortable="custom" label="上传时间" v-if="post.type == 1 || post.type == 5">
                  <template slot-scope="scope">
                    {{
    (scope.row.add_time || scope.row.c_time)
    | formatTimeStamp('yyyy-MM-dd hh:mm')
  }}
                  </template>
                </el-table-column>
                <el-table-column prop="add_time" sortable="custom" label="生成时间" v-else>
                  <template slot-scope="scope">
                    {{
      (scope.row.ctime || scope.row.add_time)
                    | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </pagination2>
      </div>
    </div>

    <div style="text-align: right">
      <el-button @click="close" size="medium" style="width: 74px">
        取消
      </el-button>
      <el-button type="primary" size="medium" @click="add" style="width: 74px">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import dataBase from '@/mixin/dataBase'
export default {
  name: 'dataBaseConfirm',

  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '选择录播文件(单选)',
    },
    selection: {
      type: Number,
      default: 1,
    },
    // title中的红色字体提示
    prompt: {
      type: String,
      default: '',
    },
    // 0两个类型 1，单独视频 2，单独音频
    singgleType: {
      type: Number,
      default: 0,
    },
    teacherInfo: {
      type: Object,
    },
    // 选择固定的加密类型 1，已加密 2， 未加密 3，加密中
    jimiType: {
      type: String,
      default: undefined,
    },
    // 视频音频的选择
    showAudioMenu: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [dataBase],

  data() {
    return {
      optionsFather: [],
      MenuTreeData: [],
      teacher_id: '',
      psl_id: '',
      options1: [
        {
          value: '0',
          label: '全部加密状态',
        },
        {
          value: '2',
          label: '未加密',
        },
        {
          value: '1',
          label: '已加密',
        },
        {
          value: '3',
          label: '加密中',
        },
      ],

      options: [
        {
          value: '0',
          label: '全部转码状态',
        },
        {
          value: '1',
          label: '未转码',
        },
        {
          value: '2',
          label: '已转码',
        },
        {
          value: '3',
          label: '转码中',
        },
      ],

      visible: true,

      tableData: [],

      tabselect: '1',

      post: {
        // 录播 1，直播2，公开课 6
        type: 1,
        active: this.singgleType == 2 ? '2' : '1',
        searchType: 1,
        search: '', //搜索内容
        state: '0', // 状态 0全部 1已加密  2未加密  3加密中
        order: '',
        sort: '', //  排序顺序   1正  2倒叙
        is_video_encryption: this.jimiType ? this.jimiType : '0',
        group_id: '0',
        group_index: '0',
      },

      total: 0,

      selectedVideo: '',

      videoData: [],
    }
  },

  filters: {
    getSize(val) {
      if (val / 1024 >= 1) {
        return (val / 1024).toFixed(2) + 'G'
      } else {
        return val + 'MB'
      }
    },
    getzm_spec(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P '
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
        default:
          '-'
      }
      return text
    },
  },

  watch: {
    'post.active': {
      handler(val) {
        this.videoData = []
        this.post.group_id = '0'
        this.post.group_index = '0'
        switch (Number(val)) {
          case 1:
            this.post.type = 1
            break;
          case 2:
            this.post.type = 5
            break;
          case 3:
            this.post.type = 2
            break;
          case 4:
            this.post.type = 6
            break;
        }
        this.$nextTick(this.renew)
      },
      immediate: true,
    },
  },

  computed: {
    selectionId() {
      return this.videoData.map(item => item.serverId)
    },
  },

  created() {
    this.getDirectoryList()
  },

  methods: {
    async getDirectoryList() {
      const { data } = await this.$http({
        url: '/Course/getDirectoryList',
        data: { course_id: this.$route.query.course_id },
      })
      this.optionsFather = data
    },
    tabClick() {
      this.videoData = []
    },

    getRowkey(row) {
      return row.video_id || row.audio_id || row.recording_id
    },

    handleSelectionChange(val) {
      this.videoData = val
    },

    tabselectChange(val) {
      this.post.type = val.name == 2 ? 2 : val.name == 3 ? 6 : 1
      this.renew()
    },
    //刷新列表
    renew() {
      this.getTreeMenu()
      this.$refs.child.reset()
    },

    sortChange(value) {
      var order = 0
      var sort = 0
      switch (value.order) {
        case null:
          break
        case 'descending':
          sort = 2
          break
        case 'ascending':
          sort = 1
          break
      }
      if (value.prop == 'add_time') {
        order = 2
      } else {
        order = 3
      }
      this.post.order = order
      this.post.sort = sort
    },

    handleNodeClick(data, index) {
      this.post.group_id = data.group_id
      this.post.group_index = `${index}`
    },

    getTreeMenu() {
      this.$http({
        url: '/DataBank/groupList',
        data: {
          type: this.post.type,
          is_video_encryption: this.jimiType ? this.jimiType : undefined,
        },
        callback: ({ data }) => {
          this.MenuTreeData = data
        },
      })
    },

    rowClick(row) {
      clearTimeout(this.rowClickTimer)
      this.rowClickTimer = setTimeout(() => {
        // const id = row.video_id || row.audio_id || row.recording_id
        if (this.selection == 1) {
          if (row.transcode_status == 3) {
            this.$root.prompt('转码中的视频不可选')
            return
          }

          this.videoData = []
          this.videoData.push(this.change(row))
        } else {
          // const index1 = this.videoData.findIndex(item => item.serverId == id)
          // if (index1 > -1) {
          //   this.videoData.splice(index1, 1)
          // } else {
          //   this.change(row)
          // }
        }
      }, 100)

      if (this.selection == 2) {
        this.$refs.table.toggleRowSelection(row)
      }
    },
    close() {
      this.visible = false
      setTimeout(() => {
        this.$emit('close')
      }, 300)
    },

    add() {
      if (this.selectionId.length === 0) {
        this.close()
        return
      }
      if (this.selection == 2 && this.teacher_id == '') {
        this.$root.prompt('请选择上课老师')
        return
      }

      if (this.selection == 1) {
        this.$emit('complete', this.videoData[0])
      } else {
        let arr = []
        this.videoData.forEach(item => {
          arr.push(this.change(item))
        })
        this.$emit('complete', arr, this.teacher_id, this.psl_id)
      }
      this.close()
    },

    // 选择视频
    change({
      video_id,
      duration,
      chapter_hour,
      url,
      video_url,
      size,
      video_size,
      file_name,
      name,
      vid,
      zmvidoe_url,
      is_video_encryption,
      zm_spec,
      audio_id,
      recording_id,
    }) {
      // this.videoData.push()
      return {
        serverId: video_id || audio_id || recording_id,
        classHour: chapter_hour || duration,
        url: video_url || url,
        file_size: (video_size || size) * 1024 * 1024,
        file_name: file_name || name,
        vid,
        is_video_encryption,
        zm_spec,
        zmvidoe_url,
        video_id,
        audio_id,
        recording_id,
      }
    },

    // type: 类型 1 音视频 2 回放管理 3 文档
    // sort:  排序   desc 最新 asc 最旧
    // search:  搜索关键字
    getData(data) {
      this.$http({
        url: '/DataBank/uploaded',
        data,
        isMultipleSelf: 'replacePrev',
        callback: ({ data, allNum }) => {
          this.total = Number(allNum)
          this.tableData = data
        },
        error: () => {
          this.total = 0
          this.tableData = []
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-menu {
  border: 0 !important;
}

// 隐藏单选会显示绑定的id
::v-deep .el-radio__label {
  display: none;
}

.redcolor {
  width: 300px;
  height: 12px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #ff3535;
  line-height: 12px;
}

.pagination2Box {
  width: 558px;
  overflow: auto;
  @extend %scroll;
  height: 355px;

  ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #e5e5e5;
  }

  // border-bottom: 1px solid #E4E4E4;
  .className {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    img {
      width: 24px;
      height: 30px;
    }

    p {
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 3px;
    }
  }

  .TitleVideo {
    display: flex;

    .imgVideo {
      background: #000;
      margin-right: 10px;
      width: 141px;
      height: 80px;
      position: relative;
      overflow: hidden;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      .img {
        border-radius: initial;
        margin: 0 auto;
        width: auto;
        height: 100%;
        margin: 0 auto;
      }

      .encryption {
        position: absolute;
        top: 0;
        width: 45px;
        height: 22px;
        border-radius: 0px 0px 11px 0px;
        overflow: hidden;
        background: #fa6400;

        span {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 18px;
          margin-left: 4px;
        }
      }

      .videoTime {
        width: 100%;
        height: 20px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0;
        text-align: center;

        p {
          height: 12px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }

    .textVideo {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      p {
        min-width: 168px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
        overflow: hidden;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h4 {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #999999;
        line-height: 14px;
        margin-top: 15px;
        font-weight: normal;
      }

      h5 {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #999999;
        line-height: 14px;
        margin-top: 13px;
        font-weight: normal;

        img {
          margin-right: 6px;
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}

.header-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

::v-deep .pagination {
  padding: 10px 0;
}

::v-deep .el-dialog__body {
  padding-top: 0px;
}

// .video_tu {
//   width: 106px;
//   height: 60px;
//   background-color: grey;
//   position: relative;
//   .video_tu_font {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     text-align: center;
//     color: #ffffff;
//     background-color: rgba(0, 0, 0, 0.2);
//     font-size: 12px;
//     color: #ffffff;
//     line-height: 20px;
//   }
// }
// .video_right {
//   margin-left: 10px;
//   .video_right_font1 {
//     width: 180px;
//     text-overflow: ellipsis;
//     font-size: 14px;
//     color: #333333;
//     line-height: 14px;
//     white-space: nowrap;
//     overflow: hidden;
//   }
//   .video_right_font2 {
//     font-size: 12px;
//     color: #999999;
//     line-height: 12px;
//   }
// }
.selectDialogBox {
  display: flex;
  position: relative;
  margin-bottom: 10px;

  .leftMenu {
    border: 1px solid #e4e4e4;
    width: 200px;
    overflow: auto;
    height: 396px;
    overflow-x: hidden;
    @extend %scroll;

    ::v-deep .el-tree-node__content {
      height: 36px;
    }

    ::v-deep .el-tree-node__expand-icon {
      font-size: 22px;
      display: none;
    }

    ::v-deep .el-tree-node:focus>.el-tree-node__content {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node__content:hover {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node:focus>.el-tree-node__content {
      background: #ebf8f7;
    }

    .my-custom {
      display: flex;
      margin-left: 10px;
      height: 30px;
      width: 185px;
      justify-content: space-between;

      img {
        width: 14px;
        height: 14px;
        margin-right: 11px;
        margin-top: 7px;
      }

      .label {
        width: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
      }

      .span_2 {
        line-height: 30px;
        text-align: right;
        margin-right: 5px;
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .input {
  text-align: right;

  .el-input {
    width: 198px;
    margin-bottom: 20px;
  }
}

::v-deep .el-menu-item:hover,
.el-menu-item:focus {
  background-color: #f0f8ff;
}

::v-deep .el-menu-item {
  height: 36px;
  line-height: 36px;
  padding-left: 0 !important;
}

::v-deep .el-menu-item.is-active {
  background-color: #ecf5ff;
}

.selectTeacher {
  width: 400px;
  display: flex;
  margin: 10px 0;

  p {
    width: 100px;
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 40px;
  }

  .p1::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }

  .p2::before {
    content: ' ';
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
