<template>
  <div>
    <!--资料库添加-->
    <dataBaseConfirm
      @close="dataBaseConfirmClose"
      @complete="complete"
      @localUpload="showUploadConfirm"
      ref="dataBase"
      :title="title1"
      :prompt="prompt"
      :selection="selection"
      :jimiType="jiamiType"
      :teacherInfo="teacherInfo"
      :showAudioMenu="showAudioMenu"
      :tabList="tabList"
      :singgleType="singgleType"
    >
  </dataBaseConfirm>
    <!--上传视频-->
    <videoUpload
      v-if="uploadStart1"
      :leftStorage="leftStorage"
      :list="group_id"
      @close="uploadClose"
      @uploadConfirmClose="uploadClose"
      @getJgInfo="getJgInfo"
      :menuName="isMenuName"
      :typeselection="1"
      title=""
    ></videoUpload>
    <!--上传音频-->
    <audioUpload
      v-if="uploadStart2"
      :leftStorage="leftStorage"
      :list="group_id"
      @close="uploadClose"
      @uploadConfirmClose="uploadClose"
      @getJgInfo="getJgInfo"
      :menuName="isMenuName"
      :typeselection="1"
      :title="'上传音频(多选)'"
      :uploadType="3"
    ></audioUpload>
  </div>
</template>
<script>
import dataBaseConfirm from '@cm/dataBaseConfirm'
import videoUpload from '@cm/base/UploadFile'
import audioUpload from '@cm/base/UploadtextFile'

export default {
  name: 'fileListAndFileUpload',

  props: {
    title1: String,
    tabList: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: Number,
      default: 1,
    },
    prompt: {
      type: String,
      default: '',
    },
    //加密类型- 智能选择智能选择未加密的视频
    jiamiType: {
      type: String,
      default: undefined,
    },
    singgleType: {
      type: Number,
      default: 0,
    },
    showAudioMenu: {
      type: Boolean,
      default: true,
    },
    teacherInfo: {
      type: Object,
    },
  },

  components: {
    dataBaseConfirm,
    videoUpload,
    audioUpload,
  },

  data() {
    return {
      uploadStart1: false,
      uploadStart2: false,
      isMenuName: '全部视频',

      group_id: '',
    }
  },

  created() {
    this.getJgInfo()
  },

  methods: {
    // 获取机构存储空间
    getJgInfo() {
      this.$http({
        url: '/User/jigouAuth',
        isMultipleSelf: 'replacePrev',
        callback: ({ data: { useStorage, jigouStorage, leftStorage } }) => {
          this.useStorage = useStorage
          this.jigouStorage = jigouStorage
          this.leftStorage = Math.floor(leftStorage * 100) / 100
          this.$store.commit('setLeftStorage', leftStorage)
        },
      })
    },

    // 上传弹窗开启
    showUploadConfirm(val) {
      this[`uploadStart${val.active}`] = !this[`uploadStart${val.active}`]
      this.group_id = val.group_id
    },

    // 上传弹窗关闭
    uploadClose() {
      this.uploadStart1 = false
      this.uploadStart2 = false
      this.$refs.dataBase.renew()
    },

    // 选择视频 音频弹窗关闭
    dataBaseConfirmClose() {
      this.$emit('close')
    },

    // 上传视频完成
    complete() {
      this.$emit('complete', ...arguments)
    },
  },
}
</script>
<style scoped></style>
