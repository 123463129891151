<template>
  <div id="selectCourse">
    <div class="selectCourse">
      <el-button
        type="primary"
        class="mr20"
        @click="chooseCourse"
        style="width: 100px"
        size="medium"
      >
        选择课程
      </el-button>

      <span class="sp_1">
        提示：关联课程后会在课程详情的【课程推荐】展示，报名当前课程后，关联的课程需要单独购买
      </span>
    </div>
    <!-- {{ course }} -->
    <div class="had_course_list">
      <transition-group class="ul" tag="ul" name="flip-list">
        <li class="item" v-for="(item, index) in course" :key="item.course_id">
          <div class="tu_name">
            <div class="tu">
              <img :src="item.photo" alt="" />
            </div>
            <div class="name" :title="item.name">{{ item.name }}</div>
          </div>
          <div class="course_type">{{ item.course_type | getCoursetype }}</div>
          <div class="price">
            <span v-if="item.price_type == 1" :title="item.price">
              ￥{{ item.price }}
            </span>
            <span v-else-if="item.price_type == 2">免费</span>
            <span v-else-if="item.price_type == 3">密码</span>
          </div>
          <div class="btns">
            <div class="no_upper" v-if="index == 0">上移</div>
            <div v-else class="cp" @click="upDown(index, index - 1)">上移</div>
            <div class="driver">|</div>

            <div class="no_downer" v-if="index == course.length - 1">下移</div>
            <div v-else class="cp" @click="upDown(index, index + 1)">下移</div>
            <div class="driver">|</div>
            <el-popconfirm title="确定移除该内容？" @confirm="removeSell(item)">
              <div class="cp" slot="reference">移除</div>
            </el-popconfirm>
          </div>
        </li>
      </transition-group>
    </div>
    <!-- 课程选择 -->
    <el-dialog
      title="选择课程"
      width="800px"
      :visible.sync="dialogSelectVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div slot="title">
        选择课程
        <span style="color: #ff3535; font-size: 13px">(多选)</span>
      </div>
      <dialog-selectcourse
        ref="dialogRef"
        v-if="dialogSelectVisible"
        :type="2"
      ></dialog-selectcourse>
    </el-dialog>
  </div>
</template>

<script>
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
export default {
  name: 'selectCourse',
  data() {
    return {
      dialogSelectVisible: false,
      course: [],
    }
  },

  mounted() {
    this.getCourseSell()
  },

  components: { dialogSelectcourse },

  filters: {
    getCoursetype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        default:
          text = '系列课'
          break
      }
      return text
    },
  },

  methods: {
    // 上移下移
    upDown(index, newIndex) {
      const oldData = this.course[newIndex]
      this.course.splice(newIndex, 1, this.course[index])
      this.course.splice(index, 1, oldData)

      this.$http({
        url: '/course/moveSell',
        data: {
          id1: this.course[index].id,
          id2: this.course[newIndex].id,
          type: 1,
        },
        callback: () => {},
      })
    },

    //选择课程
    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef.getMenu(_this.course, _this.member_id)
        _this.$refs.dialogRef.clearSelect()
      })
    },

    cancelDialog() {
      this.dialogSelectVisible = false
    },

    // 移除关联的课程
    removeSell(val) {
      this.$http({
        url: '/course/removeSell',
        data: {
          type: 1,
          id: val.id,
        },
        callback: () => {
          this.getCourseSell()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },

    // 获取已经关联的数据
    getCourseSell() {
      this.$http({
        url: '/course/getCourseSell',
        data: {
          type: 1,
          course_id: this.$route.query.course_id,
        },
        callback: ({ data }) => {
          this.course = data
        },
      })
    },

    gbDialog(list) {
      //搭配组件使用的方法,关闭弹出框
      this.dialogSelectVisible = false
      // 获取新添加的数据
      const val = []
      for (let value of list.values()) {
        val.push(value)
      }

      this.$http({
        url: '/course/addCourseSell',
        data: {
          type: 1,
          course_id: this.$route.query.course_id,
          course: val,
        },
        callback: () => {
          this.getCourseSell()
          this.$root.prompt({
            msg: '操作成功',
            type: 'success',
          })
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.35s;
}
#selectCourse {
  img {
    width: 100%;
    height: 100%;
  }
  .selectCourse {
    margin-bottom: 20px;
    .sp_1 {
      font-size: 12px;
      color: #333333;
      line-height: 12px;
    }
  }
  .had_course_list {
    .ul {
      .item {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 13px;
        .tu_name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: 10px;
          .tu {
            width: 40px;
            height: 23px;
          }
          .name {
            width: calc(100% - 50px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #333333;
            margin-left: 10px;
          }
        }
        .course_type {
          margin-right: 10px;
          color: #333333;
        }
        .price {
          text-align: center;
          width: 110px;
          margin-left: 10px;
          margin-right: 10px;
          color: #ff3535;
        }
        .btns {
          display: flex;
          color: $color;
          .no_upper,
          .no_downer {
            color: #999999;
          }
          .driver {
            margin: 0 5px;
          }
        }
      }
    }
  }
}
</style>