/*
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-30 10:33:03
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-06-27 10:21:41
 * @FilePath: \teacher.dingdingkaike\src\mixin\tencentCaptcha.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 判断是否为本地环境
import getEnv from '@/config/getEnv'

export default {
  created() {
    if (!document.querySelector('#TCaptcha')) {
      this._createTencentCaptcha()
    } else {
      this._newTencentCaptcha()
    }
  },

  methods: {
    _createTencentCaptcha() {
      // 插入验证码js
      const script = document.createElement('script')
      script.id = 'TCaptcha'
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      document.body.appendChild(script)

      script.onload = this._newTencentCaptcha
      script.onerror = () => {
        document.body.removeChild(document.querySelector('#TCaptcha'))
      }
    },

    _newTencentCaptcha() {
      let appID = ''
      if (getEnv() == 'localhost' || getEnv() == 'test') {
        appID = '2021553796'
      } else {
        appID = '2002661747'
      }
      const callback = () => {
        this.Captcha = new window.TencentCaptcha(appID, res => {
          this.tencentCaptchaCallback(res)
        })
      }
      try {
        callback()
      } catch (e) {
        document.querySelector('#TCaptcha').addEventListener('load', callback)
      }
    },
  },
}
