<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-10-08 14:15:02
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\components\course\AssociatedSelling\AssociatedSelling.vue
-->
<template>
  <div id="AssociatedSelling">
    <select-course v-if="activeName == 4"></select-course>
    <select-data v-if="activeName == 9"></select-data>
  </div>
</template>

<script>
import selectCourse from './selectCourse'
import selectData from './selectData'
export default {
  name: 'AssociatedSelling',
  data() {
    return {}
  },

  props: {
    activeName: [Number, String],
  },

  components: {
    selectCourse,
    selectData,
  },
}
</script>

<style lang="scss" scoped>
#AssociatedSelling {
  margin: 20px;
  margin-left: 0;
  ::v-deep .tabs {
    .el-tabs__nav-scroll {
      padding: 0;
    }
    .el-tabs__item {
      height: 36px !important;
      line-height: 36px !important;
      width: 100px;
      text-align: center;
    }
    .el-tabs__header {
      margin-bottom: 30px;
    }
  }
}
</style>
