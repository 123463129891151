<!--
 * @Descripttion: 新建分类弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 11:19:03
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:19:50
 -->
<template>
  <CourseConfirm :title="`${isEdit ? '编辑客服' : '新建客服'}`" :isShowBtn="true" @cancelFn="closeFn" @save="selectCustomer">
    <div class="inner-slot">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" label-position="left" @submit.native.prevent>
        <!-- 引导语 -->
        <el-form-item label="引导语">
          <div class="input-wrap">
            <el-input placeholder="长按识别二维码，加客服微信" v-model="ruleForm.content" maxlength="30" show-word-limit></el-input>
          </div>
        </el-form-item>
        <el-form-item label="上传二维码" prop="coursePic">
          <div class="course-pic-wrap">
            <img class="result-pic" :src="ruleForm.qr_code_url" v-if="ruleForm.qr_code_url" />
            <template v-else>
              <uploadImg :isShowLittleIcon="false" @getResultImg="getResultImg" :isSortIcon="true" ext="?imageView2/1/w/400/h/400">
                <div class="add-pic-btn">+</div>
              </uploadImg>
            </template>
            <div class="p-wrap">
              <p class="txt">建议尺寸400*400px，JPG、PNG格式， 图片小于2M</p>
              <p class="btn-wrap">
                <uploadImg :isShowLittleIcon="false" @getResultImg="getResultImg" :isSortIcon="true" ext="?imageView2/1/w/400/h/400">
                  <el-button type="info" size="small" plain>选择文件</el-button>
                </uploadImg>
              </p>
            </div>
          </div>
        </el-form-item>
        <!--父类标题-->
        <el-form-item label="客服昵称" prop="name">
          <div class="input-wrap">
            <el-input v-model="ruleForm.name" placeholder="输入客服昵称" maxlength="8" show-word-limit></el-input>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </CourseConfirm>
</template>
<script>
import CourseConfirm from '@/components/CourseConfirm'
import uploadImg from '@/components/UploadOneImg'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'

export default {
  name: 'newCustomerPop',
  props: {
    isEdit: {
      type: Boolean
      // default: false
    },
    customer_id: String
  },
  mixins: [pageOverflow],
  data() {
    return {
      ruleForm: {
        // qr_code_url: "", //二维码图片
        // name: "",  //引导语
        // content: ""  //关注文字内容
        qr_code_url: '', //二维码图片
        name: '', //客服昵称
        content: '' //关注文字内容
      }
    }
  },
  components: {
    CourseConfirm,
    uploadImg
  },
  inject: ['getCustomerList'],
  methods: {
    closeFn() {
      this.$emit('closeFn')
    },
    getResultImg(img_url) {
      this.ruleForm.qr_code_url = `${img_url.split('?')[0]}?imageView2/1/w/400/h/400`
    },

    // 新建客服
    addCustomer() {
      const self = this
      if (!self.ruleForm.content) {
        self.$root.prompt('请先填写引导语')
        return
      }
      if (!self.ruleForm.qr_code_url) {
        self.$root.prompt('请先选择图片')
        return
      }
      if (!self.ruleForm.name) {
        self.$root.prompt('请先填写客服昵称')
        return
      }
      const data = this.ruleForm
      self.$http({
        name: API.insertCustomer_Api.name,
        url: API.insertCustomer_Api.url,
        data,
        callback(res) {
          if (res.code == 200) {
            self.getCustomerList()
            self.$root.prompt({
              msg: '创建成功',
              type: 'success'
            })
            self.closeFn()
          }
        }
      })
    },

    // 获取单个客服信息
    editCustomer(val) {
      const self = this
      // const data = this.ruleForm;
      self.$http({
        name: API.editCustomer_Api.name,
        url: API.editCustomer_Api.url + '?customer_id=' + val,
        callback(res) {
          if (res.code == 200) {
            //   qr_code_url: "", //二维码图片
            //   name: "", //客服昵称
            //   content: "" //关注文字内容
            self.ruleForm.qr_code_url = res.data.qr_code_url
            self.ruleForm.name = res.data.name
            self.ruleForm.content = res.data.content
          }
        }
      })
    },

    // 编辑更新单个客服信息
    updataCustomer() {
      const self = this
      const data = Object.assign({}, self.ruleForm, {
        customer_id: self.customer_id
      })
      if (!data.content) {
        self.$root.prompt('请先填写引导语')
        return
      }
      if (!data.qr_code_url) {
        self.$root.prompt('请先选择图片')
        return
      }
      if (!data.name) {
        self.$root.prompt('请先填写客服昵称')
        return
      }
      self.$http({
        name: API.editCustomer_Api.name,
        url: API.editCustomer_Api.url,
        data,
        callback(res) {
          if (res.code == 200) {
            self.getCustomerList()
            self.$root.prompt({
              msg: '更新成功',
              type: 'success'
            })
            self.closeFn()
          }
        }
      })
    },
    // 更新或者新建客服
    selectCustomer() {
      if (this.isEdit) {
        this.updataCustomer()
      } else {
        this.addCustomer()
      }
    }
  },

  mounted() {
    //  编辑客服
    if (this.isEdit) {
      this.editCustomer(this.customer_id)
    }
  }
}
</script>
<style lang="scss" scoped>
.inner-slot {
  padding: 30px 20px 182px;
  .el-input {
    width: 518px;
  }
  // ::v-deep.el-select,
  // ::v-deep.el-input__inner {
  //   width: 518px;
  // }
  ::v-deep.el-form-item {
    .letter-length {
      position: absolute;
      left: 488px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .course-pic-wrap {
    display: flex;
    .add-pic-btn {
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      background: #f5f5f5;
      font-size: 20px;
      color: #ccc;
    }
    .result-pic {
      width: 120px;
      height: 120px;
    }
    .p-wrap {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      .txt {
        margin-bottom: 22px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 16px;
      }
    }
  }
}
</style>
