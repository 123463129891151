<template>
  <div class="huabei-issue">
    <div class="issues">
      <label class="item" @click="prompt">
        <el-switch
          :value="is_installment_3"
          @change="$emit('update:is_installment_3', arguments[0])"
          active-value="1"
          inactive-value="2"
          :disabled="disabled"
        ></el-switch>
        <span class="issue-text">3期分期 1.80%</span>
      </label>
      <label class="item" @click="prompt">
        <el-switch
          :value="is_installment_6"
          @change="$emit('update:is_installment_6', arguments[0])"
          active-value="1"
          inactive-value="2"
          :disabled="disabled"
        ></el-switch>
        <span class="issue-text">6期分期 4.50%</span>
      </label>
      <label class="item" @click="prompt">
        <el-switch
          :value="is_installment_12"
          @change="$emit('update:is_installment_12', arguments[0])"
          active-value="1"
          inactive-value="2"
          :disabled="disabled"
        ></el-switch>
        <span class="issue-text">12期分期 7.50%</span>
      </label>
    </div>
    <div class="issue-tips" v-if="is_alipay_flowers">
      <span class="tips">
        提示：开启分期后，分期费率由学员承担，如需免息分期，可在营销中心
      </span>
      <span class="create-btn" @click="goTo('/installment')">创建免息券</span>
    </div>
    <div class="issue-tips" v-else>
      <span class="tips">
        提示：您的网校未开通花呗分期支付，无法开启开关，请在网校设置中
      </span>
      <span class="create-btn" @click="goTo('/setting/paymentSetting')">
        开通花呗分期
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'huabeiIssue',

  props: {
    price: [String, Number],
    is_installment_3: String,
    is_installment_6: String,
    is_installment_12: String,
  },

  data() {
    return {}
  },

  computed: {
    disabled() {
      return (
        !this.$store.state.userInfo.is_alipay_flowers ||
        !this.price ||
        this.price < 100
      )
    },

    is_alipay_flowers() {
      return this.$store.state.userInfo.is_alipay_flowers
    },
  },

  watch: {
    disabled(val) {
      if (val) {
        this.$emit('update:is_installment_3', '2')
        this.$emit('update:is_installment_6', '2')
        this.$emit('update:is_installment_12', '2')
      }
    },
  },

  methods: {
    goTo(path) {
      const url = this.$router.resolve({
        path,
      })
      this.$store.dispatch('open', url.href)
    },

    // 提示信息
    prompt() { 
      // 未输入金额时（划线价可为空），不能开启开关，点击开关提示：请先设置售价金额，分期金额需大于100元
      // 金额小于100元时，不能开启开关，点击开关提示：大于100元可开启分期支付
      if (!this.is_alipay_flowers) {
        this.$root.prompt('请先开通花呗分期支付！')
      } else if (!this.price || this.price <= 0) {
        this.$root.prompt('请先设置售价金额，分期金额需大于100元')
      } else if (this.price < 100) {
        this.$root.prompt('大于100元可开启分期支付')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.huabei-issue {
  margin-top: 20px;
  .issues {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      .issue-text {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
        line-height: 1.5;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  .issue-tips {
    display: flex;
    line-height: 1.5;
    margin-top: 10px;
    align-items: center;
    .tips {
      font-size: 12px;
      color: #ff3535;
    }
    .create-btn {
      font-size: 12px;
      color: #0aa29b;
      margin-left: 10px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
