<!--
 * @Descripttion: 选择视频弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 09:45:29
 * @LastEditors: cyy
 * @LastEditTime: 2022-03-28 16:18:43
 -->
<template>
  <CourseConfirm
    title="分享"
    :isShowBtn="false"
    :isFromShare="true"
    @cancelFn="closeFn"
  >
    <div class="inner-slot">
      <div class="in-left">
        <div class="left-part">
          <t3
            ref="poster3"
            v-show="templateId == 3"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t3>
          <t1
            ref="poster1"
            v-show="templateId == 1"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t1>
          <t2
            ref="poster2"
            v-show="templateId == 2"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t2>
          <t4
            ref="poster4"
            v-show="templateId == 4"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t4>
          <t5
            ref="poster5"
            v-show="templateId == 5"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t5>
          <t6
            ref="poster6"
            v-show="templateId == 6"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t6>
          <t7
            ref="poster7"
            v-show="templateId == 7"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t7>
          <t8
            ref="poster8"
            v-show="templateId == 8"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t8>
          <!-- <t9 ref="poster9" v-show="templateId == 9" :posterData="shareInfo" :type="type" @createEwm="createEwm"></t9> -->
          <t10
            ref="poster10"
            v-show="templateId == 10"
            :posterData="shareInfo"
            :type="type"
            @createEwm="createEwm"
          ></t10>
          <div class="result-pic" v-if="resultPic">
            <img :src="resultPic" />
          </div>
        </div>
        <p v-if="!isWindowsApp" class="download-wrap">
          <span>
            <a download="poster.jpg" :href="resultPic">下载海报</a>
          </span>
          <span class="cp" @click="downEwm">下载二维码</span>
        </p>
      </div>
      <div class="right-part">
        <div class="top-box">
          <div class="link-wrap ellipsis-lines">
            【{{ shareInfo.name }}】{{ shareInfo.new_cabout }}
            <br />
            {{ shareInfo.longUrl }}
          </div>
          <div class="txt-in-right">
            <span class="cp" @click="getUrl()">获取短链接</span>
            <span>|</span>
            <span class="cp for-copy" :data-clipboard-text="shareInfo.longUrl">
              复制链接
            </span>
          </div>
        </div>
        <ul class="templates-wrap">
          <li
            class="a-template at3 cp"
            :class="{ active: templateId == 3 }"
            @click="changeTemplateId(3)"
          ></li>
          <li
            class="a-template at1 cp"
            :class="{ active: templateId == 1 }"
            @click="changeTemplateId(1)"
          ></li>
          <li
            class="a-template at2 cp"
            :class="{ active: templateId == 2 }"
            @click="changeTemplateId(2)"
          ></li>
          <li
            class="a-template at4 cp"
            :class="{ active: templateId == 4 }"
            @click="changeTemplateId(4)"
          ></li>
          <li
            class="a-template at5 cp"
            :class="{ active: templateId == 5 }"
            @click="changeTemplateId(5)"
          ></li>
          <li
            class="a-template at6 cp"
            :class="{ active: templateId == 6 }"
            @click="changeTemplateId(6)"
          ></li>
          <li
            class="a-template at7 cp"
            :class="{ active: templateId == 7 }"
            @click="changeTemplateId(7)"
          ></li>
          <li
            class="a-template at8 cp"
            :class="{ active: templateId == 8 }"
            @click="changeTemplateId(8)"
          ></li>
          <!-- <li
            class="a-template at9 cp"
            :class="{ active: templateId == 9 }"
            @click="changeTemplateId(9)"
          ></li> -->
          <li
            class="a-template at10 cp"
            :class="{ active: templateId == 10 }"
            @click="changeTemplateId(10)"
          ></li>
        </ul>
      </div>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="422px"
      :modal="false"
      top="25vh"
    >
      <span style="margin-left: 20px">
        短链接在PC端无法正常访问，请谨慎使用！
      </span>
      <p style="margin-left: 20px; margin-top: 20px">{{ shareInfo.erweima }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          class="buttonCopy"
          @click="dialogVisible = false"
          :data-clipboard-text="shareInfo.erweima"
        >
          复制并关闭
        </el-button>
      </span>
    </el-dialog>
  </CourseConfirm>
</template>
<script>
import html2canvas from 'html2canvas'
import ClipboardJS from 'clipboard'
import CourseConfirm from '@/components/CourseConfirm'
import QRCode from '@/assets/js/qrcode'
import t1 from './templates/template1'
import t2 from './templates/template2'
import t3 from './templates/Template3'
import t4 from './templates/Template4'
import t5 from './templates/Template5'
import t6 from './templates/Template6'
import t7 from './templates/Template7'
import t8 from './templates/Template8'
import t10 from './templates/Template10'

import pageOverflow from '@/mixin/pageOverflow'
export default {
  name: 'editSection',
  data() {
    return {
      templateId: 3,

      resultPic: '',
      
      dialogVisible: false,
    }
  },

  computed: {
    isWindowsApp() {
      return this.$store.state.isWindowsApp
    },
  },

  props: {
    shareInfo: Object,
    type: {
      type: String,
      default: '1', //默认是分享课程,2是资料分享
    },
  },
  mixins: [pageOverflow],
  components: {
    CourseConfirm,
    t1,
    t2,
    t3,
    t4,
    t5,
    t6,
    t7,
    t8,
    t10,
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(this.create())
    }, 50)
  },

  methods: {
    // 下载二维码
    downEwm() {
      const div = document.createElement('div')
      const imgUrl = new QRCode(div, {
        text: this.shareInfo.erweima,
        width: 300,
        height: 300,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      }).getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },
    
    closeFn() {
      this.$emit('closeFn')
    },

    changeTemplateId(id) {
      if (this.templateId == id) {
        return
      }
      this.templateId = id
      this.$nextTick(this.create)
    },

    create() {
      window.scrollTo(0, 0)
      html2canvas(this.$refs[`poster${this.templateId}`].$el, {
        useCORS: true,
        logging: false,
      })
        .then((canvas) => {
          this.resultPic = canvas.toDataURL('image/png')
        })
        .catch((res) => {
          console.log(res)
        })
      this.initCopy()
      this.initCopy_1()
    },

    initCopy_1() {
      const self = this
      let clipboard = new ClipboardJS('.buttonCopy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    getUrl() {
      this.dialogVisible = true
    },

    // 生成二维码
    createEwm(multiple, node) {
      const size = 20 * multiple
      this.qrcode = new QRCode(node, {
        text: this.shareInfo.erweima,
        width: size,
        height: size,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.inner-slot {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px 0 20px;

  .in-left {
    width: 300px;
  }

  .left-part {
    width: 100%;
    height: 459px;
    position: relative;
  }

  .right-part {
    width: 443px;
    height: 459px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-box {
      .link-wrap {
        max-height: 153px;
        padding: 18px 20px;
        font-size: 12px;
        color: #333;
        line-height: 20px;
        text-align: justify;
        background: #f5f5f5;
        word-break: break-all;
      }

      .txt-in-right {
        margin-top: 10px;
        text-align: right;
      }

      span {
        font-size: 14px;
        color: #0aa29b;
      }
    }

    .templates-wrap {
      width: 420px;
      display: flex;
      flex-wrap: wrap;

      .a-template {
        width: 60px;
        height: 60px;
        margin-left: 20px;
        margin-bottom: 20px;
        border: 2px solid #fff;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.4;
        border-radius: 4px;

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          margin-bottom: 0;
        }
      }

      .at1 {
        background-image: url('https://img.dingdingkaike.com/FqceSGBtu2CJA-jjvg15C1HArn1V?imageslim');
      }

      .at2 {
        background-image: url('https://img.dingdingkaike.com/FkLfqrcIo4pkiluNnJI_Yxon3oI9?imageslim');
      }

      .at3 {
        background-image: url('https://img.dingdingkaike.com/FtnAkxLcIkZfuXbWxe1RIxJqAcWi?imageslim');
      }

      .at4 {
        background-image: url('https://img.dingdingkaike.com/FqAMlgujD4MSqj-N8WA2OaaowUqY?imageslim');
      }

      .at5 {
        background-image: url('https://img.dingdingkaike.com/FtPxv1bKUBSBJYTrhGZG5fHcfXVx?imageslim');
      }

      .at6 {
        background-image: url('https://img.dingdingkaike.com/Fpe-9Zrck5VjQzwZGa2HvKi02LDg?imageslim');
      }

      .at7 {
        background-image: url('https://img.dingdingkaike.com/FoFgrWW8zezMIg3R_YQOkgnDIHji?imageslim');
      }

      .at8 {
        background-image: url('https://img.dingdingkaike.com/FpmbTC376udWu-2pWsZKPuNHt9FF?imageslim');
      }

      .at9 {
        background-image: url('https://img.dingdingkaike.com/FmlfDMyxxdbphBNGTFpbL5zPpjrd?imageslim');
      }

      .at10 {
        background-image: url('https://img.dingdingkaike.com/FvxMI8xPo-DfaepgZElMQT1kqySd?imageslim');
      }

      .active {
        border: 2px solid #0aa29b;
        opacity: 1;
      }
    }
  }

  .download-wrap {
    display: flex;
    justify-content: space-around;
    padding: 17px 30px 0 30px;

    span {
      font-size: 14px;
      color: #0aa29b;

      a {
        text-decoration: none;
        color: #0aa29b;
      }
    }
  }
}

.result-pic {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  // transform: scale(0.86);
  // transform-origin: 0 0 0;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
