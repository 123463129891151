<template>
  <div class="absolute" ref="tree"></div>
</template>
<script>
import echarts from 'echarts'

export default {
  name: 'treeChart',

  props: {
    lists: Array,
    options: Object,
    alone: Boolean, // 是否是一个数据条
    float: Boolean
  },

  watch: {
    lists: {
      handler(value) {
        if (value && value.length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      immediate: true
    },

    options: {
      handler(value) {
        if (value && Object.keys(value).length > 0 && this.myChart) {
          this.myChart.setOption(this.getOptionData(), true, false)
        }
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {
    this.init()
  },

  destroyed() {
    this.offResize()
    this.myChart.dispose()
    this.myChart = null
  },

  methods: {
    onResize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },

    offResize() {
      window.removeEventListener('resize', this.onResize)
    },

    // 获取option参数
    getOptionData() {
      let defaultStyle = {}

      // 默认的样式
      if (!this.options) {
        const barBaseOption = {
          type: 'bar',
          barWidth: 14,
          lineStyle: { color: '#226bea' }
        }

        const series = [Object.assign({}, barBaseOption, { itemStyle: { color: '#0093F8' } })]

        if (!this.alone) {
          series.push(Object.assign({}, barBaseOption, { itemStyle: { color: '#FED74C' } }))
        }

        defaultStyle = {
          dataset: {
            source: this.lists
          },
          series
        }
      }

      return _.merge(
        {},
        {
          legend: {
            bottom: 0,
            itemGap: 30,
            left: 'center',
            orient: 'horizontal',
            textStyle: {
              color: '#999'
            }
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#D8D8D8',
                width: 1
              }
            },
            axisLabel: {
              fontSize: 12,
              color: '#333'
            }
          },
          yAxis: {
            max: value => {
              return value.max > 4 || this.float ? null : value.max + 4
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#D8D8D8'
              }
            },
            axisLine: {
              show: false,
              onZero: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 12,
              color: '#333',
              formatter(value) {
                if (value >= 1000 && value < 10000) {
                  value = value / 1000 + '千'
                } else if (value >= 10000 && value < 10000000) {
                  value = value / 10000 + '万'
                } else if (value >= 10000000 && value < 1000000000) {
                  value = value / 10000000 + '千万'
                } else if (value >= 1000000000) {
                  value = value / 1000000000 + '亿'
                }
                return value
              }
            }
          }
        },
        defaultStyle,
        this.options
      )
    },

    init() {
      const myChart = (this.myChart = echarts.init(this.$refs.tree))

      this.offResize()
      window.addEventListener('resize', this.onResize)

      const option = this.getOptionData()

      myChart.setOption(option)
    }
  }
}
</script>
