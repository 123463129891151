<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2019-10-29 10:21:03
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-11-01 11:20:01
 -->
<template>
  <div @click.prevent="getVerifyCode">
    <slot></slot>
    <loading v-show="isLoading" leftPos="0"></loading>
  </div>
</template>
<script>
import { FromVerify } from '@/assets/js/fromVerify'
import loading from '@/components/LoadGif'
import tencentCaptcha from '@/mixin/tencentCaptcha'

export default {
  name: 'GetVerifyCode',

  mixins: [tencentCaptcha],

  components: {
    loading,
  },

  props: {
    isbind: {
      type: Number,
      default: 0,
    },
    befervertify: {
      type: Number,
      default: 0,
    },
    type: Number,
    value: String,
    url: {
      type: String,
      default: '/Message/sendVerification',
    },
    otherWay: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      nextTime: 0,
      isLoading: false,
    }
  },
  methods: {
    // 获取验证码
    async getVerifyCode() {
      if (this.nextTime > 0) return

      const msg = new FromVerify([
        {
          regExp: 'required|iphone',
          requiredMsg: '请输入手机号码',
          iphoneMsg: '手机号格式有误，请检查！',
          value: this.value,
        },
      ]).msg

      if (msg && this.otherWay) {
        this.$root.prompt(msg)
        return
      }

      if (msg && !this.otherWay) {
        this.$emit('getPhoneError', this.value)
        return
      }

      if (this.isbind == 1) {
        await this.$http({
          name: 'check',
          url: '/PcLogin/check',
          data: {
            mch_mobile: this.value,
            type: this.befervertify,
          },
        })
      }

      try {
        this.Captcha.show()
      } catch (e) {
        this.$root.prompt('加载验证码失败，请检查网络或刷新重试')
      }
    },

    tencentCaptchaCallback(res) {
      if (!res.ticket) {
        return
      }

      this.isLoading = true

      this.$root.$http({
        isLoading: true,
        loadingText: '发送中...',
        name: 'sendVerification',
        url: this.url,
        data: {
          type: this.type,
          mch_mobile: this.value,
          Ticket: res.ticket,
          Randstr: res.randstr,
        },
        callback: () => {
          this.times(60)
        },
        error: () => {
          this.isLoading = false
        },
      })
    },

    times(value) {
      this.nextTime = value
      this.isLoading = false
      this.getCodeTimes = setInterval(() => {
        this.nextTime -= 1
        this.$emit('callback', this.nextTime)
        if (this.nextTime < 1) {
          clearInterval(this.getCodeTimes)
        }
      }, 1000)
    },
  },
}
</script>
