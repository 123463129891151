<!--
 * @Descripttion: 新建分类弹窗
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 11:19:03
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:25:23
 -->
<template>
  <CourseConfirm title="设置二维码" :isShowBtn="true" @cancelFn="closeFn" @save="returnInfo">
    <div class="inner-slot">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" label-position="left" @submit.native.prevent>
        <!-- 引导语 -->
        <el-form-item label="引导语">
          <div class="input-wrap">
            <el-input placeholder="长按识别二维码，关注公众号" v-model="ruleForm.content" maxlength="30" show-word-limit></el-input>
          </div>
        </el-form-item>
        <el-form-item label="上传二维码" prop="coursePic">
          <div class="course-pic-wrap">
            <img class="result-pic" :src="ruleForm.qr_code_url" v-if="ruleForm.qr_code_url" />
            <template v-else>
              <uploadImg :isShowLittleIcon="false" @getResultImg="getResultImg" :isSortIcon="true" ext="?imageView2/1/w/400/h/400">
                <div class="add-pic-btn">+</div>
              </uploadImg>
            </template>
            <div class="p-wrap">
              <p class="txt">建议尺寸400*400px，JPG、PNG格式， 图片小于2M</p>
              <p class="btn-wrap">
                <uploadImg :isShowLittleIcon="false" @getResultImg="getResultImg" :isSortIcon="true" ext="?imageView2/1/w/400/h/400">
                  <el-button type="info" size="small" plain>选择文件</el-button>
                </uploadImg>
              </p>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </CourseConfirm>
</template>
<script>
import CourseConfirm from '@/components/CourseConfirm'
import uploadImg from '@/components/UploadOneImg'
import pageOverflow from '@/mixin/pageOverflow'

export default {
  name: 'newCustomerPop',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isShowSettingCode: [String, Number],
    info: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      ruleForm: {
        qr_code_url: '', //二维码图片
        content: '' //关注文字内容
      }
    }
  },
  mixins: [pageOverflow],
  created() {
    if (this.info) {
      this.ruleForm.qr_code_url = this.info.qr_code_url
      this.ruleForm.content = this.info.content
    }
  },
  components: {
    CourseConfirm,
    uploadImg
  },
  methods: {
    closeFn() {
      this.$emit('closeFn')
    },
    getResultImg(img_url) {
      this.ruleForm.qr_code_url = `${img_url.split('?')[0]}?imageView2/1/w/400/h/400`
    },
    //返回信息回父组件
    returnInfo() {
      if (!this.ruleForm.content) {
        this.$root.prompt('请先填写引导语')
        return
      }
      if (!this.ruleForm.qr_code_url) {
        this.$root.prompt('请先选择二维码')
        return
      }
      this.$emit('getCodeInfo', this.ruleForm, this.isShowSettingCode)
      this.closeFn()
    }
  }
}
</script>
<style lang="scss" scoped>
.inner-slot {
  padding: 30px 20px 182px;
  .el-input {
    width: 518px;
  }
  // ::v-deep.el-select,
  // ::v-deep.el-input__inner {
  //   width: 518px;
  // }
  ::v-deep.el-form-item {
    .letter-length {
      position: absolute;
      left: 488px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .course-pic-wrap {
    display: flex;
    .add-pic-btn {
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      background: #f5f5f5;
      font-size: 20px;
      color: #ccc;
    }
    .result-pic {
      width: 120px;
      height: 120px;
    }
    .p-wrap {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      .txt {
        margin-bottom: 22px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        line-height: 16px;
      }
    }
  }
}
</style>
