<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2019-11-24 18:04:59
 * @LastEditors: cyy 1346967714@qq.com
 * @LastEditTime: 2023-07-25 00:43:48
 -->
<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="`${editData.name ? '编辑章节' : '添加章节'}`"
      @close="closeFn"
      :visible="true"
      width="800px"
      style="margin-top: -8vh"
    >
      <el-form
        ref="form"
        :model="currEditData"
        :rules="rules1"
        class="timeBox"
        label-width="40px"
        @submit.native.prevent
      >
        <!-- 编辑章节 -->
        <div class="selectedTime">
          <!-- 上课模式 -->
          <div class="chapterName flx" v-if="courseType == 1 && isSpeedLive">
            <span class="lf" style="position: relative; top: -60px">
              上课模式
            </span>
            <el-form-item>
              <div class="type_box">
                <div
                  class="left_type   box"
                  :class="isActive == 3 ? 'active' : ''"
                  @click="changeIsActive(3)"
                >
                  <div class="title">
                    关联公开课
                  </div>
                  <div class="tip">
                    按公开课的标准计费，更省流量，直播功能更丰富
                  </div>
                  <!-- <div class="tip">直播端：PC客户端 / OBS / 第三方推流工具</div>
                <div class="tip">观看端：小程序 / 微信h5 / 网页浏览器</div> -->
                  <div
                    class="mask"
                    v-if="
                      Object.keys(this.editData).length > 0 && isActive != 3
                    "
                  ></div>
                  <div class="recommend">推荐</div>
                </div>
                <div
                  class="right_type  ml10 box"
                  :class="isActive == 1 ? 'active' : ''"
                  @click="changeIsActive(1)"
                >
                  <div class="title">
                    普通模式
                    <el-popover placement="bottom" trigger="hover">
                      <img
                        src="https://img.dingdingkaike.com/img_ptslt.png"
                        alt=""
                        width="100%"
                        height="100%"
                      />
                      <i slot="reference">查看示例图</i>
                    </el-popover>
                    <el-popover placement="bottom" width="300" trigger="hover">
                      <div class="tip">
                        资费单价：0.013元/分钟/人=0.13叮豆/分钟/人
                        叮豆用于直播、录播、小班开课消耗的虚拟货币
                      </div>
                      <div class="tip">1元=10叮豆</div>
                      <div class="tip">
                        计算公式：总消耗=直播时长*峰值人数*单价
                      </div>
                      <i slot="reference">资费标准</i>
                    </el-popover>
                  </div>
                  <div class="tip">
                    免上传课件，软件/桌面/区域分享，多摄像头+多场景，功能强大，隐私性好
                  </div>
                  <div class="tip">直播端：PC客户端 / OBS / 第三方推流工具</div>
                  <div class="tip">观看端：小程序 / 微信h5 / 网页浏览器</div>
                  <div
                    class="mask"
                    v-if="
                      Object.keys(this.editData).length > 0 && isActive != 1
                    "
                  ></div>
                </div>
                <!-- <div
                  class="right_type  mt10 box "
                  :class="isActive == 2 ? 'active' : ''"
                  @click="changeIsActive(2)"
                >
                  <div class="title">
                    极速模式
                    <el-popover placement="bottom" trigger="hover">
                      <img
                        src="https://img.dingdingkaike.com/img_jsslt.png"
                        alt=""
                        width="100%"
                        height="100%"
                      />
                      <i slot="reference">查看示例图</i>
                    </el-popover>
                    <el-popover placement="bottom" width="300" trigger="hover">
                      <div class="tip">极速计费=基本资费+连麦费用</div>
                      <div class="tip">
                        基本资费：0.05元/分钟/人，支持1-6人免费连麦
                      </div>
                      <div class="tip">
                        连麦费用：连麦数7-10人，0.1元/分钟/人；
                        <br />
                        <span style="margin-left: 60px">
                          连麦数11-15人，0.15元/分钟/人
                        </span>
                        <br />
                        <span style="margin-left: 52px">
                          （连麦人数7-15人时，该部分学员只收取连麦费用，免去基本资费）
                        </span>
                      </div>
                      <i slot="reference">资费标准</i>
                    </el-popover>
                  </div>
                  <div class="tip">
                    免装客户端，PC/Mac支持，软件/桌面/课件分享，毫秒级延迟，台上1V15互动连麦，台下万人
                  </div>
                  <div class="tip">直播端：谷歌(Chrome)浏览器(PC和Mac)</div>
                  <div class="tip">观看端：谷歌(Chrome)浏览器(PC和Mac)</div>
                  <div
                    class="mask"
                    v-if="
                      Object.keys(this.editData).length > 0 && isActive != 2
                    "
                  ></div>
                </div> -->
              </div>
              <div style="font-size: 12px; color: rgba(255, 53, 53, 1)">
                注意：创建成功后，上课模式暂不支持修改！
              </div>
            </el-form-item>
          </div>
          <div class="chapterName flx" style="margin-top: 10px">
            <span class="lf">章节名称</span>
            <el-form-item prop="name">
              <el-input
                type="text"
                placeholder="请输入章节名称"
                v-model="currEditData.name"
                maxlength="100"
                show-word-limit
                class="rt"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <pre>
          {{ currEditData }}
        </pre>-->
          <template v-if="isActive != 3">
            <!-- 上课老师 -->
            <div class="chapterName flx mt20">
              <span class="lf">上课老师</span>
              <el-form-item prop="teacher_id">
                <!-- <select
              style="color:#333;display: block;height: 40px;border-color: #dcdfe6;padding-left:15px;"
              class="rt"
              v-model="currEditData.teacher_id"
              placeholder="请选择老师"
            >
              <option
                v-for="item in teacherOptions"
                :key="item.uid"
                :label="item.uname"
                :value="item.uid"
              >
                {{ item.uname }}
              </option>
            </select> -->
                <el-select
                  filterable
                  v-model="currEditData.teacher_id"
                  style="width: 100%"
                  placeholder="请输入老师名称或选择上课老师"
                >
                  <el-option
                    v-for="item in teacherOptions"
                    :key="item.uid"
                    :label="item.uname"
                    :value="item.uid"
                  >
                    <div
                      style="
                    float: left;
                    height: 22px;
                    width: 22px;
                    border-radius: 50%;
                    margin-top: 6px;
                    background: grey;
                  "
                    >
                      <img
                        style="height: 22px; width: 22px"
                        :src="item.uphoto"
                        alt=""
                      />
                    </div>
                    <div
                      style="float: left; margin-left: 10px; font-size: 13px"
                    >
                      {{ item.uname }}
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- 上课时间 -->
            <div
              class="chapterName flx"
              style="margin-top: 20px; position: relative"
            >
              <div
                class="absolute"
                style="z-index: 5"
                v-if="disableSelectDate.msg1"
                @click="$root.prompt(disableSelectDate.msg1)"
              ></div>
              <span class="lf">上课时间</span>
              <div class="rt flx">
                <el-form-item prop="startTime">
                  <div class="selectTimeCm">
                    <div class="time-pluging">
                      <el-date-picker
                        format="yyyy/MM/dd HH:mm"
                        value-format="yyyy/MM/dd HH:mm"
                        v-model="currEditData.startTime"
                        type="datetime"
                        placeholder="选择日期时间"
                        v-if="selectTimeCm == 1"
                      ></el-date-picker>
                      <input
                        v-else
                        v-model="currEditData.startTime"
                        type="datetime-local"
                        v-focus
                        style="
                      color: rgb(51, 51, 51);
                      display: block;
                      height: 40px;
                      padding-left: 15px;
                      width: 100%;
                      box-sizing: border-box;
                      border: 1px solid rgb(220, 223, 230);
                      line-height: initial;
                    "
                      />
                    </div>
                    <div class="ps">
                      如选择时间没反应，点击
                      <i @click="selectTimeCm = selectTimeCm == 1 ? 2 : 1">
                        切换
                      </i>
                      可手动输入
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
            <!-- 上课时长 -->
            <div
              class="chapterName flx"
              style="margin-top: 20px; position: relative"
            >
              <div
                class="absolute"
                style="z-index: 5"
                v-if="disableSelectDate.msg2"
                @click="$root.prompt(disableSelectDate.msg2)"
              ></div>
              <span class="lf">上课时长</span>
              <div class="rt flx">
                <div v-if="!isFree" class="select-hour">
                  <el-form-item prop="classHour">
                    <el-input-number
                      v-model="classHour.t1"
                      :min="0"
                      :max="23"
                      label="描述文字"
                    ></el-input-number>
                  </el-form-item>
                  <div class="text">时</div>
                </div>
                <div class="select-minute">
                  <el-form-item prop="classHour">
                    <el-input-number
                      v-model="classHour.t2"
                      :min="0"
                      :max="isFree ? 30 : 59"
                      :precision="0"
                      label="描述文字"
                    ></el-input-number>
                  </el-form-item>
                  <div class="text">分钟</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 上课方式 -->
          <div
            class="chapterName flx"
            style="margin-top: 30px"
            v-if="isActive == 2"
          >
            <span class="lf">上课方式</span>
            <div
              class="type_info mr20"
              :class="type_active == 1 ? 'active' : ''"
              @click="type_active = 1"
            >
              课件
            </div>
            <div
              class="type_info mr20"
              :class="type_active == 2 ? 'active' : ''"
              @click="type_active = 2"
            >
              白板
            </div>
            <!-- <div class="type_info" :class="type_active == 3 ? 'active' : ''" @click="type_active = 3">桌面</div> -->
            <el-popover placement="right" trigger="hover">
              <p>
                极速模式提供多种上课方式，可根据自己的教学场景选择，直播时也可随意切换
              </p>
              <p>课件：上传课件演示教学</p>
              <p>白板：师生双向画笔涂鸦教学</p>
              <i slot="reference" class="el-icon-question ml10"></i>
            </el-popover>
          </div>
          <!-- 两个选择框 -->
          <div class="checkdiv">
            <el-checkbox
              v-show="!isVersionFree && courseType != 2 && isActive == 1"
              :true-label="1"
              :false-label="2"
              v-model="currEditData.is_play"
              :checked="currEditData.is_play == 1"
            >
              章节试看
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="在一个付费课程，开启章节试看，学员在购买前可以试看这个章节，没有设置试看的不能观看"
              ></helpIcon>
            </el-checkbox>

            <el-checkbox
              v-show="courseType != 2 && isActive == 1"
              :true-label="1"
              :false-label="2"
              v-model="currEditData.live_recall"
              :checked="currEditData.live_recall == 1"
              @change="liveRecallChange"
            >
              允许观看回放
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="开启允许观看回放，直播结束后学员可以查看回放视频"
              ></helpIcon>
            </el-checkbox>
            <!-- <el-checkbox v-if="courseType == 1" :true-label="1" :false-label="2" v-model="currEditData.is_video_call" :checked="currEditData.is_video_call == 1">
            允许举手连麦
            <el-popover placement="right" trigger="hover" content="勾选允许举手连麦后，上课中学员可举手音频/视频连麦">
              <i slot="reference" class="el-icon-question ml10"></i>
            </el-popover>
          </el-checkbox> -->
          </div>

          <div v-if="isprotect" style="position: relative">
            <div
              v-if="disableSelectDate.msg3"
              class="absolute"
              style="z-index: 5"
              @click="$root.prompt(disableSelectDate.msg3)"
            ></div>
            <div class="protect" style="margin-top: 20px">
              <div class="protect_font1" style="font-weight: bold; color: #333">
                回放加密保护
              </div>
              <helpIcon
                style="margin-left: 6px; margin-right: 30px"
                prompt="开启后，可以选择保护方案，只作用当前章节；未选择保护方案生成的回放不会保护，只对选择保护方案后，生成的回放进行加密保护"
              ></helpIcon>
              <el-switch
                active-value="1"
                inactive-value="2"
                v-model="videoencryption"
                @change="videoencryption_click"
              ></el-switch>
              <div class="flex-center" v-if="status1">
                <el-select
                  @focus="getSelectlist"
                  style="width: 211px; margin: 0px 15px 0 30px"
                  size="medium"
                  v-model="currEditData.video_safe_id"
                  placeholder="请选择保护方案"
                >
                  <el-option
                    v-for="item in videoSafeInfo"
                    :key="item.template_id"
                    :label="item.template_name"
                    :value="item.template_id"
                    :disabled="item.status != 1"
                  ></el-option>
                </el-select>
                <el-button type="text" size="small" @click="newly1">
                  新建方案
                </el-button>
              </div>
            </div>

            <div class="protect mt10">
              <div class="protect_font1" style="font-weight: bold; color: #333">
                品牌保护
              </div>
              <helpIcon
                style="margin-left: 6px; margin-right: 58px"
                prompt="开启后，可以选择保护方案，保存章节后会对视频文件进行自动添加品牌保护；如关闭保护或未选择方案，视频文件不会加密，以普通方式上传"
              ></helpIcon>
              <el-switch
                v-model="brandvalue"
                active-value="1"
                inactive-value="2"
                @change="brandvalue_click"
              ></el-switch>
              <div class="flex-center" v-if="status2">
                <el-select
                  @focus="getSelectlist"
                  style="width: 211px; margin: 0px 15px 0 30px"
                  size="medium"
                  v-model="currEditData.brand_safe_id"
                  placeholder="请选择保护方案"
                >
                  <el-option
                    v-for="item in brandSafeInfo"
                    :key="item.template_id"
                    :label="item.template_name"
                    :value="item.template_id"
                    :disabled="item.status != 1"
                  ></el-option>
                </el-select>
                <el-button type="text" size="small" @click="newly2">
                  新建方案
                </el-button>
              </div>
            </div>
          </div>

          <template v-if="isActive == 3">
            <!-- 选择公开课 -->
            <div class="chapterName ml " style="margin-top: 20px;">
              <div class="flex-center">
                <span class="lf mr10" style="flex-shrink: 0;">
                  选择公开课
                </span>
                <div style="width:100%;overflow: hidden;">
                  <p>
                    <el-button
                      @click="networkschoolSelectStatus = true"
                      style="width:180px;"
                      size="medium"
                      :disabled="courseList.length >= 1"
                    >
                      点击选择
                    </el-button>
                  </p>
                </div>
              </div>
              <div class="contain">
                <div
                  class="openclass"
                  v-for="(item, index) in courseList"
                  :key="item.open_class_id"
                >
                  <div class="left">
                    <div class="tu">
                      <img
                        :src="item.open_class_img || item.cover_img"
                        alt=""
                      />
                    </div>
                    <div class="name hidetext mr10">{{ item.name }}</div>
                  </div>
                  <el-button
                    type="text"
                    size="medium"
                    @click="openDelete(index)"
                  >
                    删除
                  </el-button>
                </div>
              </div>
            </div>
          </template>
          <div class="bottom">
            <template v-if="isActive != 3">
              <div
                v-if="
                  $route.query.courseType == 1 || $route.query.course_type == 1
                "
                class="red"
                style="
              font-size: 12px;
              color: #ff3535;
              line-height: 16px;
              width: 480px;
              font-weight: normal;
            "
              >
                提示：临近开课前15分钟和开课中不可修改上课时间；开启保护后，点击保存后开始加密，加密中学生无法观看视频
              </div>

              <div
                v-else
                class="red"
                style="
              font-size: 12px;
              color: #ff3535;
              line-height: 16px;
              width: 480px;
              font-weight: normal;
            "
              >
                提示：临近开课前15分钟、开课中和已结束时不可修改上课时间
              </div>
            </template>
            <el-button @click="closeFn" style="margin-left: 18px">
              取消
            </el-button>
            <el-button type="primary" @click="makeSubmit">保存</el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 选择商品 -->
    <networkschool-select
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="singleselectcontentval"
      title="选择公开课"
      :singleselect="true"
      :goodList="courseList"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="16"
    ></networkschool-select>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'
import { mapGetters } from 'vuex'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'

export default {
  name: 'newSchedule',

  mixins: [pageOverflow],

  props: {
    // 1是直播课，2是小班课
    courseType: [String, Number],
    editData: undefined,
  },
  components: {
    helpIcon,
    networkschoolSelect,
  },
  data() {
    return {
      courseList: [],
      networkschoolSelectStatus: false,
      // 老师列表
      teacherOptions: [],
      teacher_value: '',
      isprotect: true,
      brandSafeInfo: [],
      videoSafeInfo: [],
      videoencryption_value: '',
      videoencryption_value2: '',
      status1: false,
      videoencryption: '2',
      brandvalue: '2',
      status2: false,
      // 当前选中上课模式
      isActive: this.courseType == 1 ? 3:1,
      type_active: 1,
      // 当前正在编辑的数据
      currEditData: _.assign(
        {
          live_recall: 2,
          brand_safe_id: '',
          video_safe_id: '',
        },
        this.editData || {}
      ),

      // 时长
      classHour: {
        t1:
          this.editData && this.editData.originClassHour
            ? this.editData.originClassHour.t1 || 0
            : 1,
        t2:
          this.editData && this.editData.originClassHour
            ? this.editData.originClassHour.t2 || 0
            : 0,
      },

      // 表单验证
      rules1: {
        name: [{ required: true, message: '请输入章节名称', trigger: 'blur' }],
        teacher_id: [
          { required: true, message: '请选择上课老师', trigger: 'change' },
        ],
        startTime: [
          { required: true, message: '请选择上课时间', trigger: 'change' },
        ],
        classHour: [
          { required: true, message: '请选择上课时长', trigger: 'blur' },
        ],
      },
      //公开课的验证
      rules2: {
        name: [{ required: true, message: '请输入章节名称', trigger: 'blur' }],
      },

      // 选择时间组件类别
      selectTimeCm: 1,
    }
  },

  watch: {
    classHour: {
      deep: true,
      handler(value) {
        const t1 = this.isFree ? 0 : Number(value.t1 || 0)
        const t2 = Number(value.t2 || 0)
        this.currEditData = _.assign({}, this.currEditData, {
          classHour: t1 || t2 ? `${t1 + t2 / 60}` : undefined,
        })
      },
      immediate: true,
    },

    selectTimeCm(value) {
      this.currEditData.startTime = this.currEditData.startTime || ''
      if (value == 2 && this.currEditData.startTime) {
        this.currEditData.startTime = this.currEditData.startTime
          .replace(/\//g, '-')
          .replace(' ', 'T')
      } else {
        this.currEditData.startTime = this.currEditData.startTime
          .replace(/-/g, '/')
          .replace('T', ' ')
      }
    },
  },

  computed: {
    // 免费的直播只能创建半小时
    isFree() {
      return this.isVersionFree && this.courseType == 1
    },
    ...mapGetters(['isVersionFree', 'isSpeedLive']),

    // 5.  直播课、小班课的上课时间不可随意修改，录播课不限制；

    // 情况1：直播未到开始时间（不包括前15分钟），可以修改时间和时长

    // 情况2：直播已开始，未结束，不可修改时间，可修改时长；
    // 提示：课程已开始，不可修改时间

    // 情况3：直播已结束（不包括延期15分钟）：不可修改时间和时长
    // 提示：课程已结束，不可修改时间
    // 提示：课程已结束，不可修改时长
    disableSelectDate() {
      let info = {}
      if (this.editData && this.editData.startTime) {
        const stime = new Date(
          this.editData.startTime.replace(/-/g, '/')
        ).getTime()
        const etime = new Date(
          this.editData.endTime.replace(/-/g, '/')
        ).getTime()
        const minutes15 = 15 * 60 * 1000
        const now = new Date().getTime()

        // console.log(now, stime, etime, etime + minutes15, stime - minutes15)

        // if (now > etime + minutes15) {
        //   // 是否已结束
        //   info = {
        //     msg1: '课程已结束，不可修改时间',
        //     msg2: '课程已结束，不可修改时长',
        //     msg3: '该章节已结束',
        //   }
        // } else

        if (now <= etime + minutes15 && now > stime - minutes15) {
          // 直播中
          info = {
            msg1: '课程已开始，不可修改时间',
            msg2: '',
          }
        }
      }

      return info
    },
  },

  // 获取机构老师
  created() {
    const course_type = this.$route.query.course_type
    if (course_type) {
      if (this.currEditData.live_mode == 2 || course_type != 1) {
        this.isprotect = false
      } else if (this.currEditData.live_mode == 1) {
        this.isprotect = true
      } else {
        this.isprotect = false
      }
      if (course_type == 1 && this.currEditData.live_mode == 3) {
        this.courseList = [
          {
            open_class_id: this.currEditData.open_class_id,
            open_class_img: this.currEditData.open_class_img,
            name: this.currEditData.open_class_name,
          },
        ]
      }
    } else if (
      this.$route.query.courseType &&
      this.$route.query.courseType != 1
    ) {
      this.isprotect = false
    }
    // console.log(this.isprotect)
    // console.log(this.currEditData.video_safe_id)
    // console.log(this.currEditData.brand_safe_id)
    if (this.currEditData.video_safe_id) {
      this.status1 = true
      this.videoencryption = '1'
    }
    if (this.currEditData.brand_safe_id) {
      this.status2 = true
      this.brandvalue = '1'
    }
    if (this.$store.state.userInfo.jg_is_drm == 1) {
      this.getSelectlist()
    }
    if (this.videoencryption == 1 || this.videoencryption == 1) {
      this.currEditData.live_recall = 1
    }
    this.getJigouTeacher()
    if (Object.keys(this.editData).length > 0) {
      this.isActive = this.editData.live_mode
      this.type_active = this.editData.class_way
    }
  },

  methods: {
    openDelete(index) {
      this.courseList.splice(index, 1)
    },
    closeGoodsConfirm() {
      this.networkschoolSelectStatus = false
    },

    singleselectcontentval(val) {
      this.courseList.push(val)
    },
    // playback(val) {
    //   console.log(val)
    //   if (val == 2) {
    //     this.videoencryption = 2
    //     this.brandvalue = 2
    //     this.status1 = false
    //     this.status2 = false
    //     this.currEditData.video_safe_id = ''
    //     this.currEditData.brand_safe_id = ''
    //   }
    // },
    newly1() {
      let details1 = this.$router.resolve({
        path: '/videoSafe/video/newly1',
        query: {
          name: '新建',
          status: 1,
        },
      })
      this.$store.dispatch('open', details1.href)
    },
    newly2() {
      let details = this.$router.resolve({
        path: '/videoSafe/brand/newly2',
        query: {
          name: '新建',
          status: 1,
        },
      })
      this.$store.dispatch('open', details.href)
      // $router.push({path:'/videoSafe/brand/newly2?name=新建&status=1'})
    },
    getSelectlist() {
      this.$http({
        url: '/VideoSafeTemplate/selectSafe',
        data: {},
        callback: ({ data: { brandSafeInfo, videoSafeInfo }, code }) => {
          if (code == 200) {
            this.brandSafeInfo = brandSafeInfo
            this.videoSafeInfo = videoSafeInfo
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },

    // 监听允许开启回放按钮状态变化
    liveRecallChange(val) {
      // console.log(val)
      // 如果回放关闭了 就把回放保护 和 品牌保护 都关闭掉
      if (val == 2) {
        this.currEditData.video_safe_id = ''
        this.currEditData.brand_safe_id = ''
        this.status1 = false
        this.status2 = false
        this.videoencryption = '2'
        this.brandvalue = '2'
      }
    },

    videoencryption_click(val) {
      // 如果没开通 跳转页面
      const status = this.$store.state.userInfo.jg_is_drm
      if (status == 2) {
        this.videoencryption = 2
        let status_s1 = this.$router.resolve({
          path: '/videoSafe/video',
        })
        this.$store.dispatch('open', status_s1.href)
        return
      }

      // 自动开启回放按钮
      if (val == 1) {
        this.currEditData.live_recall = 1
      }

      // 显示方案菜单
      if (val == 1) {
        this.status1 = true

        const vidioallstatus = this.videoSafeInfo.some(e => e.status == 1)
        const first_open = this.videoSafeInfo.find(f => f.status == 1)
        if (!vidioallstatus) {
          this.currEditData.video_safe_id = ''
        } else {
          this.currEditData.video_safe_id = first_open.template_id
        }

        // this.currEditData.video_safe_id = this.videoSafeInfo[0].template_id
      } else {
        this.status1 = false
        this.currEditData.video_safe_id = ''
      }
    },

    brandvalue_click(val) {
      // 如果没开通 跳转页面
      const status = this.$store.state.userInfo.jg_is_drm
      if (status == 2) {
        this.brandvalue = 2
        let status_s1 = this.$router.resolve({
          path: '/videoSafe/brand',
        })
        this.$store.dispatch('open', status_s1.href)
        return
      }
      // 自动开启回放按钮
      if (val == 1) {
        this.currEditData.live_recall = 1
      }
      // 显示方案菜单
      if (val == 1) {
        this.status2 = true

        const brandallstatus = this.brandSafeInfo.some(e => e.status == 1)
        const first_open = this.brandSafeInfo.find(f => f.status == 1)
        if (!brandallstatus) {
          this.currEditData.brand_safe_id = ''
        } else {
          this.currEditData.brand_safe_id = first_open.template_id
        }
      } else {
        this.currEditData.brand_safe_id = ''
        this.status2 = false
      }
    },
    // 改变直播模式
    changeIsActive(val) {
      if (Object.keys(this.editData).length == 0) {
        this.isActive = val
        if (val == 1) {
          this.isprotect = true
        } else {
          this.isprotect = false
        }
        // this.currEditData.is_play = ''
        // this.currEditData.live_recall = ''
        // this.currEditData.is_video_call = ''
      }
    },
    // 获取机构老师
    getJigouTeacher() {
      const self = this
      try {
        self.$http({
          name: API.getJigouTeacher_Api.name,
          url: API.getJigouTeacher_Api.url,
          callback({ data: { teacherInfo } }) {
            self.teacherOptions = teacherInfo
            if (
              self.currEditData.teacher_id &&
              teacherInfo.filter(
                item => item.uid == self.currEditData.teacher_id
              ).length === 0
            ) {
              self.currEditData.teacher_id = ''
            }
          },
          error(error) {
            if (error.code == 204) {
              self.teacherOptions = []
            }
          },
        })
      } catch (e) {
        console.log(e)
      }
    },

    // 点击确认
    makeSubmit() {
      if (
        (!this.currEditData.video_safe_id && this.videoencryption === '1') ||
        (!this.currEditData.brand_safe_id && this.brandvalue === '1')
      ) {
        this.$confirm(
          '当前开启了视频保护，但未选择保护方案，保存后，对应的保护功能不会生效，是否确定？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(() => {
            this.makeSubmit2()
          })
          .catch(() => {})
      } else {
        this.makeSubmit2()
      }
    },
    makeSubmit2() {
      this.$nextTick(() => {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.isActive == 3) {
              if (this.courseList.length == 0) {
                this.$root.prompt('请选择公开课')
                return
              } else {
                this.currEditData.open_class_id = this.courseList[0].open_class_id
              }
            } else {
              this.currEditData.courseList = undefined
            }
            const startTime = Math.floor(
              new Date(
                this.selectTimeCm == 1
                  ? this.currEditData.startTime
                  : this.currEditData.startTime
                      .replace(/-/g, '/')
                      .replace('T', ' ')
              ) / 1000
            )
            this.currEditData.startTime = startTime
            this.currEditData.live_mode = this.isActive
            this.currEditData.class_way = this.type_active
            this.currEditData.endTime =
              this.currEditData.startTime && this.currEditData.startTime != 0
                ? startTime +
                  (this.isFree ? 0 : (this.classHour.t1 || 0) * 3600) +
                  (this.classHour.t2 || 0) * 60
                : ''
            this.$emit('save', [this.currEditData])
            this.$emit('compelet')
            this.closeFn()
          } else {
            return false
          }
        })
      })
    },

    // 关闭
    closeFn() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0px 20px 10px;
}
.contain {
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
  margin-left: 80px;
  @extend %scroll;
  .openclass {
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      width: 95%;
    }
    .tu {
      flex-shrink: 0;
      width: 60px;
      height: 25px;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.el-popover {
  .tip {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    color: rgba(74, 74, 74, 1);
  }
}
.el-form-item {
  flex: 1;
  margin-bottom: 0;
}
.timeBox {
  display: flex;
  .checkdiv {
    margin-left: 76px;
    margin-top: 20px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
  }
  .protect {
    margin-left: 76px;
    height: 36px;
    display: flex;
    align-items: center;
  }
  .el-date-picker .el-date-table td.selected span {
    background-color: #0aa29b;
  }
  .selectedTime {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    // margin: 0 21px;
    ::v-deep .el-input__suffix {
      white-space: nowrap;
    }
    .type_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .box {
        width: 334px;
        height: 134px;
        background: rgba(246, 248, 251, 1);
        border-radius: 2px;
        position: relative;
        border: 1px solid rgba(225, 225, 225, 1);
        box-sizing: border-box;
        padding: 10px 14px;
        &.active {
          border-color: rgba(27, 157, 151, 1);
          background: rgba(246, 248, 251, 1)
            url('../../assets/img/ico_xzdg.png') no-repeat right bottom;
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.7);
        }
        .title {
          height: 24px;
          line-height: 24px;
          box-sizing: border-box;
          padding-left: 25px;
          width: 100%;
          font-size: 18px;
          color: rgba(51, 51, 51, 1);
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          span {
            color: rgba(123, 177, 175, 1);
            font-size: 12px;
            width: 75px;
            margin-left: 5px;
            font-weight: normal;
            margin-right: 30px;
            cursor: pointer;
          }
          span:nth-of-type(2) {
            width: 60px;
            color: rgba(27, 157, 151, 1);
            float: right;
            margin: 0;
          }
        }
        .tip {
          width: 100%;
          font-size: 12px;
          line-height: 17px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }
      .left_type {
        position: relative;
        .title {
          background: url('../../assets/img/ico_ptzb.png') no-repeat left center;
        }

        .recommend {
          position: absolute;
          right: 0;
          top: -10px;
          color: #fff;
          padding: 5px 12px;
          background: #e94242;
          font-size: 14px;
          line-height: 1.5;
          box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.4);
        }
      }
      .right_type {
        .title {
          background: url('../../assets/img/ico_jsms.png') no-repeat left center;
        }
      }
    }
    .type_info {
      width: 92px;
      height: 40px;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid rgba(225, 225, 225, 1);
      color: rgba(51, 51, 51, 1);
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      &.active {
        border-color: rgba(27, 157, 151, 1);
        color: rgba(27, 157, 151, 1);
        background: rgba(246, 248, 251, 1) url('../../assets/img/ico_xzdg.png')
          no-repeat right bottom;
      }
    }
    .flx {
      display: flex;
      align-items: center;
      .lf {
        font-size: 14px;
        margin-right: 20px;
        line-height: 19px;
        color: rgba(51, 51, 51, 1);
      }
      .rt {
        width: 100%;
        flex: 1;
      }
      ::v-deep .el-input .el-input__count .el-input__count-inner {
        position: absolute;
        bottom: -20px;
        right: -10px;
      }
    }
    .header {
      color: rgba(51, 51, 51, 1);
    }
    .addTime {
      margin-left: 20px;
      cursor: pointer;
      color: rgba(10, 162, 155, 1);
    }
    .timeItem {
      height: 250px;
      overflow-y: scroll;
      ::v-deep .el-range-editor.el-input__inner {
        margin-right: 20px;
        margin-top: 10px;
      }
      ::v-deep .el-range-editor.is-active,
      .el-range-editor.is-active:hover {
        border-color: #0aa29b;
      }
    }
    ::v-deep .el-date-editor.el-input,
    ::v-deep .el-date-editor.el-input__inner {
      width: 180px;
      margin: 15px 15px 5px 0;
    }
    ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 36px;
    }
  }
}
.timeBox .selectedTime .el-date-editor.el-input {
  width: 100%;
  margin: 0;
}
/**选择时长 */
.select-hour {
  margin-right: 30px;
}
.select-hour,
.select-minute {
  display: flex;
  align-items: center;
  .text {
    font-size: 14px;
    margin-left: 10px;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
  }
}
.selectTimeCm {
  display: flex;
  .time-pluging {
    flex: 1;
  }
  .ps {
    color: $red;
    font-size: 14px;
    margin-left: 20px;
    i {
      color: $color;
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
    }
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  .tips {
    flex: 1;
    font-weight: 600;
    font-size: 12px;
    color: #ff3535;
    line-height: 16px;
    padding-left: 77px;
  }
}
</style>
