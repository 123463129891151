<template>
  <div class="posterTemplate posterTemplate2">
    <img src="https://img.dingdingkaike.com/FgI60AW0G1ctPJVnZsZgZS0_pY_a?imageslim" alt="背景图" class="bg-img" />
    <div>
      <h1 class="t1">{{ posterData.jg_title }}</h1>
      <div class="t2">
        <img class="ct1" :src="posterData.photo | imageslim(3)" />
        <p class="ct2">{{ posterData.name | nameStr }}</p>
        <!-- <p class="ct3">{{ posterData.time || '&nbsp;' }}</p> -->
        <!-- <p class="ct4" v-if="posterData.pre_way == 1 || posterData.pre_way == 2">{{ posterData.cou_endtime }}前报名
          {{ posterData.pre_way == 1 ? `可优惠${posterData.cou_price}元` : `可享${posterData.discount}折优惠` }}</p> -->
      </div>
      <div class="bottom">
        <div class="text">
          <div class="ct1">
            <img class="img" :src="posterData.uphoto" />
            <div class="text">
              <p class="cct1">{{ posterData.uname | unameStr }}</p>
              <p class="cct2">给你推荐了一{{type==='2' ? '份资料' : '门课程'}}</p>
            </div>
          </div>
          <p class="ct2">长按识别二维码 {{type==='2' ? '立即领取' : '立即加入学习'}}</p>
        </div>
        <div class="erweima" ref="qrcode"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Template2',

  props: {
    posterData: Object,
    type:{
      type:String,
      default:'1'
    }
  },
  filters: {
    nameStr(str) {
      return str.length > 30 ? `${str.slice(0, 30)}...` : str
    },
    unameStr(str) {
      return str.length > 10 ? `${str.slice(0, 6)}...` : str
    }
  },
  mounted() {
    // this.$parent.$parent.createEwm(3.6 * 3, this.$refs.qrcode);
    this.$emit('createEwm', 3, this.$refs.qrcode)
  }
}
</script>
<style lang="scss">
.posterTemplate2 {
  width: 300px;
  height: 459px;
  position: absolute;
  // top: -10000px;
  top: 0;
  // transform: scale(0.86);
  // transform-origin: 0 0 0;
  .bg-img {
    width: 100%;
    height: 100%;
  }
  > div {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    > .t1 {
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      line-height: 19px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > .t2 {
      margin-top: 19px;
      position: relative;
      border-radius: 4px;
      background-color: #fff;
      .ct1 {
        width: 260px;
        height: 145px;
        display: block;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .ct2 {
        height: 33px;
        margin-top: 8px;
        font-size: 13px;
        padding: 0 12px;
        line-height: 16px;
        word-break: break-all;
        color: rgba(51, 51, 51, 1);
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .ct3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        padding: 12px 12px 11px;
        color: rgba(153, 153, 153, 1);
      }
      .ct4 {
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 143px;
        color: #fff;
        font-size: 10px;
        text-align: center;
        line-height: 15px;
        margin-left: -71.5px;
        white-space: nowrap;
        border-radius: 11px;
        background-color: #ff0000;
      }
    }
    > .bottom {
      display: flex;
      padding: 32px 0 20px;
      align-items: center;
      > .text {
        flex: 1;
        .ct1 {
          display: flex;
          align-items: center;
          .img {
            width: 38px;
            height: 38px;
            border-radius: 4px;
          }
          .text {
            margin-left: 11px;
            .cct1 {
              width: 108px;
              color: #fff;
              font-size: 14px;
              line-height: 22px;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
            .cct2 {
              color: #fff;
              font-size: 12px;
              margin-top: 2px;
              line-height: 17px;
            }
          }
        }
        .ct2 {
          font-size: 12px;
          margin-top: 19px;
          line-height: 15px;
          color: rgba(255, 255, 255, 1);
        }
      }
      > .erweima {
        width: 72px;
        padding: 2px;
        background-color: #fff;
        * {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
