<!--类似flex布局 上面文字 下面粗体数字这样-->
<template>
  <div class="flex-center flex-box1">
    <div v-for="item in list" :key="item.name" class="item">
      <div class="top">
        {{ item.top }}
        <helpIcon
          v-if="item.prompt"
          class="vt"
          style="margin-left: 6px"
          :content="item.prompt"
        ></helpIcon>
      </div>
      <div class="bottom">
        <helpIcon v-if="item.prompt2" :icon2="true" :content="item.prompt2">
          <template v-slot:text>
            <div class="vm icon2">
              {{ item.bottom }}
            </div>
          </template>
        </helpIcon>
        <template v-else>{{ item.bottom }}</template>
      </div>
      <slot :name="item.name"></slot>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'

export default {
  name: 'flex-box1',

  components: {
    helpIcon,
  },

  props: {
    list: Array,
  },
}
</script>
<style lang="scss" scoped>
.flex-box1 {
  align-items: baseline;
  .item {
    flex: 1;
    .top {
      font-size: 13px;
      line-height: 17px;
      color: rgba(102, 102, 102, 1);
    }
    ::v-deep .bottom {
      margin-top: 9px;
      font-weight: bold;
      font-size: 22px;
      line-height: 29px;
      .icon2 {
        font-size: 22px;
        line-height: 29px;
        margin-right: 6px;
        cursor: pointer;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
}
</style>
