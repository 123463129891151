<!--
 * @Descripttion: 评论管理
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-21 18:02:35
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:12:44
 -->
<template>
  <div class="comment-wrap">
    <div class="control-box">
      <div class="left-box">
        <el-checkbox
          v-model="isCheckedAll"
          @change="getAllCheck"
          label="选中本页"
        ></el-checkbox>
        <span
          class="cp setting-erweima"
          style="margin-left: 30px"
          @click="
            statusCourseReview(
              isCheckedAll ? allreview_ids : review_ids,
              2,
              'all'
            )
          "
        >
          批量显示
        </span>
        <span
          class="cp setting-erweima"
          @click="
            statusCourseReview(
              isCheckedAll ? allreview_ids : review_ids,
              3,
              'all'
            )
          "
        >
          批量隐藏
        </span>
      </div>
      <div class="right-box">
        <span>发布评论需审核</span>
        <el-switch
          v-model="is_review"
          @change="getIsReview"
          active-color="#0AA29B"
          inactive-color="#ccc"
        ></el-switch>
        <div class="select-wrap">
          <el-select
            placeholder="全部"
            v-model="requestParam.status"
            @change="getStatus"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="title-list">
      <p class="p1">用户</p>
      <div class="title-inner">
        <p class="p2">真实姓名</p>
        <p class="p2">评论内容</p>
        <p class="p3">评论时间</p>
        <p class="p4">状态</p>
      </div>
      <p class="p5">操作</p>
    </div>
    <ul
      v-if="
        resultInfo != undefined && resultInfo.data && resultInfo.data.length > 0
      "
    >
      <li class="item" v-for="(item, index) in resultInfo.data" :key="index">
        <div class="checkbox-wrap">
          <el-checkbox
            v-model="flagArr[index]"
            @change="
              (checked) => getReviewIdSet(checked, item.review_id, index)
            "
          ></el-checkbox>
        </div>
        <div class="item1 info">
          <div class="cover">
            <lazy class="img" :src="item.uphoto"></lazy>
            <!-- <div class="shangjia">已下架</div> -->
          </div>
          <div class="right">
            <div class="title">{{ item.uname }}</div>
          </div>
        </div>

        <div class="item2">
          <div class="vm type item2-1">
            {{ item.student_remarks || '-' }}
          </div>
          <div class="vm type item2-1">
            <div class="inner-title">{{ item.content }}</div>
          </div>
          <div class="vm teacher item2-2">
            {{ item.createtime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </div>
          <div class="vm count item2-3" v-if="item.status == 3">已隐藏</div>
          <div class="vm count item2-3" v-if="item.status == 2">已显示</div>
          <div class="vm count item2-3" v-if="item.status == 1">待处理</div>
        </div>

        <div class="function item6">
          <span
            class="vm btn"
            @click="
              statusCourseReview(
                item.review_id,
                item.status == 1 || item.status == 3 ? 2 : 3,
                'single'
              )
            "
          >
            {{ item.status == 1 || item.status == 3 ? '显示' : '隐藏' }}
          </span>
          <span class="vm space"></span>
          <span
            class="vm btn"
            @click.stop="delId = `${item.review_id}&${index}`"
          >
            删除
          </span>
        </div>
      </li>
    </ul>
    <div style="position: relative; height: 500px" v-if="isNoData">
      <empty :isOld="false" :positionCenter="true" str="absolute"></empty>
    </div>
    <loading v-show="isLoading" leftPos="0"></loading>
    <CourseConfirm
      v-if="delId"
      title="删除评论"
      :isSmallPop="true"
      @cancelFn="toggleShowDel"
      @save="getDel(delId)"
    >
      <h4 class="reset-txt">是否删除该评论内容？</h4>
    </CourseConfirm>
    <div class="pagination-wrap" v-show="resultInfo.allNum > 15">
      <el-pagination
        background
        :page-size="15"
        layout="prev, pager, next"
        :page-count="Number(resultInfo.pageNum)"
        :total="Number(resultInfo.allNum)"
        @current-change="getChangeCurrent"
        hide-on-single-page
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import API from '@/request/api'
import CourseConfirm from '@/components/CourseConfirm'
import tool from '@ass/js/tool'
import empty from '@/components/Empty'
import loading from '@/components/LoadGif'

export default {
  data() {
    return {
      is_review: '', // 1是 2 否
      options: [
        { label: '全部', value: '' },
        { label: '已显示', value: '2' },
        { label: '已隐藏', value: '3' },
        { label: '待处理', value: '1' },
      ],
      requestParam: {
        course_id: '',
        status: '',
        page: '1',
      },
      resultInfo: {},
      delId: '',
      review_ids: [],
      allreview_ids: [],
      isCheckedAll: false,
      flagArr: [],
      isNoData: false,
      // 显示加载图标
      isLoading: false,
    }
  },
  components: {
    empty,
    CourseConfirm,
    loading,
  },
  created() {
    this.requestParam.course_id = this.$route.query.course_id
    this.editCourseReview()
    this.updateComment()
  },
  methods: {
    getReviewIdSet(value, review_id, index) {
      this.flagArr.splice(index, 1, value)
      let some = this.flagArr.some((item) => item === false)
      let every = this.flagArr.every((item) => item === true)
      if (this.review_ids.includes(review_id)) {
        let delIndex = tool.contains(this.review_ids, review_id)
        this.review_ids.splice(delIndex, 1)
        if (some) {
          this.isCheckedAll = false
        }
        return
      }
      if (every) {
        this.isCheckedAll = true
      }
      this.review_ids.push(review_id)
    },
    getStatus() {
      this.requestParam.page = 1
      this.editCourseReview()
    },
    // 显示/隐藏删除弹窗
    toggleShowDel() {
      this.delId = ''
    },
    getAllCheck(value) {
      this.isCheckedAll = value
      this.flagArr = []
      if (this.isCheckedAll) {
        this.review_ids = JSON.parse(JSON.stringify(this.allreview_ids))
      } else {
        this.review_ids = []
      }
      this.allreview_ids.forEach(() => {
        this.flagArr.push(this.isCheckedAll)
      })
    },
    // 批量显示、批量隐藏、单个显示、单个隐藏 status 1显示 2隐藏
    statusCourseReview(review_ids, status, type) {
      const self = this
      let temp = []
      if (type === 'all' && review_ids.length == 0) {
        self.$root.prompt('请先勾选评论')
        return
      }
      if (type === 'single') {
        temp[0] = review_ids
      }
      self.$http({
        name: API.statusCourseReview_Api.name,
        url: API.statusCourseReview_Api.url,
        data: {
          review_ids: type === 'single' ? temp : review_ids,
          status,
        },
        callback(res) {
          if (res.code == 200) {
            self.isCheckedAll = false
            self.allreview_ids = []
            self.flagArr = []
            self.review_ids = []
            self.editCourseReview()
          }
        },
      })
    },
    // 删除评论
    getDel(delId) {
      const self = this
      const temp = delId.split('&')
      self.$http({
        name: API.delCourseReview_Api.name,
        url: API.delCourseReview_Api.url,
        data: {
          review_id: temp[0],
        },
        callback(res) {
          if (res.code == 200) {
            self.resultInfo.data.splice(temp[1], 1)
            self.toggleShowDel()
            self.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            self.editCourseReview()
            // if (self.resultInfo.data.length == 0) {

            // }
          }
        },
      })
    },
    // 当前页发生改变
    getChangeCurrent(value) {
      this.requestParam.page = value
      this.editCourseReview()
    },
    // 获取评论列表
    editCourseReview() {
      const self = this
      const data = this.requestParam
      self.isLoading = true
      self.$http({
        name: API.editCourseReview_Api.name,
        url: API.editCourseReview_Api.url,
        data,
        callback(res) {
          if (res.code == 200) {
            self.resultInfo = res
            self.isLoading = false
            self.isNoData = false
            self.resultInfo.data.forEach((element) => {
              self.flagArr.push(false)
              self.allreview_ids.push(element.review_id)
            })
          }
        },
        error(error) {
          if (error.code == 204) {
            self.isLoading = false
            self.isNoData = true
            self.resultInfo = {}
            self.flagArr = []
          }
        },
      })
    },
    getIsReview(value) {
      const self = this
      self.$http({
        name: API.updateComment_Api.name,
        url: API.updateComment_Api.url,
        data: {
          course_id: self.requestParam.course_id,
          is_review: value ? 1 : 2,
        },
        error() {
          self.$root.prompt('更改失败')
        },
      })
    },
    // 更改评论是否需要审核
    updateComment() {
      const self = this
      self.$http({
        name: API.updateComment_Api.name,
        url: `${API.updateComment_Api.url}?course_id=${self.requestParam.course_id}`,
        callback({ code, is_review }) {
          if (code == 200) {
            self.is_review = is_review == 1
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.comment-wrap {
  padding-right: 34px;
  .title-list {
    display: flex;
    padding: 12px 30px;
    background: #f5f5f5;
    .title-inner {
      display: flex;
      align-items: center;
      min-width: 442px;
    }
    p {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .p1 {
      flex: 1;
      min-width: 176px;
    }
    .p2 {
      width: 289px;
    }
    .p3 {
      width: 304px;
    }
    .p4 {
      width: 114px;
    }
    .p5 {
      flex: 1;
      min-width: 176px;
      text-align: right;
    }
  }
}
%table {
  .item1 {
    flex: 1;
    min-width: 176px;
  }
  .item2 {
    font-size: 0;
    min-width: 442px;
    display: flex;
    align-items: center;
    .item2-2,
    .item2-3,
    .item2-4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item2-1 {
      width: 289px;
      .inner-title {
        width: 186px;
        word-break: break-all;
      }
    }
    .item2-2 {
      width: 304px;
      padding-right: 18px;
      box-sizing: border-box;
    }
    .item2-3 {
      width: 114px;
    }
    .item2-4 {
      width: 81px;
    }
  }
  .item6 {
    flex: 1;
    min-width: 176px;
    text-align: right;
  }
}
.item {
  font-size: 0;
  display: flex;
  position: relative;
  align-items: center;
  padding: 30px 0 20px 30px;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
  &:first-child {
    margin-top: -10px;
  }
  &:hover {
    background: #f5f5f5;
  }
  .checkbox-wrap {
    position: absolute;
    left: 0;
    // top: 39px;
  }
  @extend %table;
  .info {
    display: flex;
    align-items: center;
    .cover {
      width: 38px;
      height: 38px;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
      }
      .shangjia {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 62px;
        height: 62px;
        font-size: 12px;
        font-weight: bold;
        line-height: 62px;
        color: white;
        border-radius: 50%;
        background: black;
        text-align: center;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.8);
      }
    }
    .right {
      flex: 1;
      max-width: 410px;
      margin-left: 8px;
      .title {
        width: 118px;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        // min-height: 34px;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(51, 51, 51, 1);
      }
      .price {
        margin-top: 6px;
        white-space: nowrap;
        .p1 {
          font-size: 12px;
          font-weight: 600;
          line-height: 17px;
          margin-right: 15px;
          color: rgba(255, 53, 53, 1);
          i {
            margin-left: 20px;
            color: #ccc;
          }
        }
        .tag {
          width: 16px;
          height: 13px;
          margin-right: 5px;
        }
        .tag0 {
          background: url('../../assets/img/courseAdmin/ico_yhq.png') no-repeat;
        }
        .tag1 {
          background: url('../../assets/img/courseAdmin/ico_pk.png') no-repeat;
        }
        .tag2 {
          background: url('../../assets/img/courseAdmin/ico_liebian.png')
            no-repeat;
        }
        .tag3 {
          background: url('../../assets/img/courseAdmin/ico_dhm.png') no-repeat;
        }
      }
    }
  }
  .type,
  .teacher,
  .count,
  .sigUp {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    color: rgba(51, 51, 51, 1);
  }
  .function {
    margin-right: 30px;
    .btn {
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      color: rgba(10, 162, 155, 1);
    }
    .space {
      width: 1px;
      height: 12px;
      margin: 0 8px;
      border-right: 1px solid rgba(10, 162, 155, 1);
    }
  }
}
.control-box {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px;
  .left-box {
    display: flex;
    align-items: center;
    .setting-erweima {
      margin-right: 20px;
      font-size: 12px;
      color: rgba(10, 162, 155, 1);
      line-height: 16px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    .select-wrap {
      width: 120px;
      margin-left: 30px;
    }
    .input-wrap {
      width: 218px;
    }
    span {
      margin-right: 20px;
      font-size: 14px;
      color: #333;
    }
  }
}
.pagination-wrap {
  padding: 30px 0;
  text-align: center;
}
.reset-txt {
  padding: 10px 20px 0 20px;
  margin-bottom: 70px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
</style>
